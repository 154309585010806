import React from 'react';
import { IonModal, IonHeader, IonToolbar } from '@ionic/react';
import { FC } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import PurchaseStatusInfo from './PurchaseStatusInfo';

interface Props {
  isOpen: boolean;
  setOpen: (b: boolean) => void;
}

const PurchaseStatusModal: FC<Props> = ({ isOpen, setOpen }) => {
  return (
    <IonModal
      style={{ '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }}
      mode={'ios'}
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={() => setOpen(false)}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '24px' }}>
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => setOpen(!isOpen)}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: '0',
            paddingBottom: '36px',
            paddingTop: '16px',
          }}
        >
          <Typography variant="body1" component="h2" color={'#313D53'} textAlign="center" pb={3}>
            Καταστάσεις αγοράς
          </Typography>
          <PurchaseStatusInfo biggerPadding />
        </Box>
      </Box>
    </IonModal>
  );
};

export default PurchaseStatusModal;
