import { pigogoApi } from '../Api';
import { ContactParams } from '../types/ContactParams';

const contactApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    postContact: builder.mutation<ContactParams, { body: ContactParams }>({
      query: ({ body }) => ({
        url: '/support-tickets',
        method: 'POST',
        body: { ...body },
      }),
    }),
  }),
});

export const { usePostContactMutation } = contactApi;
