import React, { FC, ReactNode, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfoModal from './InfoModal';
import { ReactComponent as Infolight } from '../../../assets/svgs/info_light_color.svg';

interface Props {
  title: string;
  value?: string;
  extraField?: string;
  action?: ReactNode;
  color: string;
}

const HeaderCard: FC<Props> = ({ title, value, action, color, extraField }) => {
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [summary, setSummary] = useState<'Σε αναμονή' | 'Προς πληρωμή' | 'Πληρωμένες' | null>(null);

  const handleInfoClick = (title: any) => {
    setSummary(title);
    setInfoModal(true);
  };

  return (
    <Box
      p={2}
      gap={1}
      flex="1 1 0"
      display="flex"
      maxHeight="216px"
      borderRadius="16px"
      flexDirection="column"
      sx={{ backgroundColor: '#F7F7F8' }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color={color} component="h4" variant="subtitle900">
          {title}
        </Typography>
        <Box
          height={'24px'}
          width={'24px'}
          display="inline-flex"
          alignItems="center"
          justifyContent={'center'}
          sx={{ cursor: 'pointer', color: color }}
          onClick={() => handleInfoClick(title)}
        >
          <Infolight />
        </Box>
      </Box>
      <Typography variant="body2" color={'#313D53'}>
        {value}
      </Typography>
      {extraField && (
        <Typography variant="subtitle1SmallR" component="p" color={'#313D53'}>
          {extraField}
        </Typography>
      )}
      <Box>{action && action}</Box>
      <InfoModal isOpen={infoModal} setOpen={setInfoModal} summary={summary} setSummary={setSummary} />
    </Box>
  );
};

export default HeaderCard;
