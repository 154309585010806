import React, { FC, ReactNode } from 'react';
import { Avatar, Box, Container, IconButton, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Edit } from '../../assets/svgs/edit.svg';
import { theme } from 'components';

interface Props {
  title: string;
  index: number;
  subtitle?: string;
  highlighted: boolean;
  handleEdit?: () => void;
  children?: ReactNode;
  currentStep: number;
}

const ClaimStep: FC<Props> = ({ title, index, subtitle, handleEdit, highlighted, children, currentStep }) => {
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ width: '848px', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box
          sx={{ backgroundColor: highlighted ? '#FEF6F8' : '#F7F7F8' }}
          width="100%"
          borderRadius="16px"
          display="flex"
          justifyContent="space-between"
          alignItems={'center'}
          p={2}
        >
          <Box display="flex" alignItems={xsOnly && subtitle ? 'flex-start' : 'center'} gap={3}>
            <Avatar sx={{ backgroundColor: highlighted ? '#E9688A' : '#838B98' }}>{index}</Avatar>
            <Box display="flex" gap={1} flexDirection={smDown ? 'column' : 'row'}>
              <Typography variant={xsOnly ? 'h6' : 'body1'} component="h3" color={'#1D2532'}>
                {title}
              </Typography>
              {subtitle && currentStep !== 0 && (
                <Typography variant="body2MediumR" component="p" color={'#1D2532'}>
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Box>
          {handleEdit && (
            <IconButton sx={{ width: '32px', height: '32px', padding: 0 }} disableRipple onClick={() => handleEdit()}>
              <Edit />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box pt={3}>{children}</Box>
    </Box>
  );
};

export default ClaimStep;
