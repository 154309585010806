import { pigogoApi } from '../Api';
import { Promo } from '../../../models/Promo';
import { GenericRequest } from '../../../models/GenericRequest';

const promosApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    promos: builder.query<GenericRequest<Promo[]>, void>({
      query: () => ({
        url: '/promos',
      }),
      transformResponse(response: Promo[], meta) {
        if (response.length === 0) {
          response.push({
            id: 0,
            name: '',
            starts_at: '',
            ends_at: '',
            active: true,
            created_at: '',
            updated_at: '',
            title: '',
            subtitle: '',
            additional_information: '',
            image: '',
            call_to_action: '',
            label: '',
            url: '',
            is_double: false,
            header_image: '',
            parent_menu_categories: [],
            promo_type_id: 1,
            color: '#FCE5D8',
          });
        }
        return {
          data: response,
          totalCount: response.length,
          nextCursor: undefined,
        };
      },
      providesTags: ['promos'],
    }),
  }),
});

export const { usePromosQuery } = promosApi;
