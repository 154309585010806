import React, { FC, useEffect, useState } from 'react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { Assets, PigogoButton, theme } from 'components';
import moment from 'moment';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: '#fff',
  flexDirection: 'column',
  borderSizing: 'border-box',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '400px',
  },
}));

const ContactCard: FC<unknown> = () => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const openingTime = moment('09:30:00', 'hh:mm:ss');
  const closingTime = moment('17:30:00', 'hh:mm:ss');

  const [currentTime, setCurrentTime] = useState(moment('20:30:00'));
  const [isChatOnline, setIsChatOnline] = React.useState(false);

  useEffect(() => {
    const secTimer = setInterval(() => {
      setCurrentTime(moment());
      if (currentTime.isBetween(openingTime, closingTime)) {
        setIsChatOnline(true);
      } else {
        setIsChatOnline(false);
      }
    }, 1000);

    return () => clearInterval(secTimer);
  }, [currentTime]);

  return (
    <StyleBox>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Assets.Email />
          <Typography variant={'body1'} component="h3" color="#1D2532">
            Email
          </Typography>
        </Box>
        <Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body2MediumL" component="p" color="#313D53">
              Αν προτιμάς, στείλε μας email!
            </Typography>
            <Typography variant="body2MediumL" component="p" color="#313D53">
              Συνήθως απαντάμε εντός λίγων ωρών.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            color="#1D2532"
            variant="body2MediumM"
            component="a"
            href={'mailto:help@pigogo.gr'}
            sx={{
              textDecoration: 'underline',
              textDecorationColor: '#E9688A',
              textUnderlineOffset: '5px',
              textDecorationStyle: { height: '1px' },
            }}
          >
            help@pigogo.gr
          </Typography>
        </Box>
      </Box>

      <Box>
        <div style={{ border: '1px solid #EAECEE' }} />
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Assets.Chat />
          <Typography variant={'body1'} component="h3" color="#1D2532" fontWeight={'900'}>
            Online Chat
          </Typography>
        </Box>
        <Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body2MediumL" component="p" color="#313D53">
              Είμαστε διαθέσιμοι να μιλήσουμε στο chat
            </Typography>
            <Typography variant="body2MediumL" component="p" color="#313D53">
              Δευτέρα - Παρασκευή 09:30 - 17:30
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <div style={{ border: '1px solid #EAECEE' }} />
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Assets.Pin />
          <Typography variant={'body1'} component="h3" color="#1D2532" fontWeight={'900'}>
            Διεύθυνση
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2MediumL" component="address" fontStyle={'normal'} color="#313D53">
            Τα γραφεία μας βρίσκονται Αβέρωφ 24, Νέα Ιωνία, 142 32
          </Typography>
        </Box>
      </Box>
    </StyleBox>
  );
};

export default ContactCard;
