import { ReactComponent as Facebook } from '../assets/svgs/social/facebook.svg';
import { ReactComponent as Twitter } from '../assets/svgs/social/twitter.svg';
import { ReactComponent as Instagram } from '../assets/svgs/social/instagram.svg';
import { ReactComponent as Youtube } from '../assets/svgs/social/youTube.svg';

const SocialLinks = () => {
  const twitter = 'https://twitter.com/pigogogr';
  const facebook = 'https://www.facebook.com/Pigogo.gr/';
  const instagram = 'https://www.instagram.com/pigogo_gr/';
  const youtube = 'https://www.youtube.com/channel/UCnNxbMxtfTdteZXHOkdmnWg';

  return (
    <ul className="noStyleList socialList" style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
      <li className="noStyleList__item">
        <a className="socialLink" href={facebook} target="_blank">
          <Facebook style={{ cursor: 'pointer' }} />
        </a>
      </li>
      <li className="noStyleList__item">
        <a className="socialLink" href={instagram} target="_blank">
          <Instagram style={{ cursor: 'pointer' }} />
        </a>
      </li>
      <li className="noStyleList__item">
        <a className="socialLink" href={twitter} target="_blank">
          <Twitter style={{ cursor: 'pointer' }} />
        </a>
      </li>
      <li className="noStyleList__item">
        <a className="socialLink" href={youtube} target="_blank">
          <Youtube style={{ cursor: 'pointer' }} />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
