import React, { FC, useState } from 'react';
import { Box, Skeleton, styled, Typography, useMediaQuery } from '@mui/material';
import PigogoChip from './PigogoChip';
import { Copy, PigogoSymbol } from '../assets';
import PigogoDiscount from './PigogoDiscount';
import theme from '../theme';
import moment from 'moment';
import { Link } from 'react-router-dom';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '332px',
  //width: '167.5px',
  width: '100%',
  cursor: 'pointer',
  borderRadius: '16px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 16,
  backgroundColor: '#FDF0F3',
  transition: 'background-color .3s ease-out',
  position: 'relative',
  gap: '16px',
  [theme.breakpoints.up('md')]: {
    padding: 24,
    height: '392px',
    borderRadius: '24px',
    gap: '28px',
  },
  '&:hover': {
    backgroundColor: '#FBE1E8',
  },
}));

const StyleImgBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    height: 60,
    width: 120,
  },
  [theme.breakpoints.up('sm')]: {
    height: 64,
    width: 128,
  },
}));

const StyleSymbol = styled(Box)(({ theme }) => ({
  height: '64px',
  left: 0,
  textAlign: 'start',
  position: 'absolute',
  bottom: 0,
}));

const StyleBoxDiscount = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '4px',
  },
  [theme.breakpoints.up('md')]: {
    gap: '8px',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '4px',
    flexWrap: 'wrap',
  },
}));

interface Props {
  loading?: boolean;
  logo?: string;
  name?: string;
  subTitle?: string;
  date?: string;
  discount?: string;
  deleteDisc?: string;
  code?: string;
  logout?: boolean;
  cashbackCount: number;
  onClick?: () => void;
  onClickCopy?: () => void;
  setLogin?: () => void;
  isPreloginHome?: boolean;
  storeSlug?: string;
  id?: string;
}
const PigogoSingleCouponCard: FC<Props> = ({
  loading,
  logout,
  logo,
  name,
  discount,
  deleteDisc,
  cashbackCount,
  code,
  onClick,
  onClickCopy,
  setLogin,
  date,
  subTitle,
  storeSlug,
  id,
}) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [codeView, setCodeView] = useState<boolean>(false);

  const handleClickCoupon = (event: any) => {
    onClick && onClick();
  };

  const handleCopyCoupon = (event: any) => {
    event.stopPropagation();
    if (code) {
      navigator.clipboard.writeText(code);

      onClickCopy && onClickCopy();
    }
  };

  return (
    <StyleBox className="couponCard" onClick={() => (onClick ? onClick() : undefined)}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'12px'}
        flexGrow={1}
        justifyContent={'space-between'}
        sx={{ position: 'relative', zIndex: 1, maxHeight: 'calc(100% - 64px)' }}
      >
        <Box display={'flex'} flexDirection={'column'} gap={matches ? '16px' : '20px'}>
          <StyleImgBox>
            {loading ? (
              <Skeleton
                variant="rectangular"
                animation={'wave'}
                style={{
                  background: 'rgb(255 255 255 / 46%)',
                  borderRadius: 16,
                  height: '100%',
                }}
              />
            ) : (
              <img
                alt={'logo'}
                src={logo}
                style={{
                  borderRadius: 8,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  backgroundColor: '#ffffff',
                }}
              />
            )}
          </StyleImgBox>
          <Box display={'flex'} flexDirection={'column'} gap={smDown ? 0.5 : 1} height={matches ? 'auto' : '155px'}>
            <Typography variant={matches ? 'subtitle1SmallL' : 'largeXL'} textAlign={'start'} color={'#313D53'}>
              {loading ? (
                <Skeleton
                  animation={'wave'}
                  style={{ background: 'rgb(255 255 255 / 46%)', borderRadius: '16px' }}
                  width={'50%'}
                />
              ) : (
                name
              )}
            </Typography>
            <Typography
              variant={matches ? 'subtitle1SmallM' : 'body2MediumM'}
              textAlign={'start'}
              component="p"
              sx={{
                color: '#313D53',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '4',
                display: '-webkit-box',
                overflow: 'hidden',
              }}
            >
              {loading ? (
                <Skeleton animation={'wave'} style={{ background: 'rgb(255 255 255 / 46%)', borderRadius: '16px' }} />
              ) : (
                subTitle
              )}
            </Typography>
            <Typography variant={matches ? 'caption' : 'subtitle1SmallR'} textAlign={'start'} color={'#313D53'}>
              {loading ? (
                <Skeleton
                  animation={'wave'}
                  style={{ background: 'rgb(255 255 255 / 46%)', borderRadius: '16px' }}
                  width={'40%'}
                />
              ) : date ? (
                `έως ${moment(date).format('DD/MM/YYYY')}`
              ) : null}
            </Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={matches ? '16px' : '20px'}
          style={{
            position: 'relative',
            height: matches ? '40px' : '48px ',
            padding: matches ? '12px 16px' : '14px 24px',
            background: 'unset',
            borderRadius: '48px',
            border: '1px dashed #313D53',
            fontSize: matches ? '12px' : '14px',
            color: '#313D53',
            fontWeight: '500',
            lineHeight: '1.3',
            textAlign: 'center',
          }}
        >
          Δες τον κωδικό
        </Box>
      </Box>
      <StyleSymbol>{<PigogoSymbol />}</StyleSymbol>
      <StyleBoxDiscount>
        {deleteDisc && <PigogoDiscount discount={deleteDisc} small />}
        <Typography
          variant={matches ? 'captionSB' : 'subtitle1'}
          component="p"
          textAlign={'start'}
          style={{ color: ' #E9688A' }}
        >
          {!loading && cashbackCount >= 2 && `έως ${' ' + discount} επιστροφή`}
          {!loading && cashbackCount == 1 && `${discount} επιστροφή`}
        </Typography>
      </StyleBoxDiscount>
    </StyleBox>
  );
};

export default PigogoSingleCouponCard;
