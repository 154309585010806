import { Box, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';
import { ReactComponent as ChevronRight } from '../../../assets/svgs/chevronRight.svg';
import { Claim } from '../../../models/Claims';
import ExtractClaimStatus from '../../ExtractClaimStatus';
import ExtractClaimType from '../../ExtractClaimType';

interface Props {
  row?: Claim;
  isLoading?: boolean;
  onRowClick?: (claim: Claim) => void;
}

const ClaimsMobileRow: FC<Props> = ({ row, isLoading, onRowClick }) => {
  return (
    <TableRow
      hover
      onClick={row && onRowClick ? () => onRowClick(row) : undefined}
      sx={{
        height: '72px',
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }}
    >
      <TableCell sx={{ padding: '24px 0', verticalAlign: 'top' }}>
        <Box>
          <Typography variant="body2" color={'#313D53'}>
            {isLoading ? <Skeleton sx={{ width: '90%' }} /> : row?.shop_name}
          </Typography>

          <Typography variant="subtitle1SmallR" color={'#6F7787'} component="p" mb={0.5}>
            {isLoading ? <Skeleton sx={{ width: '90%' }} /> : moment(row?.created_at).format('DD/MM/YYYY')}
          </Typography>
          <Typography>
            {isLoading ? (
              <Skeleton sx={{ width: '90%' }} />
            ) : (
              <ExtractClaimType claim={row?.claim_type_id} variant={'subtitle1SmallR'} color={'#6F7787'} />
            )}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="right" sx={{ padding: '24px 0', verticalAlign: 'top' }}>
        <Box>
          <Typography variant="body2" color={'#313D53'}>
            {isLoading ? <Skeleton sx={{ width: '90%' }} /> : row?.order_amount}
          </Typography>
          {isLoading ? (
            <Skeleton sx={{ width: '90%' }} />
          ) : (
            <ExtractClaimStatus claim={row?.claim_status_id} variant={'subtitle1SmallR'} />
          )}
        </Box>
      </TableCell>
      {!isLoading && (
        <TableCell sx={{ padding: 0, width: '24px', textAlign: 'right', verticalAlign: 'top', paddingBlock: '24px' }}>
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <ChevronRight />
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ClaimsMobileRow;
