import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';

import React, { ReactNode } from 'react';
import { FC } from 'react';

interface Props {
  icon?: ReactNode;
  title?: string;
  subtitle: string;
  background?: string;
  fontFamily?: any;
}

const WinCard: FC<Props> = ({ icon, title, subtitle, background = '#EAECEE', fontFamily }) => {
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box display={'flex'} flexDirection={'column'} gap={3}>
      <Paper elevation={0} sx={{ background: background, borderRadius: '24px', height: '232px' }}>
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          {icon}
        </Box>
      </Paper>
      <Box display="flex" flexDirection="column" gap={2}>
        {title && (
          <Typography variant="h4" component="h3" textAlign={'left'} color={'#313D53'}>
            {title}
          </Typography>
        )}
        <Typography
          variant={fontFamily ? fontFamily : 'subtitle1SmallR'}
          component="h4"
          textAlign={'left'}
          color={'#313D53'}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default WinCard;
