import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import { Link } from 'react-router-dom';

const NoResults = () => {
  const router = useIonRouter();

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2" color={'#313D53'}>
        Δεν βρέθηκαν αποτελέσματα
      </Typography>
      <Box pt={2} pb={2}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="subtitle1" color={'#313D53'}>
            Ψάχνεις κάποιο κατάστημα και δεν το βρίσκεις;
          </Typography>
          <Typography variant="subtitle1SmallR" component="p" color={'#313D53'}>
            Στείλε μας το κατάστημα και θα το προσθέσουμε στη λίστα με τα συνεργαζόμενα καταστήματα
          </Typography>
        </Box>
        <Box>
          <Link className="link" to={'/contact'}>
            Στείλε μας μήνυμα!
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default NoResults;
