import { Popover, PopoverProps, styled } from '@mui/material';
import { theme } from 'components';
import React from 'react';

const SearchPopover = styled((props: PopoverProps & { islinksearch: string }) => (
  <Popover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    disableAutoFocus
    sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}
    {...props}
  />
))((props) => ({
  '& .MuiBackdrop-root': {
    background: '#1D2532',
    opacity: '0.64 !important',
  },
  '& .MuiPaper-root': {
    width: '538px',
    display: 'flex',
    [theme.breakpoints.up('extraLg')]: {
      width: '668px',
    },
    height: 'auto',
    overflow: 'hidden',
    boxShadow: 'none',
    minHeight: '221px',
    maxHeight: props.islinksearch === 'true' ? 'auto' : '480px',
    boxSizing: 'border-box',
    borderTop: '1px solid #EAECEE',
    padding: '32px 16px 32px 32px',
    borderRadius: '0px 0px 16px 16px',
  },
}));

export default SearchPopover;
