import { Box, BoxProps } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from 'components';
import { FC } from 'react';

const CustomContainer: FC<BoxProps> = ({ children, ...props }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const calcWidth = () => {
    if (lgDown) return '100%';
    if (lgUp) return '1344px';
  };

  const calcPadding = () => {
    if (smDown) return '16px';
    return '32px';
  };

  return (
    <Box
      zIndex={2}
      margin={'auto'}
      position={'relative'}
      maxWidth={calcWidth()}
      boxSizing={'border-box'}
      paddingX={calcPadding()}
      {...props}
    >
      {children}
    </Box>
  );
};

export default CustomContainer;
