import { ButtonProps, styled, SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { FC } from 'react';
import * as React from 'react';
import { CSSProperties } from '@mui/styles';

const ButtonStyled = styled(LoadingButton)<LoadingButtonProps & { simplebutton?: string }>((props) => ({
  color: 'var(--text)',
  borderRadius: props.simplebutton === 'true' ? '32px' : '0px 32px 32px 32px',
  background: 'var(--background)',
  padding: '16px',
  boxShadow: 'none',
  variant: 'contained',
  boxSizing: 'border-box',
  height: '56px',
  transition: 'background .35s ease',
  appearance: 'none',
  textDecoration: 'none',
  '&:hover': {
    boxShadow: 'none',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), var(--hover)`,
  },
  '&:active': {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), var(--focus)`,
  },
  '&:focus': {
    outline: 0,
    background: 'var(--focus)',
  },
  '&:focus-visible': {
    background: 'var(--focus)',
    outline: '2px dashed var(--focus)',
    outlineOffset: '2px',
  },
  '&.MuiButton--innerFocus:focus': {
    background: 'var(--focus)',
    boxShadow: 'none',
    outline: '4px solid rgba(60, 104, 200, 0.5)',
    outlineOffset: '-4px',
  },
  '&:disabled': {
    background: 'var(--disabled)',
  },
  '&.MuiButton-outlined': {
    color: 'var(--background)',
    border: `1px solid var(--background)`,
    background: 'none',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
    '&:active': {
      background: 'rgba(0, 0, 0, 0.12)',
    },
    '&:focus': {
      boxShadow: '0 0 0 3px rgba(60, 104, 200, 0.5)',
      border: `1px solid var(--background)`,
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.12)',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  '& .MuiButton-startIcon': {
    '&:disabled': {
      backgroundColor: 'red',
    },
  },
}));

const primaryVars: CSSProperties = {
  '--text': '#fff',
  '--focus': '#2C374B',
  '--hover': '#1D2532',
  '--background': '#313D53',
  '--disabled': 'rgba(0, 0, 0, 0.12)',
};

const secondaryVars: CSSProperties = {
  '--text': '#fff',
  '--focus': '#E9688A',
  '--hover': '#E9688A',
  '--background': '#E9688A',
  '--disabled': 'rgba(0, 0, 0, 0.12)',
};

const whiteVars: CSSProperties = {
  '--focus': '#fff',
  '--hover': '#fff',
  '--text': '#E9688A',
  '--background': '#fff',
  '--disabled': 'rgba(255, 255, 255, 0.12)',
};

interface Props {
  text: string;
  loading?: boolean;
  simpleButton?: boolean;
  typographyProps?: TypographyProps;
  variation?: 'primary' | 'secondary' | 'white';
}

const PigogoButton: FC<Props & Omit<ButtonProps, 'color'>> = ({
  text,
  loading,
  variation,
  simpleButton,
  typographyProps,
  ...rest
}) => {
  const getVar = () => {
    if (rest.variant === 'outlined') {
      if (variation === 'white') {
        return whiteVars;
      } else {
        return primaryVars;
      }
    } else {
      if (variation === 'secondary') {
        return secondaryVars;
      } else if (variation === 'white') {
        return whiteVars;
      } else {
        return primaryVars;
      }
    }
  };

  return (
    <ButtonStyled {...rest} style={getVar()} loading={loading} simplebutton={simpleButton ? 'true' : 'false'}>
      <Typography {...typographyProps} variant="button" sx={{ textDecoration: 'none' }}>
        {text}
      </Typography>
    </ButtonStyled>
  );
};

export default PigogoButton;
