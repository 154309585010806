import React from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { ReactComponent as PaymentDay } from '../../../assets/svgs/illustrations/payment_day.svg';
import { FC } from 'react';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { theme } from 'components';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const PaymentModal: FC<Props> = ({ open, handleClose }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <IonModal
      mode={'ios'}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => handleClose()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '12px',
            height: '24px',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: smDown ? '0' : '16px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} height={'100%'}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
              <Box>
                <PaymentDay height={mdDown ? '220px' : '280px'} width={mdDown ? '220px' : '280px'} />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={0.5}>
                <Typography variant={mdDown ? 'largeRegular' : 'smallR'} component="h2" color={'#313D53'}>
                  Ημέρα πληρωμών
                </Typography>

                <Typography
                  variant={mdDown ? 'subtitle1SmallR' : 'body2MediumR'}
                  component="p"
                  textAlign={'center'}
                  color={'#313D53'}
                >
                  Σε ενημερώνουμε ότι σήμερα είναι ημέρα πληρωμών στο Pigogo. Μπορείς να αλλάξεις τα στοιχεία πληρωμής
                  σου τώρα, αλλά τα νέα στοιχεία θα χρησιμοποιηθούν από τις επόμενες προγραμματισμένες πληρωμές μας.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default PaymentModal;
