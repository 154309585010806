import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import { ReactComponent as SearchIllustration } from '../../assets/svgs/illustrations/search.svg';
import { ReactComponent as Buy } from '../../assets/svgs/illustrations/buy.svg';
import { ReactComponent as Cashback } from '../../assets/svgs/illustrations/cashback.svg';
import WinCard from './WinCard';
import CustomContainer from '../CustomContainer';
import { useIonRouter } from '@ionic/react';
import { Link } from 'react-router-dom';

const WinSection = () => {
  const router = useIonRouter();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('extraLg'));

  return (
    <CustomContainer
      gap={6}
      display={'flex'}
      pt={mdDown ? 4 : 10}
      pb={mdDown ? 4 : 10}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Box display="flex" flexDirection="column" justifyContent={'center'} alignItems="center" gap={2}>
        <Typography textAlign="center" color="#1D2532" variant="smallB" component="h2">
          Πώς λειτουργεί
        </Typography>
        <Box display="flex" alignItems="center" maxWidth={smDown ? '327px' : lgDown ? '672px' : '800px'}>
          <Typography textAlign="center" color="primary" variant="body2MediumL" component="p">
            Κάθε φορά που κάνεις μία αγορά σε ένα από τα 400+ καταστήματα που συνεργαζόμαστε, κερδίζεις επιστροφή
            χρημάτων στον λογαριασμό σου στο Pigogo.
          </Typography>
        </Box>
      </Box>

      <Box display="flex" gap={4} justifyContent="center" flexWrap={mdDown ? 'wrap' : 'nowrap'}>
        <Box width={mdDown ? '100%' : 'min(calc(33.33% - 21.33px),320px)'}>
          <WinCard
            icon={<SearchIllustration />}
            title="Βρες το κατάστημα που θες στο Pigogο"
            subtitle="συνεργαζόμαστε με 400+ καταστήματα"
          />
        </Box>

        <Box width={mdDown ? '100%' : '320px'}>
          <WinCard
            background={'#FEF6F1'}
            icon={<Buy />}
            title="Ολοκλήρωσε την αγορά σου στο κατάστημα"
            subtitle="όπως κάνεις κάθε φορά"
          />
        </Box>

        <Box width={mdDown ? '100%' : '320px'}>
          <WinCard
            background={'#FDF0F3'}
            icon={<Cashback />}
            title="Κέρδισε επιστροφή χρημάτων"
            subtitle="στον Pigogo λογαριασμό σου"
          />
        </Box>
      </Box>

      <Box width={mdDown ? '100%' : '327px'}>
        <Link className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth" to={'/how-it-works'}>
          Δες περισσότερα
        </Link>
      </Box>
    </CustomContainer>
  );
};

export default WinSection;
