import React from 'react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import Gourouni from '../../assets/jpg/tips_hero_banner_desktop.jpg';
import GourouniMob from '../../assets/jpg/tips_hero_banner_mobile.jpg';
import { theme } from 'components';
import { ReactComponent as SymbolPink } from '../../assets/svgs/illustrations/favourites/symbolPink3.svg';

const StyleBoxContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    height: '444px',
    flexDirection: 'row',
  },
}));

const StyleBoxImage = styled(Box)(({ theme }) => ({
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  borderSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '-32px',
    width: 'calc(100% + 64px)',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    width: 'calc(100% + 32px)',
  },
  [theme.breakpoints.up('md')]: {
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    width: '581.5px',
    position: 'absolute',
  },
  [theme.breakpoints.up('lg')]: {
    width: '667px',
  },
}));

const StyleBoxContent = styled(Box)(({ theme }) => ({
  zIndex: 200,
  gap: '24px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    width: '360px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '493.5px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '592px',
  },
}));

const TipsSection = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const chooseImage = () => {
    if (smDown) {
      return GourouniMob;
    } else {
      return Gourouni;
    }
  };

  const calcWidth = () => {
    if (lgDown) return '100%';
    if (lgUp) return '1344px';
  };

  const calcPadding = () => {
    if (smDown) return '16px';
    return '32px';
  };

  return (
    <StyleBoxContainer sx={{ borderBottom: '2px solid #FDF0F3' }}>
      <Box
        zIndex={2}
        margin={'auto'}
        position={'relative'}
        maxWidth={calcWidth()}
        boxSizing={'border-box'}
        paddingX={calcPadding()}
        sx={{ display: 'flex', alignItems: 'center', flexDirection: mdDown ? 'column' : 'row', height: '100%' }}
      >
        <StyleBoxContent pt={mdDown ? '40px' : '0'} pb={mdDown ? '20px' : '0'}>
          <Typography variant={'h3'} component="h2" color={'#1D2532'}>
            Tips για την επιστροφή
          </Typography>
          <Typography variant={'bodyLargeRMediumL'} component="p" color={'#1D2532'}>
            Δες 5 tips που θα σε βοηθήσουν να κερδίζεις επιστροφή χρημάτων από κάθε αγορά σου!
          </Typography>
        </StyleBoxContent>
        <Box zIndex={100} position="absolute" top="0px" right="0px">
          <SymbolPink />
        </Box>
        <StyleBoxImage>
          <img
            alt={'content-image'}
            src={chooseImage()}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </StyleBoxImage>
      </Box>
    </StyleBoxContainer>
  );
};

export default TipsSection;
