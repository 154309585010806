import React, { FC, useRef } from 'react';
import { isPlatform } from '@ionic/react';
import Header from './Header/Header';
import { Box, Portal, useMediaQuery } from '@mui/material';
import { PigogoSnackbar, theme } from 'components';
import { useLocation } from 'react-router-dom';
import Loading from '../Loading';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { setStateSnackBar } from '../../redux/slices/layoutSlice';

interface Props {
  permitShowLogo?: boolean;
  setResetFilters?: (value: boolean) => void;
  setSearchKeyword?: (value: string) => void;
  searchKeyword?: string;
  children: JSX.Element | JSX.Element[];
}

const Layout: FC<Props> = ({ permitShowLogo = false, setResetFilters, setSearchKeyword, searchKeyword, children }) => {
  const dispatch = useDispatch();

  const tablet = isPlatform('tablet');

  const location = useLocation();

  const home = location.pathname === '/';

  const childRef = useRef(null);

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const { loading, error } = useAppSelector((state) => state.layout.meta);
  const { messageSnackbar, severity } = useAppSelector((state) => state.layout.data);

  const showPigogoLogo = smDown && home && permitShowLogo;

  return (
    <Box>
      <Loading open={loading} />
      <Portal>
        <PigogoSnackbar
          isOpen={error}
          severity={severity}
          message={messageSnackbar}
          setSnackbar={(value) => dispatch(setStateSnackBar(value))}
        />
      </Portal>
      {(smUp || showPigogoLogo || tablet) && !(location.pathname === '/intro') && (
        <Header
          contentRef={childRef.current}
          setResetFilters={setResetFilters}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
        />
      )}
      <Box ref={childRef}>{children}</Box>
    </Box>
  );
};

export default Layout;
