import { useIonRouter } from '@ionic/react';
import { Box, IconButton, styled, Typography, useMediaQuery } from '@mui/material';
import { PigogoLink, PigogoSwitch, theme } from 'components';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import React, { FC } from 'react';
import { Favourite } from '../../models/Favourite';
import { Link } from 'react-router-dom';

const StyleImgHorizontalBox = styled(Box)(() => ({
  position: 'relative',
  height: 40,
  width: 80,
}));

type FavouritesRowProps = {
  index: number;
  removed?: boolean;
  favouriteShop: Favourite;
  revertRemove: (shopId: number, notifications: number) => void;
  handleRemove: (favouriteShop: Favourite) => void;
  changeNotifications: (favouriteShop: Favourite, index: number) => void;
};

const FavouritesRow: FC<FavouritesRowProps> = ({
  index,
  favouriteShop,
  removed,
  handleRemove,
  revertRemove,
  changeNotifications,
}) => {
  const router = useIonRouter();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { shop = { id: 0, name: 'N/A', image: 'N/A', slug: { name: 'N/A' } }, notifications = 0 } = favouriteShop
    ? favouriteShop
    : {};

  const renderRemoved = () => {
    return (
      <Box
        key={index}
        style={{
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid #D6D8DD',
          width: '100%',
          justifyContent: mdDown ? 'space-between' : 'flex-start',
          paddingBlock: 16,
          background: '#FEF6F1',
          minHeight: '72px',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            width: mdDown ? '41%' : '60.78%',
            paddingInline: mdDown ? 2 : '120px 24px',
            textAlign: 'left',
            flexGrow: mdDown ? 1 : 0,
          }}
        >
          <Typography variant={'subtitle1SmallM'} component="p" role="alert" color={'#313D53'}>
            Μόλις διέγραψες το κατάστημα από τη λίστα αγαπημένων.
          </Typography>
        </Box>
        <Box sx={{ width: mdDown ? '0' : '32.44%', paddingInline: mdDown ? 0 : 3 }} />
        <Box sx={{ width: mdDown ? '26.54%' : '19.60%', textAlign: 'center', paddingRight: mdDown ? 0 : '28px' }}>
          <PigogoLink
            typoProps={{ variant: 'captionM' }}
            color={'#3C68C8'}
            text={'Αναίρεση'}
            handleClick={() => revertRemove(shop.id, notifications as number)}
          />
        </Box>
      </Box>
    );
  };

  const renderNormal = () => {
    return (
      <Box
        key={index}
        style={{
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid #D6D8DD',
          width: '100%',
          justifyContent: mdDown ? 'space-evenly' : 'flex-start',
          padding: mdDown ? '10px 0' : '16px 0',
          boxSizing: 'border-box',
        }}
      >
        <Box sx={{ width: mdDown ? '41%' : '60.78%', paddingInline: mdDown ? 2 : 3 }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 16,
              cursor: 'pointer',
              position: 'relative',
            }}
          >
            <Link className="absLink" to={`/${shop.slug.name}`}></Link>
            {!mdDown && (
              <Box sx={{ width: '80px' }}>
                <StyleImgHorizontalBox>
                  <img alt={'logo'} src={shop.image} style={{ borderRadius: 8, width: '100%', height: '100%' }} />
                </StyleImgHorizontalBox>
              </Box>
            )}
            <Box style={{ textAlign: 'start' }}>
              <Typography variant={mdDown ? 'subtitle1SmallM' : 'body2'} color={'#313D53'} component="h4">
                {shop.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: mdDown ? '32.44%' : '19.60%',
            paddingInline: mdDown ? 2 : 3,
            textAlign: mdDown ? 'center' : 'left',
          }}
        >
          <Box
            style={{ display: 'flex', justifyContent: mdDown ? 'center' : 'flex-start', alignItems: 'center', gap: 16 }}
          >
            <PigogoSwitch
              inputProps={{ color: 'secondary' }}
              value={!!notifications}
              setValue={() => changeNotifications(favouriteShop, index)}
            />
            {!mdDown && (
              <Typography variant={'captionM'} color={'#313D53'}>
                {notifications ? 'ενεργές' : 'ανενεργές'}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ width: mdDown ? '26.54%' : '19.60%' }} style={{ textAlign: 'center' }}>
          <IconButton onClick={() => handleRemove(favouriteShop)}>
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return <>{removed ? renderRemoved() : renderNormal()}</>;
};
export default FavouritesRow;
