import { FC } from 'react';
import { Typography } from '@mui/material';
import { ClaimStatusId } from '../models/ClaimStatusId';

interface Props {
  claim?: ClaimStatusId;
  variant: 'subtitle1SmallR' | 'caption';
}

const ExtractClaimStatus: FC<Props> = ({ claim, variant }) => {
  switch (claim) {
    case 1:
      return (
        <Typography variant={variant} component="p" color="#6F7787">
          Σε επεξεργασία
        </Typography>
      );
    case 2:
      return (
        <Typography variant={variant} component="p" color="#3CA377">
          Εγκρίθηκε
        </Typography>
      );
    case 3:
      return (
        <Typography variant={variant} component="p" color="#BC415A">
          Δεν εγκρίθηκε
        </Typography>
      );
    default:
      return null;
  }
};

export default ExtractClaimStatus;
