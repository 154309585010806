import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import stepOne from '../../../assets/stepOne.png';
import stepOneMobile from '../../../assets/stepOne_mobile.png';
import stepTwo from '../../../assets/stepTwo.png';
import stepTwoMobile from '../../../assets/stepTwo_mobile.png';
import stepThree from '../../../assets/stepThree.png';
import stepThreeMobile from '../../../assets/stepThree_mobile.png';
import { theme } from 'components';

export const StepOne = () => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      gap={smDown ? 2 : 4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={smDown ? 0 : '52px'}
    >
      <Box mt={2} width={smDown ? '100%' : 'inherit'}>
        <img src={smDown ? stepOneMobile : stepOne} width={smDown ? '100%' : 'inherit'} />
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={0.5}
        sx={{ paddingInline: smDown ? 4 : 0 }}
      >
        <Typography variant={'body2MediumR'} color={'#313D53'} component="h3">
          Βήμα 1
        </Typography>
        <Typography
          textAlign="center"
          variant="smallR"
          color={'#313D53'}
          component="h4"
          sx={{ lineHeight: '1.2', fontSize: smDown ? '20px' : '24px' }}
        >
          Βρες το κατάστημα στο Pigogo και κάνε κλικ στην επιλογή «Μεταφορά στο κατάστημα»
        </Typography>
      </Box>
    </Box>
  );
};

export const StepTwo = () => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      gap={smDown ? 2 : 4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={smDown ? 0 : '52px'}
    >
      <Box mt={2} width={smDown ? '100%' : 'inherit'}>
        <img src={smDown ? stepTwoMobile : stepTwo} width={smDown ? '100%' : 'inherit'} />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={0.5}
        sx={{ paddingInline: smDown ? 4 : 0 }}
      >
        <Typography variant={'body2MediumR'} color={'#313D53'} component="h3">
          Βήμα 2
        </Typography>
        <Typography
          textAlign="center"
          variant="smallR"
          color={'#313D53'}
          component="h4"
          sx={{ lineHeight: '1.2', fontSize: smDown ? '20px' : '24px' }}
        >
          Κάνε την αγορά σου από το κατάστημα (πλήρωσε με όποιον τρόπο θέλεις)
        </Typography>
      </Box>
    </Box>
  );
};

export const StepThree = () => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      gap={smDown ? 2 : 4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={smDown ? 0 : '52px'}
    >
      <Box mt={2} width={smDown ? '100%' : 'inherit'}>
        <img src={smDown ? stepThreeMobile : stepThree} width={smDown ? '100%' : 'inherit'} />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={0.5}
        sx={{ paddingInline: smDown ? 4 : 0 }}
      >
        <Typography variant={'body2MediumR'} color={'#313D53'} component="h3">
          Βήμα 3
        </Typography>
        <Typography
          textAlign="center"
          variant="smallR"
          color={'#313D53'}
          component="h4"
          sx={{ lineHeight: '1.2', fontSize: smDown ? '20px' : '24px' }}
        >
          Συγχαρητήρια, η αγορά σου καταγράφηκε στο Pigogo
        </Typography>
      </Box>
    </Box>
  );
};
