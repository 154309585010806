import { Box, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';
import { Payment } from '../../../models/Payment';
import ExtractPaymentMethod from '../../ExtractPaymentMethod';

interface Props {
  row?: Payment;
  isLoading?: boolean;
}

const PaymentsMobileRow: FC<Props> = ({ row, isLoading }) => {
  return (
    <TableRow
      hover
      sx={{
        height: '72px',
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }}
    >
      <TableCell sx={{ paddingLeft: 0 }}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body2MediumR" component="p" color={'#313D53'}>
            {isLoading ? <Skeleton /> : row?.payment_account}
          </Typography>
          <Typography variant="subtitle1SmallR" component="p" color={'#6F7787'}>
            {isLoading ? <Skeleton /> : moment(row?.date).format('DD/MM/YYYY')}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ paddingRight: 0 }}>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Typography variant="body2" component="p" color={'#313D53'}>
            {isLoading ? <Skeleton sx={{ px: 3 }} /> : row?.amount}
          </Typography>
          {isLoading ? (
            <Skeleton sx={{ px: 5 }} />
          ) : (
            <ExtractPaymentMethod saver={row?.payment_method_id} mobile={true} />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default PaymentsMobileRow;
