import { Box, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { PigogoCheckbox } from 'components';
import { FC } from 'react';
import { CreateClaimStepTwo } from '../../../models/CreateClaimStepTwo';
import { Purchase } from '../../../models/Purchase';
import { ReactComponent as Unchecked } from '../../../assets/svgs/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svgs/checked.svg';
import { ChevronRight } from '@mui/icons-material';
import moment from 'moment';
import ExtractPurchaseStatus from '../../ExtractPurchasesStatus';

interface Props {
  row?: Purchase;
  loading?: boolean;
  stepTwoState?: CreateClaimStepTwo;
  setStepTwoState?: (stepTwoState: CreateClaimStepTwo) => void;
}

const StepTwoMobileRow: FC<Props> = ({ row, loading, stepTwoState, setStepTwoState }) => {
  return (
    <TableRow
      onClick={
        row && stepTwoState && setStepTwoState
          ? () => setStepTwoState({ ...stepTwoState, purchase_id: row?.purchase_id.toString() })
          : undefined
      }
      hover
      sx={{
        height: '72px',
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }}
    >
      <TableCell>
        <PigogoCheckbox
          inputProps={{
            disableRipple: true,
            color: 'secondary',
            icon: <Unchecked />,
            checkedIcon: <CheckedIcon />,
          }}
          checked={loading ? false : stepTwoState?.purchase_id === row?.purchase_id.toString()}
          setValue={
            row && stepTwoState && setStepTwoState
              ? () => setStepTwoState({ ...stepTwoState, purchase_id: row?.purchase_id.toString() })
              : undefined
          }
        />
      </TableCell>
      <TableCell>
        <Box>
          <Typography variant="body2" fontWeight={700} lineHeight="24px">
            {loading ? <Skeleton /> : row?.amount}
          </Typography>
          <Typography variant="subtitle1SmallR" fontWeight={400} lineHeight="24px">
            {moment(row?.date).format('DD/MM/YYYY')}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box>
          <Typography variant="body2" fontWeight={500} lineHeight="24px">
            {loading ? <Skeleton /> : row?.cashback_amount}
          </Typography>

          {loading ? <Skeleton /> : <ExtractPurchaseStatus saver={row?.purchase_status_id || 0} />}
        </Box>
      </TableCell>
      <TableCell padding="none">
        <ChevronRight />
      </TableCell>
    </TableRow>
  );
};

export default StepTwoMobileRow;
