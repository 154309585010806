import { Box, styled } from '@mui/material';
import CustomContainer from '../CustomContainer';
import { FC, ReactNode } from 'react';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderSizing: 'border-box',
  paddingTop: theme.spacing(13),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(8),
  },
}));

interface Props {
  children: ReactNode;
}

const BasicContentBody: FC<Props> = ({ children }) => {
  return (
    <CustomContainer>
      <StyleBox>{children}</StyleBox>
    </CustomContainer>
  );
};

export default BasicContentBody;
