import React, { useRef } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import TopBanner from '../components/CreateClaim/TopBanner';
import ClaimStepContainer from '../components/CreateClaim/ClaimStepContainer';
import AlertSection from '../components/Home/AlertSection';
import PigogoFooter from '../components/PigogoFooter';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import Layout from '../components/Navigation/Layout';
import backArrow from '../assets/svgs/back_arrow.svg';
import { Helmet } from 'react-helmet-async';

const CreateClaim = () => {
  const desktop = isPlatform('desktop');

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    contentRef.current && contentRef.current.scrollToTop();
  });

  return (
    <IonPage>
      {tablet && (
        <IonHeader class="ion-no-border">
          <IonToolbar
            style={{
              '--background': '#FFFFFF',
              '--border-width': 0,
              paddingTop: '0 + --ion-safe-area-top',
            }}
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
              <IonButtons slot="start">
                <IonBackButton
                  className="backBtn"
                  text=""
                  style={{
                    '--color': '#313D53',
                    '--padding-start': 0,
                    '--padding-end': 0,
                    '--icon-font-size': '24px',
                    '--border-radius': 0,
                    '--min-height': '24px',
                    '--min-width': '24px',
                    width: '24px',
                    height: '24px',
                    minWidth: '24px',
                    maxHeight: '24px',
                    display: 'inline-flex',
                  }}
                  icon={backArrow}
                  defaultHref={'/'}
                />
              </IonButtons>
              <Typography component="h2" variant="body2" color={'#313D53'}>
                Δημιουργία αιτήματος
              </Typography>
              <Box minWidth={'24px'}></Box>
            </Box>
          </IonToolbar>
        </IonHeader>
      )}
      {location.pathname === '/create-claim' && (
        <Helmet>
          <title>Δημιουργία αιτήματος | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
        </Helmet>
      )}
      <IonContent ref={contentRef}>
        <Layout>
          <>
            <TopBanner />
            <ClaimStepContainer />
            {!mobile && (
              <>
                {!tablet && <AlertSection />}
                <PigogoFooter />
              </>
            )}
          </>
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default CreateClaim;
