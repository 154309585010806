import React, { FC, useEffect, useState } from 'react';
import {
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonToolbar,
  useIonViewWillLeave,
} from '@ionic/react';
import { Box, Checkbox, FormControlLabel, Grid, Radio, Typography, IconButton } from '@mui/material';
import { PigogoButton } from 'components';
import { ClaimStatusId } from '../../models/ClaimStatusId';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  sort: 'asc' | 'desc';
  setSort: (sort: 'asc' | 'desc') => void;
  claim: { label: string; value: ClaimStatusId }[];
  setClaim: (claim: { label: string; value: ClaimStatusId }[]) => void;
  selectedShops: { label: string; value: number }[];
  setSelectedShops: (selectedShops: { label: string; value: number }[]) => void;
  claimStatuses: { label: string; value: ClaimStatusId }[];
  shops: { label: string; value: number }[] | undefined;
}

const ClaimsFilterModal: FC<Props> = ({
  isOpen,
  setOpen,
  sort,
  setSort,
  claim,
  setClaim,
  selectedShops,
  setSelectedShops,
  claimStatuses,
  shops,
}) => {
  const [sortState, setSortState] = useState<'asc' | 'desc'>('desc');
  const [claimState, setClaimState] = useState<{ label: string; value: ClaimStatusId }[]>([]);
  const [shopsState, setShopsState] = useState<{ label: string; value: number }[]>([]);

  useIonViewWillLeave(() => {
    setSortState('asc');
    setClaimState([]);
    setShopsState([]);
  });

  useEffect(() => {
    setSortState(sort);
    setClaimState(claim);
    setShopsState(selectedShops);
  }, [sort, claim, selectedShops, isOpen]);

  const handleFilterSave = () => {
    setSelectedShops(shopsState);
    setClaim(claimState);
    setSort(sortState);
    setOpen(false);
  };

  const handleClaimStates = (label: string, value: ClaimStatusId) => {
    const find = claimState.find((state) => state.value === value);
    if (find) {
      setClaimState(claimState.filter((e) => e.value !== value));
    } else {
      const newCategories = [...claimState, { value, label }];
      setClaimState(newCategories);
    }
  };

  const handleShopsState = (label: string, value: number) => {
    const find = shopsState.find((state) => state.value === value);
    if (find) {
      setShopsState(shopsState.filter((e) => e.label !== label));
    } else {
      const newCategories = [...shopsState, { value: value, label: label }];
      setShopsState(newCategories);
    }
  };

  const getClassification = () => {
    return (
      <IonGrid style={{ padding: 0 }}>
        <IonRow style={{ marginBottom: 16 }}>
          <Typography variant="body2MediumM" component="p" color={'#313D53'}>
            Ταξινόμηση
          </Typography>
        </IonRow>
        <IonRow style={{ marginBottom: 16 }}>
          <FormControlLabel
            label={'Νεότερη'}
            sx={{
              margin: 0,
              '& .MuiRadio-root': {
                padding: 0,
              },
              '& .MuiTypography-root': {
                fontWeight: 400,
                fontSize: 14,
                marginLeft: '24px',
                display: 'block',
                color: '#313D53',
              },
            }}
            control={<Radio color={'secondary'} checked={sortState === 'desc'} onChange={() => setSortState('desc')} />}
          />
        </IonRow>
        <IonRow style={{ marginBottom: 16 }}>
          <FormControlLabel
            label={'Παλαιότερη'}
            sx={{
              margin: 0,
              '& .MuiRadio-root': {
                padding: 0,
              },
              '& .MuiTypography-root': {
                fontWeight: 400,
                fontSize: 14,
                marginLeft: '24px',
                display: 'block',
                color: '#313D53',
              },
            }}
            control={<Radio color={'secondary'} checked={sortState === 'asc'} onChange={() => setSortState('asc')} />}
          />
        </IonRow>
      </IonGrid>
    );
  };

  const getClaimState = () => {
    return (
      <IonGrid style={{ padding: 0, marginTop: 24 }}>
        <IonRow style={{ marginBottom: 16 }}>
          <Typography variant="body2MediumM" component="p" color={'#313D53'}>
            Κατάσταση
          </Typography>
        </IonRow>
        <IonRow style={{ marginBottom: 12 }}>
          <Grid item xs={12} container sx={{ gap: '12px' }}>
            {claimStatuses.map((option, index) => (
              <Grid item xs={12} key={index}>
                <FormControlLabel
                  label={option.label}
                  sx={{
                    margin: '-10px 0 0',
                    gap: '20px',
                    '& .MuiTypography-root': {
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: '1.71',
                      color: '#313D53',
                    },
                  }}
                  control={
                    <Checkbox
                      color={'secondary'}
                      onChange={() => handleClaimStates(option.label, option.value)}
                      checked={claimState.some((state) => state.value === option.value)}
                      sx={{
                        '&': {
                          padding: 0,
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                          border: '1px solid #838B98',
                          borderRadius: '4px',
                          color: 'transparent',
                          position: 'relative',
                          top: '0',
                        },
                        '&.Mui-checked': {
                          borderColor: '#E9688A',
                          outline: '1px solid #E9688A',
                          outlineOffset: '-2px',
                        },
                      }}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </IonRow>
      </IonGrid>
    );
  };

  const getShops = () => {
    return (
      <IonGrid style={{ padding: 0, marginTop: 24 }}>
        <IonRow style={{ marginBottom: 16 }}>
          <Typography variant="body2MediumM" component="p" color={'#313D53'}>
            Κατάστημα
          </Typography>
        </IonRow>
        <IonRow>
          <Grid item xs={12} container sx={{ gap: '12px' }}>
            {shops !== undefined &&
              shops?.map((option, index) => (
                <Grid item xs={12} key={index}>
                  <FormControlLabel
                    label={option.label}
                    sx={{
                      margin: '-10px 0 0',
                      gap: '20px',
                      '& .MuiTypography-root': {
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: '1.71',
                        color: '#313D53',
                      },
                    }}
                    control={
                      <Checkbox
                        color={'secondary'}
                        onChange={() => handleShopsState(option.label, option.value)}
                        checked={shopsState.some((state) => state.label === option.label)}
                        sx={{
                          '&': {
                            padding: 0,
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                            border: '1px solid #838B98',
                            borderRadius: '4px',
                            color: 'transparent',
                            position: 'relative',
                            top: '0',
                          },
                          '&.Mui-checked': {
                            borderColor: '#E9688A',
                            outline: '1px solid #E9688A',
                            outlineOffset: '-2px',
                          },
                        }}
                      />
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </IonRow>
      </IonGrid>
    );
  };

  return (
    <IonModal mode={'ios'} isOpen={isOpen} canDismiss={true} onDidDismiss={() => setOpen(false)}>
      <IonHeader class="ion-no-border">
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
          <Box display="flex" alignItems="center" justifyContent={'space-between'} pt={4} px={'27px'}>
            <Typography variant="body2" component="h2" color={'#313D53'}>
              Φίλτρα & Ταξινόμηση
            </Typography>
            <IconButton
              disableRipple
              sx={{
                position: 'absolute',
                right: 24,
                top: 36,
                padding: 0,
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => setOpen(false)}
            >
              <Close />
            </IconButton>
          </Box>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid style={{ padding: 0 }}>
          <Grid container style={{ paddingInline: 32 }}>
            <Grid item xs={12} container style={{ paddingTop: 24 }}>
              {getClassification()}
            </Grid>
            <Grid container item xs={12} justifyContent={'center'}>
              {getClaimState()}
            </Grid>
            <Grid container item xs={12} justifyContent={'center'}>
              {getShops()}
            </Grid>
          </Grid>
        </IonGrid>
      </IonContent>
      <IonFooter style={{ padding: 16, boxSizing: 'border-box', display: 'inline-flex' }}>
        <Grid container alignItems={'center'} justifyContent={'center'} style={{ paddingBottom: '16px' }}>
          <Grid item xs={12} p={1}>
            <PigogoButton
              fullWidth
              text={`Επιλογή`}
              onClick={() => handleFilterSave()}
              sx={{ backgroundColor: '#1D2532' }}
            />
          </Grid>
        </Grid>
      </IonFooter>
    </IonModal>
  );
};

export default ClaimsFilterModal;
