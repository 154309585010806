import { pigogoApi } from '../Api';
import { PaymentThreshold } from '../../../models/PaymentThreshold';

const paymentThresholdsApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    ibanThresholds: builder.mutation<PaymentThreshold[], { iban: string }>({
      query: ({ iban }) => ({
        method: 'POST',
        url: '/payment-thresholds',
        body: { iban },
      }),
    }),
    vivaThresholds: builder.mutation<PaymentThreshold[], void>({
      query: () => ({
        method: 'POST',
        url: '/payment-thresholds',
      }),
    }),
  }),
});

export const { useIbanThresholdsMutation, useVivaThresholdsMutation } = paymentThresholdsApi;
