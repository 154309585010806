import React, { FC } from 'react';
import { Box, Dialog, DialogContent, IconButton, styled, Typography, useMediaQuery } from '@mui/material';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { ReactComponent as Success } from '../../../assets/svgs/illustrations/successIcon.svg';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { theme } from 'components';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '480px',
    borderRadius: '16px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      width: '343px',
      maxWidth: '100%',
      marginInline: '16px',
    },
  },
}));

const SuccessDeleteModal: FC<Props> = ({ open, handleClose }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <IonModal
      mode={'ios'}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => handleClose()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '48px', boxSizing: 'border-box' }}>
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: smDown ? 4 : 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: smDown ? '0' : '16px',
          }}
        >
          <Box
            display="flex"
            textAlign={'center'}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={3}
            height={'100%'}
          >
            <Box gap={3} height={'100%'} display={'contents'}>
              <Box>
                <Success />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={mdDown ? 2 : 3}
              >
                <Typography
                  variant={mdDown ? 'mediumM' : 'largeMedium'}
                  color={'#313D53'}
                  fontWeight={700}
                  mt={-1}
                  component="h2"
                >
                  Έχει σταλεί αίτημα διαγραφής του λογαριασμού σου στην ομάδα του Pigogo.
                </Typography>

                <Typography
                  variant={mdDown ? 'subtitle1SmallR' : 'body2MediumR'}
                  textAlign={'center'}
                  color={'#313D53'}
                  component="p"
                >
                  Θα λάβεις ενημερωτικό email μόλις ολοκληρωθεί η οριστική διαγραφή του λογαριασμού σου
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default SuccessDeleteModal;
