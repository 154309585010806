import React, { useEffect, useState } from 'react';
import { Assets, theme } from 'components';
import { Box, Button, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import { ReactComponent as PigogoImg } from '../assets/svgs/logo/light/pigogo_m.svg';
import { ReactComponent as Up } from '../assets/svgs/vectors/up.svg';
import { ReactComponent as Down } from '../assets/svgs/vectors/down.svg';
import { ReactComponent as Blog } from '../assets/svgs/logo/light/blog.svg';
import SocialLinks from '../components/SocialLinks';
import CustomContainer from './CustomContainer';
import { Link } from 'react-router-dom';

const currentYear = new Date().getFullYear();

const ListPigogo = () => {
  const router = useIonRouter();

  return (
    <nav className="footerNav">
      <ul className="noStyleList footerNav__list">
        <li className="noStyleList__item">
          <Link className="footerLink" to={'/alert'}>
            Pigogo ALERT!
          </Link>
        </li>
        <li className="noStyleList__item">
          <Link className="footerLink" to={'/mobile-app'}>
            Pigogo mobile app
          </Link>
        </li>
      </ul>
    </nav>
  );
};

const ListHelp = () => {
  const route = useIonRouter();

  return (
    <nav className="footerNav">
      <ul className="noStyleList footerNav__list">
        <li className="noStyleList__item">
          <Link className="footerLink" to={'/how-it-works'}>
            Πώς λειτουργεί
          </Link>
        </li>
        <li className="noStyleList__item">
          <Link className="footerLink" to={'/faq'}>
            Συχνές ερωτήσεις
          </Link>
        </li>
        <li className="noStyleList__item">
          <Link className="footerLink" to={'/contact'}>
            Επικοινώνησε μαζί μας
          </Link>
        </li>
      </ul>
    </nav>
  );
};

const PigogoFooter = () => {
  const route = useIonRouter();

  const matchedUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchedExtraLg = useMediaQuery(theme.breakpoints.up('extraLg'));

  const [showListHelp, setShowListHelp] = useState<boolean>(false);
  const [showListPigogo, setShowListPigogo] = useState<boolean>(false);

  useEffect(() => {
    if (matchedUpMd) {
      setShowListPigogo(true);
      setShowListHelp(true);
    }
  }, [matchedUpMd]);

  const renderDesktop = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} paddingTop={'70px'} paddingBottom={'60px'}>
        <Box paddingBottom={'48px'}>
          <PigogoImg />
        </Box>

        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box alignItems={'center'}>
              <Typography variant={'body2'} component="h2" color={'#1D2532'}>
                TO PIGOGO
              </Typography>
            </Box>
            <Box>
              <ListPigogo />
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box>
              <Typography variant={'body2'} component="h2" color={'#1D2532'}>
                ΒΟΗΘΕΙΑ & ΕΠΙΚΟΙΝΩΝΙΑ
              </Typography>
            </Box>
            <Box>
              <ListHelp />
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box>
              <Typography variant={'body2'} component="h2" color={'#1D2532'}>
                ΑΚΟΛΟΥΘΗΣΕ ΜΑΣ
              </Typography>
            </Box>
            <Box display={'flex'} gap={2}>
              <SocialLinks />
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={2} alignContent={'flex-start'}>
            <Box>
              <Typography variant={'body2'} component="h2" color={'#1D2532'}>
                PIGOGO APP
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={matchedExtraLg ? 'row' : 'column'} gap={2}>
              <Box>
                <a href={'https://play.google.com/store/apps/details?id=gr.pigogo.android'} target="_blank">
                  <Assets.GooglePlayBadge />
                </a>
              </Box>
              <Box>
                <a href={'https://apps.apple.com/us/app/pigogo/id6475775568'} target="_blank">
                  <Assets.AppStoreBadge />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box marginTop={'60px'}>
          <Button sx={{ paddingX: 0 }} onClick={() => window.open('https://www.pigogo.gr/blog/')}>
            <Blog />
            <Typography variant={'body2MediumR'} color={'primary'} sx={{ paddingLeft: 1 }}>
              pigogo blog
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  };

  const renderTablet = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} paddingTop={'56px'} paddingBottom={'60px'}>
        <Box style={{ paddingBottom: 32 }}>
          <PigogoImg />
        </Box>

        <Box display={'flex'} alignItems={'center'} paddingBottom={'16px'}>
          <Typography variant={'body2'} color={'#1D2532'}>
            TO PIGOGO
          </Typography>
          <IconButton onClick={() => setShowListPigogo(!showListPigogo)}>
            {showListPigogo ? <Up /> : <Down />}
          </IconButton>
        </Box>

        <Box paddingBottom={'16px'}>{showListPigogo && <ListPigogo />}</Box>

        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'body2'} color={'#1D2532'}>
            ΒΟΗΘΕΙΑ & ΕΠΙΚΟΙΝΩΝΙΑ
          </Typography>
          <IconButton onClick={() => setShowListHelp(!showListHelp)}>{showListHelp ? <Up /> : <Down />}</IconButton>
        </Box>

        <Box paddingTop={'16px'}>{showListHelp && <ListHelp />}</Box>

        <Box display={'flex'} alignItems={'center'} paddingY={'48px'}>
          <Button sx={{ padding: 0 }} onClick={() => window.open('https://www.pigogo.gr/blog/')}>
            <Blog />
            <Typography variant={'body2MediumR'} color={'primary'} sx={{ paddingLeft: 1 }}>
              pigogo blog
            </Typography>
          </Button>
        </Box>

        <Box display={'flex'} alignItems={'center'} gap={2} paddingBottom={'32px'}>
          <SocialLinks />
        </Box>

        <Box display={'flex'} gap={3}>
          <Box>
            <a href={'https://play.google.com/store/apps/details?id=gr.pigogo.android'} target="_blank">
              <Assets.GooglePlayBadge />
            </a>
          </Box>
          <Box>
            <a href={'https://apps.apple.com/us/app/pigogo/id6475775568'} target="_blank">
              <Assets.AppStoreBadge />
            </a>
          </Box>
        </Box>
      </Box>
    );
  };

  const ThirdBox = () => {
    return (
      <Box sx={{ background: '#FCE5D8' }}>
        <CustomContainer>
          <Box
            display="flex"
            gap={matchesDownMd ? 2 : ''}
            boxSizing={'border-box'}
            justifyContent="space-between"
            py={matchesDownMd ? '16px' : '18px'}
            height={matchesDownMd ? '84px' : '52px'}
            flexDirection={matchesDownMd ? 'column' : 'row'}
            alignItems={matchesDownMd ? 'flex-start' : 'center'}
          >
            <Box>
              <Typography variant={'subtitle1SmallR'} color={'#1D2532'}>
                © Copyright {currentYear}
              </Typography>
            </Box>
            <ul className="copylinkList noStyleList">
              <li className="noStyleList__item">
                <Link className="copyrightLink" to={'/terms'}>
                  Όροι χρήσης
                </Link>
              </li>
              <li className="noStyleList__item">
                <Link className="copyrightLink" to={'/privacy'}>
                  Πολιτική Απορρήτου
                </Link>
              </li>
              <li className="noStyleList__item">
                <Link className="copyrightLink" to={'/privacy?cookies=true'}>
                  Cookies
                </Link>
              </li>
            </ul>
          </Box>
        </CustomContainer>
      </Box>
    );
  };

  return (
    <Box sx={{ background: '#FDF0F3', borderTop: '4px solid #FCE5D8' }}>
      <CustomContainer>
        {matchesDownMd && renderTablet()}
        {matchedUpMd && renderDesktop()}
      </CustomContainer>
      <ThirdBox />
    </Box>
  );
};

export default PigogoFooter;
