export type User = {
  email: string;
  password: string;
  signup_firstname?: string;
  signup_lastname?: string;
  signup_agree?: boolean;
};

export enum GamificationLevelString {
  you = 'you',
  saver = 'Saver',
  smart = 'Smart Saver',
  expert = 'Expert Saver',
  super = 'Super Saver',
  guru = 'Guru Saver',
}

export interface UserData {
  id: number;
  email: string;
  login_type_id: number;
  social_id: string | null;
  //role_id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  birthday: string;
  gender_id: number | null;
  gamification_level_id: 0 | 10 | 20 | 30 | 40 | 50;
  total_on_hold_cashback_amount: string;
  next_gamification_level_amount: string;
  user_warning_message: string | null;
  next_gamification_level_name: GamificationLevelString;
  gamification_level_name: GamificationLevelString;
  total_validated_cashback_amount: string;
  total_new_cashback_amount: string;
  total_payment_due_cashback_amount: string;
  total_paid_cashback_amount: string;
  total_tracked_cashback_amount: string;
  next_payment: string;
  notification_new_shop: boolean;
  notification_newsletter: boolean;
  notification_new_purchase: boolean;
  notification_new_payment: boolean;
  is_under_payment_threshold: boolean;
  payment_date: string;
  payment_bank_iban: string | null;
  payment_bank_account_holder: string | null;
  payment_threshold_bank_id: string | null;
  payment_method_id: number | null;
  total_payment_due_purchases: number | null;
  total_on_hold_purchases: number | null;
  total_paid_purchases: number;
  total_completed_payments: number;
  user_icon_id: number;
  //terms_of_service: boolean;
  viva_wallet_phone_number: string | null;
  viva_wallet_country_code: string | null;
  viva_wallet_beneficiary: string | null;
  last_tracked_purchase: string;
  payment_threshold_remainder?: string;
  payment_threshold_viva_wallet_id: string | null;
  has_valid_email: boolean;
  //payment_generation_validity: number;
  //payment_generation_validity_comment: null;
  //marketing_gender_id: number;
  //last_login_at: string;
  extension_browser: string | null;
  extension_browser_used_at: string | null;
  //pg_slider_seen: boolean;
  //blocked_from_tellafriend: boolean;
  //admin_notes: string | null;
  has_used_extension: boolean;
  shown_extension_modal_date: string;
}
