import { Box, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as VivaWallet } from '../../assets/svgs/illustrations/cashback/viva_wallet.svg';
import { ReactComponent as Bank } from '../../assets/svgs/illustrations/cashback/bank.svg';

import React from 'react';
import ReturnCard from './ReturnCard';
import CustomContainer from '../CustomContainer';
import { theme } from 'components';

const MoneyReturnSection = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box py={mdDown ? 4 : 5} pb={mdDown ? 4 : 10} sx={{ backgroundColor: '#FDF0F3' }}>
      <CustomContainer>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
          sx={{ paddingTop: mdDown ? '0' : '40px', paddingBottom: mdDown ? '40px' : '48px' }}
        >
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <Typography variant="smallB" component="h2" color={'#1D2532'}>
              Πώς λαμβάνω την επιστροφή χρημάτων από το Pigogo
            </Typography>
          </Box>
          <Box display="flex" gap={0.5} flexDirection="column" alignItems="center">
            <Typography textAlign="center" variant="body2MediumL" component="p" color={'#1D2532'}>
              Υπάρχουν 2 διαθέσιμοι τρόποι πληρωμής
            </Typography>
          </Box>
        </Box>

        <Box display={'flex'} gap={4} justifyContent="center" sx={{ flexWrap: mdDown ? 'wrap' : 'nowrap' }}>
          <Box sx={{ width: '624px', maxWidth: '100%' }}>
            <ReturnCard
              title="Κατάθεση σε τραπεζικό λογαριασμό"
              description="(ελληνικό IBAN λογαριασμό)"
              icon={<Bank />}
            />
          </Box>
          <Box sx={{ width: '624px', maxWidth: '100%' }}>
            <ReturnCard
              title="Κατάθεση σε Viva Wallet"
              description="(ελληνικό κινητό τηλέφωνο)"
              icon={<VivaWallet />}
            />
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default MoneyReturnSection;
