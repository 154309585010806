import { Box, Typography } from '@mui/material';
import CustomContainer from './CustomContainer';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from 'components';
import howItWorksStep1 from '../assets/png/howItWorksStep1.png';
import howItWorksStep1Sm from '../assets/png/howItWorksStep1Sm.png';
import howItWorksStep2 from '../assets/png/howItWorksStep2.png';
import howItWorksStep2Sm from '../assets/png/howItWorksStep2Sm.png';
import howItWorksStep3 from '../assets/png/howItWorksStep3.png';
import howItWorksStep3Sm from '../assets/png/howItWorksStep3Sm.png';
import stepLeft from '../assets/svgs/step_left.svg';
import stepRight from '../assets/svgs/step_right.svg';

const StepSection = () => {
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box
      bgcolor={'#F7F7F8'}
      pt={downMd ? '0' : '80px'}
      pb={downMd ? '32px' : '80px'}
      display={'flex'}
      flexDirection={'column'}
      gap={downMd ? 0 : 8}
    >
      <Box>
        <CustomContainer
          display="flex"
          gap={!downMd ? 8 : 2}
          px={!downLg ? 0 : 2}
          maxWidth={'1024px'}
          flexDirection={downMd ? 'column' : 'unset'}
        >
          <Box display="flex" justifyContent="center" position={'relative'}>
            {!downMd && (
              <img
                alt=""
                src={stepLeft}
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '-88px', zIndex: '-1' }}
              />
            )}
            {!downMd && <img alt={'pigogoApp'} src={howItWorksStep1} width={'462px'} />}
            {downMd && <img alt={'pigogoApp'} src={howItWorksStep1Sm} />}
          </Box>
          <Box display="flex" alignItems="center" width={!downMd ? '498px' : '100%'} mt={downMd ? -8 : 0}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              borderLeft={'2px solid #E9688A'}
              height={'fit-content'}
              py={2}
              pl={4}
              pr={downSm ? 2 : '62px'}
              ml={2}
              mr={2}
              width={'466px'}
              maxWidth={'100%'}
            >
              <Typography variant="mediumRAll" component="h2" color="#E9688A">
                Βήμα 1
              </Typography>
              <Typography variant="h4All" component="h3" color="#2C374B">
                Βρες το κατάστημα στο Pigogo
              </Typography>
              <Typography variant="body2MediumR" component="p" color="#313D53">
                Περισσότερα από 400 επώνυμα ηλεκτρονικά καταστήματα σε περιμένουν στο Pigogo! Βρες το κατάστημα από το
                οποίο θέλεις να αγοράσεις και κάνε κλικ στην επιλογή «Μεταφορά στο κατάστημα».
              </Typography>
            </Box>
          </Box>
        </CustomContainer>
      </Box>
      <Box>
        <CustomContainer
          display="flex"
          gap={!downMd ? 8 : 2}
          px={!downLg ? 0 : 2}
          maxWidth={'1024px'}
          flexDirection={downMd ? 'column' : 'unset'}
        >
          {downMd && (
            <Box display="flex" justifyContent="center">
              <img alt={'pigogoApp'} src={howItWorksStep2Sm} />
            </Box>
          )}
          <Box display="flex" alignItems="center" width={!downMd ? '498px' : '100%'} mt={downMd ? -8 : 0}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              borderLeft={'2px solid #E9688A'}
              height={'fit-content'}
              py={2}
              pl={4}
              pr={downSm ? 2 : '62px'}
              ml={2}
              mr={2}
              width={'466px'}
              maxWidth={'100%'}
            >
              <Typography variant="mediumRAll" component="h2" color="#E9688A">
                Βήμα 2
              </Typography>
              <Typography variant="h4All" component="h3" color="#2C374B">
                Κάνε την αγορά σου από το κατάστημα
              </Typography>
              <Typography variant="body2MediumR" component="p" color="#313D53">
                Πραγματοποίησε την online αγορά σου πληρώνοντας με όποιον τρόπο θέλεις (αντικαταβολή,
                χρεωστική/πιστωτική κάρτα, PayPal, τραπεζική κατάθεση κ.ο.κ.).
              </Typography>
            </Box>
          </Box>
          {!downMd && (
            <Box position={'relative'}>
              <img
                alt=""
                src={stepRight}
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '-88px',
                  zIndex: '-1',
                }}
              />
              <img alt={'pigogoApp'} src={howItWorksStep2} width={'462px'} />
            </Box>
          )}
        </CustomContainer>
      </Box>
      <Box>
        <CustomContainer
          display="flex"
          gap={!downMd ? 8 : 2}
          px={!downLg ? 0 : 2}
          maxWidth={'1024px'}
          flexDirection={downMd ? 'column' : 'unset'}
        >
          <Box display="flex" justifyContent="center" position={'relative'}>
            {!downMd && (
              <img
                alt=""
                src={stepLeft}
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '-88px', zIndex: '-1' }}
              />
            )}
            {!downMd && <img alt={'pigogoApp'} src={howItWorksStep3} width={'462px'} />}
            {downMd && <img alt={'pigogoApp'} src={howItWorksStep3Sm} />}
          </Box>
          <Box display="flex" alignItems="center" width={!downMd ? '498px' : '100%'} mt={downMd ? -8 : 0}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              borderLeft={'2px solid #E9688A'}
              height={'fit-content'}
              py={2}
              pl={4}
              pr={downSm ? 2 : '62px'}
              ml={2}
              mr={2}
              width={'466px'}
              maxWidth={'100%'}
            >
              <Typography variant="mediumRAll" component="h2" color="#E9688A">
                Βήμα 3
              </Typography>
              <Typography variant="h4All" component="h3" color="#2C374B">
                Συγχαρητήρια, η αγορά σου καταγράφηκε!
              </Typography>
              <Typography variant="body2MediumR" component="p" color="#313D53">
                Η αγορά σου θα εμφανιστεί αυτόματα στον λογαριασμό σου στο Pigogo!
              </Typography>
            </Box>
          </Box>
        </CustomContainer>
      </Box>
    </Box>
  );
};

export default StepSection;
