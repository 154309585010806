import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import React, { useRef, useState } from 'react';
import AlertSection from '../components/Home/AlertSection';
import PigogoFooter from '../components/PigogoFooter';
import DownloadSection from '../components/Home/DownloadSection';
import QuestionSection from '../components/Home/QuestionSection';
import TipsSection from '../components/ReturnTips/TipsSection';
import PigogoLoginRegister from '../components/PigogoLoginRegister';
import FlipCards from '../components/FlipCards';
import RegisterModal from '../components/Navigation/Modals/RegisterModal';
import LoginModal from '../components/Navigation/Modals/LoginModal';
import Layout from '../components/Navigation/Layout';
import ForgotModal from '../components/Navigation/Modals/ForgotModal';
import SuccessPasswordModal from '../components/Navigation/Modals/SuccessPasswordModal';
import { useGetUserDataQuery } from '../redux/api/queries/userQueries';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import CustomHeader from '../components/CustomHeader';
import { isWeb } from '../utils/device';
import { Helmet } from 'react-helmet-async';

const ReturnTips = () => {
  const desktop = isPlatform('desktop');

  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [forgotModal, setForgotModal] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState<boolean>(false);
  const [successForgotModal, setSuccessForgotModal] = useState<boolean>(false);

  const { data: userData } = useGetUserDataQuery();

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (history.action !== 'PUSH') {
      contentRef.current && contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    } else {
      contentRef.current && contentRef.current.scrollToTop();
    }
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <>
            <Box>
              <CustomHeader />
              {mdDown && (
                <IonHeader class="ion-no-border">
                  <IonToolbar
                    style={{
                      '--background': '#FFFFFF',
                      '--border-width': 0,
                      paddingTop: '0 + --ion-safe-area-top',
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                      <IonButtons slot="start">
                        <IonBackButton
                          className="backBtn"
                          text=""
                          style={{
                            '--color': '#313D53',
                            '--padding-start': 0,
                            '--padding-end': 0,
                            '--icon-font-size': '24px',
                            '--border-radius': 0,
                            '--min-height': '24px',
                            '--min-width': '24px',
                            width: '24px',
                            height: '24px',
                            minWidth: '24px',
                            maxHeight: '24px',
                            display: 'inline-flex',
                          }}
                          icon={backArrow}
                          defaultHref={'/'}
                        />
                      </IonButtons>
                      <Typography component="h2" variant="body2" color={'#313D53'}>
                        Tips για την επιστροφή
                      </Typography>
                      <Box minWidth={'24px'}></Box>
                    </Box>
                  </IonToolbar>
                </IonHeader>
              )}
              {location.pathname === '/return-tips' && (
                <Helmet>
                  <title>Tips για την επιστροφή | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                  <meta
                    name="description"
                    content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                  />
                </Helmet>
              )}
            </Box>
            <TipsSection />
            <Box pt={mdUp ? 10 : 4}>
              <FlipCards />
            </Box>

            {matchesDesktop && <AlertSection />}
            {matchesDesktop && isWeb() && (
              <DownloadSection
                readMore
                title={'Pigogo mobile app'}
                subtitle={
                  <span>
                    Κάνε τις αγορές σου εύκολα από το κινητό και κέρδισε μέσα από την εφαρμογή Pigogo. <br /> Διαθέσιμη
                    σε
                    <strong> Google play </strong> και <strong>App store</strong>
                  </span>
                }
              />
            )}
            {matchesDesktop && <QuestionSection faqCategory={'2'} hasExtraPadding />}
            {matchesDesktop && <PigogoFooter />}
          </>
        </Layout>
      </IonContent>
      <RegisterModal isOpen={registerModal} setOpen={setRegisterModal} setLoginModal={setLoginModal} />
      <LoginModal
        isOpen={loginModal}
        setLoginModal={(value: boolean) => {
          setLoginModal(value);
        }}
        dismiss={() => setLoginModal(false)}
        openForgotModal={() => setForgotModal(true)}
        openRegisterModal={() => setRegisterModal(true)}
      />
      <SuccessPasswordModal
        open={successForgotModal}
        title={'Ευχαριστούμε πολύ!'}
        handleClose={setSuccessForgotModal}
        subTitle={'Το link δημιουργίας νέου κωδικού έχει σταλεί στο email σου.'}
      />
      <ForgotModal isOpen={forgotModal} setOpen={setForgotModal} success={setSuccessForgotModal} />
    </IonPage>
  );
};

export default ReturnTips;
