import React, { ReactNode } from 'react';
import { Box, Snackbar, styled, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const AlertStyled = styled(MuiAlert)(() => ({
  background: '#1D2532',
  color: '#FFFFFF',
  borderRadius: '16px',
  fontSize: '16px',
  fontWeight: 500,
}));

interface Props {
  open: boolean;
  title: string;
  text?: string;
  icon?: React.ReactNode;
  actionButton?: ReactNode;
  handleUndo?: () => void;
  setOpen?: (open: boolean) => void;
}

const StartStepsAlert: React.FC<Props> = ({ open, setOpen, title, icon, actionButton }) => {
  const handleClose = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  const extractVariant = () => {
    return (
      <>
        <Box flexDirection="column" display="flex" alignItems="center" gap="20px">
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography variant="body2MediumR">{title}</Typography>
          </Box>
          {actionButton && <Box width={'100%'}>{actionButton}</Box>}
        </Box>
      </>
    );
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      style={{ zIndex: 8999999999999999 }}
      sx={{
        bottom: '10px !important',
      }}
      onClose={setOpen ? handleClose : undefined}
    >
      <AlertStyled
        onClose={handleClose}
        icon={icon ? icon : false}
        elevation={3}
        sx={{ width: '100%', p: '24px 32px 24px 32px' }}
      >
        {extractVariant()}
      </AlertStyled>
    </Snackbar>
  );
};

export default StartStepsAlert;
