import React, { useEffect, useState, FC } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { PigogoHorizontalProductCard, theme } from 'components';
import { useIonRouter } from '@ionic/react';
import CustomContainer from '../CustomContainer';
import { Shop } from '../../models/Shop';
import { Link } from 'react-router-dom';

type RecentSectionProps = {
  recentShops: { shop: Shop; createdAt: string }[];
  isLoading?: boolean;
};

const RecentsSection: FC<RecentSectionProps> = ({ recentShops, isLoading = false }) => {
  const router = useIonRouter();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const getCols = () => {
    if (smDown) {
      return 'repeat(1,1fr)';
    }
    if (mdDown) {
      return 'repeat(1,1fr)';
    }

    return 'repeat(3,1fr)';
  };
  const [viewRecently, setViewRecently] = useState<{ shop: Shop; createdAt: string }[]>([]);

  useEffect(() => {
    setViewRecently(recentShops);
  }, [recentShops]);

  const getRecentStoresView = () => {
    const lengthRecentlyView = viewRecently.length;
    const max = [...Array(3)].map(() => 1);

    if (isLoading) {
      return [1, 1, 1, 1, 1].map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              width: '100%',
              [theme.breakpoints.down('md')]: {
                maxWidth: '343px',
                marginInline: 'auto',
              },
            }}
          >
            <PigogoHorizontalProductCard loading animation={false} />
          </Box>
        );
      });
    }

    return max.map((item, index) => {
      if (index < lengthRecentlyView) {
        return (
          <Box
            key={index}
            sx={{
              width: '100%',
              position: 'relative',
              [theme.breakpoints.down('md')]: {
                maxWidth: '343px',
                marginInline: 'auto',
              },
            }}
          >
            <Link className="absLink" to={`/${viewRecently[index].shop.slug.name}`}></Link>
            <PigogoHorizontalProductCard key={index} shop={viewRecently[index].shop} />
          </Box>
        );
      } else {
        return (
          <Box
            key={index}
            sx={{
              width: '100%',
              [theme.breakpoints.down('md')]: {
                maxWidth: '343px',
                marginInline: 'auto',
              },
            }}
          >
            <PigogoHorizontalProductCard loading animation={false} />
          </Box>
        );
      }
    });
  };

  return (
    <CustomContainer pb={3}>
      <Box pt={smDown ? 4 : 10} pb={smDown ? 3 : 6} display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Typography variant="smallB" sx={{ fontWeight: '700 !important' }} component="h2" color="#1D2532">
          Είδες πρόσφατα
        </Typography>
        <Box display={'flex'} flexDirection="column" alignItems="center">
          <Typography textAlign="center" variant="body2MediumL" component="p" color="#1D2532">
            Τα καταστήματα του Pigogo που επισκέφτηκες πρόσφατα
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: getCols(),
          gridGap: '16px',
          justifyContent: 'center',
        }}
      >
        {getRecentStoresView()}
      </Box>
    </CustomContainer>
  );
};

export default RecentsSection;
