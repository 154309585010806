import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SvgIcon from '@mui/material/SvgIcon';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '300px',
      borderRadius: 8,
      marginTop: '10px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.02), 0px 12px 25px rgba(0, 0, 0, 0.08)',
    },
  },
};

const StyledSelect = styled(Select)({
  padding: '0',
  [`& fieldset`]: {
    borderRadius: '30px',
  },
  '& .MuiSvgIcon-root': {
    right: '24px',
  },
  '& [aria-expanded="true"] ~ .MuiSvgIcon-root': {
    transform: 'rotate(180deg)',
  },
  '& .MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: '14px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  mb: '4px',
  color: '#313D53',
  padding: '15px 15px 15px 24px',
  '&.MuiMenuItem-root': {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#313D53',
  },
  '&.Mui-selected': {
    backgroundColor: '#EAECEE',
  },
});

const ArrowDown = () => (
  <SvgIcon sx={{ position: 'absolute', top: 'calc(50% - 0.5em)', pointerEvents: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z"
      fill="#838B98"
    />
  </SvgIcon>
);

type LabelValue = {
  label: string;
  value: string;
};

type CustomDropdownProps = {
  label?: string;
  multiple?: boolean;
  selected: string | string[];
  options: LabelValue[] | string[];
  handleChange: (event: SelectChangeEvent<unknown>) => void;
};

const PigogoValueLabelDropdown: React.FC<CustomDropdownProps & FormControlProps> = ({
  label,
  multiple,
  options,
  selected,
  handleChange,
  ...rest
}) => {
  return (
    <FormControl variant="outlined" {...rest}>
      <style>
        {`
        .MuiMenu-list {
          padding-top: 0;
          padding-bottom: 0;
          max-height: 245px;
          overflow-y: auto;
          border-radius: 8px;
        }
        `}
      </style>
      <StyledSelect
        id={label}
        displayEmpty
        labelId={label}
        multiple={multiple}
        value={selected}
        MenuProps={MenuProps}
        onChange={(e) => handleChange && handleChange(e)}
        renderValue={(selected: any) => (
          <Box>
            {multiple ? (
              <Typography variant={'subtitle2'} paddingLeft={'10px'} fontWeight={'600'} color={'#313D53'}>
                {selected?.length === 0
                  ? label
                  : selected?.length === 1
                  ? `${selected?.length} επιλεγμένο`
                  : `${selected?.length} επιλεγμένα`}
              </Typography>
            ) : (
              <Typography variant={'subtitle2'} color={'#313D53'} fontWeight={600} paddingLeft={'10px'}>
                {typeof options[0] === 'string'
                  ? (options as string[]).find((option) => option === selected) ?? label
                  : (options as LabelValue[]).find((option) => option.value === selected)?.label ?? label}
              </Typography>
            )}
          </Box>
        )}
        IconComponent={ArrowDown}
      >
        {options.map((option) => {
          if (multiple) {
            const item = option as LabelValue;
            return (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{
                  padding: '16px 24px',
                  backgroundColor: (selected as string[]).some((category) => category === item.value)
                    ? '#EAECEE'
                    : 'none',
                }}
              >
                <FormControlLabel
                  label={item.label}
                  sx={{
                    marginLeft: 0,
                    '& .MuiTypography-root': {
                      fontWeight: 500,
                      fontSize: 14,
                      color: '#313D53',
                    },
                  }}
                  control={
                    <Checkbox
                      color={'secondary'}
                      checked={(selected as string[]).some((category) => category === item.value)}
                      sx={{
                        '&': {
                          padding: 0,
                          width: '20px',
                          height: '20px',
                          marginRight: '10px',
                          border: '1px solid #838B98',
                          borderRadius: '4px',
                          color: 'transparent',
                          position: 'static',
                        },
                        '&.Mui-checked': {
                          borderColor: '#E9688A',
                          outline: '1px solid #E9688A',
                          outlineOffset: '-2px',
                        },
                      }}
                    />
                  }
                />
              </MenuItem>
            );
          } else {
            if (typeof option === 'string') {
              return (
                <StyledMenuItem key={option} value={option}>
                  {option}
                </StyledMenuItem>
              );
            }
            return (
              <StyledMenuItem key={option.value} value={option.value}>
                {option.label}
              </StyledMenuItem>
            );
          }
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default PigogoValueLabelDropdown;
