import { Box, IconButton, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import { PigogoTextField, RichTooltip, theme } from 'components';
import { ReactComponent as Check } from '../../../assets/svgs/check.svg';
import ExtractBank from '../../ExtractBank';
import { ReactComponent as InfoOutlined } from '../../../assets/svgs/info.svg';
import React, { FC, useEffect, useState } from 'react';

type IbanProps = {
  iban: string;
  errorIban: boolean;
  successThreshold: boolean;
  setIban: (iban: string) => void;
  helperText: string;
  inputProps?: any;
};

const IbanField: FC<IbanProps> = ({ iban, setIban, errorIban, successThreshold, helperText, inputProps }) => {
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [displayBank, setDisplayBank] = useState<boolean>(false);

  const MouseOver = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const MouseOut = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (iban.replace(/\s+/g, '').length === 27) {
      const bank = iban.replace(/\s+/g, '').slice(4, 7);
      switch (bank) {
        case '026': //eurobank
        case '014': //alphabank
        case '017': //piraeaus bank
        case '011': //national bank
          setDisplayBank(true);
          break;
        default:
          setDisplayBank(false);
      }
    } else {
      setDisplayBank(false);
    }
  }, [iban]);

  return (
    <Box display={'flex'} alignItems={'flex-start'} gap={2}>
      <Box width={mobile ? '271px' : '316px'}>
        <PigogoTextField
          fullWidth
          label="IBAN"
          value={iban}
          size={'medium'}
          error={errorIban}
          autoComplete={'off'}
          setValue={(value: string) => setIban(value)}
          helperText={errorIban ? helperText : ''}
          inputProps={inputProps}
          InputProps={{
            endAdornment: !errorIban && successThreshold && iban !== '' && (
              <InputAdornment position="end">
                <IconButton disableRipple>
                  <Check />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {!errorIban && iban !== '' && displayBank && (
        <Box
          sx={{
            border: '1px solid #313D53',
            borderRadius: '50%',
            width: '56px',
            height: '56px',
            textAlign: 'center',
            flexShrink: 0,
          }}
        >
          {<ExtractBank iban={iban} />}
        </Box>
      )}
      <Box
        onMouseOver={MouseOver}
        onClick={MouseOver}
        onMouseOut={MouseOut}
        sx={{
          width: '24px',
          height: '24px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          cursor: 'pointer',
          alignSelf: 'flex-start',
          marginTop: 2,
          position: 'relative',
          zIndex: '999',
          '&:before': {
            content: '""',
            width: 'calc(100% + 12px)',
            height: 'calc(100% + 12px)',
            position: 'absolute',
            top: '-6px',
            left: '-6px',
            zIndex: '999',
          },
        }}
      >
        <RichTooltip
          content={
            <Typography variant={'caption'} component="p">
              Συμπλήρωσε τους 27 αλφαριθμητικούς χαρακτήρες του IBAΝ (συμπεριλαμβανομένων των 2 αλφαβητικών γραμμάτων),
              χωρίς κενά.
            </Typography>
          }
          open={open}
          placement="bottom"
          color="dark"
          onClose={() => setOpen(false)}
        >
          <InfoOutlined />
        </RichTooltip>
      </Box>
    </Box>
  );
};

export default IbanField;
