import { FC } from 'react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '16px',
  background: ' #FCE5D8',
  boxSizing: 'border-box',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginBottom: '16px',
  },
}));

interface Props {
  img: string;
  title: string;
  subTitle: string;
  description: string;
  onClick?: () => void;
}

const PigogoBigPromoCard: FC<Props> = ({ img, title, subTitle, description, onClick }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <StyleBox onClick={onClick}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        px={mdDown ? 2 : lgDown && mdUp ? 6 : 11}
        pt={mdDown ? 4 : 0}
        pb={mdDown ? 3 : 0}
        width={mdDown ? '100%' : 'calc(100% - 496px)'}
      >
        <Box
          py={mdUp ? 6 : 0}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          gap={2}
          width={'100%'}
          maxWidth={'100%'}
        >
          <Typography variant={mdDown ? 'h4All' : 'h3'} component="h2" color={'#1D2532'} pb={2}>
            {title}
          </Typography>
          <Typography
            color={'#1D2532'}
            component="h3"
            variant={mdDown ? 'body2' : 'body1'}
            sx={{
              lineHeight: mdDown ? '1.5' : '1.4',
              letterSpacing: mdDown ? '0' : '-0.4px',
            }}
          >
            {subTitle}
          </Typography>
          <Typography color={'#1D2532'} variant={mdDown ? 'subtitle1SmallL' : 'body2MediumL'} component="p">
            {description}
          </Typography>
        </Box>
      </Box>
      <Box
        width={mdDown ? '100%' : '496px'}
        maxWidth={'100%'}
        height={mdDown ? '343px' : 'inherit'}
        minHeight={mdDown ? '343px' : '392px'}
      >
        <img
          src={img}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: mdDown ? '0 0 16px 16px' : '0 16px 16px 0',
          }}
        />
      </Box>
    </StyleBox>
  );
};

export default PigogoBigPromoCard;
