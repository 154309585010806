import { pigogoApi } from '../Api';

const membersApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    members: builder.query<{ data: any[]; meta: any }, void>({
      query: () => ({
        url: '/static-content/testimonials',
      }),
    }),
  }),
});

export const { useMembersQuery } = membersApi;
