import React, { FC } from 'react';
import { Box, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { Purchase } from '../../../models/Purchase';
import { CreateClaimStepTwo } from '../../../models/CreateClaimStepTwo';
import StepTwoMobileRow from './StepTwoMobileRow';

interface Props {
  loading: boolean;
  cancelledPurchases: Purchase[];
  stepTwoState: CreateClaimStepTwo;
  setStepTwoState: (stepTwoState: CreateClaimStepTwo) => void;
}

const StepTwoTableMobile: FC<Props> = ({ cancelledPurchases = [], stepTwoState, setStepTwoState, loading }) => {
  return (
    <Box p={1}>
      <Box py={1}>
        <Typography variant="mediumM" color={'#1D2532'} component="h4">
          Επίλεξε την αγορά σου από τη λίστα με τις Ακυρωμένες
        </Typography>
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            {loading && [1, 1, 1, 1].map((item, index) => <StepTwoMobileRow key={index} loading />)}
            {!loading &&
              cancelledPurchases.map((row, index: number) => (
                <StepTwoMobileRow key={index} row={row} stepTwoState={stepTwoState} setStepTwoState={setStepTwoState} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StepTwoTableMobile;
