import React, { FC, useEffect } from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { useFaqQuery, useGetAllFaqCategoriesQuery } from '../../redux/api/queries/faqQueries';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { PigogoAccordion, PigogoButton, theme } from 'components';
import CustomContainer from '../CustomContainer';
import rehypeRaw from 'rehype-raw';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link } from 'react-router-dom';

const StyleBoxContainer = styled(Box)((props: BoxProps) => ({
  boxSizing: 'border-box',
  width: '848px',
  maxWidth: 'calc(100% - 32px)',
  marginInline: 'auto',
}));

const StyleMoreFaqs = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  background: '#FEF6F8',
  borderRadius: '24px',

  flexGrow: 1,

  padding: '48px',
  maxWidth: '766px',
  [theme.breakpoints.down('md')]: {
    padding: '48px 17px',
    maxWidth: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '32px 24px',
    maxWidth: '100%',
  },
}));

const BoxButtonsStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  boxSizing: 'border-box',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

type Props = {
  hasExtraPadding?: boolean;
  faqCategory?: string;
};
const QuestionSection: FC<Props> = ({ hasExtraPadding = false, faqCategory = '1' }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { data: content, isLoading, isError } = useFaqQuery({ categoryId: faqCategory, limit: 5, offset: 0 });
  const { data: contentAllFaqCategories } = useGetAllFaqCategoriesQuery();

  useEffect(() => {
    if (isError) {
      dispatch(setSnackBar({ value: true, msg: 'Αποτυχία φόρτωσης ερωτήσεων.', severity: 'error' }));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [isError]);

  const extractContent = () => {
    if (isLoading) {
      return [1, 1, 1, 1, 1].map((item, index) => (
        <Box key={index}>
          <PigogoAccordion key={index} disabled loading>
            -loading-
          </PigogoAccordion>
        </Box>
      ));
    } else {
      if (!content) return null;

      return content.data.map((item, index) => (
        <PigogoAccordion
          key={index}
          titleProps={{ variant: 'mediumM', component: 'h3', color: 'primary.dark' }}
          title={item.question}
        >
          <Typography variant="body2MediumL" component="div" color={'#313D53'}>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              className="markdown"
              components={{
                div: ({ node, ...props }) => (
                  <div
                    style={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: 300,
                      letterSpacing: 0,
                    }}
                    {...props}
                  />
                ),
                ol: ({ node, ...props }) => (
                  <ol style={{ paddingLeft: '18px', margin: 0 }} {...{ ...props, ordered: 'true' }} />
                ),
                ul: ({ node, ...props }) => (
                  <ul style={{ paddingLeft: '18px', margin: 0 }} {...{ ...props, ordered: 'false' }} />
                ),
                a: ({ node, ...props }) => (
                  <a
                    style={{
                      color: '#313D53',
                      textDecorationColor: '#313D53',
                      fontWeight: '400',
                    }}
                    {...props}
                  />
                ),
              }}
            >
              {item.answer}
            </ReactMarkdown>
          </Typography>
        </PigogoAccordion>
      ));
    }
  };

  const moreFaqsBox = () => {
    return (
      <StyleMoreFaqs>
        <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
          <Box>
            <Typography textAlign="center" variant={'body1'} color={'primary.dark'}>
              Έχεις επιπλέον ερωτήσεις;
            </Typography>
          </Box>
          <BoxButtonsStyle>
            <Link className="pigogoButton pigogoButton--outlined pigogoButton--fullwidth" to={'/faq'}>
              Όλες οι συχνές ερωτήσεις
            </Link>
            <Link className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth" to={'/contact'}>
              Επικοινωνία
            </Link>
          </BoxButtonsStyle>
        </Box>
      </StyleMoreFaqs>
    );
  };

  return (
    <Box pt={mdDown ? 4 : 10} pb={mdDown ? 8 : 10}>
      <StyleBoxContainer>
        <Box pb={6} display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Box>
            <Typography variant={'smallB'} component="h2" color={'primary.dark'}>
              Συχνές ερωτήσεις
            </Typography>
          </Box>
          <Box>
            {/*TODO: waiting for the actual text, for now leave dummy as in figma*/}
            <Typography variant={'body2MediumL'} component="p" color={'#1D2532'}>
              {contentAllFaqCategories?.data.find((elem) => elem.id.toString() === faqCategory)?.name}
            </Typography>
          </Box>
        </Box>

        <Box pb={8}>{extractContent()}</Box>
      </StyleBoxContainer>

      <CustomContainer display={'flex'} justifyContent={'center'}>
        {moreFaqsBox()}
      </CustomContainer>
    </Box>
  );
};

export default QuestionSection;
