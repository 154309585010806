import React, { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { PigogoNavigationIconButton, theme } from 'components';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../scss/components/partnerstores.css';

const CustomRightArrow = ({ onClick }: { onClick?: any }) => {
  return (
    <Box className="navButton navButton--right">
      <PigogoNavigationIconButton
        icon={<KeyboardArrowRightRounded style={{ color: 'white' }} />}
        handleClick={() => onClick()}
      />
    </Box>
  );
};

const CustomLeftArrow = ({ onClick }: { onClick?: any }) => {
  return (
    <Box className="navButton navButton--left">
      <PigogoNavigationIconButton
        icon={<KeyboardArrowLeftRounded style={{ color: 'white' }} />}
        handleClick={() => onClick()}
      />
    </Box>
  );
};

type Props = {
  children: any;
};
const HorizontalCategories: FC<Props> = ({ children }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentScroll, setCurrentScroll] = useState<number>(0);

  const ref = useRef<any>(null);

  useEffect(() => {
    ref.current = document.getElementById('horizontal-categories');
  }, []);

  const goLeft = () => {
    if (ref.current) {
      ref.current.scroll({
        left: ref.current.scrollLeft - 150,
        behavior: 'smooth',
      });
    }
  };

  const goRight = () => {
    if (ref.current) {
      ref.current.scroll({
        left: ref.current.scrollLeft + 150,
        behavior: 'smooth',
      });
    }
  };

  const showLeftArrow = () => {
    if (ref.current) {
      return currentScroll <= 0;
    }
  };

  const showRightArrow = () => {
    if (ref.current) {
      return currentScroll < ref.current.scrollWidth - ref.current.clientWidth;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        background: '#FFFFFF',
        height: '96px',
        boxSizing: 'border-box',
        borderRadius: '64px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: smDown ? '16px 0' : '16px',
      }}
    >
      <Box sx={{ padding: '0 8px' }} hidden={showLeftArrow() || smDown}>
        <CustomLeftArrow onClick={goLeft} />
      </Box>
      <Box
        id={'horizontal-categories'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          overflowX: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          transition: 'all 0.5s ease',
        }}
        onScroll={(e) => setCurrentScroll(e.currentTarget.scrollLeft)}
      >
        {children}
      </Box>
      <Box sx={{ padding: '0 8px' }} hidden={!showRightArrow() || smDown}>
        <CustomRightArrow onClick={goRight} />
      </Box>
    </Box>
  );
};

export default HorizontalCategories;
