import React, { FC, useEffect, useState } from 'react';
import { IonModal, useIonRouter, IonHeader, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { Box, Icon, IconButton, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Store } from '../../assets/svgs/storeIcon.svg';
import { ReactComponent as ChevronLeft } from '../../assets/svgs/chevronLeft24.svg';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import { theme } from 'components';

interface Props {
  isOpen: boolean;
  shopName: string;
  shopTrackingSpeed: number;
  setOpen: (b: boolean) => void;
}

const StoreNotYetTrackedPurchaseWarning: FC<Props> = ({ isOpen, setOpen, shopTrackingSpeed, shopName }) => {
  const router = useIonRouter();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [trackingSpeed, setTrackingSpeed] = useState<string>('');

  useEffect(() => {
    // Determine display value and unit based on speed
    let trackingSpeed = `${shopTrackingSpeed} λεπτά`;
    if (shopTrackingSpeed > 60) {
      const hours = Math.round(shopTrackingSpeed / 60);
      trackingSpeed = `${hours} ώρες`;
    }

    setTrackingSpeed(trackingSpeed);
  }, [shopName, shopTrackingSpeed]);
  return (
    <IonModal
      mode={'ios'}
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={() => setOpen(false)}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
    >
      <IonHeader class="ion-no-border">
        {smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
            <Box pr={1} pl={'5px'} py={2} display="flex" gap={2} alignItems="flex-start">
              <IconButton
                sx={{
                  padding: 0,
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                disableRipple
                onClick={() => setOpen(!isOpen)}
              >
                <ChevronLeft />
              </IconButton>
            </Box>
          </IonToolbar>
        )}
        {!smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '24px' }}>
            <IconButton
              disableRipple
              sx={{
                position: 'absolute',
                right: 12,
                top: 0,
                padding: 0,
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => setOpen(!isOpen)}
            >
              <Close />
            </IconButton>
          </IonToolbar>
        )}
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap={2}
          pl={!smDown ? 4 : 2}
          pr={!smDown ? 3 : 2}
          pt={0}
          pb={!smDown ? 3 : 2}
        >
          <Box display="flex" justifyContent="center">
            <Store />
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <Typography textAlign="center" variant="body2MediumR" color={'#313D53'} component="p">
              Η ταχύτητα καταγραφής για το κατάστημα {shopName}, δηλαδή ο μέσος χρόνος που απαιτείται για να εμφανιστεί
              μία αγορά στον λογαριασμό σου, είναι {trackingSpeed}. Ως εκ τούτου, θα μπορείς να δημιουργήσεις αίτημα για
              ‘Μη καταγραφή αγοράς’ μόλις περάσει το απαιτούμενο χρονικό διάστημα.
            </Typography>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default StoreNotYetTrackedPurchaseWarning;
