import React, { FC } from 'react';
import { Checkbox, CheckboxProps, styled } from '@mui/material';

const StyledCheckbox = styled(Checkbox)({
  '&': {
    padding: 0,
    width: '20px',
    height: '20px',
    border: '0',
    borderRadius: '34px',
    color: 'transparent',
  },
  '&.disabled': {
    color: '#D6D8DD',
  },
  '&.Mui-checked': {},
});

interface Props {
  checked: boolean;
  setValue?: (value: any) => void;
  inputProps?: CheckboxProps;
}

const PigogoCheckboxCircle: FC<Props> = ({ inputProps, checked, setValue }) => {
  return <StyledCheckbox {...inputProps} checked={checked} onChange={setValue} />;
};

export default PigogoCheckboxCircle;
