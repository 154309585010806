import { Box, Typography, useMediaQuery } from '@mui/material';
import { PigogoCardsCarousel, theme } from 'components';
import React, { useEffect, useState } from 'react';
import MemberCard from './MemberCard';
import { useMembersQuery } from '../../redux/api/queries/membersQuery';
import CustomContainer from '../CustomContainer';
import 'react-multi-carousel/lib/styles.css';
import { StateCallBack } from 'react-multi-carousel';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1280 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1179, min: 600 },
    items: 1,
    slidesToSlide: 1,
    partialVisible: true,
  },
  mobile: {
    breakpoint: { max: 509, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisible: true,
    partialVisibilityGutter: 32,
  },
};

const MemberSection = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const { data: members, isError: errorMembers } = useMembersQuery();

  const [centeredIndex, setCenteredIndex] = useState(1);

  useEffect(() => {
    if (errorMembers) {
      dispatch(setSnackBar({ value: true, msg: 'Η φόρτωση των σχολίων απέτυχε.', severity: 'error' }));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorMembers]);

  const afterChange = (previousSlide: number, state: StateCallBack) => {
    const _currentSlide = state.currentSlide;
    const _offsetCenter = Math.floor(state.slidesToShow / 2);
    const centerElemIndex = _currentSlide + _offsetCenter;
    setCenteredIndex(centerElemIndex);
  };

  return (
    <CustomContainer>
      <Box py={mdDown ? 5 : 10} pb={mdDown ? 13 : 18} textAlign="center">
        <Box pb={6}>
          <Typography textAlign="center" variant="smallB" component="h2" color={'#1D2532'}>
            Τι λένε τα μέλη μας
          </Typography>
        </Box>

        {members && (
          <PigogoCardsCarousel partialVisible={true} responsive={responsive} afterChange={afterChange}>
            {members.data.map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ paddingBottom: '12px' }}
              >
                <MemberCard
                  border={index === centeredIndex}
                  description={item.attributes.testimonial}
                  memberName={item.attributes.name}
                  memberSince={item.attributes.memberSince}
                />
              </Box>
            ))}
          </PigogoCardsCarousel>
        )}
      </Box>
    </CustomContainer>
  );
};

export default MemberSection;
