import { Skeleton, styled, TableCell, TableRow, Typography } from '@mui/material';
import { ReactComponent as Unchecked } from '../../../assets/svgs/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svgs/checked.svg';
import { Purchase } from '../../../models/Purchase';
import { CreateClaimStepTwo } from '../../../models/CreateClaimStepTwo';
import { FC } from 'react';
import moment from 'moment';
import ExtractPurchaseStatus from '../../ExtractPurchasesStatus';
import { PigogoCheckboxCircle } from 'components';

interface Props {
  purchase?: Purchase;
  loading?: boolean;
  stepTwoState?: CreateClaimStepTwo;
  setStepTwoState?: (stepTwoState: CreateClaimStepTwo) => void;
}

const StyledTableCell = styled(TableCell)(() => ({
  paddingLeft: '24px',
}));

const StepTwoDesktopRow: FC<Props> = ({ purchase, loading, stepTwoState, setStepTwoState }) => {
  return (
    <TableRow
      onClick={
        purchase && stepTwoState && setStepTwoState
          ? () => setStepTwoState({ ...stepTwoState, purchase_id: purchase?.purchase_id.toString() })
          : undefined
      }
      hover
      sx={{
        cursor: 'pointer',
        '&:children': {
          borderLeft: 0,
          borderRight: 0,
        },
        height: '72px',
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }}
    >
      <StyledTableCell sx={{ pr: 0 }}>
        <PigogoCheckboxCircle
          inputProps={{
            disableRipple: true,
            color: 'secondary',
            icon: <Unchecked />,
            checkedIcon: <CheckedIcon />,
          }}
          checked={loading ? false : stepTwoState?.purchase_id === purchase?.purchase_id.toString()}
          setValue={
            purchase && stepTwoState && setStepTwoState
              ? () => setStepTwoState({ ...stepTwoState, purchase_id: purchase?.purchase_id.toString() })
              : undefined
          }
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography variant="caption" color={'#313D53'}>
          {loading ? <Skeleton /> : moment(purchase?.date).format('DD/MM/YYYY')}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography variant="body2MediumM" color={'#313D53'}>
          {loading ? <Skeleton /> : purchase?.amount}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography variant="body2MediumM" color={'#313D53'}>
          {loading ? <Skeleton /> : purchase?.cashback_amount}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography variant="body2MediumM">
          {loading ? <Skeleton /> : <ExtractPurchaseStatus saver={purchase?.purchase_status_id || 0} />}
        </Typography>
      </StyledTableCell>
    </TableRow>
  );
};

export default StepTwoDesktopRow;
