import { FC } from 'react';
import { Typography } from '@mui/material';
import { PurchaseStatus } from '../redux/api/types/enum/PurchaseStatus';

interface Props {
  saver: number;
}

const ExtractPurchaseStatus: FC<Props> = ({ saver }) => {
  switch (saver) {
    case PurchaseStatus.cancelled:
      return (
        <Typography variant="subtitle1SmallR" component="p" fontSize={'12px'} color="#BC415A">
          Ακυρωμένη
        </Typography>
      );
    case PurchaseStatus.hold:
      return (
        <Typography variant="subtitle1SmallR" component="p" fontSize={'12px'} color="#6F7787">
          Σε αναμονή
        </Typography>
      );
    case PurchaseStatus.due:
      return (
        <Typography variant="subtitle1SmallR" component="p" fontSize={'12px'} color="#C07848">
          Προς πληρωμή
        </Typography>
      );
    case PurchaseStatus.completed:
      return (
        <Typography variant="subtitle1SmallR" component="p" fontSize={'12px'} color="#3CA377">
          Πληρωμένη
        </Typography>
      );
    default:
      return null;
  }
};

export default ExtractPurchaseStatus;
