import { FC } from 'react';
import { ReactComponent as Piraeus } from '../assets/svgs/banks/piraeus_circle.svg';
import { ReactComponent as Ethniki } from '../assets/svgs/banks/ethniki.svg';
import { ReactComponent as Eurobank } from '../assets/svgs/banks/eurobankCircle.svg';
import { ReactComponent as Alpha } from '../assets/svgs/banks/alpha.svg';

interface Props {
  iban: string;
}
const ExtractBank: FC<Props> = ({ iban }) => {
  const bank = iban.replace(/\s+/g, '').slice(4, 7);
  switch (bank) {
    case '026':
      //eurobank
      return <Eurobank style={{ height: '100%' }} />;
    case '014':
      //alphbank
      return <Alpha style={{ height: '100%' }} />;
    case '017':
      //piraeaus bank
      return <Piraeus style={{ height: '100%' }} />;
    case '011':
      //national bank
      return <Ethniki style={{ height: '100%' }} />;
    default:
      return null;
  }
};

export default ExtractBank;
