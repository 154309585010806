import { pigogoApi } from '../Api';
import { clearUser } from '../../slices/authenticationSlice';

const loginApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    obtainCsrfToken: builder.query({
      query: () => ({
        url: '/sanctum/csrf-cookie',
        method: 'GET',
      }),
    }),
    login: builder.mutation<
      { access_token?: string; refresh_token?: string; message?: string; resource_created: boolean; uid?: number },
      {
        email?: string;
        password?: string;
        rememberMe?: boolean;
        social_access_token?: string;
        provider?: string;
        devName?: string;
        familyName?: string;
        givenName?: string;
      }
    >({
      query: ({
        email,
        password,
        rememberMe,
        social_access_token,
        provider,
        devName = 'Browser',
        familyName,
        givenName,
      }) => ({
        url: '/users/actions/authenticate',
        method: 'POST',
        body: {
          email,
          password,
          remember_me: rememberMe,
          device_name: devName,
          social_access_token,
          provider,
          familyName,
          givenName,
        },
      }),
      invalidatesTags: ['shop', 'promos'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          return;
        }
      },
    }),

    logout: builder.mutation({
      query: () => ({
        url: '/users/actions/log-out',
        method: 'POST',
      }),
      invalidatesTags: ['shop'],
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(clearUser());
        } catch (err) {
          dispatch(clearUser());
        }
      },
    }),

    forgotPassword: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: '/users/actions/send-reset-password/email',
        method: 'POST',
        body: { email },
        headers: {
          Accept: 'application/json',
        },
      }),
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          return;
        }
      },
    }),
    resetPassword: builder.mutation<void, { email: string; token: string; password: string; confirmPassword: string }>({
      query: ({ email, token, password, confirmPassword }) => ({
        url: '/users/actions/reset-password',
        method: 'PATCH',
        body: { email, token, password, password_confirmation: confirmPassword },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLazyObtainCsrfTokenQuery,
} = loginApi;
