import { Box } from '@mui/material';
import { ReactComponent as ChevronDown } from '../../../../assets/svgs/chevron_down_color.svg';

const chevronICon = {
  size: '24px',
};
function SaverArrowDown({ color }) {
  return (
    <Box
      sx={{
        width: chevronICon.size,
        height: chevronICon.size,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        color: color,
      }}
    >
      <ChevronDown />
    </Box>
  );
}

export default SaverArrowDown;
