import { pigogoApi } from '../Api';
import { Shop } from '../../../models/Shop';
import { GenericRequest } from '../../../models/GenericRequest';
import { AllShop } from '../../../models/AllShop';
import { ListingParams } from '../types/ListingParams';
import { GenericResponse } from '../../../models/GenericResponse';
import { extractData } from '../../../utils/extractData';

const shopApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    shops: builder.query<GenericRequest<AllShop[]>, ListingParams>({
      query: ({
        id,
        promo,
        category,
        nextCursor,
        discount,
        limit,
        order,
        sort,
        no_promo = false,
        fetchPrevious = false,
      }) => ({
        method: 'GET',
        url: '/shops',
        params: { id, promo, category, cursor: nextCursor, discount, limit, order, sort, no_promo, fetchPrevious },
      }),
      providesTags: ['shops'],
      async onQueryStarted(_args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          return;
        }
      },
      async transformResponse(response: GenericResponse<AllShop[]>, meta) {
        const { responseData, nextCursor, totalCount } = extractData<AllShop[]>(response);
        await Promise.all(
          responseData?.map((shop: AllShop) => {
            return new Promise((res) => {
              const shopImage = new Image();
              shopImage.src = shop.image as string;
              res(shopImage);
            });
          }),
        );
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
    }),
    shopsCategory: builder.query<GenericRequest<AllShop[]>, ListingParams>({
      query: ({ menuCategoryId, category, nextCursor, limit, order, discount, sort, fetchPrevious = false }) => ({
        method: 'GET',
        url: `/menu-categories/${menuCategoryId}/shops`,
        params: { category, cursor: nextCursor, limit, order, discount, sort, fetchPrevious },
      }),
      providesTags: ['shops'],
      async onQueryStarted(_args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          return;
        }
      },
      async transformResponse(response: GenericResponse<AllShop[]>, meta, args) {
        const { responseData, nextCursor, totalCount } = extractData<AllShop[]>(response);
        await Promise.all(
          responseData?.map((shop: AllShop) => {
            return new Promise((res) => {
              const shopImage = new Image();
              shopImage.src = shop.image as string;
              res(shopImage);
            });
          }),
        );
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
          category: args.menuCategoryId,
        };
      },
    }),
    totalShopsOfCategory: builder.query<any, { menuCategoryId: string; category?: string }>({
      query: ({ menuCategoryId, category }) => ({
        method: 'GET',
        url: `/menu-categories/${menuCategoryId}/shops?total_count=true`,
        params: { category },
      }),
      transformResponse(response: any, meta) {
        const { totalCount } = extractData<AllShop[]>(response);
        return { totalCount };
      },
    }),
    shop: builder.query<Shop, { slugName: string }>({
      query: ({ slugName }) => ({
        method: 'GET',
        url: `/shops/${slugName}`,
      }),
      providesTags: ['shop'],
      async transformResponse(response: Shop, meta, args) {
        await new Promise((res) => {
          const shopImage = new Image();
          shopImage.src = response.image as string;
          res(shopImage);
        });
        return response;
      },
    }),
  }),
});

export const {
  useShopQuery,
  useShopsQuery,
  useLazyShopQuery,
  useLazyShopsQuery,
  useLazyShopsCategoryQuery,
  useLazyTotalShopsOfCategoryQuery,
} = shopApi;
