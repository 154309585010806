const detectBrowser = (): 'Firefox' | 'Chrome' | 'other' => {
  const ua = window.navigator.userAgent;
  const firefox = ua.indexOf('Firefox/');
  const chrome = ua.indexOf('Chrome/');
  const operaAgent = ua.indexOf('OP') > -1;
  const safariAgent = ua.indexOf('Safari') > -1;
  const edgeAgent = ua.indexOf('Edg') > -1;

  if (firefox > 0) {
    return 'Firefox';
  }
  if (chrome > 0 && (!operaAgent || !safariAgent || !edgeAgent)) {
    return 'Chrome';
  }
  return 'other';
};

export default detectBrowser;
