import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, IconButtonProps } from '@mui/material';

const CustomIconButton = styled(IconButton)(() => ({
  color: 'var(--text)',
  background: 'var(--background)',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    background: 'rgba(0, 0, 0, 0.12)',
  },
  '&:active': {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), var(--focus)`,
  },
  '&:focus': {
    background: 'var(--background)',
    outline: '2px solid rgba(60, 104, 200, 0.5)',
  },
  '&:disabled': {
    background: 'var(--disabled)',
  },
}));

interface Props {
  props?: IconButtonProps;
  handleClick: (value: any) => void;
  icon: ReactNode;
}

const PigogoIconButton: FC<Props> = ({ props, icon, handleClick }) => {
  return (
    <CustomIconButton disableRipple {...props} onClick={handleClick}>
      {icon}
    </CustomIconButton>
  );
};

export default PigogoIconButton;
