import { IonCol, IonRow } from '@ionic/react';
import { Box, Skeleton, styled, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { theme } from 'components';

const StyleImgHorizontalBox = styled(Box)(() => ({
  position: 'relative',
  height: 40,
  width: 80,
}));

const FavouritesSkeletonRow = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <IonRow
      style={{
        alignItems: 'center',
        borderTop: '1px solid #D6D8DD',
        width: '100%',
        justifyContent: mdDown ? 'space-evenly' : 'center',
        paddingBlock: 16,
      }}
    >
      <IonCol sizeXs={'3'} sizeMd={'7'}>
        <IonRow style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {!mdDown && (
            <IonCol size={'2'} style={{ textAlign: 'flex-start' }}>
              <StyleImgHorizontalBox>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  style={{ background: '#EAECEE', borderRadius: '16px' }}
                  height={'100%'}
                />
              </StyleImgHorizontalBox>
            </IonCol>
          )}
          <IonCol sizeXs={'12'} sizeMd={'9'} style={{ textAlign: 'flex-start' }}>
            <Typography variant={'body2'}>
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'50%'} />
            </Typography>
          </IonCol>
        </IonRow>
      </IonCol>
      <IonCol style={{ textAlign: 'start' }} size={'2'}>
        <IonRow>
          <IonCol style={{ textAlign: 'start' }}>
            <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} height={'100%'} />
          </IonCol>
          {!mdDown && (
            <IonCol>
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} />
            </IonCol>
          )}
        </IonRow>
      </IonCol>
      <IonCol style={{ textAlign: 'center' }} size={'2'}>
        <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} />
      </IonCol>
    </IonRow>
  );
};
export default FavouritesSkeletonRow;
