import React from 'react';
import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as ArrowRight } from '../../../assets/svgs/arrowRight.svg';

import { useGetUserDataQuery } from '../../../redux/api/queries/userQueries';
import GuruSaver from './SaverMeter/GuruSaver';
import { theme } from 'components';
import SaverExtractor from './SaverMeter/SaverExtractor';
import { useIonRouter } from '@ionic/react';
import BecomeSaver from './SaverMeter/BecomeSaver';
import { GamificationLevelString } from '../../../models/User';
import { Link } from 'react-router-dom';

const SaverMeter = () => {
  const router = useIonRouter();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: userData, isLoading } = useGetUserDataQuery();

  const gamificationLvl = userData?.gamification_level_id;
  const gamificationLvlName = userData?.gamification_level_name;

  const extractSaverColor = (saver: GamificationLevelString) => {
    switch (saver) {
      case GamificationLevelString.saver:
        return '#FEE2CF';
      case GamificationLevelString.smart:
        return '#CAF3E1';
      case GamificationLevelString.expert:
        return '#C3F2FB';
      case GamificationLevelString.super:
        return '#FDCCD6';
      case GamificationLevelString.guru:
        return '#DFCCFD';
      default:
        return '#FEE2CF';
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={3} sx={{ boxSizing: 'border-box' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="largeB" component="h3" color={'#1D2532'}>
          Πόσο Saver είσαι;
        </Typography>
        <Link className="iconLink iconLink--right" to={'/gamification'}>
          {!mobile && (
            <Typography variant="body2MediumM" color={'#1D2532'}>
              Δες περισσότερα
            </Typography>
          )}
          <ArrowRight />
        </Link>
      </Box>
      <Box
        borderRadius="24px"
        sx={{
          outlineOffset: '-4px',
          outlineWidth: '4px',
          outlineStyle: 'solid',
          outlineColor: extractSaverColor(gamificationLvlName || GamificationLevelString.you),
        }}
      >
        {isLoading && (
          <Box
            display={'flex'}
            flexDirection={mobile ? 'column' : 'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={mobile ? 2 : 4}
            px={6}
            py={mobile ? 4 : 6}
          >
            <Skeleton
              height="136px"
              width="120px"
              sx={{ borderRadius: '24px', flexGrow: 1, transform: 'scale(1)', maxWidth: '100%' }}
            />
            <Skeleton height="24px" width="24px" sx={{ borderRadius: '4px', transform: 'scale(1)' }} />
            <Skeleton
              height="136px"
              width="247px"
              sx={{ borderRadius: '24px', flexGrow: 1, transform: 'scale(1)', maxWidth: '100%' }}
            />
            <Skeleton height="24px" width="24px" sx={{ borderRadius: '4px', transform: 'scale(1)' }} />
            <Skeleton
              height="136px"
              width="120px"
              sx={{ borderRadius: '24px', flexGrow: 1, transform: 'scale(1)', maxWidth: '100%' }}
            />
          </Box>
        )}
        {!isLoading && gamificationLvl === 0 && <BecomeSaver />}
        {!isLoading && gamificationLvl !== 0 && gamificationLvl !== 50 && <SaverExtractor />}
        {!isLoading && gamificationLvl === 50 && <GuruSaver />}
      </Box>
    </Box>
  );
};

export default SaverMeter;
