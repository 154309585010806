import { Box, Chip, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { ReactComponent as Close } from '../../assets/svgs/vectors/close.svg';
import { theme } from 'components';

type Props = {
  selectedDiscounts: string[];
  allDiscounts: { label: string; value: string }[];
  handleRemoveDiscount: (discount: string) => void;
};
const DiscountChipList: FC<Props> = ({ selectedDiscounts, handleRemoveDiscount, allDiscounts }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const findLabel = (value: string) => {
    const foundDiscount = allDiscounts.find((discount) => discount.value === value);
    return foundDiscount?.label;
  };

  return (
    <>
      {selectedDiscounts.map((item, index) => (
        <Chip
          label={findLabel(item)}
          size={'medium'}
          deleteIcon={<Close />}
          key={`${item}-${index}`}
          onDelete={() => handleRemoveDiscount(item)}
          sx={{
            fontSize: mdDown ? '14px' : '12px',
            fontWeight: 500,
            lineHeight: '1.33',
            backgroundColor: '#EAECEE',
            color: '#313D53',
            padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
            borderRadius: '32px',
            minHeight: mdDown ? '40px' : '32px',
            '& .MuiChip-deleteIcon': {
              width: '24px',
              height: '24px',
              margin: '0',
              color: '#313D53',
              padding: mdDown ? '0' : '3px',
            },
            '& .MuiChip-label': {
              paddingRight: '0px',
              paddingLeft: '0px',
            },
          }}
        />
      ))}
    </>
  );
};

export default DiscountChipList;
