import { pigogoApi } from '../Api';

const blogApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    blog: builder.query<any[], { limit: number }>({
      query: ({ limit }) => ({
        method: 'GET',
        url: '/static-content/blog-posts',
        params: { limit },
      }),
    }),
  }),
});

export const { useBlogQuery } = blogApi;
