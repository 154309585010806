import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import ExtractInactiveLevel from './ExtractInactiveLevel';
import { GamificationLevelString } from '../../../../models/User';
import { ReactComponent as ChevronRight } from '../../../../assets/svgs/chevronRightColor.svg';
import SaverArrowRight from './SaverArrowRight';

const BecomeSaver = () => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {!mobile && (
        <Box display="flex" gap={1} alignItems="center" justifyContent="space-between" width="100%" p={6}>
          <Box>
            <Typography variant="body2" color="#313D53" sx={{ maxWidth: '151px' }}>
              Κάνε την πρώτη σου αγορά και γίνε Saver!
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start" justifyContent="space-between" width="100%" gap={2}>
            <SaverArrowRight color={'#313D53'} />
            <ExtractInactiveLevel saver={GamificationLevelString.saver} />
            <SaverArrowRight color={'#D6D8DD'} />
            <ExtractInactiveLevel saver={GamificationLevelString.smart} />
            <SaverArrowRight color={'#D6D8DD'} />
            <ExtractInactiveLevel saver={GamificationLevelString.expert} />
          </Box>
        </Box>
      )}
      {mobile && (
        <Box display="flex" gap={4} p={3} flexDirection="column" width="100%">
          <Typography variant="largeMedium" color="#313D53" maxWidth="271px">
            Κάνε την πρώτη σου αγορά και γίνε Saver!
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} width="100%">
            <ExtractInactiveLevel saver={GamificationLevelString.saver} />
            <SaverArrowRight color={'#D6D8DD'} />
            <ExtractInactiveLevel saver={GamificationLevelString.smart} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default BecomeSaver;
