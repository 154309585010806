import { IonHeader, IonToolbar } from '@ionic/react';
import React, { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ReactComponent as PigogoLogo } from '../assets/PigogoLogo.svg';
import { theme } from 'components';

const CustomHeaderHome = () => {
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  return tablet ? (
    <IonHeader mode={'ios'} class="ion-no-border">
      <IonToolbar
        style={{
          '--padding-bottom': 0,
          '--padding-start': 0,
          '--padding-end': 0,
          '--min-height': 0,
          '--border-width': 0,
          '--border-style': 0,
          '--background': '#ffff',
          paddingLeft: 0,
          paddingTop: '0 + --ion-safe-area-top',
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          p={2}
          sx={{ borderBottom: '1px solid #DADCE0' }}
        >
          <PigogoLogo />
        </Box>
      </IonToolbar>
    </IonHeader>
  ) : null;
};

export default CustomHeaderHome;
