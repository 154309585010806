import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Divider, Grid, Icon, InputAdornment, Typography, Container, IconButton } from '@mui/material';
import { PigogoButton, PigogoHorizontalProductCard, PigogoLink } from 'components';
import { ReactComponent as LinkVertical } from '../../assets/svgs/link_Vertical.svg';
import SearchTextField from '../SearchTextField';
import { useLazySearchQuery } from '../../redux/api/queries/searchQueries';
import { openInNewTab } from '../../utils/openInNewTab';
import { useIonRouter } from '@ionic/react';
import { useAppSelector } from '../../redux/hooks';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import { Link } from 'react-router-dom';
import { isWeb } from '../../utils/device';
import { Browser } from '@capacitor/browser';

interface Props {
  link: boolean;
  setLink: (link: boolean) => void;
  redirectModal?: boolean;
  image?: any;
  name?: string;
  setRedirectModal?: (val: boolean) => void;
  setImage?: (val: any) => void;
  setName?: (val: string) => void;
}

const LinkSearchMobile: FC<Props> = ({
  link,
  setLink,
  redirectModal,
  image,
  name,
  setRedirectModal,
  setImage,
  setName,
}) => {
  const router = useIonRouter();

  const [linkContent, setLinkContent] = useState<string>('');
  const [checkLink, setCheckLink] = useState<boolean>(false);

  const [trigger, { isFetching, data: linkResult }] = useLazySearchQuery();

  const web = isWeb();

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const scrollRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setCheckLink(false);
  }, [linkContent, setCheckLink]);

  useEffect(() => {
    if (checkLink) trigger({ link: encodeURIComponent(linkContent) });
  }, [trigger, linkContent, checkLink]);

  useEffect(() => {
    if (!isFetching) scrollRef && scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [isFetching]);

  const handleCreateClickout = () => {
    if (linkResult) {
      if (!web) {
        Browser.open({ url: linkResult.data[0]?.url });
      } else {
        openInNewTab(linkResult.data[0]?.url);
      }
    }
    setLink(false);
    linkResult?.data[0] && setRedirectModal && setRedirectModal(true);
    linkResult?.data[0] && setName && setName(linkResult?.data[0].name);
    linkResult?.data[0] && setImage && setImage(linkResult?.data[0].image);
  };

  const extractContent = () => {
    if (checkLink) {
      if (isFetching) {
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <Divider sx={{ borderColor: '#EAECEE' }} />
            </Box>
            <PigogoHorizontalProductCard loading />
          </Box>
        );
      } else if (!isFetching && linkResult?.data.length) {
        return (
          <>
            <Divider sx={{ borderColor: '#EAECEE' }} />
            <Grid container ref={scrollRef}>
              <Grid item xs={12}>
                <PigogoHorizontalProductCard
                  shop={linkResult?.data[0]}
                  onClick={() => router.push(`/${linkResult.data[0].slug.name}`)}
                />
              </Grid>
            </Grid>
            {(!!accessToken || sessionLoggedIn) && (
              <PigogoButton variation="secondary" text="Μεταφορά στο προϊόν" onClick={() => handleCreateClickout()} />
            )}
          </>
        );
      } else if (checkLink) {
        return (
          <Box gap={2} display={'flex'} flexDirection={'column'} ref={scrollRef}>
            <Box>
              <Divider sx={{ borderColor: '#EAECEE' }} />
            </Box>

            <Box gap={1}>
              <Typography variant="body2" color={'#313D53'}>
                Δε συνεργαζόμαστε με το συγκεκριμένο κατάστημα
              </Typography>
              <Typography variant="body2MediumL" color={'#313D53'}>
                Θα ήθελες να το προσθέσουμε στη λίστα μας;
              </Typography>
            </Box>

            <Box display="flex" justifyContent="flex-start" pb={1}>
              <Link className="linkMob" to={'/contact'}>
                Στείλε μας μήνυμα!
              </Link>
            </Box>
          </Box>
        );
      }
    } else return <></>;
  };

  return (
    <Container maxWidth={'sm'} sx={{ padding: 0, height: '100%' }}>
      <style>
        {`
         .scrollContainerY {
          overflow-y: auto;
          -ms-overflow-style: none !important;
          scrollbar-width: none !important;
          max-height: 99%;
         }
          .scrollContainer::-webkit-scrollbar {
            display: none !important;
          }
        `}
      </style>
      <Box className={'scrollContainerY'}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
              <Typography variant="largeB" component="h3" color={'#313D53'}>
                Αναζήτηση με link προϊόντος
              </Typography>
              <IconButton sx={{ width: '24px', height: '24px', padding: 0 }} onClick={() => setLink(!link)}>
                <Close />
              </IconButton>
            </Box>

            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Βρήκες ένα προϊόν που σου άρεσε; Μπορείς να αντιγράψεις εδώ το link του και να δεις αν θα κερδίσεις
              επιστροφή χρημάτων!
            </Typography>
          </Box>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Το link θα πρέπει να είναι από κάποιο συνεργαζόμενο κατάστημα.
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <SearchTextField
              value={linkContent}
              sx={{
                '& .MuiOutlinedInput-root': {
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  minHeight: '56px',
                  gap: 1,
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '1.42',
                },
              }}
              placeholder="συμπλήρωσε το link"
              autoComplete="off"
              onChange={(e) => setLinkContent(e.target.value)}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Icon>
                        <LinkVertical />
                      </Icon>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <PigogoButton
              text="Έλεγχος"
              variation="primary"
              disabled={linkContent === ''}
              onClick={() => setCheckLink(true)}
            />
          </Box>
          {extractContent()}
        </Box>
      </Box>
    </Container>
  );
};

export default LinkSearchMobile;
