import React, { FC } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { Purchase } from '../../../models/Purchase';
import PurchasesMobileRow from './PurchasesMobileRow';

interface Props {
  purchases?: Purchase[];
  isLoading?: boolean;
  onRowClick?: (purchase: Purchase) => void;
}

const PurchasesTableMobile: FC<Props> = ({ purchases, isLoading, onRowClick }) => {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableBody>
          {isLoading && [1, 1, 1, 1].map((item, index) => <PurchasesMobileRow key={index} isLoading />)}
          {!isLoading &&
            purchases?.map((row, index: number) => (
              <PurchasesMobileRow key={index} row={row} onRowClick={onRowClick} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchasesTableMobile;
