import React, { FC } from 'react';
import { Box, Button, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { IonRouterLink, useIonRouter } from '@ionic/react';
import { AllShop } from '../../../../models/AllShop';
import { GenericRequest } from '../../../../models/GenericRequest';
import { PigogoHorizontalProductCard, theme } from 'components';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  searchId: string;
  searchResults: GenericRequest<AllShop[]> | undefined;
  searchResultsLoading: boolean;
  setAnchorEl?: (anchorEl: HTMLInputElement | HTMLTextAreaElement | null) => void;
  closeSearch?: () => void;
  setSearchKeyword?: (value: string) => void;
}

const Results: FC<Props> = ({
  searchId,
  searchResults,
  searchResultsLoading,
  setAnchorEl,
  closeSearch,
  setSearchKeyword,
}) => {
  const router = useIonRouter();
  const history = useHistory();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCategoryClick = (category: string) => {
    localStorage.setItem('previous-search', `/search-stores?search=${searchId}`);
    if (setAnchorEl) setAnchorEl(null);
  };

  const handleAllResults = () => {
    setSearchKeyword && setSearchKeyword(searchId);
    localStorage.removeItem(`search`);
    history.push(`/search?keyword=${searchId}`);
    setTimeout(() => {
      if (closeSearch) closeSearch();
    }, 200);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Typography variant="body2" color={'#313D53'}>
          Κατηγορίες
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        {searchResultsLoading &&
          [1, 1, 1].map((item, index) => (
            <Typography key={index} variant="subtitle1SmallR">
              <Skeleton sx={{ px: 7 }} />
            </Typography>
          ))}

        {searchResults?.categories?.slice(0, 3).map((category, index) => (
          <Box key={index}>
            <IonRouterLink
              className="btnLink btnLink--result"
              routerLink={category.slug.name}
              routerDirection={'none'}
              onClick={() => handleCategoryClick(category.slug.name)}
            >
              {category.label}
            </IonRouterLink>
          </Box>
        ))}
      </Box>

      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <Typography variant="body2" color={'#313D53'}>
            Καταστήματα
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          {searchResultsLoading &&
            [1, 1, 1].map((item, index) => (
              <Box key={index} display="flex" flexDirection="column" gap={1}>
                <PigogoHorizontalProductCard loading />
              </Box>
            ))}
          {searchResults?.data.map((item, index) => (
            <Box key={index} display="flex" flexDirection="column" gap={1}>
              <Box sx={{ width: smDown ? 323 : 400, position: 'relative' }}>
                <Link className="absLink" to={'/' + item.slug.name}></Link>
                <PigogoHorizontalProductCard shop={item} />
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          onClick={(event: any) => {
            handleAllResults();
          }}
        >
          <Button
            size="small"
            sx={{ padding: 0, textDecoration: 'underline', color: '#3C68C8', fontSize: '12px', fontWeight: 500 }}
          >
            Δες όλα τα αποτελέσματα ({`${searchResults ? searchResults.totalCount : 0}`})
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Results;
