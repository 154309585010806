import { Sort } from '../redux/api/types/enum/Sort';
import { PigogoButton, PigogoLink } from 'components';
import { ShopCategory } from '../models/ShopCategory';
import React, { FC, useEffect, useState } from 'react';
import { Discount } from '../redux/api/types/enum/Discount';
import { Box, Checkbox, FormControlLabel, Grid, Radio, Typography, IconButton } from '@mui/material';
import { IonFooter, IonHeader, IonContent, IonModal, IonToolbar } from '@ionic/react';
import { ReactComponent as Close } from '../assets/svgs/close.svg';
import { useHistory } from 'react-router';

interface Props {
  sort: string;
  isOpen: boolean;
  categories: ShopCategory[];
  discount: string[];
  setOpen: (b: boolean) => void;
  setSort: (sort: string) => void;
  setDiscount: (discount: any) => void;
  categoriesChecked: { slugName: string; label: string }[];
  setCategoriesChecked: (categoriesChecked: { slugName: string; label: string }[]) => void;
}

const discountCategories = [
  { value: Discount.OFFERS, label: 'Προσφορές' },
  { value: Discount.COUPONS, label: 'Κουπόνια' },
];

const FilterModal: FC<Props> = ({
  isOpen,
  setOpen,
  categoriesChecked,
  setCategoriesChecked,
  categories,
  sort,
  setSort,
  discount,
  setDiscount,
}) => {
  const history = useHistory();

  const [sortState, setSortState] = useState<string>(history.location.pathname == '/search' ? Sort.RL : Sort.P);
  const [discountState, setDiscountState] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<{ slugName: string; label: string }[]>([]);
  const [stallApply, setStallApply] = useState<boolean>(false);

  const sortOptions =
    history.location.pathname == '/search'
      ? [
          { label: 'Σχετικότητα', value: 'rl' },
          { label: 'Δημοτικότητα', value: 'p' },
          { label: 'Αλφαβητικά', value: 'a' },
          { label: 'Πιο πρόσφατα', value: 'r' },
          { label: 'Υψηλότερη επιστροφή', value: 'hr' },
        ]
      : [
          { label: 'Δημοτικότητα', value: 'p' },
          { label: 'Αλφαβητικά', value: 'a' },
          { label: 'Πιο πρόσφατα', value: 'r' },
          { label: 'Υψηλότερη επιστροφή', value: 'hr' },
        ];

  const handleClose = () => {
    setOpen(false);
    setSelectedCategories(categoriesChecked);
    setDiscountState(discount);
    setSortState(sort);
  };

  useEffect(() => {
    if (!stallApply) {
      setSortState(sort);
      setDiscountState(discount);
      setSelectedCategories(categoriesChecked);
    }
  }, [sort, discount, categoriesChecked]);

  const handleFilterSave = () => {
    if (history.location.pathname === '/stores') {
      localStorage.removeItem('stores');
    } else if (history.location.pathname === '/offers') {
      localStorage.removeItem('offers');
    }

    setStallApply(false);

    setOpen(false);
    setSort(sortState);
    setDiscount(discountState);
    setCategoriesChecked(selectedCategories);
  };

  const handleCategories = (slugName: string, value: string) => {
    const find = selectedCategories.find((category) => category.slugName === slugName);
    if (find) {
      setSelectedCategories(selectedCategories.filter((e) => e.label !== value));
    } else {
      const newCategories = [...selectedCategories, { slugName: slugName, label: value }];
      setSelectedCategories(newCategories);
    }
  };

  const handleChecked = (item: string) => {
    const find = discountState.find((category) => category === item);
    if (find) {
      setDiscountState(discountState.filter((e) => e !== item));
    } else {
      const newCategories = [...discountState, item];
      setDiscountState(newCategories);
    }
  };

  const clear = () => {
    setStallApply(true);

    setSortState(history.location.pathname == '/search' ? Sort.RL : Sort.P);
    setSelectedCategories([]);
    setDiscountState([]);
  };

  const getClassification = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={2} pb={2}>
        <Typography variant="body2MediumM" component="h3" color={'#313D53'}>
          Ταξινόμηση
        </Typography>
        {sortOptions.map((category, index) => (
          <Box key={index}>
            <FormControlLabel
              label={category.label}
              sx={{
                marginLeft: 0,
                marginRight: 0,
                gap: 2,
                '& .MuiTypography-root': {
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#313D53',
                },
              }}
              control={
                <Radio
                  color={'secondary'}
                  sx={{
                    '&': {
                      padding: 0,
                      width: '24px',
                      height: '24px',
                      marginRight: '0',
                      border: '1px solid #838B98',
                      borderRadius: '50%',
                      color: 'transparent',
                      position: 'relative',
                    },
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      inset: 0,
                      borderRadius: '50%',
                      border: '6px solid #E9688A',
                      transform: 'scale(0)',
                    },
                    '&.Mui-checked': {
                      color: 'transparent',
                      borderColor: '#E9688A',
                    },
                    '&.Mui-checked:before': {
                      transform: 'scale(1)',
                    },
                  }}
                  checked={sortState === category.value}
                  onChange={() => setSortState(category.value)}
                />
              }
            />
          </Box>
        ))}
      </Box>
    );
  };

  const getCategories = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={2} pb={2}>
        <Typography variant="body2MediumM" component="h3" color={'#313D53'}>
          Κατηγορίες
        </Typography>
        {categories.map((category, index) => (
          <Box key={index} sx={{ position: 'relative' }}>
            <FormControlLabel
              label={category.label}
              sx={{
                marginLeft: 0,
                marginRight: 0,
                gap: 2,
                '& .MuiTypography-root': {
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#313D53',
                },
              }}
              control={
                <Checkbox
                  color={'secondary'}
                  sx={{
                    '&': {
                      padding: 0,
                      width: '20px',
                      height: '20px',
                      marginRight: '0',
                      border: '1px solid #838B98',
                      borderRadius: '4px',
                      color: 'transparent',
                      position: 'static',
                    },
                    '&.Mui-checked': {
                      borderColor: '#E9688A',
                      outline: '1px solid #E9688A',
                      outlineOffset: '-2px',
                    },
                  }}
                  checked={selectedCategories.some((option) => option.slugName === category.slug.name)}
                  onChange={() => handleCategories(category.slug.name, category.label)}
                />
              }
            />
          </Box>
        ))}
      </Box>
    );
  };

  const getDiscount = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={2} pb={2}>
        <Typography variant="body2MediumM" component="h3" color={'#313D53'}>
          Είδος έκπτωσης
        </Typography>
        {discountCategories.map((category, index) => (
          <Box key={index} sx={{ position: 'relative' }}>
            <FormControlLabel
              label={category.label}
              sx={{
                marginLeft: 0,
                marginRight: 0,
                gap: 2,
                '& .MuiTypography-root': {
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#313D53',
                },
              }}
              control={
                <Checkbox
                  color={'secondary'}
                  sx={{
                    '&': {
                      padding: 0,
                      width: '20px',
                      height: '20px',
                      marginRight: '0',
                      border: '1px solid #838B98',
                      borderRadius: '4px',
                      color: 'transparent',
                      position: 'static',
                    },
                    '&.Mui-checked': {
                      borderColor: '#E9688A',
                      outline: '1px solid #E9688A',
                      outlineOffset: '-2px',
                    },
                  }}
                  checked={discountState.some((item) => item === category.value)}
                  onChange={() => handleChecked(category.value)}
                />
              }
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <IonModal mode={'ios'} isOpen={isOpen} canDismiss={true} onDidDismiss={handleClose}>
      <IonHeader class="ion-no-border">
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
          <Box display="flex" alignItems="center" justifyContent={'space-between'} pt={'29px'} px={'28px'}>
            <Typography variant="body2" component="h2">
              Φίλτρα & Ταξινόμηση
            </Typography>
            <IconButton sx={{ width: '24px', height: '24px', padding: 0 }} onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Box display={'flex'} flexDirection={'column'} gap={2} px={4}>
          <Box pt={'21px'}>
            <PigogoLink
              text={'Καθαρισμός'}
              typoProps={{ variant: 'subtitle2', color: '#3C68C8' }}
              handleClick={() => clear()}
            />
          </Box>
          <Box pt={1}>{getClassification()}</Box>
          <Box pt={1}>{getCategories()}</Box>
          <Box pt={1}>{getDiscount()}</Box>
        </Box>
      </IonContent>
      <IonFooter style={{ padding: '16px 32px 32px', boxSizing: 'border-box', display: 'inline-flex' }}>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            <PigogoButton fullWidth text={`Επιλογή`} onClick={handleFilterSave} />
          </Grid>
        </Grid>
      </IonFooter>
    </IonModal>
  );
};

export default FilterModal;
