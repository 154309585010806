import { Button, ButtonProps, styled, Typography, TypographyProps } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

const ButtonStyled = styled(Button)(() => ({
  borderRadius: '0px 32px 32px 32px',
  padding: '16px 32px',
  boxShadow: 'none',
  background: 'none',
  variant: 'contained',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.04)',
  },
  '&:active': {
    background: 'rgba(0, 0, 0, 0.12)',
  },
  '&:focus': {
    background: 'none',
    outline: '4px solid rgba(60, 104, 200, 0.5)',
  },
}));

interface Props {
  text: string;
  handleClick: (value: any) => void;
  buttonProps?: ButtonProps;
  typographyProps?: TypographyProps;
}

const PigogoLinkButton: FC<Props> = (props) => {
  return (
    <ButtonStyled {...props.buttonProps} onClick={props.handleClick}>
      <Typography sx={{ textDecorationLine: 'underline' }} {...props.typographyProps}>
        {props.text}
      </Typography>
    </ButtonStyled>
  );
};

export default PigogoLinkButton;
