import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { useIonRouter } from '@ionic/react';
import { Link } from 'react-router-dom';

const NoResultsMobile = () => {
  const router = useIonRouter();

  return (
    <Box className={'scrollContainerY'}>
      <style>
        {`
         .scrollContainerY {
          overflow-y: auto;
          -ms-overflow-style: none !important;
          scrollbar-width: none !important;
          max-height: 99%;
         }
          .scrollContainer::-webkit-scrollbar {
            display: none !important;
          }
        `}
      </style>
      <Box pb={2} display="flex" flexDirection="column" gap={2}>
        <Typography variant="body2" color={'#313D53'} component="h3">
          Δε βρέθηκαν αποτελέσματα
        </Typography>
        <Divider sx={{ borderColor: '#EAECEE' }} />
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="subtitle1" component="p" color={'#313D53'}>
            Ψάχνεις κάποιο κατάστημα και δεν το βρίσκεις;
          </Typography>
          <Typography variant="subtitle1SmallR" component="p" color={'#313D53'}>
            Στείλε μας το κατάστημα και θα το προσθέσουμε στη λίστα με τα συνεργαζόμενα καταστήματα.
          </Typography>
        </Box>
        <Box>
          <Link className="linkMob" to={'/contact'}>
            Στείλε μας μήνυμα!
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default NoResultsMobile;
