import React, { CSSProperties, FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface Props {
  text: string;
  selectedCategory: string;
  buttonProps?: CSSProperties;
  typoProps?: TypographyProps;
  handleClick: (value: any) => void;
}

const PigogoSelectableButton: FC<Props> = ({ text, typoProps, buttonProps, selectedCategory, handleClick }) => {
  return (
    <button
      style={{
        display: 'flex',
        color: '#000000',
        width: 'max-content',
        borderRadius: '48px',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #ECEFF4',
        padding: '20px 24px 20px 24px',
        borderColor: selectedCategory === text ? '#FCE5D8' : '#ECEFF4',
        backgroundColor: selectedCategory === text ? '#FCE5D8' : '#FFFFFF',
        ...buttonProps,
      }}
      onClick={handleClick}
    >
      <Typography variant="subtitle1SmallR" {...typoProps}>
        {text}
      </Typography>
    </button>
  );
};

export default PigogoSelectableButton;
