import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { PigogoProductCard, theme } from 'components';
import { useIonRouter } from '@ionic/react';
import { useShopsQuery } from '../../redux/api/queries/shopQuery';
import { Sort } from '../../redux/api/types/enum/Sort';
import { AllShop } from '../../models/AllShop';
import { useAppSelector } from '../../redux/hooks';
import { ListingParams } from '../../redux/api/types/ListingParams';
import CustomContainer from '../CustomContainer';
import useDebounce from '../../hooks/useDebounce';
import useAppendData from '../../hooks/useAppendData';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { useDeleteFavouriteMutation, usePostFavouriteMutation } from '../../redux/api/mutations/favouritesMutation';
import { Link, useHistory } from 'react-router-dom';

const paginationShop: ListingParams = {
  limit: 4,
  sort: Sort.R,
  nextCursor: undefined,
  no_promo: true,
};

const NewShopsSection = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const history = useHistory();

  const xxsDown = useMediaQuery(theme.breakpoints.down('xxs'));
  const smDown = useMediaQuery(theme.breakpoints.down('ssm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const { accessToken } = useAppSelector((state) => state.authentication.data);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [shopsList, setShopsList] = useState<AllShop[]>([]);
  const { data: shops, isLoading: shopsLoad, isError: shopsError } = useShopsQuery(paginationShop);

  const [postAppend, setPostAppend] = useState<number[]>([]);
  const [deleteAppend, setDeleteAppend] = useState<number[]>([]);

  const [favShops, setFavShops] = useState<number[]>([]);
  const [unfavShops, setUnfavShops] = useState<number[]>([]);

  const [isLoadingShops, setIsLoadingShops] = useState<boolean>(true);

  const [postFavourite, { isError: postFavouriteFailed, isSuccess: postFavouriteSucceeded, reset: resetPostFav }] =
    usePostFavouriteMutation();
  const [
    deleteFavourite,
    { isError: deleteFavouriteFailed, isSuccess: deleteFavouriteSucceeded, reset: resetDeleteFav },
  ] = useDeleteFavouriteMutation();

  const finalData = useAppendData(shops?.data, false);

  useEffect(() => {
    setFavShops(shopsList.filter((elem) => !!elem.user_favourite).map((elem) => elem.id));
    setUnfavShops(shopsList.filter((elem) => !elem.user_favourite).map((elem) => elem.id));
  }, [shopsList]);

  useEffect(() => {
    setShopsList(finalData);
  }, [finalData]);

  useEffect(() => {
    if (shopsList && shopsList?.length) {
      setIsLoadingShops(false);
    }
  }, [shopsList]);

  useEffect(() => {
    if (shopsError) {
      const params = { value: true, msg: 'Κατι πήγε στραβά!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [dispatch, shopsError]);

  const getCols = () => {
    if (smDown) {
      return 'repeat(auto-fit,calc(50% - 4px))';
    }
    if (mdDown) {
      return 'repeat(auto-fit,calc(25% - 6px))';
    }
    if (lgDown) {
      return 'repeat(auto-fit,calc(25% - 12px))';
    }
    return 'repeat(auto-fit,244px)';
  };

  const handleSetFavourite = (shop: AllShop, index: number) => {
    if ((!!accessToken || sessionLoggedIn) && setFavourite) {
      setFavourite(shop, index);
    }
  };

  const setFavourite = async (shop: AllShop, index: number) => {
    if (!shop) {
      return;
    }

    if (!favShops.some((elem) => elem === shop.id) && !postAppend.includes(index)) {
      setPostAppend([...postAppend, index]);

      setFavShops([...favShops, shop?.id]);
      setUnfavShops(unfavShops.filter((elem) => elem !== shop?.id));

      const data: any = postFavourite({ shop_id: shop.id, notifications: 1 });
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής προσθήκη αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setUnfavShops([...unfavShops, shop?.id]);
        setFavShops(favShops.filter((elem) => elem !== shop?.id));
      }

      const idx = postAppend.findIndex((elem) => elem === index);
      setPostAppend(postAppend.splice(idx, idx).splice(index, index));
    } else if (favShops.some((elem) => elem === shop.id) && !deleteAppend.includes(index)) {
      setDeleteAppend([...deleteAppend, index]);

      setUnfavShops([...unfavShops, shop?.id]);
      setFavShops(favShops.filter((elem) => elem !== shop?.id));

      const data: any = deleteFavourite(shop.id);
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής αφαίρεση αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setFavShops([...favShops, shop?.id]);
        setUnfavShops(unfavShops.filter((elem) => elem !== shop?.id));
      }

      const idx = deleteAppend.findIndex((elem) => elem === index);
      setDeleteAppend(deleteAppend.splice(idx, idx).splice(index, index));
    }

    return;
  };

  const storeCards = () => {
    if (shopsLoad) {
      return [1, 1, 1, 1].map((item, index) => (
        <Box key={index}>
          <PigogoProductCard loading={true} />
        </Box>
      ));
    }

    if (shops && shopsList && !shopsLoad) {
      return shopsList.map((shop: AllShop, index: number) => (
        <a className="storeCard" href={`/${shop.slug.name}`} key={index}>
          <PigogoProductCard
            shop={{
              ...shop,
              user_favourite: favShops?.some((elem) => elem === shop?.id),
            }}
            logout={!(!!accessToken || sessionLoggedIn)}
            handleClick={(e: any) => {
              e.preventDefault();
              history.push(`/${shop.slug.name}`);
            }}
            setFavourite={() => handleSetFavourite(shop, index)}
          />
        </a>
      ));
    }

    if (shops && shopsList && shopsList.length === 0 && !shopsLoad) {
      return <Typography textAlign="center">Δεν υπάρχουν καταστήματα</Typography>;
    }
  };

  return (
    <CustomContainer>
      <Box display={'flex'} flexDirection={'column'} gap={6} py={smDown ? 4 : 10}>
        <Typography variant="smallB" component="h2" textAlign="center" color={'#1D2532'}>
          Νέα συνεργαζόμενα καταστήματα
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: getCols(),
            gridGap: mdDown ? '8px' : '16px',
            justifyContent: 'center',
          }}
        >
          {storeCards()}
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default NewShopsSection;
