import React, { FC, useEffect } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { ReactComponent as DeleteUser } from '../../../assets/svgs/illustrations/deleteUser.svg';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { PigogoButton, theme } from 'components';
import { usePostContactMutation } from '../../../redux/api/mutations/contactMutation';
import { ContactParams } from '../../../redux/api/types/ContactParams';
import { useAppDispatch } from '../../../redux/hooks';
import { setSnackBar } from '../../../redux/slices/layoutSlice';

interface Props {
  open: boolean;
  data: { fullname: string; email: string; userId: number };
  handleClose: () => void;
  accept: () => void;
}

const DeleteModalUser: FC<Props> = ({ open, data, handleClose, accept }) => {
  const dispatch = useAppDispatch();

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [postDelete, { isSuccess: successPost, isLoading: loading, isError: deleteError }] = usePostContactMutation();

  useEffect(() => {
    if (successPost) {
      accept();
    }
    if (deleteError) {
      dispatch(setSnackBar({ value: true, msg: 'Το αίτημα διαγραφής απέτυχε!', severity: 'error' }));
    }
  }, [successPost, deleteError]);

  const handleAccept = () => {
    const params: ContactParams = {
      name: data.fullname,
      email: data.email,
      subject: 'Διαγραφή λογαριασμού χρήστη',
      message: `Αίτημα διαγραφής χρήστη με ID ${data.userId}.`,
    };
    postDelete({ body: params });
  };

  return (
    <IonModal
      mode={'ios'}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => handleClose()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '48px', boxSizing: 'border-box' }}>
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: smDown ? 4 : 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: smDown ? '0' : '16px',
          }}
        >
          <Box
            display="flex"
            textAlign={'center'}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={3}
            height={'100%'}
          >
            <Box gap={3} height={'100%'} display={'contents'}>
              <Box>
                <DeleteUser height={'104px'} width={'104px'} />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={mdDown ? 2 : 3}
              >
                <Typography
                  variant={mdDown ? 'mediumM' : 'largeMedium'}
                  color={'#313D53'}
                  fontWeight={700}
                  mt={-1}
                  component="h2"
                >
                  Είσαι σίγουρος/η ότι επιθυμείς τη διαγραφή του λογαριασμού σου;
                </Typography>
                <Typography
                  variant={mdDown ? 'subtitle1SmallR' : 'body2MediumR'}
                  textAlign={'center'}
                  color={'#313D53'}
                  component="p"
                >
                  Αυτό σημαίνει ότι δεν θα λάβεις ξανά πληρωμή από το Pigogo.{' '}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection={mdDown ? 'column' : 'row'} gap={2} width={'100%'}>
              <PigogoButton
                fullWidth
                text={'Όχι'}
                disabled={loading}
                variant={'outlined'}
                variation={'secondary'}
                onClick={() => handleClose()}
              />
              <PigogoButton
                fullWidth
                text={'Ναι'}
                disabled={loading}
                variation={'secondary'}
                onClick={() => handleAccept()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default DeleteModalUser;
