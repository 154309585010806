import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import React, { ReactNode } from 'react';
import { FC } from 'react';
import { theme } from 'components';

interface Props {
  icon: ReactNode;
  title: string;
  description: string;
}

const ReturnCard: FC<Props> = ({ icon, title, description }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper elevation={0} sx={{ padding: mdDown ? '24px' : '24px 48px 24px 32px', borderRadius: '24px' }}>
      <Box display="flex" alignItems="center" gap={mdDown ? 3 : 4}>
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: '#FCE5D8',
            borderRadius: '24px',
            width: smDown ? '120px' : 'inherit',
            height: smDown ? '120px' : 'inherit',
          }}
        >
          {icon}
        </Box>
        <Box display="flex" flexDirection="column" gap={1} justifyContent="center">
          <Typography color="primary" variant="largeMedium" component="h3">
            {title}
          </Typography>
          <Typography variant="body2MediumR" component="p" color={'#6F7787'}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default ReturnCard;
