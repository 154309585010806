import { Checkbox, FormControlLabel, MenuItem, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useLazyTotalShopsOfCategoryQuery } from '../../redux/api/queries/shopQuery';
import { ShopCategory } from '../../models/ShopCategory';
import { useLazySearchCountOfCategoryQuery } from '../../redux/api/queries/searchQueries';
import { useLazyTotalSavingsOfCategoryQuery } from '../../redux/api/queries/savingsQueries';
import { useIonRouter } from '@ionic/react';

interface PropsItem {
  offers?: boolean;
  searchKeyword: string;
  parentCategory?: string;
  subCategory: ShopCategory;
  selectedCategories: { slugName: string; label: string }[];
  setSelectedCategories: (categoriesChecked: { slugName: string; label: string }[]) => void;
}

const MenuItemCategory: FC<PropsItem> = ({
  offers = false,
  searchKeyword,
  parentCategory,
  subCategory,
  selectedCategories,
  setSelectedCategories,
}) => {
  const path = useIonRouter().routeInfo.pathname;
  const promoId = path.includes('/promo') ? path.split('/').slice(-1)[0] : null;

  const [shopsCountTrigger, { data: shopsCountData, isLoading: isLoadingShops, isError: isErrorShops }] =
    useLazyTotalShopsOfCategoryQuery();

  const [searchCountTrigger, { data: searchCountData, isLoading: isLoadingSearch, isError: isErrorSearch }] =
    useLazySearchCountOfCategoryQuery();

  const [savingsCountTrigger, { data: savingsCountData, isLoading: isLoadingSavings, isError: isErrorSavings }] =
    useLazyTotalSavingsOfCategoryQuery();

  useEffect(() => {
    if (searchKeyword) {
      searchCountTrigger({
        category: subCategory.slug.name,
        keyword: searchKeyword,
      });
    } else if (offers) {
      savingsCountTrigger({
        menuCategoryId: parentCategory ? parentCategory.toString() : subCategory.id.toString(),
        category: parentCategory ? subCategory.slug.name : undefined,
      });
    } else {
      shopsCountTrigger({
        menuCategoryId: parentCategory ? parentCategory.toString() : subCategory.id.toString(),
        category: parentCategory ? subCategory.slug.name : undefined,
      });
    }
  }, [
    offers,
    searchKeyword,
    parentCategory,
    subCategory.id,
    subCategory.slug.name,
    searchCountTrigger,
    shopsCountTrigger,
    savingsCountTrigger,
  ]);

  const handleCategories = (slugName: string, value: string) => {
    const find = selectedCategories.find((category) => category.slugName === slugName);
    if (find) {
      setSelectedCategories(selectedCategories.filter((e) => e.label !== value));
    } else {
      const newCategories = [...selectedCategories, { slugName, label: value }];
      setSelectedCategories(newCategories);
    }
  };

  const formLabel = () => {
    const loading = isLoadingShops || isLoadingSearch || isLoadingSavings;
    const error = isErrorShops || isErrorSearch || isErrorSavings;

    const count = searchCountData?.totalCount || shopsCountData?.totalCount || savingsCountData?.totalCount || 0;
    const countText = loading ? '...' : error ? 'N/A' : count;

    return promoId ? `${subCategory.label}` : `${subCategory.label} (${countText})`;
  };

  return (
    <MenuItem
      key={subCategory.id}
      className={
        selectedCategories.some((category) => category.slugName === subCategory.slug.name) ? 'Mui-selected' : ''
      }
      sx={{
        padding: '16px 24px',
        backgroundColor: selectedCategories.some((category) => category.slugName === subCategory.slug.name)
          ? '#EAECEE'
          : 'none',
      }}
    >
      <FormControlLabel
        label={formLabel()}
        sx={{
          marginLeft: 0,
          '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: 14,
            color: '#313D53',
          },
        }}
        control={
          <Checkbox
            color={'secondary'}
            checked={selectedCategories.some((category) => category.slugName === subCategory.slug.name)}
            onChange={() => handleCategories(subCategory.slug.name, subCategory.label)}
            sx={{
              '&': {
                padding: 0,
                width: '20px',
                height: '20px',
                marginRight: '10px',
                border: '1px solid #838B98',
                borderRadius: '4px',
                color: 'transparent',
                position: 'static',
              },
              '&.Mui-checked': {
                borderColor: '#E9688A',
                outline: '1px solid #E9688A',
                outlineOffset: '-2px',
              },
            }}
          />
        }
      />
    </MenuItem>
  );
};

export default MenuItemCategory;
