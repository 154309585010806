import { Box, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import AccordionCard from './AccordionCard';
import { PigogoButton, PigogoCheckboxCircle, PigogoDropdown, PigogoTextField, RichTooltip, theme } from 'components';
import { ReactComponent as InfoOutlined } from '../../../assets/svgs/info.svg';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ReactComponent as Unchecked } from '../../../assets/svgs/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svgs/checked.svg';
import { UserData } from '../../../models/User';
import { useUpdateUserMutation } from '../../../redux/api/mutations/userMutations';
import { useDispatch } from 'react-redux';
import { setLoad, setSnackBar, setStateSnackBar } from '../../../redux/slices/layoutSlice';
import { PaymentThreshold } from '../../../models/PaymentThreshold';
import { UpdateUserBankDetails } from '../../../redux/api/types/UpdateUserVivaDetails';
import { useVivaThresholdsMutation } from '../../../redux/api/mutations/paymentThresholdsMutation';
import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';

const initialToolTip = { openNumber: false, openBenef: false };

interface Props {
  userData?: UserData;
  loading: boolean;
  setUpdateError: (value: boolean) => void;
}

const VivaCard: FC<Props> = ({ userData, loading, setUpdateError }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [getVivaThresholds, thresholdsResult] = useVivaThresholdsMutation();
  const [updateVivaDetails, { isLoading: updateLoad, isError: updateError, error: updateErr, reset: resetUser }] =
    useUpdateUserMutation();

  const [mobile, setMobile] = useState('');
  const [threshold, setThreshold] = useState('');
  const [beneficiary, setBeneficiary] = useState('');
  const [leaving, setLeaving] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [defaultMethod, setDefaultMethod] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(initialToolTip);
  const [focusMobile, setFocusMobile] = useState<boolean>(false);
  const [errorMobile, setErrorMobile] = useState<boolean>(false);
  const [errorBeneficiary, setErrorBeneficiary] = useState<boolean>(false);
  const [errorThreshold, setErrorThreshold] = useState<boolean>(false);
  const [thresholds, setThresholds] = useState<PaymentThreshold[]>([]);

  const [isPhoneFieldSelected, setIsPhoneFieldSelected] = useState(false);

  const [resetError, setResetError] = useState<boolean>(true);

  useIonViewDidEnter(() => {
    setLeaving(false);
  });

  useIonViewDidLeave(() => {
    setLeaving(true);
  });

  useEffect(() => {
    if (leaving) {
      resetUser();
    }
  }, [leaving]);

  useEffect(() => {
    getVivaThresholds();
  }, []);

  useEffect(() => {
    if (updateError) {
      setUpdateError(true);
      const err = updateErr as any;
      if (err && err.data && !err.data.viva_wallet_phone_number) {
        const params = {
          value: true,
          msg: 'To αίτημα ενημέρωσης απέτυχε!',
          severity: 'error',
        };
        dispatch(setSnackBar(params));
      }

      if (err && err.data && err.data.viva_wallet_phone_number) {
        setResetError(false);
      }
    } else {
      setUpdateError(false);
      dispatch(setStateSnackBar(false));
    }
  }, [updateError]);

  useEffect(() => {
    if (thresholdsResult.data) {
      setThresholds(thresholdsResult.data);
    }

    if (thresholdsResult.isError) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά!', severity: 'error' };
      dispatch(setSnackBar(params));
    }
  }, [thresholdsResult.data, thresholdsResult.isError]);

  useEffect(() => {
    dispatch(setLoad(updateLoad));
  }, [updateLoad]);

  useEffect(() => {
    const {
      viva_wallet_phone_number = null,
      viva_wallet_beneficiary = null,
      payment_method_id = null,
    } = userData ? userData : {};

    if (viva_wallet_phone_number) {
      setDefaultMethod(payment_method_id === 3);
    }

    setMobile(viva_wallet_phone_number ?? '');
    setBeneficiary(viva_wallet_beneficiary ?? '');
  }, [userData]);

  useEffect(() => {
    if (thresholds) {
      const threshold = thresholds.find((t) => t.id === userData?.payment_threshold_viva_wallet_id);
      setThreshold(threshold?.value ?? '');
    }
  }, [userData, thresholds]);

  const getThresholds = useCallback(() => {
    return thresholds.map((threshold) => threshold.value);
  }, [thresholds]);

  const hasData = useCallback(() => {
    return !!(mobile || beneficiary || threshold);
  }, [mobile, beneficiary, threshold]);

  useEffect(() => {
    setResetError(true);
  }, [mobile]);

  useEffect(() => {
    setErrorBeneficiary(false);
  }, [beneficiary]);

  useEffect(() => {
    setErrorThreshold(false);
  }, [threshold]);

  // const MouseOver = () => {
  //   setOpenToolTip({ ...openToolTip, openNumber: !openToolTip.openNumber });
  // };

  // const MouseOut = () => {
  //   setOpenToolTip({ ...openToolTip, openNumber: false });
  // };

  const [open, setOpen] = useState<boolean>(false);

  const MouseOver = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const MouseOut = () => {
    setOpen(false);
  };

  const [openMob, setOpenMob] = useState<boolean>(false);

  const MouseOverMob = () => {
    setOpenMob((previousOpen) => !previousOpen);
  };

  const MouseOutMob = () => {
    setOpenMob(false);
  };

  const disabled = () => {
    if (userData?.viva_wallet_phone_number) {
      const prevDefaultMethod = userData?.payment_method_id === 3;
      const prevThreshold = thresholds.find((t) => t.id === userData?.payment_threshold_viva_wallet_id);

      const thresholdEqual = threshold === prevThreshold?.value;
      const equalDefaultMethod = defaultMethod === prevDefaultMethod;
      const equalPhone = mobile === userData.viva_wallet_phone_number;
      const equalBenefiaciary = beneficiary === userData.viva_wallet_beneficiary;

      /* eslint-disable */
      return (
        (equalPhone && equalBenefiaciary && equalDefaultMethod && thresholdEqual) ||
        (!isMobile && updateErr && (updateErr as any).data && !resetError) ||
        errorMobile ||
        errorBeneficiary ||
        errorThreshold
      );
    }
    return (
      errorMobile ||
      threshold === '' ||
      beneficiary === '' ||
      mobile === '' ||
      errorMobile ||
      errorBeneficiary ||
      errorThreshold ||
      (!isMobile && updateErr && (updateErr as any).data && !resetError)
    );

    /* eslint-enable */
  };

  const handleDefaultPaymentMethod = () => {
    updateVivaDetails({ payment_method_id: '3' });
  };

  const handleSubmit = () => {
    const data: UpdateUserBankDetails = {
      viva_wallet_country_code: 'GR',
      viva_wallet_phone_number: mobile.trim(),
      viva_wallet_beneficiary: beneficiary.trim(),
      payment_threshold_id: thresholds.find((thresholdItem) => thresholdItem.value === threshold)?.id || '',
    };

    let hasError = false;

    if (defaultMethod) {
      data.payment_method_id = 3;
    }

    if (data.viva_wallet_phone_number === '' || !data.viva_wallet_phone_number.startsWith('69')) {
      setErrorMobile(true);
      hasError = true;
    }

    if (
      data.viva_wallet_beneficiary === '' ||
      data.viva_wallet_beneficiary.length < 2 ||
      data.viva_wallet_beneficiary.length > 200 ||
      !data.viva_wallet_beneficiary.trim().match(/^[\p{L}\s.]+$/u)
    ) {
      setErrorBeneficiary(true);
      hasError = true;
    }

    if (data.payment_threshold_id === '') {
      setErrorThreshold(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (!userData?.payment_bank_iban) {
      data.payment_method_id = 3;
      setDefaultMethod(true);
    } else if (defaultMethod) {
      data.payment_method_id = 3;
    }

    updateVivaDetails(data);
    setExpanded(true);
  };

  const handleCancelClick = () => {
    const {
      viva_wallet_phone_number = null,
      viva_wallet_beneficiary = null,
      payment_method_id = null,
    } = userData ? userData : {};

    setErrorMobile(false);
    setErrorBeneficiary(false);
    setErrorThreshold(false);

    setMobile(viva_wallet_phone_number ?? '');
    setBeneficiary(viva_wallet_beneficiary ?? '');
    setDefaultMethod(payment_method_id === 3);
    setExpanded(false);
  };

  const determineErrorMsg = () => {
    if (errorMobile && !mobile.startsWith('69') && mobile.length === 10) {
      return 'Ο αριθμός δεν φαίνεται να αντιστοιχεί σε ελληνικό κινητό.';
    } else if (errorMobile && mobile.length === 0) {
      return 'Το πεδίο είναι υποχρεωτικό.';
    } else if (errorMobile) {
      return 'Το κινητό δεν φαίνεται να είναι σωστό.';
    } else if (!isMobile && updateErr && (updateErr as any).data && !resetError) {
      return (
        'Το συγκεκριμένο κινητό τηλέφωνο δεν μπορεί να χρησιμοποιηθεί. Παρακαλούμε χρησιμοποίησε' +
        ' κάποιο άλλο ή επικοινώνησε μαζί μας.'
      );
    }

    return '';
  };

  const determineBeneficiaryErrorMsg = () => {
    if (errorBeneficiary && beneficiary.trim() === '') {
      return 'Το πεδίο είναι υποχρεωτικό.';
    } else if (errorBeneficiary && beneficiary.trim().length < 2) {
      return 'Το πεδίο πρέπει να αποτελείται από τουλάχιστον 2 χαρακτήρες.';
    } else if (errorBeneficiary && beneficiary.trim().length > 200) {
      return 'Οι χαρακτήρες δεν πρέπει να ξεπερνούν τους 200.';
    } else if (errorBeneficiary && !beneficiary.trim().match(/^[\p{L}\s.]+$/u)) {
      return 'O δικαιούχος πρέπει να αποτελείται από αλφαβητικούς χαρακτήρες.';
    }

    return '';
  };

  const getButtons = () => {
    return (
      <Box display={'flex'} alignItems={'center'} gap={2}>
        {hasData() && (
          <Box>
            <PigogoButton
              fullWidth
              simpleButton
              text={'Ακύρωση'}
              variant={'outlined'}
              onClick={() => handleCancelClick()}
              sx={{ padding: '16px 24px', borderBottom: '1px solid black' }}
            />
          </Box>
        )}
        <Box>
          <PigogoButton
            fullWidth
            simpleButton
            text={'Αποθήκευση'}
            disabled={disabled()}
            onClick={() => handleSubmit()}
            sx={{ padding: '16px 24px' }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <AccordionCard
        bank={false}
        loading={loading}
        minimumAmount={1}
        hasData={hasData()}
        paymentDetails={{
          mobile: mobile,
          beneficiary: beneficiary,
          threshold: threshold,
        }}
        isExpanded={expanded}
        setIsExpanded={(value) => (value ? setExpanded(value) : handleCancelClick())}
        defaultMethod={userData?.payment_method_id === 3 && !!userData?.viva_wallet_phone_number}
        setCheck={() => handleDefaultPaymentMethod()}
      >
        <Box display={'flex'} flexDirection={'column'} gap={5} pt={3}>
          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <Box display={'flex'} alignItems={'flex-start'} gap={2}>
              <Box width={isMobile ? '271px' : '316px'}>
                <PigogoTextField
                  error={errorMobile || (!isMobile && updateErr && (updateErr as any).data && !resetError)}
                  helperText={determineErrorMsg()}
                  fullWidth
                  InputProps={
                    isPhoneFieldSelected || mobile !== ''
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography variant={'subtitle1SmallM'} lineHeight={'24px'} color={'#313D53'}>
                                +30
                              </Typography>
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                  inputProps={{
                    onFocus: () => {
                      setErrorMobile(false);
                      setFocusMobile(true);
                    },
                    onBlur: () => {
                      if ((mobile.length !== 10 && mobile.length !== 0) || !mobile.startsWith('69')) {
                        setErrorMobile(true);
                      } else {
                        setErrorMobile(false);
                      }
                    },
                  }}
                  size={'medium'}
                  value={mobile}
                  setValue={(value: string) => setMobile(value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1'))}
                  label="Κινητό"
                  onFocus={(e) => setIsPhoneFieldSelected(true)}
                  onBlur={(e) => setIsPhoneFieldSelected(false)}
                />
              </Box>
              <Box
                onMouseOver={MouseOverMob}
                onClick={MouseOverMob}
                onMouseOut={MouseOutMob}
                sx={{
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                  cursor: 'pointer',
                  alignSelf: 'flex-start',
                  marginTop: 2,
                  position: 'relative',
                  zIndex: '999',
                  '&:before': {
                    content: '""',
                    width: 'calc(100% + 12px)',
                    height: 'calc(100% + 12px)',
                    position: 'absolute',
                    top: '-6px',
                    left: '-6px',
                    zIndex: '999',
                  },
                }}
              >
                <RichTooltip
                  content={
                    <Typography variant={'caption'} component="p">
                      Συμπλήρωσε τον αριθμό κινητού τηλεφώνου που αντιστοιχεί στο Viva Wallet στο οποίο θες να γίνει η
                      πληρωμή σου.
                    </Typography>
                  }
                  open={openMob}
                  placement="bottom"
                  color="dark"
                  onClose={MouseOutMob}
                >
                  <InfoOutlined />
                </RichTooltip>
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <Box width={isMobile ? '271px' : '316px'}>
                <PigogoTextField
                  fullWidth
                  size={'medium'}
                  label="Δικαιούχος"
                  value={beneficiary}
                  error={errorBeneficiary}
                  helperText={determineBeneficiaryErrorMsg()}
                  setValue={(value: string) => {
                    setErrorBeneficiary(false);
                    setBeneficiary(value);
                  }}
                  inputProps={{
                    onFocus: () => {
                      setErrorBeneficiary(false);
                    },
                    onBlur: () => {
                      if (
                        beneficiary.trim().length < 2 ||
                        beneficiary.trim().length > 200 ||
                        !beneficiary.trim().match(/^[\p{L}\s.]+$/u) ||
                        beneficiary.trim() === ''
                      ) {
                        setErrorBeneficiary(true);
                      } else {
                        setErrorBeneficiary(false);
                      }
                    },
                  }}
                />
              </Box>
              <Box
                onMouseOver={MouseOver}
                onClick={MouseOver}
                onMouseOut={MouseOut}
                sx={{
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                  cursor: 'pointer',
                  alignSelf: 'flex-start',
                  marginTop: 2,
                  position: 'relative',
                  zIndex: '999',
                  '&:before': {
                    content: '""',
                    width: 'calc(100% + 12px)',
                    height: 'calc(100% + 12px)',
                    position: 'absolute',
                    top: '-6px',
                    left: '-6px',
                    zIndex: '999',
                  },
                }}
              >
                <RichTooltip
                  content={
                    <Typography variant={'caption'} component="p">
                      Συμπλήρωσε το όνομα του δικαιούχου, όπως αυτό εμφανίζεται στο Viva Wallet σου.
                    </Typography>
                  }
                  open={open}
                  placement="bottom"
                  color="dark"
                  onClose={() => setOpen(false)}
                >
                  <InfoOutlined />
                </RichTooltip>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography variant={'mediumM'} component="h4" color={'#1D2532'}>
                Ελάχιστο ποσό πληρωμής
              </Typography>
              <Typography variant={'subtitle1SmallR'} component="p" color={'#313D53'}>
                Διάλεξε το ελάχιστο ποσό που πρέπει να συγκεντρώσεις για να πραγματοποιηθεί η πληρωμή σου.
              </Typography>
            </Box>

            <Box width={'316px'} maxWidth={'100%'}>
              <PigogoDropdown
                width={'100%'}
                size={'medium'}
                options={getThresholds()}
                value={threshold}
                setSelected={(value: string) => {
                  setThreshold(value);
                  setErrorThreshold(false);
                }}
                label="Επίλεξε ποσό"
                error={errorThreshold}
                helperText={errorThreshold ? 'Το πεδίο είναι υποχρεωτικό.' : ''}
              />
            </Box>
          </Box>

          <Box display={'flex'} alignItems={smDown ? 'flex-start' : 'center'} gap={2} sx={{ position: 'relative' }}>
            <Box
              sx={{
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
              }}
            >
              <PigogoCheckboxCircle
                inputProps={{
                  disableRipple: true,
                  color: 'secondary',
                  icon: <Unchecked />,
                  checkedIcon: <CheckedIcon />,
                  sx: { '&': { width: '100%', height: '100%', alignItems: 'center !important', position: 'static' } },
                }}
                checked={defaultMethod && !!userData?.viva_wallet_phone_number}
                setValue={() =>
                  setDefaultMethod((prevState) => {
                    return !prevState;
                  })
                }
              />
            </Box>

            {(userData?.payment_method_id !== 3 || !userData?.viva_wallet_phone_number) && (
              <Typography variant={'mediumM'} component="p" color={'#313D53'}>
                Ορισμός ως επιλεγμένου τρόπος πληρωμής
              </Typography>
            )}
            {userData?.payment_method_id === 3 && !!userData?.viva_wallet_phone_number && (
              <Typography variant={'mediumM'} component="p" color={'#313D53'}>
                Επιλεγμένος τρόπος πληρωμής
              </Typography>
            )}
          </Box>
          {getButtons()}
        </Box>
      </AccordionCard>
    </Box>
  );
};

export default VivaCard;
