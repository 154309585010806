import { Box, Typography } from '@mui/material';
import { useGetUserDataQuery } from '../../../redux/api/queries/userQueries';
import { ReactComponent as Warning } from '../../../assets/svgs/warningWhite.svg';

const WarningMessage = () => {
  const { data } = useGetUserDataQuery();

  const warningMessage = data?.user_warning_message;

  return warningMessage ? (
    <Box display="flex" gap={2} p={2} borderRadius="16px" alignItems={'center'} sx={{ backgroundColor: '#FEEEF1' }}>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          display: 'inlineFlex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        }}
      >
        <Warning />
      </Box>
      <Typography variant="subtitle1SmallR" component="p" role="alert" color="#BC415A">
        {warningMessage}
      </Typography>
    </Box>
  ) : null;
};

export default WarningMessage;
