import { pigogoApi } from '../Api';
import { ShopCategory } from '../../../models/ShopCategory';
import { GenericRequest } from '../../../models/GenericRequest';

const categoriesApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    allCategories: builder.query<GenericRequest<ShopCategory[]>, { filter: string }>({
      query: ({ filter }) => ({
        method: 'GET',
        url: '/menu-categories',
        params: { filter },
      }),
      transformResponse(response: ShopCategory[], meta) {
        return {
          data: response,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')),
          nextCursor: undefined,
        };
      },
      providesTags: ['allCategories'],
    }),
    selectedCategory: builder.query<ShopCategory, { id: string }>({
      query: ({ id }) => ({
        method: 'GET',
        url: `/menu-categories/${id}`,
      }),
    }),
    childrenCategory: builder.query<GenericRequest<ShopCategory[]>, { menuCategoryId: string }>({
      query: ({ menuCategoryId }) => ({
        method: 'GET',
        url: `/menu-categories/${menuCategoryId}/children`,
      }),
      transformResponse(response: ShopCategory[], meta) {
        return {
          data: response,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')),
          nextCursor: undefined,
        };
      },
      providesTags: ['allCategories'],
    }),
  }),
});

export const {
  useAllCategoriesQuery,
  useLazyAllCategoriesQuery,
  useLazySelectedCategoryQuery,
  useLazyChildrenCategoryQuery,
} = categoriesApi;
