import React, { FC, useEffect } from 'react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { CashbackCategory } from '../../models/CashbackCategory';
import { Assets, theme } from 'components';
import { useIonViewWillEnter } from '@ionic/react';

const StyleBox = styled(Box)(() => ({
  width: '100%',
  boxSizing: 'border-box',
  background: '#FEF6F1',
  borderRadius: 24,
  padding: '40px 32px',
  display: 'flex',
  flexDirection: 'column',
}));

type StoreCardProps = {
  data: CashbackCategory[];
};

const PigogoReturnMoney: FC<StoreCardProps> = ({ data }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const renderStrikedCashback = (item: CashbackCategory, index: number) => {
    return (
      <Box key={index} display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'} gap={2} py={1}>
        <Box display={'flex'} alignItems={'center'} flexDirection={smDown ? 'column' : 'row'}>
          <Typography
            variant={'body2'}
            component="p"
            sx={{
              color: '#E9688A',
              minWidth: '64px',
              textAlign: 'center',
              marginInline: '8px',
            }}
          >
            {`${item.cashback}${item.currency}`}
          </Typography>
          {item.strikedCashback && item.strikedCurrency && (
            <Typography
              variant={'subtitle1'}
              component="p"
              sx={{ color: '#838B98', textDecorationLine: 'line-through', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              {`${item.strikedCashback || ''}${item.strikedCurrency || ''}`}
            </Typography>
          )}
        </Box>
        <Box flexGrow={1}>
          <Box flexGrow={1} className={'description'}>
            <Typography variant={'body2MediumR'} component="p" color={'#1D2532'}>
              {item.description}
            </Typography>
          </Box>
          {data.length - 1 !== index && (
            <Box height={'1px'} bgcolor={'#EAECEE'} position={'relative'} top={'24px'}></Box>
          )}
        </Box>
      </Box>
    );
  };
  const renderList = () => {
    return data.map((item, index) => renderStrikedCashback(item, index));
  };

  return (
    <StyleBox>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} paddingBottom={2}>
        <Box>
          <Typography variant="large900" component="h3" color={'#1D2532'}>
            Επιστροφή χρημάτων
          </Typography>
        </Box>
        <Box
          sx={{ width: '48px', height: '48px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {<Assets.EuroPink />}
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={4}>
        {renderList()}
      </Box>
    </StyleBox>
  );
};

export default PigogoReturnMoney;
