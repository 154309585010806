import React, { FC } from 'react';
import { Dialog, IconButton, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import { IonHeader, IonToolbar } from '@ionic/react';
import { Close } from '@mui/icons-material';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const VideoModal: FC<Props> = ({ open, handleClose }) => {
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const dmDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxHeight: 'calc(100vh - 40px)',
          maxWidth: '870px',
          padding: '40px 0 0',
          margin: 0,
          background: 'none',
          boxShadow: 'none',
        },
      }}
      onClose={handleClose}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': 'transparent',
            '--min-height': '50px',
            position: 'absolute',
            width: '40px',
            right: '0',
            top: '-45px',
          }}
        >
          <IconButton
            className="iconBtn"
            sx={{ position: 'absolute', right: 0, top: 8, color: 'white' }}
            disableRipple
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <iframe
        style={{
          border: '0',
          borderRadius: '4px',
          backgroundColor: 'white',
          padding: '8px',
          boxShadow:
            '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
          maxWidth: '100%',
        }}
        width={dmDown ? (xsOnly ? '415' : '700') : '870'}
        height={dmDown ? (xsOnly ? '237' : '399') : '496'}
        src="https://www.youtube.com/embed/_NdEcaSDZLM?autoplay=1?controls=0 "
      ></iframe>
    </Dialog>
  );
};

export default VideoModal;
