import React, { FC, Ref } from 'react';
import PigogoIconButton from './PigogoIconButton';
import { Box } from '@mui/material';
import Carousel, { StateCallBack } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import theme from '../theme/index';
import useMediaQuery from '@mui/material/useMediaQuery';
import PigogoCustomDot from './PigogoCustomDot';
import { ChevronLeft, ChevronRight } from '../assets';

const responsiveDefaults = {
  desktop: {
    items: 4,
    slidesToSlide: 4,
    partialVisibilityGutter: 40,
    breakpoint: { max: 3000, min: 1116 },
  },
  tablet: {
    items: 3,
    slidesToSlide: 3,
    breakpoint: { max: 1115, min: 601 },
  },
  mobile: {
    items: 2,
    slidesToSlide: 2,
    breakpoint: { max: 600, min: 0 },
  },
};

const ButtonGroupDefaults = ({ next, previous }: { next?: any; previous?: any }) => {
  return (
    <Box
      top={'50%'}
      left={'50%'}
      width={'110%'}
      display={'flex'}
      position={'absolute'}
      justifyContent={'space-between'}
      sx={{ transform: 'translate(-50%,-50%)', zIndex: '-1' }}
    >
      <PigogoIconButton
        props={{ sx: { background: 'transparent', color: '#313D53' } }}
        icon={<ChevronLeft />}
        handleClick={() => previous()}
      />
      <PigogoIconButton
        props={{ sx: { background: 'transparent', color: '#313D53' } }}
        icon={<ChevronRight />}
        handleClick={() => next()}
      />
    </Box>
  );
};

interface Props {
  responsive?: any;
  children: React.ReactNode;
  beforeChange?: (previousSlide: number, state: StateCallBack) => void;
  afterChange?: (previousSlide: number, state: StateCallBack) => void;
  partialVisible?: boolean;
  carouselRef?: Ref<any>;
}

const PigogoCardsCarousel: FC<Props> = ({
  children,
  responsive,
  partialVisible = false,
  beforeChange,
  afterChange,
  carouselRef,
}) => {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Box position="relative">
      <Carousel
        ref={carouselRef}
        showDots
        arrows={false}
        swipeable={true}
        draggable={true}
        deviceType={'desktop'}
        keyBoardControl={true}
        renderDotsOutside={true}
        transitionDuration={500}
        renderButtonGroupOutside={true}
        shouldResetAutoplay={false}
        customDot={<PigogoCustomDot />}
        containerClass="carousel-container"
        partialVisible={matchesMd || matches || partialVisible}
        responsive={responsive ? responsive : responsiveDefaults}
        customButtonGroup={matches ? undefined : <ButtonGroupDefaults />}
        afterChange={(previousSlide, state) => (afterChange ? afterChange(previousSlide, state) : null)}
        beforeChange={(previousSlide, state) => (beforeChange ? beforeChange(previousSlide, state) : null)}
      >
        {children}
      </Carousel>
    </Box>
  );
};

export default PigogoCardsCarousel;
