import { Box, BoxProps, Skeleton, styled, Typography, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import el from 'moment/locale/el';
import { Offer } from '../../models/Offer';
import { ReactComponent as Arrow_right_white } from './../../assets/svgs/vectors/arrow_right_white.svg';
import { Assets, PigogoNavigationIconButton, theme } from 'components';
import { getColorDate } from '../../utils/getColorDate';
import { isWeb } from '../../utils/device';
import { Browser } from '@capacitor/browser';
import { useAppSelector } from '../../redux/hooks';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 32px',
  background: '#F7F7F8',
  borderRadius: 24,
  gap: theme.spacing(2),
}));

type StoreCardProps = {
  loading?: boolean;
  title?: string;
  data?: Offer[];
  handleButtonOffer?: () => void;
  footerRedirLink?: (val: string) => void;
};

const PigogoOfferTagCard: FC<StoreCardProps & BoxProps> = ({
  loading,
  title,
  data,
  handleButtonOffer,
  footerRedirLink,
}) => {
  moment.updateLocale('el', el);
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const renderNoData = () => {
    return (
      <Box mt={downSm ? 0 : -3} maxWidth={'calc(100% - 48px)'}>
        <Typography variant="subtitle1SmallL" component="p" color={'#1D2532'}>
          Δεν υπάρχουν διαθέσιμες προσφορές αυτή τη στιγμή.
        </Typography>
        <Typography variant="subtitle1SmallL" component="p" color={'#1D2532'}>
          Πρόσθεσε το κατάστημα στα Αγαπημένα σου και θα ενημερωθείς μόλις έχει διαθέσιμες προσφορές.
        </Typography>
      </Box>
    );
  };

  const renderData = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        sx={{
          '&>:nth-of-type(n)': {
            borderBottom: '1px solid #EAECEE',
          },
          '&> :last-child': {
            borderBottom: 'none',
          },
        }}
      >
        {loading && (
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'100%'} />
            <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'100%'} />
            <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'100%'} />
          </Box>
        )}
        {data &&
          !loading &&
          data.map((item, index) => (
            <Box
              gap={downMd ? 3 : 5}
              key={index}
              display={'flex'}
              alignItems={downMd ? 'flex-start' : 'center'}
              justifyContent={'space-between'}
              flexDirection={downMd ? 'column' : 'row'}
              paddingBottom={2}
              id={item.id.toString()}
              sx={{
                '&:last-child': {
                  paddingBottom: 0,
                },
              }}
            >
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box>
                  <Typography variant={'body2'} component="h4" color={'#1D2532'}>
                    {item.title}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant={'body2MediumL'} component="p" color={'#1D2532'}>
                    {item.description}
                  </Typography>
                </Box>
                {item.end_date && (
                  <Box>
                    <Typography variant={'caption'} component="p" color={getColorDate(item.expiration_class)}>
                      έως {moment(item.end_date).format('LL')}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                {isWeb() && (!!accessToken || sessionLoggedIn) && (
                  <a href={item.url} target="_blank">
                    <PigogoNavigationIconButton
                      simple
                      icon={<Arrow_right_white />}
                      handleClick={() => {
                        handleButtonOffer && handleButtonOffer();
                      }}
                    />
                  </a>
                )}
                {(!isWeb() || (!accessToken && !sessionLoggedIn)) && (
                  <PigogoNavigationIconButton
                    simple
                    icon={<Arrow_right_white />}
                    handleClick={async () => {
                      handleButtonOffer && handleButtonOffer();
                      footerRedirLink && footerRedirLink(item.url);
                      if (!!accessToken || sessionLoggedIn) {
                        await Browser.open({ url: item.url });
                      }
                    }}
                  />
                )}
              </Box>
            </Box>
          ))}
      </Box>
    );
  };

  return (
    <>
      <Box marginTop={2}></Box>
      <StyleBox>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <Typography variant="large900" component="h3" color={'#1D2532'}>
              {loading ? (
                <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'20%'} />
              ) : (
                title
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '48px',
              height: '48px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading ? (
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'50%'} />
            ) : (
              <Assets.TagPink />
            )}
          </Box>
        </Box>
        {data && data.length === 0 ? renderNoData() : renderData()}
      </StyleBox>
    </>
  );
};

export default PigogoOfferTagCard;
