import { pigogoApi } from '../Api';

interface DecryptResponse {
  shopName: string | null;
}

interface DecryptParams {
  name: string;
}

interface GetNotificationsResponse {
  notification_newsletter: boolean;
  notification_new_payment: boolean;
  notification_new_purchase: boolean;
}

interface GetNotificationsParams {
  email: string;
}

const unsubscribeApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    decrypt: builder.query<DecryptResponse, DecryptParams>({
      query: (args) => {
        const { name } = args;
        return {
          method: 'GET',
          url: '/users/actions/decrypt-token',
          params: { name },
        };
      },
    }),
    getUserNotifications: builder.query<GetNotificationsResponse, GetNotificationsParams>({
      query: (args) => {
        const { email } = args;
        return {
          method: 'GET',
          url: '/users/actions/get-notifications',
          params: { email },
        };
      },
      providesTags: ['notifications'],
    }),
  }),
});

export const { useLazyDecryptQuery, useDecryptQuery, useLazyGetUserNotificationsQuery } = unsubscribeApi;
