import {
  IonContent,
  IonGrid,
  IonPage,
  isPlatform,
  useIonRouter,
  IonHeader,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react';
import { ReactComponent as Right } from '../assets/svgs/vectors/right.svg';
import { Box, Button, Divider, Icon, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Facebook } from '../assets/svgs/social/facebook.svg';
import { ReactComponent as Twitter } from '../assets/svgs/social/twitter.svg';
import { ReactComponent as Instagram } from '../assets/svgs/social/instagram.svg';
import { ReactComponent as Youtube } from '../assets/svgs/social/youTube.svg';
import { ReactComponent as GooglePlay } from '../assets/svgs/app/googlePlay.svg';
import { ReactComponent as AppStore } from '../assets/svgs/app/appStore.svg';
import { theme } from 'components';
import React, { FC, useRef } from 'react';
import SocialLinks from '../components/SocialLinks';
import { Link, useHistory } from 'react-router-dom';
import { isWeb } from '../utils/device';
import { Helmet } from 'react-helmet-async';

const row1 = isWeb()
  ? [
      {
        text: 'Pigogo ALERT!',
        url: '/alert',
      },
      {
        text: 'Pigogo app',
        url: '/mobile-app',
      },
    ]
  : [
      {
        text: 'Pigogo ALERT!',
        url: '/alert',
      },
    ];
const row2 = [
  {
    text: 'Πώς λειτουργεί',
    url: '/how-it-works',
  },
  {
    text: 'Συχνές ερωτήσεις',
    url: '/faq',
  },
  {
    text: 'Επικοινώνησε μαζί μας',
    url: '/contact',
  },
];
const row3 = [
  {
    text: 'Όροι χρήσης',
    url: '/terms',
  },
  {
    text: 'Πολιτική Απορρήτου',
    url: '/privacy',
  },
  {
    text: 'Cookies',
    url: '/privacy?cookies=true',
  },
];

type InformationProps = {
  hasLogOut: boolean;
  handleLogout: () => void;
};
const Information: FC<InformationProps> = ({ hasLogOut = false, handleLogout }) => {
  const router = useIonRouter();

  const desktop = isPlatform('desktop');
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (localStorage.getItem('tab-changed') === '1') {
      contentRef.current && contentRef.current.scrollToTop();
    } else {
      setTimeout(() => {
        history.action !== 'PUSH' &&
          contentRef.current &&
          contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
      }, 10);
    }
    localStorage.removeItem('tab-changed');
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  return (
    <IonPage>
      {matches && (
        <IonHeader class="ion-no-border">
          <IonToolbar
            style={{
              '--background': '#FFFFFF',
              '--border-width': 0,
              paddingTop: '0 + --ion-safe-area-top',
            }}
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
              <Box minWidth={'24px'} height={'24px'}></Box>
              <Typography component="h2" variant="body2" color={'#313D53'}>
                Πληροφορίες
              </Typography>
              <Box minWidth={'24px'}></Box>
            </Box>
          </IonToolbar>
        </IonHeader>
      )}
      {location.pathname === '/information' && (
        <Helmet>
          <title>Πληροφορίες | Pigogo - Επιστροφή & προσφορές</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <IonGrid style={{ '--ion-grid-padding': '24px' }}>
          <Typography component="h3" variant="subtitle1SmallR" mb={'8px'} color={'#838B98'}>
            TO PIGOGO
          </Typography>
          <ul className="noStyleList" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {row1.map((item) => (
              <li className="noStyleList__item" key={item.text}>
                <Link to={item.url} className="mobMenuLink">
                  {item.text}
                  <Icon
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '24px',
                      height: '24px',
                    }}
                  >
                    <Right />
                  </Icon>
                </Link>
              </li>
            ))}
          </ul>
          <Divider sx={{ my: 3, mx: 0, borderColor: '#EAECEE' }} variant="middle" />
          <Typography component="h3" variant="subtitle1SmallR" mb={'8px'} color={'#838B98'}>
            ΒΟΗΘΕΙΑ & ΕΠΙΚΟΙΝΩΝΙΑ
          </Typography>
          <ul className="noStyleList" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {row2.map((item) => (
              <li className="noStyleList__item" key={item.text}>
                <Link to={item.url} className="mobMenuLink">
                  {item.text}
                  <Icon
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '24px',
                      height: '24px',
                    }}
                  >
                    <Right />
                  </Icon>
                </Link>
              </li>
            ))}
          </ul>
          <Divider sx={{ my: 3, mx: 0, borderColor: '#EAECEE' }} variant="middle" />
          <ul className="noStyleList" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {row3.map((item) => (
              <li className="noStyleList__item" key={item.text}>
                <Link to={item.url} className="mobMenuLink">
                  {item.text}
                  <Icon
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '24px',
                      height: '24px',
                    }}
                  >
                    <Right />
                  </Icon>
                </Link>
              </li>
            ))}
          </ul>
          <Divider sx={{ my: 3, mx: 0, borderColor: '#EAECEE' }} variant="middle" />
          <Typography component="h3" variant="subtitle1SmallR" mb={'8px'} color={'#838B98'}>
            ΑΚΟΛΟΥΘΗΣΕ ΜΑΣ
          </Typography>
          <SocialLinks />
          {isWeb() && (
            <>
              <Divider sx={{ my: 3, mx: 0, borderColor: '#EAECEE' }} variant="middle" />
              <Typography component="h3" variant="subtitle1SmallR" mb={'8px'} color={'#838B98'}>
                PIGOGO APP
              </Typography>
              <Box display={'flex'} gap={2}>
                <a
                  className="appLink"
                  href="https://play.google.com/store/apps/details?id=gr.pigogo.android"
                  target="_blank"
                >
                  <GooglePlay />
                </a>
                <a className="appLink" href="https://apps.apple.com/us/app/pigogo/id6475775568" target="_blank">
                  <AppStore />
                </a>
              </Box>
            </>
          )}
          {hasLogOut && (
            <>
              <Divider sx={{ my: 3, mx: 0, borderColor: '#EAECEE' }} variant="middle" />
              <Button onClick={handleLogout} sx={{ p: 0 }}>
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ textDecoration: 'underline', textDecorationColor: '#FCE5D8' }}
                >
                  Έξοδος
                </Typography>
              </Button>
            </>
          )}
          {/* <Box pt={2}>
            <Typography variant="subtitle1SmallR" pl={2} color={'#838B98'}>
              Έκδοση 1.2.1
            </Typography>
          </Box> */}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Information;
