import React, { FC, ReactNode } from 'react';
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

interface Props {
  title: ReactNode;
  icon: ReactNode;
  subtitle?: ReactNode;
}

const HeroCardItem: FC<Props> = ({ title, subtitle, icon }) => {
  return (
    <ListItem disableGutters disablePadding sx={{ py: '0', gap: 2 }}>
      <ListItemAvatar sx={{ margin: 0, minWidth: '48px' }}>
        <Avatar sx={{ backgroundColor: '#FDF0F3', height: '48px', width: '48px' }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          margin: 0,
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: 1,
          '& .MuiListItemText-secondary': {
            lineHeight: 1,
          },
        }}
        primary={
          <Typography variant="body2MediumR" color={'#313D53'}>
            <Box alignItems="center" display="flex" gap={1}>
              {title}
            </Box>
          </Typography>
        }
        secondary={subtitle ? subtitle : undefined}
      />
    </ListItem>
  );
};

export default HeroCardItem;
