import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, styled, TextFieldProps } from '@mui/material';
import EyeStriked from '../assets/EyeStriked';
import EyeVisible from '../assets/EyeVisible';

const StyledTextField = styled(TextField)<TextFieldProps>(({ error, InputProps, label, value, theme }) => ({
  '& .MuiInputLabel-root': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    paddingLeft: '4px',
    paddingRight: '4px',
    backgroundColor: '#ffffff',
    color: error ? '#F75676' : theme.palette.primary.main,
    top: 0,
    left: '6px',
    '&.Mui-focused': {
      top: 0,
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    paddingLeft: '4px',
    paddingRight: '4px',
    backgroundColor: '#ffffff',
    color: error ? '#F75676' : theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    color: error ? '#F75676' : theme.palette.primary.main,
    WebkitTextFillColor: error ? '#F75676 !important' : theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    display: 'flex',
    height: '56px',
    borderRadius: '56px',
    gap: InputProps?.startAdornment ? theme.spacing(1) : theme.spacing(1),
    padding: InputProps?.startAdornment ? '16px 16px' : '16px 24px',

    '&.Mui-focused fieldset': {
      paddingLeft: '18px',
      border: error ? '1px solid #F75676' : '1px solid #313D53',
    },
    fieldset: {
      legend: {
        span: {
          padding: label ? '0' : '0',
        },
      },
      paddingLeft: '18px',
      border: error ? '1px solid #F75676' : '1px solid #838B98',

      fontSize: '14px',
      fontWeight: 600,
      span: {
        padding: '0',
      },
    },
  },
  '& .MuiInputAdornment-root': {
    width: '24px',
    minWidth: '24px',
    height: '24px',
    display: 'flex',
    marginRight: '0',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    margin: '8px 24px 0 24px',
    color: '#838B98',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#F75676',
  },
}));

interface Props {
  label?: string;
  value: string | null;
  bold?: boolean;
  setValue?: (value: string) => void;
  size?: 'small' | 'medium';
  placeholder?: string;
  helperText?: string | any;
  error?: boolean;
  readonly?: boolean;
  numeric?: boolean;
  name?: string;
  autoComplete?: string;
}

const inputStyle = {
  borderRadius: '0px',
  WebkitTextFillColor: '#313D53',
  WebkitBoxShadow: '0 0 0 1000px white inset',
};

const PigogoTextField: FC<Props & TextFieldProps> = ({
  label,
  value,
  setValue,
  placeholder,
  size,
  helperText,
  error,
  readonly = false,
  type,
  numeric = false,
  name,
  autoComplete,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <StyledTextField
      size={size}
      name={name}
      error={error}
      label={label ? label : undefined}
      value={value}
      variant="outlined"
      inputMode={numeric ? 'numeric' : undefined}
      helperText={helperText}
      placeholder={placeholder}
      type={showPassword ? 'text' : type}
      InputProps={{
        readOnly: readonly,
        autoComplete: autoComplete ? autoComplete : 'no',
        endAdornment:
          type === 'password' && !readonly ? (
            <InputAdornment position="end">
              <IconButton
                onMouseUp={handleMouseDownPassword}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{ margin: '0', padding: '0' }}
              >
                {showPassword ? (
                  <EyeStriked fill={error ? '#F75676' : '#838B98'} />
                ) : (
                  <EyeVisible fill={error ? '#F75676' : '#838B98'} />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
      inputProps={{ style: error ? undefined : inputStyle }}
      {...rest}
      onChange={
        setValue
          ? (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue(e.target.value)
          : undefined
      }
    />
  );
};

export default PigogoTextField;
