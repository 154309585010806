import React from 'react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import PigogoSymbolWrapper from '../PigogoSymbolWrapper';
import CustomContainer from '../CustomContainer';

const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  padding: theme.spacing(10, 0),
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const ErrorIntro = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PigogoSymbolWrapper typeOfWrapper="404">
      <CustomContainer>
        <BoxStyle>
          {!mdDown && (
            <Box>
              <Typography variant="smallB" component="h2" color={'#1D2532'}>
                Η σελίδα που ψάχνεις δεν υπάρχει
              </Typography>
            </Box>
          )}
          <Box maxWidth={'608px'}>
            <Typography variant="body2MediumL" component="p" color="#1D2532">
              Πιθανώς να ακολούθησες κάποιο παλιό link και η σελίδα να καταργήθηκε ή απλά κάπου να έγινε λάθος. Μπορείς
              να χρησιμοποιήσεις το μενού ή τη μπάρα αναζήτησης για να πλοηγηθείς!
            </Typography>
          </Box>
        </BoxStyle>
      </CustomContainer>
    </PigogoSymbolWrapper>
  );
};

export default ErrorIntro;
