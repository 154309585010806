import { styled, TextField } from '@mui/material';

const SearchTextField = styled(TextField)(({ theme }) => ({
  '& .MuiIcon-root': {
    width: '24px',
    height: '24px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiInputAdornment-root': {
    width: '24px',
    height: '24px',
    margin: '0',
  },
  '& .MuiInputBase-root': {
    padding: '0',
    zIndex: 1000,
    height: '48px',
    fontWeight: 400,
    color: '#313D53',
    fontSize: '14px',
    lineHeight: '20px',
  },
  '&.linkSearch .MuiInputBase-root': {
    height: '56px',
    gap: '8px',
  },
  '& .MuiOutlinedInput-root': {
    display: 'flex',
    borderRadius: '32px',
    padding: '12px 16px',
    gap: theme.spacing(2),

    '&.Mui-focused fieldset': {
      border: '1px solid #313D53',
    },
    fieldset: {
      border: '1px solid #838B98',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.primary.main,
  },
  '&.linkSearch .MuiOutlinedInput-input': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#313D53',
  },
}));

export default SearchTextField;
