import React from 'react';
import { Box, IconButton, Paper, Typography, useMediaQuery } from '@mui/material';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { theme, PigogoButton } from 'components';
import { ReactComponent as Chrome } from '../../../assets/svgs/searchApp/chromeWhite.svg';
import { ReactComponent as Info } from '../../../assets/svgs/info.svg';
import { ReactComponent as Firefox } from '../../../assets/svgs/searchApp/firefoxWhite.svg';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import detectBrowser from '../../../utils/browserDetection';

import { FC } from 'react';

interface Props {
  extensionPigogo: boolean;
  icon: any;
  shop: string;
  subTitle: string;
  open: boolean;
  handleClose: () => void;
}

const RedirectModal: FC<Props> = ({ icon, shop, subTitle, open, handleClose, extensionPigogo }) => {
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const browser = detectBrowser();

  const titleTypography = () => {
    if (mdDown) {
      return 'h6';
    } else {
      return 'h4';
    }
  };
  const textTypography = () => {
    if (mdDown) {
      return 'subtitle1SmallR';
    } else {
      return 'body2MediumR';
    }
  };
  return (
    <IonModal
      mode={'ios'}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => handleClose()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '12px',
            height: '48px',
            boxSizing: 'border-box',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: smDown ? '0' : '16px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={0.5}
              pb={mdDown ? 0 : 1}
            >
              <img alt={'logo'} src={icon} style={{ width: '128px', height: '64px', marginBottom: '16px' }} />
              <Typography variant={titleTypography()} component="h2" textAlign="center" color={'#313D53'}>
                Το κατάστημα {shop} έχει ανοίξει σε νέο παράθυρο.
              </Typography>
              <Typography variant={textTypography()} component="p" textAlign="center" color={'#313D53'}>
                {subTitle}
              </Typography>
            </Box>

            <Paper elevation={0} sx={{ p: 2, background: '#ECEFF4', borderRadius: '8px' }}>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexShrink: 0,
                  }}
                >
                  <Info />
                </Box>
                <Typography variant="subtitle1SmallM" component="p" color={'#313D53'}>
                  Η αγορά θα εμφανιστεί αυτόματα στον λογαριασμό σου. Σε περίπτωση που δεν εμφανιστεί, δημιούργησε ένα
                  &nbsp;
                  <a
                    href={'/create-claim'}
                    style={{ color: 'inherit', fontSize: 'inherit', fontWeight: 'inherit', textUnderlineOffset: '5px' }}
                  >
                    αίτημα μη καταγραφής
                  </a>
                </Typography>
              </Box>
            </Paper>
            {!xsOnly && !mdDown && !extensionPigogo && browser !== 'other' && (
              <Paper elevation={1} sx={{ px: 4, py: 3, background: '#1D2532', borderRadius: '16px' }}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={3}>
                  <Typography textAlign="center" variant="body2MediumR" component="p" color="white">
                    Για να μη χρειάζεται να πηγαίνεις στο κατάστημα μέσα από το Pigogo, εγκατάστησε το Pigogo ALERT!
                  </Typography>
                  {browser === 'Chrome' && (
                    <PigogoButton
                      fullWidth
                      variation="secondary"
                      startIcon={<Chrome />}
                      text="Εγκατάσταση στον Chrome"
                      onClick={() =>
                        window.open(
                          'https://chrome.google.com/webstore/detail/pigogo-alert/adhfocpeiaaklpfbaipnjehejfbghjeg?hl=el',
                          '_blank',
                        )
                      }
                    />
                  )}
                  {browser === 'Firefox' && (
                    <PigogoButton
                      fullWidth
                      variation="secondary"
                      startIcon={<Firefox />}
                      sx={{ width: '327px' }}
                      text={`Εγκατάσταση στον Firefox`}
                      onClick={() => window.open('https://addons.mozilla.org/el/firefox/addon/pigogo-alert/', '_blank')}
                    />
                  )}
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default RedirectModal;
