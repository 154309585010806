import React, { FC } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import theme from '../theme';

type CustomProps = {
  small?: boolean;
  discount: string;
};

const PigogoDiscount: FC<CustomProps> = ({ discount, small }) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Typography
      variant={!small ? 'h4' : matches ? 'captionSB' : 'subtitle1'}
      component="p"
      sx={{ color: '#838B98', textDecorationLine: 'line-through' }}
    >
      {discount}
    </Typography>
  );
};

export default PigogoDiscount;
