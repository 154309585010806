import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import { useGetUserDataQuery } from '../../../../redux/api/queries/userQueries';
import ExtractActiveLevel from './ExtractActiveLevel';
import ExtractInactiveLevel from './ExtractInactiveLevel';
import SaverArrowRight from './SaverArrowRight';
import SaverArrowDown from './SaverArrowDown';
import { GamificationLevelString } from '../../../../models/User';

const SaverExtractor = () => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: userData } = useGetUserDataQuery();

  const currentLvl = userData?.gamification_level_name;
  const nextLvl = userData?.next_gamification_level_name;
  const nextLvlAmount = userData?.next_gamification_level_amount;

  const getText = (curLevel: string) => {
    switch (curLevel) {
      case GamificationLevelString.saver:
        return (
          <Typography variant="caption" component="p" color={'#313D53'}>
            Εξοικονόμησε περισσότερα χρήματα για να γίνεις{' '}
            <Typography variant="captionSB" component="span">
              {nextLvl}
            </Typography>
            !
          </Typography>
        );
      case GamificationLevelString.smart:
        return (
          <Typography variant="caption" component="p" color={'#313D53'}>
            Γίνε{' '}
            <Typography variant="captionSB" component="span">
              {nextLvl}
            </Typography>{' '}
            και κέρδισε{' '}
            <Typography variant="captionSB" component="span">
              5€
            </Typography>{' '}
            στον λογαριασμό σου στο Pigogo!
          </Typography>
        );
      case GamificationLevelString.expert:
        return (
          <Typography variant="caption" component="p" color={'#313D53'}>
            Γίνε{' '}
            <Typography variant="captionSB" component="span">
              {nextLvl}
            </Typography>{' '}
            και κέρδισε{' '}
            <Typography variant="captionSB" component="span">
              10€
            </Typography>{' '}
            στον λογαριασμό σου στο Pigogo!
          </Typography>
        );
      case GamificationLevelString.super:
        return (
          <Typography variant="caption" component="p" color={'#313D53'}>
            Γίνε{' '}
            <Typography variant="captionSB" component="span">
              {nextLvl}
            </Typography>{' '}
            και κέρδισε{' '}
            <Typography variant="captionSB" component="span">
              30€
            </Typography>{' '}
            στον λογαριασμό σου στο Pigogo!
          </Typography>
        );
      case GamificationLevelString.guru:
        return '';
      default:
        return null;
    }
  };

  return currentLvl !== undefined && nextLvl !== undefined ? (
    <Box
      display="flex"
      alignItems="center"
      gap={mobile ? 2 : 0}
      justifyContent="space-between"
      flexDirection={mobile ? 'column' : 'row'}
      px={6}
      py={mobile ? 4 : 6}
      sx={{ boxSizing: 'border-box' }}
    >
      <ExtractActiveLevel saver={currentLvl} />
      {mobile ? <SaverArrowDown color={'#313D53'} /> : <SaverArrowRight color={'#313D53'} />}
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1SmallR" component="p" textAlign="center" color={'#313D53'}>
          απομένουν
        </Typography>
        <Typography textAlign="center" variant="mediumSB" component="p" color={'#313D53'}>
          {nextLvlAmount}
        </Typography>
        <Typography variant="subtitle1SmallR" component="p" textAlign="center" color={'#313D53'}>
          για το επόμενο επίπεδο
        </Typography>
        <Box
          pt={1.5}
          mt={1.5}
          mx={'auto'}
          sx={{
            borderTop: '1px solid #EAECEE',
            width: mobile ? '247px' : '220px',
            maxWidth: '100%',
            textAlign: 'center',
          }}
        >
          {getText(currentLvl)}
        </Box>
      </Box>
      {mobile ? <SaverArrowDown color={'#D6D8DD'} /> : <SaverArrowRight color={'#D6D8DD'} />}
      <ExtractInactiveLevel saver={nextLvl} />
    </Box>
  ) : null;
};

export default SaverExtractor;
