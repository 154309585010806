import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { Claim } from '../../../models/Claims';
import { ClaimSort } from '../../../redux/api/types/enum/ClaimSort';
import ClaimsStatusModal from '../ClaimsStatusInfoMondal';
import ClaimsDesktopRow from './ClaimsDesktopRow';
import { ReactComponent as ChevronDown } from '../../../assets/svgs/chevron_down_light.svg';
import { ReactComponent as Info } from '../../../assets/svgs/info_light.svg';
import { theme } from 'components';

const headCells = [
  { label: 'Ημερομηνία', id: ClaimSort.submission_date },
  { label: 'Κατάστημα', id: ClaimSort.shop_name },
  { label: 'Ποσό', id: ClaimSort.cashback_amount },
  { label: 'Είδος αιτήματος', id: ClaimSort.claim_status_id },
];

const StyledTableRow = styled(TableRow)(() => ({
  // height: 44,
}));

const StyledTableCell = styled(TableCell)(() => ({
  padding: '12px',
  minWidth: '147px',
  boxSizing: 'border-box',
  '&:nth-of-type(1)': {
    paddingLeft: '24px',
  },
  '&:nth-of-type(3)': {
    minWidth: '107px',
  },
  '&:nth-of-type(4)': {
    minWidth: '135px',
  },
  '&:nth-of-type(5)': {
    minWidth: '141px',
  },
  '&:last-child': {
    paddingRight: '24px',
  },
}));

interface Props {
  claims?: Claim[];
  isLoading: boolean;
  order: 'asc' | 'desc';
  setOrder: (order: 'asc' | 'desc') => void;
  orderBy: ClaimSort;
  setOrderBy: (orderBy: ClaimSort) => void;
  onRowClick: (claim: Claim) => void;
}

const ClaimsTableDesktop: FC<Props> = ({ claims, isLoading, order, setOrder, orderBy, setOrderBy, onRowClick }) => {
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const StyleTableContainer = styled(TableContainer)(() => ({
    overflowY: 'hidden',
    overflowX: downLg ? 'auto' : 'hidden',
    '&::-webkit-scrollbar': {
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }));
  const [infoModal, setInfoModal] = useState<boolean>(false);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: ClaimSort) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: ClaimSort) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <>
      <StyleTableContainer>
        <Table aria-label="claims table">
          <TableHead sx={{ backgroundColor: '#F7F7F8' }}>
            <StyledTableRow>
              {headCells.map((headCell, index: number) => (
                <StyledTableCell key={index} sortDirection={orderBy === headCell.id ? order : false}>
                  <TableSortLabel
                    IconComponent={ChevronDown}
                    active={orderBy === headCell.id}
                    onClick={createSortHandler(headCell.id)}
                    direction={orderBy === headCell.id ? order : 'asc'}
                  >
                    <Typography variant="captionSB" color="#6F7787">
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell>
                <Box display="flex" alignItems="center">
                  <Typography variant="captionSB" color="#6F7787">
                    Κατάσταση
                  </Typography>
                  <IconButton size="small" disableRipple onClick={() => setInfoModal(true)}>
                    <Info />
                  </IconButton>
                </Box>
              </StyledTableCell>
              <TableCell sx={{ minWidth: '34px', padding: 0 }} />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              claims?.map((row, index: number) => <ClaimsDesktopRow key={index} row={row} onRowClick={onRowClick} />)}
            {isLoading &&
              [...(claims as any), 1, 1, 1, 1]?.map((row, index) => (
                <ClaimsDesktopRow key={index} row={row || undefined} isLoading={row === 1} />
              ))}
          </TableBody>
        </Table>
      </StyleTableContainer>
      <ClaimsStatusModal isOpen={infoModal} setOpen={setInfoModal} />
    </>
  );
};

export default ClaimsTableDesktop;
