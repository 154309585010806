import React from 'react';
import { FormControl, FormHelperText, MenuItem, Select, styled, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '280px',
      borderRadius: 8,
      marginTop: '8px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.02), 0px 12px 25px rgba(0, 0, 0, 0.08)',
    },
  },
};

const StyledSelect = styled(Select)({
  [`& fieldset`]: {
    borderRadius: '30px',
    border: '1px solid #838B98',
  },
  '& .MuiSvgIcon-root': {
    right: '24px',
  },
  '& [aria-expanded="true"] ~ .MuiSvgIcon-root': {
    transform: 'rotate(180deg)',
  },
  '& .MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: '14px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  mb: '4px',
  padding: '16px 24px',
  '&.MuiMenuItem-root': {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#313D53',
  },
  '&.Mui-selected': {
    backgroundColor: '#EAECEE',
  },
});

const ArrowDown = () => (
  <SvgIcon sx={{ position: 'absolute', top: 'calc(50% - 0.5em)', pointerEvents: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z"
      fill="#838B98"
    />
  </SvgIcon>
);

type CustomDropdownProps = {
  width?: number | string;
  error?: boolean;
  helperText?: string;
  label?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  options: string[];
  value: string;
  setSelected: (s: string) => void;
};

const PigogoDropdown: React.FC<CustomDropdownProps> = ({
  width = 300,
  error,
  helperText,
  label,
  fullWidth,
  size = 'small',
  options = [],
  disabled,
  value,
  setSelected,
}) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelected(value);
  };

  return (
    <FormControl
      error={error}
      size={size}
      variant="outlined"
      sx={{
        width: fullWidth ? '100%' : width,
      }}
      disabled={disabled}
    >
      <style>
        {`
        .MuiMenu-list {
          padding-top: 0;
          padding-bottom: 0;
        }
        `}
      </style>
      <StyledSelect
        labelId={label}
        id={label}
        displayEmpty
        value={value}
        renderValue={(selected: any) => {
          return (
            <Typography variant="subtitle2" fontWeight={600} paddingLeft={'10px'} color={'#313D53'}>
              {selected ? selected : label}
            </Typography>
          );
        }}
        onChange={handleChange}
        MenuProps={MenuProps}
        IconComponent={ArrowDown}
      >
        {options.map((item) => (
          <StyledMenuItem key={item} value={item}>
            {item}
          </StyledMenuItem>
        ))}
        {helperText}
      </StyledSelect>
      {helperText && <FormHelperText sx={{ margin: '8px 24px 0 24px' }}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default PigogoDropdown;
