import React, { FC, useEffect, useState, KeyboardEvent } from 'react';
import { Box, Divider, Icon, IconButton, InputAdornment, SvgIcon, Typography } from '@mui/material';
import { PigogoButton, PigogoHorizontalProductCard } from 'components';
import { ReactComponent as LinkVertical } from '../../../../assets/svgs/link_Vertical.svg';
import SearchTextField from '../../../SearchTextField';
import { ReactComponent as Close } from '../../../../assets/svgs/close.svg';
import { useLazySearchQuery } from '../../../../redux/api/queries/searchQueries';
import { useDispatch } from 'react-redux';
import { setSnackBar, setStateSnackBar } from '../../../../redux/slices/layoutSlice';
import { useIonRouter } from '@ionic/react';
import { useAppSelector } from '../../../../redux/hooks';
import { Link } from 'react-router-dom';
import { useGetUserDataQuery } from '../../../../redux/api/queries/userQueries';
import RedirectModal from '../../Modals/RedirectModal';

interface Props {
  closePopover: () => void;
  setRedirectModal?: (value: boolean) => void;
  setShopName?: (value: string) => void;
  setShopImage?: (value: any) => void;
}

const LinkSearch: FC<Props> = ({ closePopover, setRedirectModal, setShopName, setShopImage }) => {
  const dispatch = useDispatch();

  const [trigger, { isFetching, data: linkResult, isError: errorLink }] = useLazySearchQuery();

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [linkContent, setLinkContent] = useState<string>('');
  const [checkLink, setCheckLink] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setCheckLink(false);
  }, [linkContent, setCheckLink]);

  useEffect(() => {
    if (errorLink) {
      const params = { value: true, msg: 'Το αίτημα αναζήτησης με link απέτυχε.', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorLink]);

  useEffect(() => {
    if (!isFetching) {
      setIsLoading(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isLoading]);

  useEffect(() => {
    if (checkLink) trigger({ link: encodeURIComponent(linkContent) });
  }, [trigger, linkContent, checkLink]);

  const handleClose = () => {
    closePopover();
    setLinkContent('');
    setCheckLink(false);
  };

  const enterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setCheckLink(true);
      setIsLoading(true);
    }
  };

  const extractLinkResults = () => {
    if (checkLink && isLoading) {
      return (
        <Box display="flex" flexDirection="column" gap={3}>
          <Box>
            <Divider />
          </Box>
          <Box width={'400px'}>
            <PigogoHorizontalProductCard loading />
          </Box>
        </Box>
      );
    }

    if (checkLink && linkResult?.data.length && !isLoading) {
      return (
        <Box display="flex" flexDirection="column" gap={4}>
          <Box>
            <Divider />
          </Box>
          {linkResult.data[0] && (
            <Box width={'400px'} position={'relative'}>
              <Link className="absLink" to={`/${linkResult.data[0].slug.name}`}></Link>
              <PigogoHorizontalProductCard shop={linkResult.data[0]} />
            </Box>
          )}
          {(!!accessToken || sessionLoggedIn) && (
            <a
              href={`${linkResult.data[0]?.url}?redirect=${encodeURIComponent(linkContent)}`}
              target="_blank"
              onClick={() => {
                setShopImage && setShopImage(linkResult.data[0].image);
                setShopName && setShopName(linkResult.data[0].name);
                setRedirectModal && setRedirectModal(true);
                closePopover();
              }}
            >
              <Box width={'327px'}>
                <PigogoButton fullWidth variation="secondary" text="Μεταφορά στο προϊόν" />
              </Box>
            </a>
          )}
        </Box>
      );
    } else if (checkLink) {
      return (
        <Box display="flex" flexDirection="column" gap={3}>
          <Box>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-start" gap={1}>
            <Typography variant="body2" component="p" color={'#313D53'}>
              Δεν συνεργαζόμαστε με το συγκεκριμένο κατάστημα
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Θα ήθελες να το προσθέσουμε στην λίστα μας;
            </Typography>
            <Box>
              <Link className="link" to={'/contact'}>
                Στείλε μας μήνυμα!
              </Link>
            </Box>
          </Box>
        </Box>
      );
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} mr={2}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Box>
          <Typography variant="largeB" color={'#313D53'}>
            Αναζήτηση με link προϊόντος
          </Typography>
        </Box>

        {/* <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} onClick={handleClose}>
          <Close />
        </Box> */}
        <IconButton className="iconBtn" sx={{ width: '24px', height: '24px', padding: 0 }} onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>

      <Box display="flex" gap={4} flexDirection="column">
        <Box>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Βρήκες ένα προϊόν που σου άρεσε; Μπορείς να αντιγράψεις εδώ το link του και να δεις αν θα κερδίσεις
            επιστροφή χρημάτων!
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Το link θα πρέπει να είναι από κάποιο συνεργαζόμενο κατάστημα.
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'} gap={2}>
          <Box flexGrow={1}>
            <SearchTextField
              fullWidth
              value={linkContent}
              onChange={(e) => setLinkContent(e.target.value)}
              error={checkLink && linkContent === ''}
              helperText={checkLink && linkContent === '' ? 'Το πεδίο είναι υποχρεωτικό.' : ''}
              placeholder="συμπλήρωσε το link"
              className="linkSearch"
              onKeyPress={enterPress}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <LinkVertical />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
          <Box>
            <PigogoButton
              text="Έλεγχος"
              sx={{ width: '132px' }}
              onClick={() => {
                setCheckLink(true);
                setIsLoading(true);
              }}
              className="MuiButton--innerFocus"
            />
          </Box>
        </Box>
        {!(checkLink && linkContent === '') && extractLinkResults()}
      </Box>
    </Box>
  );
};

export default LinkSearch;
