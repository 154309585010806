import React from 'react';
import { IonModal, IonHeader, IonToolbar } from '@ionic/react';
import { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import ClaimsStatusInfo from './ClaimsStatusInfo';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from 'components';

interface Props {
  isOpen: boolean;
  setOpen: (b: boolean) => void;
}

const ClaimsStatusModal: FC<Props> = ({ isOpen, setOpen }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <IonModal
      mode={'ios'}
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={() => setOpen(false)}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
    >
      <IonHeader class="ion-no-border">
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '48px', boxSizing: 'border-box' }}>
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: smDown ? 4 : 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => setOpen(!isOpen)}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: smDown ? '0' : '16px',
          }}
        >
          <Typography variant="body1" textAlign="center" color={'#313D53'} component="h2" mb={2}>
            Καταστάσεις αιτημάτων
          </Typography>
          <ClaimsStatusInfo />
        </Box>
      </Box>
    </IonModal>
  );
};

export default ClaimsStatusModal;
