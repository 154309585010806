import { Box, Grid, styled, Typography } from '@mui/material';
import { PigogoButton, PigogoTextField } from 'components';
import React, { FC, useEffect, useState } from 'react';
import { useForgotPasswordMutation } from '../../redux/api/mutations/loginMutations';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';

const StyleBox = styled(Box)(({ theme }) => ({
  paddingTop: 32,
  paddingBottom: 32,
  paddingInline: 32,
  boxSizing: 'border-box',
  justifyContent: 'center',
  display: 'flex',
  borderRadius: '16px',
  background: theme.palette.error.contrastText,
}));

const ForgotPassword: FC = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');

  const [forgotPassword, result] = useForgotPasswordMutation();

  useEffect(() => {
    if (!result.isLoading && result.isSuccess) {
      setEmail('');
    }
    if (result.isError) {
      dispatch(setSnackBar({ value: true, msg: 'Το αίτημα αλλαγής κώδικου απέτυχε!', severity: 'error' }));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [result]);

  const handleForgot = () => {
    if (email) {
      forgotPassword({ email: email.trim() });
    }
  };

  return (
    <StyleBox>
      <Grid container textAlign={'center'} justifyContent={'center'}>
        <Grid item xs={12}>
          <Typography variant={'body1'}>Ξέχασα τον κωδικό</Typography>
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: '8px', paddingBottom: '32px' }}>
          <Typography variant={'body2MediumR'}>
            Συμπλήρωσε το email με το οποίο έχεις γραφτεί στο Pigogo, για να σου στείλουμε εκεί link δημιουργίας νέου
            κωδικού.
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ paddingBottom: '32px' }}>
          <PigogoTextField
            fullWidth
            value={email}
            label="Email"
            type={'email'}
            size={'medium'}
            autoComplete={'email'}
            setValue={setEmail}
          />
        </Grid>

        <Grid item xs={12}>
          <PigogoButton
            fullWidth
            type={'submit'}
            text={'Αποστολή'}
            disabled={!email}
            variation="secondary"
            onClick={handleForgot}
            loading={result.isLoading}
          />
        </Grid>
      </Grid>
    </StyleBox>
  );
};

export default ForgotPassword;
