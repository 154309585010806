import React from 'react';
import { Box, Container, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import PigogoSymbolWrapper from '../PigogoSymbolWrapper';

const BoxStyle = styled(Box)(() => ({
  alignItems: 'center',
  textAlign: 'left',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'start',
    paddingBlock: 40,
    height: '176px',
  },
  [theme.breakpoints.up('sm')]: {
    paddingBlock: 32,
    height: '160px',
  },
  [theme.breakpoints.up('md')]: {
    paddingBlock: 80,
    height: '256px',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

const ContactIntro = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PigogoSymbolWrapper typeOfWrapper="faq">
      <Container maxWidth={'md'}>
        <BoxStyle>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={mdDown ? 'flex-start' : 'center'}
            justifyContent={mdDown ? 'flex-start' : 'center'}
            gap={2}
          >
            {!smDown && (
              <Grid item xs={12} textAlign={mdDown ? 'start' : 'center'}>
                <Typography variant="smallB" component="h2" color="#1D2532">
                  Επικοινώνησε μαζί μας
                </Typography>
              </Grid>
            )}
            <Box
              display={'flex'}
              flexDirection={'column'}
              textAlign={mdDown ? 'justify' : 'center'}
              justifyContent={mdDown ? 'start' : 'center'}
            >
              <Typography
                variant="body2MediumL"
                component="p"
                color="#1D2532"
                style={{ maxWidth: '608px', textAlign: 'left' }}
              >
                Μπορείς να έρθεις σε επαφή μαζί μας μέσω e-mail στο help@pigogo.gr, μέσω του live chat, είτε
                συμπληρώνοντας την παρακάτω φόρμα επικοινωνίας.
              </Typography>
            </Box>
          </Box>
        </BoxStyle>
      </Container>
    </PigogoSymbolWrapper>
  );
};

export default ContactIntro;
