import React, { useEffect, useState } from 'react';
import { ReactComponent as PigogoLogo } from '../assets/PigogoLogo.svg';
import { IonContent, IonFooter, IonPage, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import CustomHeader from '../components/CustomHeader';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { IntroCarousel, PigogoButton, theme } from 'components';
import FirstSlide from '../components/Intro/FirstSlide';
import SecondSlide from '../components/Intro/SecondSlide';
import ThirdSlide from '../components/Intro/ThirdSlide';
import RegisterModal from '../components/Navigation/Modals/RegisterModal';
import LoginModal from '../components/Navigation/Modals/LoginModal';
import ForgotModal from '../components/Navigation/Modals/ForgotModal';
import SuccessPasswordModal from '../components/Navigation/Modals/SuccessPasswordModal';
import Layout from '../components/Navigation/Layout';
import TermsAndConditionsModal from '../components/Navigation/Modals/TermsAndConditionsModal';

const Intro = () => {
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [forgotModal, setForgotModal] = useState<boolean>(false);
  const [successForgotModal, setSuccessForgotModal] = useState<boolean>(false);
  const xxsDown = useMediaQuery(theme.breakpoints.down('xxs'));
  const [termsModal, setTermsModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const closeTermsModal = () => {
    setTermsModal(false);
    setRegisterModal(true);
  };

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <IonPage>
      <CustomHeader />
      <IonContent forceOverscroll={false}>
        <Layout>
          <>
            <Box
              display="flex"
              alignItems="center"
              style={{ height: xxsDown ? 'calc(100vh - 150px)' : 'calc(100vh - 170px)' }}
            >
              <Box style={{ width: '100%' }}>
                <Box display="flex" justifyContent="center" style={{ marginBottom: '10px' }}>
                  <PigogoLogo style={{ width: '160px', height: 'auto' }} />
                </Box>
                <Box>
                  {isLoading ? (
                    <Box style={{ width: '100%' }}>
                      <FirstSlide />
                    </Box>
                  ) : (
                    <Box>
                      <IntroCarousel>
                        <FirstSlide />
                        <SecondSlide />
                        <ThirdSlide />
                      </IntroCarousel>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        </Layout>
      </IonContent>
      <IonFooter class="ion-no-border" style={{ '--background': '#FFFFFF' }}>
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
          <Box px={3} pb={xxsDown ? 2 : 4} display="flex" flexDirection="column" gap={2}>
            <PigogoButton text="Σύνδεση" variation="secondary" onClick={() => setLoginModal(true)} />
            <PigogoButton
              variation="white"
              text="Γίνε μέλος δωρεάν"
              sx={{ border: '1px solid #5E77A1', color: '#5E77A1' }}
              onClick={() => setRegisterModal(true)}
            />
          </Box>
        </IonToolbar>
      </IonFooter>
      <ForgotModal isOpen={forgotModal} setOpen={setForgotModal} success={setSuccessForgotModal} />
      <SuccessPasswordModal
        title={'Ευχαριστούμε πολύ!'}
        subTitle={'Το link δημιουργίας νέου κωδικού έχει σταλεί στο email σου.'}
        open={successForgotModal}
        handleClose={setSuccessForgotModal}
      />
      <LoginModal
        isOpen={loginModal}
        setLoginModal={(value: boolean) => {
          setLoginModal(value);
        }}
        openRegisterModal={() => setRegisterModal(true)}
        dismiss={() => setLoginModal(false)}
        openForgotModal={() => setForgotModal(true)}
      />
      <TermsAndConditionsModal isOpen={termsModal} setTermsModal={setTermsModal} closeTermsModal={closeTermsModal} />
      <RegisterModal
        isOpen={registerModal}
        setOpen={setRegisterModal}
        setLoginModal={() => setLoginModal(true)}
        setTermsModal={setTermsModal}
      />
    </IonPage>
  );
};

export default Intro;
