import { pigogoApi } from '../Api';
import { GenericRequest } from '../../../models/GenericRequest';
import { BasicContent } from '../../../models/BasicContent';

const shopWin = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBasicContent: builder.query<GenericRequest<{ slug: string; menuBarName: string }[]>, void>({
      query: () => ({
        method: 'GET',
        url: '/static-content/basic-content-pages',
      }),
      transformResponse(response: any, meta) {
        return {
          data: response,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')),
          nextCursor: undefined,
        };
      },
    }),
    getBasicContent: builder.query<GenericRequest<BasicContent>, { pageID: string }>({
      query: ({ pageID }) => ({
        method: 'GET',
        url: `static-content/basic-content-pages/${pageID}`,
      }),
      transformResponse(response: any, meta) {
        return {
          data: response,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')),
          nextCursor: undefined,
        };
      },
    }),
  }),
});

export const { useGetAllBasicContentQuery, useLazyGetBasicContentQuery } = shopWin;
