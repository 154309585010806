import { Box, Paper, Skeleton, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ReactComponent as Symbol } from '../../assets/svgs/illustrations/favourites/symbolWhite.svg';
import { theme } from 'components';
import { ReactComponent as StarFilled } from '../../assets/svgs/stars/light/filled.svg';
import { ReactComponent as HalfStar } from '../../assets/svgs/stars/light/half.svg';

const StyledPaper = styled(Paper)(() => ({
  padding: '16px',
  borderRadius: '20px',
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    border: '1px solid  #EAECEE',
    height: '272px',
    width: 'calc(100% - 21px)',
  },
  [theme.breakpoints.down('lg')]: {
    border: '1px solid  #EAECEE',
    height: '296px',
    width: '311px',
    maxWidth: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    border: '1px solid #E9688A',
    height: '296px',
    width: 'calc(100% - 32px)',
  },
}));

interface Props {
  border: boolean;
  description: string;
  memberName: string;
  memberSince: string;
  loading?: boolean;
  rating?: boolean;
}

const MemberCard: FC<Props> = ({ border, description, memberName, memberSince, loading, rating }) => {
  return (
    <StyledPaper
      style={{
        border: border ? '1px solid #E9688A' : '1px solid  #EAECEE',
        height: rating ? '278px' : '',
        width: rating ? '286px' : '',
      }}
    >
      <Box
        position={'relative'}
        display="flex"
        flexDirection="column"
        justifyContent={'space-between'}
        height={'100%'}
        gap={4}
      >
        <Box textAlign={'start'} style={{ padding: '16px 16px 0' }}>
          <Typography variant="body2MediumR" component="p" textAlign={'start'} color={'#313D53'}>
            {loading ? (
              <Box display="flex" flexDirection="column" gap="2px">
                <Skeleton width="340px" />
                <Skeleton width="340px" />
                <Skeleton width="310px" />
              </Box>
            ) : (
              description
            )}
          </Typography>
          {rating && (
            <Box display="flex" gap={'6px'} pt={2}>
              <StarFilled />
              <StarFilled />
              <StarFilled />
              <StarFilled />
              <StarFilled />
            </Box>
          )}
        </Box>
        <Box position={'absolute'} sx={{ bottom: '0px', right: '0px' }}>
          <Symbol style={{ width: 54.86, height: 72, fill: '#FDF0F3' }} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{ zIndex: 1500, paddingRight: '10px' }}
        >
          <Typography variant="smallR" component="p" color={'#313D53'}>
            {loading ? <Skeleton width="90px" /> : memberName}
          </Typography>
          <Typography variant="subtitle1SmallR" component="p" lineHeight="20px" color={'#838B98'}>
            {loading ? <Skeleton width="105px" /> : `μέλος απο το '${memberSince}`}
          </Typography>
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default MemberCard;
