import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import { Purchase } from '../../models/Purchase';

interface Props {
  trigger: () => void;
  setIsRefreshingData: (value: boolean) => void;
}

const UserTablesRefresher: FC<Props> = ({ trigger, setIsRefreshingData }) => {
  return (
    <IonRefresher
      slot="fixed"
      onIonRefresh={async (event: CustomEvent<RefresherEventDetail>) => {
        setIsRefreshingData(true);
        await trigger();
        setTimeout(() => {
          event.detail.complete();
          return;
        }, 300);
      }}
    >
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
  );
};

export default UserTablesRefresher;
