import React, { FC } from 'react';
import { Box, IconButton, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import { PigogoTextField, theme } from 'components';
import { ReactComponent as Search } from '../../assets/svgs/search.svg';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import PigogoSymbolWrapper from '../PigogoSymbolWrapper';
import CustomContainer from '../CustomContainer';

interface Props {
  search: string;
  setSearch: (search: string) => void;
}

const TopBanner: FC<Props> = ({ search, setSearch }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PigogoSymbolWrapper typeOfWrapper="faq">
      <Box py={smDown ? 4 : 10} sx={{ maxHeight: '336px', width: '100%', boxSizing: 'border-box' }}>
        <CustomContainer
          gap={4}
          display="flex"
          px={smDown ? 2 : 3}
          flexDirection="column"
          justifyContent="center"
          alignItems={smDown ? 'flex-start' : 'center'}
        >
          <Box
            gap={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={smDown ? 'flex-start' : 'center'}
          >
            {!smDown && (
              <Typography variant="smallB" component="h2" color={'#1D2532'}>
                Συχνές ερωτήσεις
              </Typography>
            )}
            <Box maxWidth={'608px'} textAlign={smDown ? 'start' : 'center'}>
              <Typography variant="body2MediumL" color={'#1D2532'} component="p">
                Δες όλες τις Συχνές Ερωτήσεις ομαδοποιημένες σε κατηγορίες. Αν παρόλα αυτά δεν βρίσκεις αυτό που
                ψάχνεις, μπορείς να επικοινωνήσεις μαζί μας!
              </Typography>
            </Box>
          </Box>
          <Box width={mdDown ? '100%' : '640px'}>
            <PigogoTextField
              fullWidth
              value={search}
              setValue={setSearch}
              autoComplete={'off'}
              sx={{ backgroundColor: 'white', borderRadius: '32px' }}
              placeholder="αναζήτησε ερωτήσεις..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {search !== '' && (
                      <IconButton onClick={() => setSearch('')}>
                        <Close />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </CustomContainer>
      </Box>
    </PigogoSymbolWrapper>
  );
};

export default TopBanner;
