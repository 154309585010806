import { Box, Typography, useMediaQuery } from '@mui/material';
import PigogoSad from '../assets/svgs/illustrations/pigogoSad.svg';
import React from 'react';
import { theme } from 'components';

const NoResultsPage = () => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" flexDirection="column" gap={1} alignItems="center" pt={smDown ? 3 : 8} pb={'162px'}>
      <Box>
        <img alt={'pigogoApp'} src={PigogoSad} />
      </Box>
      <Box>
        <Typography
          fontSize={'14px !important'}
          lineHeight={'16px !important'}
          fontWeight={'700 !important'}
          color={'#313D53'}
          textAlign="center"
        >
          Δεν βρέθηκαν αποτελέσματα
        </Typography>
      </Box>
      <Box>
        <Typography
          fontSize={'14px !important'}
          lineHeight={'16px !important'}
          fontWeight={'400 !important'}
          color={'#313D53'}
          textAlign="center"
        >
          Δοκίμασε μία διαφορετική αναζήτηση.
        </Typography>
      </Box>
    </Box>
  );
};

export default NoResultsPage;
