export interface CreateClaimStepThree {
  order_amount: string;
  cancellation_details_id: CancellationDetails;
  has_confirmation_email: boolean;
  confirmation_email: string;
  order_number: string;
  purchase_datetime: string;
  user_note: string;
  has_coupon_code: boolean;
  order_coupon_code: string;
  payment_type: string;
  cashback_category_id: string[];
  confirmation_date: Date | null;
  confirmation_time: Date | null;
}

export enum CancellationDetails {
  'noChange' = '1',
  'change' = '2',
  'other' = '3',
  'def' = '0',
}
