import { Box, styled, Typography, Skeleton } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { ReactComponent as SymbolPink } from '../assets/svgs/illustrations/favourites/symbolPink3.svg';
import CustomContainer from './CustomContainer';
import { relative } from 'path';

const StyleBoxContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    height: '444px',
    flexDirection: 'row',
  },
}));

const StyleBoxImage = styled(Box)(({ theme }) => ({
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  borderSizing: 'border-box',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '-32px',
    width: 'calc(100% + 64px)',
    position: 'relative',
    zIndex: '201',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '-16px',
    width: 'calc(100% + 32px)',
  },
  [theme.breakpoints.up('md')]: {
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    width: '581.5px',
    position: 'absolute',
  },
  [theme.breakpoints.up('lg')]: {
    width: '667px',
  },
}));

const StyleBoxContent = styled(Box)(({ theme }) => ({
  zIndex: 200,
  gap: '24px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    paddingTop: '40px',
    paddingBottom: '20px',
  },
  [theme.breakpoints.up('md')]: {
    width: '360px',
    height: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    width: '493.5px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '504px',
  },
}));

interface Props {
  children: ReactNode;
  title: string;
  image: string | undefined;
  shape: boolean | undefined;
}

const TopBannerImage: FC<Props> = ({ children, title, image, shape }) => {
  return (
    <StyleBoxContainer>
      <CustomContainer zIndex={200} sx={{ borderBottom: '2px solid #FDF0F3', height: '100%' }}>
        <StyleBoxContent>
          {title === '' && <Skeleton height="40px" width="100%" />}
          {title !== '' && (
            <Typography variant={'h3'} component="h2" color={'#1D2532'}>
              {title}
            </Typography>
          )}
          <Typography variant={'bodyLargeRMediumL'} component="p" color={'#1D2532'}>
            {children}
          </Typography>
        </StyleBoxContent>
        {shape && (
          <Box zIndex={100} position="absolute" top="0px" right="0px">
            <SymbolPink />
          </Box>
        )}

        <StyleBoxImage>
          <img alt={'content-image'} src={image} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
        </StyleBoxImage>
      </CustomContainer>
    </StyleBoxContainer>
  );
};

export default TopBannerImage;
