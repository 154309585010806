import {
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { Box, styled, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { theme } from 'components';
import { useLazyFavouritesQuery } from '../../redux/api/queries/favouritesQuery';
import { Favourite } from '../../models/Favourite';
import {
  useChangeNotificationsMutation,
  useRemoveFavouriteMutation,
  useRevertFavouriteMutation,
} from '../../redux/api/mutations/favouritesMutation';
import Pagination from '../Stores/Pagination';
import useDebounce from '../../hooks/useDebounce';
import FavouritesSkeletonRow from './FavouritesSkeletonRow';
import FavouritesTableHeader from './FavouritesTableHeader';
import FavouritesRow from './FavouritesRow';
import { setLoad, setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { notifications } from 'ionicons/icons';

const StyleBox = styled(Box)(() => ({
  justifyContent: 'center',
  boxSizing: 'border-box',
  display: 'inline-flex',
  border: '2px solid #EAECEE',
  overflowY: 'hidden',
  borderRadius: '24px',
  background: '#fff',
  maxHeight: 'auto',
  width: '100%',
}));

interface FavouritesTableProps {
  triggerFetch: boolean;
  setTriggerFetch?: (v: boolean) => void;
}

const FavouritesTable: FC<FavouritesTableProps> = ({ triggerFetch, setTriggerFetch }) => {
  const dispatch = useDispatch();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [removeFavourite, { isError: deleteError, isSuccess: deleteSucceeded, reset: deleteReset }] =
    useRemoveFavouriteMutation();
  const [putFavourite, { isError: changeNotificationError, isSuccess: changeNotificationSuccess }] =
    useChangeNotificationsMutation();
  const [revertFavourite, { isError: revertFavouriteError, isSuccess: revertSucceeded, reset: revertReset }] =
    useRevertFavouriteMutation();

  const [fetching, setFetching] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<Favourite | null>();
  const [allFavouritesState, setAllFavouritesState] = useState<Favourite[]>([]);
  const [selectedFavouriteRevert, setSelectedFavouriteRevert] = useState<number | null>(null);
  const [selectedFavouriteRemove, setSelectedFavouriteRemove] = useState<number | null>(null);

  const [trigger, { data: allFavourites, isLoading, error: loadDataError }] = useLazyFavouritesQuery();

  const totalCountRef = useRef<number>(0);
  const cursorRef = useRef<string | null | undefined>(undefined);
  const debounced = useDebounce(selectedStore, 500);

  useIonViewWillEnter(async () => {
    const response = await trigger({
      //limit: 16,
      //nextCursor: allFavourites ? cursorRef.current : undefined,
    });

    totalCountRef.current = 'data' in response && response?.data?.totalCount ? response?.data?.totalCount : 0;

    if ('data' in response) {
      setAllFavouritesState((prev) => {
        const incomingData: any[] = [...(response?.data?.data ? response?.data?.data || [] : [])];
        return incomingData.map((fav: any) => {
          return {
            ...fav,
            removed: false,
          };
        });
      });
    }
  });

  useIonViewWillLeave(async () => {
    const response = await trigger({
      //limit: 16,
      //nextCursor: allFavourites ? cursorRef.current : undefined,
    });

    totalCountRef.current = 'data' in response && response?.data?.totalCount ? response?.data?.totalCount : 0;

    if ('data' in response) {
      setAllFavouritesState((prev) => {
        const incomingData: any[] = [...(response?.data?.data ? response?.data?.data || [] : [])];
        return incomingData.map((fav: any) => {
          return {
            ...fav,
            removed: false,
          };
        });
      });
    }
  });

  useEffect(() => {
    if (triggerFetch) {
      const fetchData = async () => {
        const response = await trigger({
          //limit: 16,
          //nextCursor: allFavourites ? cursorRef.current : undefined,
        });

        totalCountRef.current = 'data' in response && response?.data?.totalCount ? response?.data?.totalCount : 0;

        if ('data' in response) {
          setAllFavouritesState((prev) => {
            const incomingData: any[] = [...(response?.data?.data ? response?.data?.data || [] : [])];
            return incomingData.map((fav: any) => {
              return {
                ...fav,
                removed: false,
              };
            });
          });
        }
      };

      setTriggerFetch && setTriggerFetch(false);

      fetchData();
    }
  }, [triggerFetch]);

  useEffect(() => {
    if (revertSucceeded) {
      trigger({
        //limit: allFavouritesState.filter((favourite) => favourite.removed === false).length,
        //nextCursor: undefined,
      });
      setSelectedFavouriteRevert(null);
    } else if (deleteSucceeded) {
      //totalCountRef.current = totalCountRef.current - 1;
      setSelectedFavouriteRemove(null);
    }
    deleteReset();
    revertReset();
  }, [revertSucceeded, deleteSucceeded]);

  useEffect(() => {
    if (allFavourites) {
      setAllFavouritesState((prev) => {
        const incomingData: any[] = [...allFavourites.data];
        const mutatedData: any[] = prev
          .filter((fav: any) => {
            return incomingData.find((elem) => elem.id == fav.id);
          })
          .map((fav: any) => {
            const newDataIndex = incomingData.findIndex(
              (newFav: any) => newFav.id === fav.id || newFav.shop.id === fav.shop.id,
            );
            if (newDataIndex !== -1) {
              const tempData: any = incomingData[newDataIndex];
              incomingData.splice(newDataIndex, 1);

              return { ...fav, ...tempData };
            }

            return fav;
          });

        return [...incomingData, ...mutatedData];
      });

      cursorRef.current = allFavourites.nextCursor;
    }
  }, [allFavourites]);

  useEffect(() => {
    if (debounced && selectedStore) {
      const notification = !selectedStore.notifications;
      putFavourite({
        favouriteId: selectedStore.id,
        notifications: notification,
      });
    }
  }, [debounced]);

  useEffect(() => {
    if (deleteError) {
      setAllFavouritesState((prevState) => {
        const newData = prevState.map((item) => {
          if (item.id === selectedFavouriteRemove) {
            return {
              ...item,
              removed: false,
            };
          }
          return item;
        });
        return [...newData];
      });

      const params = { value: true, msg: 'Κάτι πήγε στραβά με τα αγαπημένα!', severity: 'error' };
      dispatch(setSnackBar(params));
      setSelectedFavouriteRemove(null);
    } else if (revertFavouriteError) {
      setAllFavouritesState((prevState) => {
        const newData = prevState.map((item) => {
          if (item.shop.id === selectedFavouriteRevert) {
            return {
              ...item,
              removed: true,
            };
          }
          return item;
        });
        return [...newData];
      });
      const params = { value: true, msg: 'Κάτι πήγε στραβά με τα αγαπημένα!', severity: 'error' };
      dispatch(setSnackBar(params));
      setSelectedFavouriteRevert(null);
    } else if (loadDataError) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά με τα αγαπημένα!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
    setTimeout(() => {
      deleteReset();
      revertReset();
    }, 100);
  }, [deleteError, revertFavouriteError, loadDataError]);

  //Revert notification if error
  useEffect(() => {
    if (changeNotificationError) {
      setAllFavouritesState((prevState) => {
        const newData = prevState.map((item) => {
          if (item.id === selectedStore?.id) {
            return {
              ...item,
              notifications: !item.notifications,
            };
          }
          return item;
        });
        return [...newData];
      });

      const params = { value: true, msg: 'Το αίτημα αλλαγής δεν πραγματοποιήθηκε!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }

    if (changeNotificationSuccess) {
      setSelectedStore(null);
    }
  }, [changeNotificationError, changeNotificationSuccess]);

  const loadData = async (ev?: any) => {
    setFetching(true);
    cursorRef.current = allFavourites?.nextCursor || undefined;
    await trigger({
      //limit: 16,
      //nextCursor: allFavourites ? allFavourites?.nextCursor : undefined,
    });
    setFetching(false);

    if (ev) ev.target.complete();
  };

  const hasNoNextPage = () => {
    return !!(allFavourites && allFavourites?.totalCount && allFavouritesState.length >= allFavourites?.totalCount);
  };

  const changeNotifications = (store: Favourite, index: number) => {
    setSelectedStore(store);
    if (allFavouritesState) {
      let favouritesCopy = [];
      favouritesCopy = [...allFavouritesState];
      favouritesCopy[index] = { ...favouritesCopy[index], notifications: !favouritesCopy[index].notifications };
      setAllFavouritesState(favouritesCopy);
    }
  };

  const handleDelete = (storeFavourite: Favourite) => {
    setSelectedFavouriteRemove(storeFavourite.id);
    removeFavourite(storeFavourite.id); // delete from db

    // mark store as removed
    setAllFavouritesState((prevState) => {
      const newData = prevState.map((item) => {
        if (item.id === storeFavourite.id) {
          return {
            ...item,
            removed: true,
          };
        }
        return item;
      });
      return [...newData];
    });
  };

  const revertRemove = async (shopId: number, notifications: number) => {
    if (shopId) {
      dispatch(setLoad(true));
      const revertedFavourite = await revertFavourite({ shop_id: shopId, notifications: notifications }); // add to db
      setSelectedFavouriteRevert(shopId);
      // mark store as not removed
      setAllFavouritesState((prevState) => {
        const newData = prevState.map((item) => {
          if (item.shop.id === shopId) {
            return {
              ...item,
              id: 'data' in revertedFavourite ? revertedFavourite?.data.id : 0,
              removed: false,
              notifications: notifications === 0 ? false : true,
            };
          }
          return item;
        });
        return [...newData];
      });

      dispatch(setLoad(false));
    }
  };

  const getPagination = () => {
    if (!smDown && (!isLoading || fetching) && allFavourites && allFavouritesState.length <= 16) {
      return (
        <Box pt="50px">
          <Pagination currentCount={allFavouritesState.length} total={totalCountRef.current} loadMore={loadData} />
        </Box>
      );
    }
  };

  const getLoadBody = () => {
    return [1, 1, 1, 1, 1].map((item, index) => <FavouritesSkeletonRow key={index} />);
  };

  const getTableBody = () => {
    return (
      allFavouritesState &&
      allFavouritesState.map((favouriteShop: Favourite, index: number) => (
        <FavouritesRow
          key={index}
          index={index}
          handleRemove={handleDelete}
          favouriteShop={favouriteShop}
          revertRemove={(shopId, notifs) => revertRemove(shopId, notifs)}
          changeNotifications={changeNotifications}
          removed={favouriteShop.removed}
        />
      ))
    );
  };

  return (
    <>
      <StyleBox>
        <IonGrid style={{ padding: 0 }}>
          <FavouritesTableHeader />
          {getTableBody()}
          {(isLoading || fetching) && getLoadBody()}
          {smDown && (
            <IonInfiniteScroll
              onIonInfinite={loadData}
              threshold="10px"
              disabled={hasNoNextPage()}
              style={{ marginTop: '-84px' }}
            >
              <IonInfiniteScrollContent loadingSpinner={null} />
            </IonInfiniteScroll>
          )}
        </IonGrid>
      </StyleBox>
    </>
  );
};

export default FavouritesTable;
