import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import React from 'react';
import { FC } from 'react';

interface Props {
  title?: string;
  subtitle?: string;
  content?: string;
  url?: string;
  loading?: boolean;
}

const BlogCard: FC<Props> = ({ title, url, subtitle, content, loading }) => {
  const htmlDecode = (input: string) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      width={smDown ? '343px' : '320px'}
      borderRadius={'16px'}
      padding={'24px 24px 32px 24px'}
      boxSizing={'border-box'}
      sx={{
        cursor: 'pointer',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.02), 0px 12px 25px rgba(0, 0, 0, 0.08)',
        maxWidth: '100%',
        position: 'relative',
      }}
      display="flex"
      flexDirection="column"
      gap={1.5}
    >
      <a className="absLink" target="_blank" href={url}></a>
      <Typography variant="subtitle1" color="secondary">
        {loading ? <Skeleton width="80px" /> : title}
      </Typography>
      <Typography
        variant="h4All"
        component="h3"
        color={'#1D2532'}
        sx={{
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitLineClamp: '2',
        }}
      >
        {loading ? (
          <Box display="flex" flexDirection="column" gap={'2px'}>
            <Skeleton />
            <Skeleton />
          </Box>
        ) : (
          htmlDecode(subtitle ?? '')
        )}
      </Typography>
      <Typography
        color="#6F7787"
        variant="body2MediumR"
        component="div"
        sx={{
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitLineClamp: '3',
          marginTop: '-4px',
        }}
      >
        {loading ? (
          <Box display="flex" flexDirection="column" gap={'2px'}>
            <Skeleton />
            <Skeleton />
          </Box>
        ) : (
          htmlDecode(content ?? '')
        )}
      </Typography>
    </Box>
  );
};

export default BlogCard;
