import { pigogoApi } from '../Api';

const alertTestimonialsApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    alertTestimonials: builder.query<{ data: any[]; meta: any }, void>({
      query: () => ({
        url: '/static-content/testimonials?alert',
      }),
    }),
  }),
});

export const { useAlertTestimonialsQuery } = alertTestimonialsApi;
