import React, { ReactNode } from 'react';
import { AlertTitle, Box, Button, Grid, Snackbar, styled, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const AlertStyled = styled(MuiAlert)(() => ({
  color: 'var(--text)',
  borderRadius: '16px',
  fontSize: '16px',
  fontWeight: 500,
  background: 'var(--background)',
}));

const lightVars = {
  '--background': '#FCE5D8',
  '--text': '#1D2532',
} as React.CSSProperties;

const darkVars = {
  '--background': '#1D2532',
  '--text': '#FFFFFF',
} as React.CSSProperties;

interface Props {
  open: boolean;
  setOpen?: (open: boolean) => void;
  color: 'light' | 'dark';
  size?: 'sm' | 'md';
  icon?: React.ReactNode;
  title: string;
  text?: string;
  handleUndo?: () => void;
  learnMore?: () => void;
  learnMoreText: string;
  actionButton?: ReactNode;
}

const PigogoFloatingAlert: React.FC<Props> = ({
  open,
  setOpen,
  color,
  size = 'md',
  title,
  icon,
  text,
  learnMore,
  learnMoreText,
  actionButton,
}) => {
  const handleClose = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  const getVar = () => {
    if (color === 'light') {
      return lightVars;
    } else if (color === 'dark') {
      return darkVars;
    }
  };

  const extractVariant = () => {
    if (size === 'md') {
      return (
        <>
          <Box display="flex" alignItems="center" gap={10}>
            <Box display="flex" flexDirection="column" gap="10px">
              <Typography width="562px" variant="body2MediumM">
                {title}
              </Typography>
              {text && text}
              {learnMore && (
                <Box>
                  <Button onClick={learnMore}>
                    <Typography
                      variant="body2MediumM"
                      color={color === 'dark' ? 'white' : 'inherit'}
                      sx={{
                        textDecoration: 'underline',
                        textDecorationColor: '#E9688A',
                        ml: '-10px',
                      }}
                    >
                      {learnMoreText}
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
            {actionButton && <Box>{actionButton}</Box>}
          </Box>
        </>
      );
    } else
      return (
        <>
          <Typography fontWeight="500" fontSize="16px">
            {title}
          </Typography>
          <Typography fontWeight="400" fontSize="14px">
            {text}
          </Typography>
        </>
      );
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={setOpen ? handleClose : undefined}
      autoHideDuration={6000}
    >
      <AlertStyled
        style={getVar()}
        onClose={size === 'sm' ? handleClose : undefined}
        icon={icon ? icon : false}
        elevation={6}
        sx={{ width: '100%', p: '24px 32px 24px 32px' }}
      >
        {extractVariant()}
      </AlertStyled>
    </Snackbar>
  );
};

export default PigogoFloatingAlert;
