import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import Layout from '../components/Navigation/Layout';
import PigogoFooter from '../components/PigogoFooter';
import React, { useRef } from 'react';
import { theme } from 'components';
import CustomHeader from '../components/CustomHeader';
import Saver from '../assets/svgs/illustrations/saver.svg';
import SmartSaver from '../assets/svgs/illustrations/smart_saver.svg';
import ExpertSaver from '../assets/svgs/illustrations/expert_saver.svg';
import SuperSaver from '../assets/svgs/illustrations/super_saver.svg';
import GuruSaver from '../assets/svgs/illustrations/guru_saver.svg';
import SaverCard from '../components/SaverCard';
import CustomContainer from '../components/CustomContainer';
import QuestionSection from '../components/Home/QuestionSection';
import TopBannerImage from '../components/TopBannerImage';
import GamificationBanner from '../assets/jpg/gamificationBanner.jpg';
import GamificationBannerMd from '../assets/jpg/gamificationBannerMd.jpg';
import GamificationBannerSm from '../assets/jpg/gamificationBannerSm.jpg';
import GamificationBannerXl from '../assets/jpg/gamificationBannerXl.jpg';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';

const savers = [
  {
    icon: Saver,
    title: 'Saver',
    description:
      'Αυτό το επίπεδο μπορείς να το κατακτήσεις πανεύκολα κάνοντας απλά την πρώτη σου αγορά μέσω του Pigogo!',
    color: '#FC9E5F',
  },
  {
    icon: SmartSaver,
    title: 'Smart Saver',
    description:
      'Μόλις συγκεντρώσεις 30€ επιστροφή στον λογαριασμό σου, θα ανακηρυχτείς ένας άξιος Smart Saver με πρόσβαση σε περισσότερα προνόμια και bonus 50% επιπλέον επιστροφή στην επόμενη αγορά σου!',
    color: '#4FD69C',
  },
  {
    icon: ExpertSaver,
    title: 'Expert Saver',
    description:
      'Θέλεις περισσότερα αποκλειστικά προνόμια; Θα τα έχεις μόλις συγκεντρώσεις 100€ επιστροφή στον λογαριασμό σου. Αυτό σημαίνει ότι θα είσαι πλέον ένας αχτύπητος Expert Saver, ενώ εμείς θα σε επιβραβεύσουμε με bonus 5€!',
    color: '#37D5F2',
  },
  {
    icon: SuperSaver,
    title: 'Super Saver',
    description:
      'Για να ανακηρυχτείς Super Saver, θα χρειαστεί να συγκεντρώσεις 300€ επιστροφή! Μόλις τα καταφέρεις, εμείς θα σε επιβραβεύσουμε με bonus 10€ ενώ θα έχεις πλέον πρόσβαση σε ακόμα περισσότερα προνόμια!',
    color: '#F75676',
  },
  {
    icon: GuruSaver,
    title: 'Guru Saver',
    description:
      'Για να γίνεις ο απόλυτος Guru της εξοικονόμησης χρημάτων, θα χρειαστεί να συγκεντρώσεις 1.000€ επιστροφή στον λογαριασμό σου στο Pigogo! Μόλις το πετύχεις, θα σε ανταμείψουμε αμέσως με ένα bonus 30€ ενώ τα προνόμια και οι εκπλήξεις δεν τελειώνουν εδώ…',
    color: '#9456F7',
  },
];

const Gamification = () => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mdToXl = useMediaQuery(theme.breakpoints.between('md', 'xl'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    setTimeout(() => {
      history.action !== 'PUSH' &&
        contentRef.current &&
        contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    }, 10);
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  const chooseImg = () => {
    if (mobile) {
      return GamificationBannerSm;
    }
    if (tablet) {
      return GamificationBannerMd;
    }
    if (mdToXl) {
      return GamificationBanner;
    }
    if (xl) {
      return GamificationBannerXl;
    }
  };

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <Box>
            <CustomHeader />
            {tablet && (
              <IonHeader class="ion-no-border">
                <IonToolbar
                  style={{
                    '--background': '#FFFFFF',
                    '--border-width': 0,
                    paddingTop: '0 + --ion-safe-area-top',
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                    <IonButtons slot="start">
                      <IonBackButton
                        className="backBtn"
                        text=""
                        style={{
                          '--color': '#313D53',
                          '--padding-start': 0,
                          '--padding-end': 0,
                          '--icon-font-size': '24px',
                          '--border-radius': 0,
                          '--min-height': '24px',
                          '--min-width': '24px',
                          width: '24px',
                          height: '24px',
                          minWidth: '24px',
                          maxHeight: '24px',
                          display: 'inline-flex',
                        }}
                        icon={backArrow}
                        defaultHref={'/'}
                      />
                    </IonButtons>
                    <Typography component="h2" variant="body2" color={'#313D53'}>
                      Πόσο Saver είσαι;
                    </Typography>
                    <Box minWidth={'24px'}></Box>
                  </Box>
                </IonToolbar>
              </IonHeader>
            )}
            {location.pathname === '/gamification' && (
              <Helmet>
                <title>Πόσο Saver είσαι; | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                <meta
                  name="description"
                  content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                />
              </Helmet>
            )}
          </Box>
          <TopBannerImage image={chooseImg()} title={'Πόσο Saver είσαι;'} shape={true}>
            Στο Pigogo, παίρνουμε την εξοικονόμηση χρημάτων πολύ σοβαρά, για αυτό και έχουμε δημιουργήσει επίπεδα
            εξοικονόμησης ή, όπως τα λέμε αλλιώς, «σήματα» ώστε να επιβραβεύουμε τους χρήστες μας ανάλογα με το ποσό
            επιστροφής που συγκεντρώνουν.
          </TopBannerImage>
          <Box bgcolor="#F7F7F8" pt={mobile ? 5 : 10} pb={mobile ? 8 : 10}>
            <CustomContainer sx={{ maxWidth: '1088px' }}>
              <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
                {savers.map((saver, idx) => (
                  <SaverCard
                    key={idx}
                    icon={saver.icon}
                    title={saver.title}
                    description={saver.description}
                    color={saver.color}
                  />
                ))}
              </Box>
            </CustomContainer>
          </Box>

          <CustomContainer>
            <QuestionSection faqCategory={'3'} />
          </CustomContainer>
          <>{!mobile && !tablet && <PigogoFooter />}</>
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default Gamification;
