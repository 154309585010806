import React, { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface Props {
  typoProps?: TypographyProps;
  text: string;
  selectedCategory: string;
  handleClick: (value: any) => void;
}

const PigogoClaimSelectableButton: FC<Props> = ({ text, typoProps, selectedCategory, handleClick }) => {
  return (
    <button
      style={{
        padding: '12px 14px',
        borderRadius: '88px',
        // height: '48px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: selectedCategory === text ? '#FCE5D8' : '#ECEFF4',
        backgroundColor: selectedCategory === text ? '#FCE5D8' : '#FFFFFF',
        color: '#313D53',
        whiteSpace: 'nowrap',
      }}
      onClick={handleClick}
    >
      <Typography variant="subtitle1SmallR" color={'#313D53'} {...typoProps}>
        {text}
      </Typography>
    </button>
  );
};

export default PigogoClaimSelectableButton;
