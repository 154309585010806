import { Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

interface CustomProps {
  text: string;
  disabled?: boolean;
  background?: string;
  color?: string;
  colorText?: string;
  buttonProps?: ButtonProps;
  typoProps?: TypographyProps;
  handleClick: () => void;
}

const PigogoLink: FC<CustomProps> = ({
  text,
  disabled,
  background,
  color,
  colorText,
  buttonProps,
  typoProps,
  handleClick,
}) => {
  return (
    <Button
      variant={'text'}
      disabled={disabled}
      {...buttonProps}
      sx={{
        padding: 0,
        color: color ? color : '#313D53',
        cursor: 'pointer',
        textUnderlineOffset: '5px',
        '&:hover': {
          textDecorationColor: background ? background : 'none',
          background: background ? background : 'none',
          textDecoration: 'underline',
        },
      }}
      onClick={() => handleClick()}
    >
      <Typography sx={{ textDecorationColor: color ? color : '#FCE5D8', textDecoration: 'underline' }} {...typoProps}>
        {text}
      </Typography>
    </Button>
  );
};

export default PigogoLink;
