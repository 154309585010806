import { theme } from 'components';
import CustomContainer from '../CustomContainer';
import { Box, Typography, useMediaQuery } from '@mui/material';
/* eslint-disable */
const TermsAndConditions = () => {
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <CustomContainer>
      <style>
        {`
        ol li::marker {
          font-size: 16px;
          font-weight: 300;
          line-height: 1.5;
          color: #313D53;
        }
      `}
      </style>
      <Box display="flex" flexDirection="column" gap={5} pl={desktop ? 4 : 0} pr={desktop ? 4 : 0}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            1.ΕΙΣΑΓΩΓΗ
          </Typography>
          <Typography variant="body2MediumL" color={'#313D53'}>
            Η χρήση των υπηρεσιών που παρέχονται από το Pigogo μέσω της ιστοσελίδας www.pigogo.gr διέπεται από τους
            παρακάτω όρους χρήσης. Πριν πλοηγηθείτε στην ιστοσελίδα μας και χρησιμοποιήσετε την υπηρεσία μας,
            βεβαιωθείτε πως έχετε διαβάσει προσεκτικά, πως έχετε κατανοήσει και πως συμφωνείτε με τους παρόντες όρους
            χρήσης.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            2. Η ΥΠΗΡΕΣΙΑ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Η υπηρεσία του Pigogo («Υπηρεσία») επιτρέπει στους χρήστες της ιστοσελίδας να δημιουργούν λογαριασμούς
              («Λογαριασμός») μέσω των οποίων να πραγματοποιούν αγορές στα «Συνεργαζόμενα Καταστήματα» και να κερδίζουν
              «Επιστροφή» χρημάτων.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Για κάθε αγορά που πραγματοποιεί ένας «Εγγεγραμμένος Χρήστης» του Pigogo σε ένα από τα Συνεργαζόμενα
              Καταστήματα και εφόσον αυτή η αγορά καταγραφεί από το σύστημα καταγραφής του καταστήματος ή των συνεργατών
              του και επιβεβαιωθεί από το Κατάστημα («Επιβεβαιωμένη Αγορά»), το Pigogo λαμβάνει μια «Προμήθεια».
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Οι Εγγεγραμμένοι Χρήστες που πραγματοποιούν Επιβεβαιωμένες Αγορές από τα Συνεργαζόμενα Καταστήματα,
              πιστώνονται την αντίστοιχη Επιστροφή στον Λογαριασμό τους. Εφόσον η Προμήθεια πληρωθεί από το
              Συνεργαζόμενο Κατάστημα στο Pigogo, τότε το Pigogo πληρώνει την αντίστοιχη Επιστροφή στον Εγγεγραμμένο
              Χρήστη με βάση τα όσα αναφέρονται στην ενότητα «ΠΛΗΡΩΜΕΣ».
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Το ποσό της Επιστροφής που λαμβάνουν οι Εγγεγραμμένοι Χρήστες για κάθε Επιβεβαιωμένη Αγορά τους, δεν είναι
              σταθερό αλλά εξαρτάται από την Προμήθεια που έχει συμφωνήσει να πληρώνει το κάθε Συνεργαζόμενο Κατάστημα
              στο Pigogo.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Ως Συνεργαζόμενα Καταστήματα, νοούνται τα καταστήματα αυτά τα οποία έχουν συμφωνήσει να συμμετέχουν στην
              Υπηρεσία και να πληρώνουν Προμήθεια στο Pigogo για κάθε Επιβεβαιωμένη Αγορά που πραγματοποιείται από
              κάποιον Εγγεγραμμένο Χρήστη.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            3. ΔΗΜΙΟΥΡΓΙΑ ΛΟΓΑΡΙΑΣΜΟΥ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Για να εγγραφείτε στην Υπηρεσία, θα πρέπει να είστε κάτοικος Ελλάδος και να έχετε συμπληρώσει το 18ο έτος
              της ηλικίας σας.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Τα στοιχεία που θα δηλώσετε κατά την εγγραφή σας στο Pigogo θα πρέπει να είναι δικά σας και να είναι
              ακριβή. Επίσης, τα στοιχεία πληρωμής σας θα πρέπει (εφόσον τα συμπληρώσετε) να είναι ακριβή και να αφορούν
              στοιχεία λογαριασμών οι οποίοι ανήκουν σε εσάς. Θα πρέπει να φροντίζετε όλα τα στοιχεία που είναι δηλωμένα
              στον Λογαριασμό σας να παραμένουν σε ισχύ και να τα ανανεώνετε όταν αυτό χρειάζεται.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Η χρήση του Λογαριασμού είναι προσωπική και δεν επιτρέπεται η δημιουργία λογαριασμών από εταιρείες.
              Επίσης, κάθε άτομο επιτρέπεται να έχει μόνο έναν Λογαριασμό στην Υπηρεσία.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            4. ΕΝΗΜΕΡΩΣΕΙΣ
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Κατά την εγγραφή σας στην Υπηρεσία και την αποδοχή των παρόντων όρων χρήσης, δίνετε την συγκατάθεσή για τη
            λήψη κάποιων email όπως η επιβεβαίωση της εγγραφής σας και η επιβεβαίωση του πρώτου σας κλικ σε
            Συνεργαζόμενο Κατάστημα, καθώς και ενημερωτικά emails για θέματα όπως η προσθήκη νέων Συνεργαζόμενων
            Καταστημάτων στην Υπηρεσία, τα νέα της Υπηρεσίας, η καταγραφή αγορών στον Λογαριασμό σας, η ειδοποίηση
            πληρωμής και άλλα. Μπορείτε ανα πάσα στιγμή να απενεργοποιήσετε τις ενημερώσεις αυτές, μέσω της αντίστοιχης
            σελίδας στον λογαριασμό σας.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            5. ΕΠΙΣΤΡΟΦΗ ΧΡΗΜΑΤΩΝ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Ως Εγγεγραμμένος Χρήστης, έχετε τη δυνατότητα να κερδίσετε Επιστροφή χρημάτων από τις Επιβεβαιωμένες
              Αγορές που πραγματοποιείτε στα Συνεργαζόμενα Καταστήματα.
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                Για να σας αποδοθεί η Επιστροφή, θα πρέπει να συντρέχουν μια σειρά από προϋποθέσεις:
              </Typography>
              <ol
                style={{
                  paddingLeft: '20px',
                  display: 'flex',
                  margin: 0,
                  flexDirection: 'column',
                  gap: '7px',
                  color: '#313D53',
                }}
              >
                <li>
                  <Typography variant="body2MediumL" p={0} color={'#313D53'}>
                    Η αγορά να καταγραφεί από τα συστήματα καταγραφής του Συνεργαζόμενου Καταστήματος ή των συνεργατών
                    του και να υπάρχει αντιστοίχιση με τον Λογαριασμό σας. Για περισσότερες πληροφορίες σχετικά με την
                    καταγραφή των αγορών και το πώς να μειώσετε τις πιθανότητες μη καταγραφής, διαβάστε την ενότητα
                    «Καταγραφή αγορών» στις{' '}
                    <a style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }} href="/faq">
                      Συχνές Ερωτήσεις
                    </a>
                    . <br />
                    Σε περίπτωση μη καταγραφής, μπορείτε να ενημερώσετε το Pigogo το οποίο θα προσπαθήσει να προχωρήσει
                    στην εκ των υστέρων απόδοση της εν λόγω αγοράς σε εσάς, επικοινωνώντας με το Συνεργαζόμενο
                    Κατάστημα. Σε περίπτωση μη καταγραφής, μπορείτε να καταχωρήσετε ένα{' '}
                    <a
                      style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }}
                      href="/user/claims"
                    >
                      Αίτημα
                    </a>{' '}
                    και το Pigogo θα προσπαθήσει να προχωρήσει στην εκ των υστέρων απόδοση της εν λόγω αγοράς σε εσάς,
                    επικοινωνώντας με το Συνεργαζόμενο Κατάστημα. Σε κάθε περίπτωση όμως, η τελική απόφαση για την
                    απόδοση ή μη μιας αγοράς ανήκει στο Συνεργαζόμενο Κατάστημα και στους συνεργάτες του.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2MediumL" color={'#313D53'}>
                    Η αγορά να επιβεβαιωθεί από το Συνεργαζόμενο Κατάστημα. Κάποιοι λόγοι που μπορεί να οδηγήσουν στην
                    μη επιβεβαίωση μιας αγοράς είναι εάν η πληρωμή για την αγορά δεν πραγματοποιήθηκε (π.χ. στην
                    περίπτωση της τραπεζικής κατάθεσης ή της αντικαταβολής), εάν η αγορά ακυρώθηκε ή επεστράφη ή εάν δεν
                    τηρήθηκε κάποιος από τους όρους του καταστήματος σχετικά με την επιστροφή χρημάτων μέσω του Pigogo
                    (π.χ. εάν χρησιμοποιήθηκε στην ίδια αγορά κάποιο κουπόνι εκτός του Pigogo και αυτό δεν επιτρέπεται
                    από το κατάστημα).
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2MediumL" color={'#313D53'}>
                    Η Προμήθεια που προκύπτει από την Επιβεβαιωμένη Αγορά να πληρωθεί από το Συνεργαζόμενο Κατάστημα στο
                    Pigogo.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2MediumL" color={'#313D53'}>
                    Να είναι ενεργός ο λογαριασμός σας.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2MediumL" color={'#313D53'}>
                    Να μην έχετε χρησιμοποιήσει αθέμιτες μεθόδους, με βάση τα όσα αναφέρονται στην ενότητα «ΑΘΕΜΙΤΗ
                    ΧΡΗΣΗ».
                  </Typography>
                </li>
              </ol>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            6. ΑΙΤΗΜΑΤΑ
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Στις περιπτώσεις που δεν βλέπετε την επιστροφή χρημάτων από μία αγορά, ή βλέπετε λάθος ποσό επιστροφής ή η
            αγορά ακυρώθηκε και θεωρείτε ότι αυτό δεν θα έπρεπε να έχει συμβεί, έχετε τη δυνατότητα να καταχωρήσετε ένα
            αίτημα ώστε να το διερευνήσουμε με το Συνεργαζόμενο Κατάστημα. Η καταχώρηση του αιτήματος μπορεί να γίνει
            μέσω της ειδικής ενότητας{' '}
            <a href="/create-claim" style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }}>
              ΑΙΤΗΜΑΤΑ
            </a>{' '}
            . Δικαιούστε να καταχωρήσετε το αίτημά σας μέχρι και 1 μήνα από την ημερομηνία αγοράς εάν το αίτημα αφορά μη
            καταγραφή αγοράς ή λανθασμένο ποσό επιστροφής και 1 μήνα από την ημερομηνία ακύρωσης της αγοράς εάν το
            αίτημα αφορά ακυρωμένη αγορά αντίστοιχα. Αφού συμπληρώσετε όλα τα στοιχεία που απαιτούνται, θα έχουμε τη
            δυνατότητα να επικοινωνήσουμε με το Συνεργαζόμενο Κατάστημα και να διερευνήσουμε το αίτημά σας. Θα κάνουμε
            το καλύτερο δυνατό για να το επιλύσουμε αλλά δεν μπορούμε να εγγυηθούμε την επιτυχή επίλυσή του αφού η
            τελική απόφαση λαμβάνεται από το Συνεργαζόμενο κατάστημα και τους συνεργάτες του. Μπορείτε να ενημερώνεστε
            για την εξέλιξη των αιτημάτων σας στη σελίδα{' '}
            <a href="/user/claims" style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }}>
              ΑΙΤΗΜΑΤΑ
            </a>{' '}
            .
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            7. ΠΛΗΡΩΜΕΣ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Το Pigogo πραγματοποιεί όλες τις πληρωμές στα Εγγεγραμμένα Μέλη του δύο φορές κάθε μήνα. Συγκεκριμένα, η
              πρώτη πληρωμή πραγματοποιείται στις 15 του μήνα και η δεύτερη την τελευταία ημέρα του μήνα. Εάν η ημέρα
              πληρωμής δεν είναι εργάσιμη, η πληρωμή μετατίθεται για την πρώτη εργάσιμη ημέρα μετά την αρχική ημερομηνία
              πληρωμής. Για να πραγματοποιηθεί μια πληρωμή σε ένα Εγγεγραμμένο Μέλος, θα πρέπει να έχει εισάγει σωστά τα
              στοιχεία πληρωμής στον Λογαριασμό του και να έχει ξεπεράσει ένα «Ελάχιστο Ποσό Πληρωμής».
            </Typography>{' '}
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Το ποσό προς πληρωμή αποτελείται από όλες τις αγορές σε κατάσταση «Προς πληρωμή» (αυτές δηλαδή για τις
              οποίες έχει πληρωθεί στο Pigogo η αντίστοιχη Προμήθεια από το Συνεργαζόμενο Κατάστημα) που έχουν
              πραγματοποιηθεί έως και την προηγούμενη ημέρα της πληρωμής.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Περισσότερες πληροφορίες για τους διαθέσιμους τρόπους πληρωμής καθώς και τα αντίστοιχα Ελάχιστα Ποσά
              Πληρωμής για κάθε έναν από αυτούς, μπορείτε να βρείτε στην ενότητα «Θέματα πληρωμών» στις{' '}
              <a href="/faq" style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }}>
                Συχνές Ερωτήσεις
              </a>{' '}
              .
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            8. ΕΝΗΜΕΡΩΣΕΙΣ
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Στα πλαίσια της Υπηρεσίας, το Pigogo παρέχει στους χρήστες εκπτωτικά κουπόνια, τα οποία οι χρήστες μπορούν
            να χρησιμοποιούν κατά τις αγορές τους στα Συνεργαζόμενα Καταστήματα. Το Pigogo δεν φέρει ευθύνη για την
            ακρίβεια των πληροφοριών που παρουσιάζονται στα κουπόνια καθώς και δεν εγγυάται τη σωστή λειτουργία τους
            στις ιστοσελίδες των Συνεργαζόμενων Καταστημάτων. Υπεύθυνο για την ορθότητα και την εγκυρότητα των κουπονιών
            είναι το Συνεργαζόμενο Κατάστημα που εκδίδει το εκάστοτε κουπόνι.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            9. TELL A FRIEND
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              To Pigogo δίνει την ευκαιρία στους Εγγεγραμμένους Χρήστες του να κερδίσουν επιπλέον χρήματα προσκαλώντας
              τους φίλους τους να εγγραφούν στην Υπηρεσία μέσω της ενότητας Tell a friend.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Για να κερδίσει ένας Εγγεγραμμένος Χρήστης τα χρήματα αυτά, πρέπει να προσκαλέσει κάποιον άλλον χρήστη
              χρησιμοποιώντας έναν από τους τρόπους που διαθέτει η Υπηρεσία για τον σκοπό αυτό. Για να εγκριθούν τα
              χρήματα, θα πρέπει να ολοκληρωθεί η εγγραφή του προσκαλούμενου καθώς και 2 ή περισσότερες αγορές οι οποίες
              θα εγκριθούν από τα αντίστοιχα Συνεργαζόμενα Καταστήματα και θα εξασφαλίζουν στον φίλο σου συνολική
              επιστροφή χρημάτων τουλάχιστον 3€ μέσα σε 12 μήνες από την ημερομηνία εγγραφής του. Μόνο αγορές από τα
              Συνεργαζόμενα Καταστήματα θα λαμβάνονται υπόψη για τον υπολογισμό του ελάχιστου ποσoύ επιστροφής των 3€
              που θα πρέπει να συγκεντρώσει ο προσκαλούμενος. Άλλου είδους συναλλαγές όπως Tell A Friend ή άλλα μπόνους
              χωρίς αγορά δεν θα λαμβάνονται υπόψη. Δεν θα αποδίδεται προμήθεια αν ο προσκαλούμενος είχε ήδη λογαριασμό
              στο Pigogo. Επίσης, δεν θα αποδίδεται προμήθεια για πολλαπλές εγγραφές από το ίδιο άτομο ή για ψεύτικες
              εγγραφές. To Pigogo διατηρεί το δικαίωμα να απαγορεύσει την πρόσβαση στο πρόγραμμα Tell a Friend σε
              συγκεκριμένους χρήστες.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            10. ΑΘΕΜΙΤΗ ΧΡΗΣΗ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Οι Εγγεγραμμένοι Χρήστες πρέπει να δρουν ηθικά και λογικά αναφορικά στη χρήση της Υπηρεσίας. Το Pigogo
              διατηρεί το δικαίωμα να απενεργοποιήσει ή και να διαγράψει τον Λογαριασμό ενός Εγγεγραμμένου Χρήστη εφόσον
              αυτός χρησιμοποιεί την Υπηρεσία με αθέμιτο τρόπο. Αθέμιτη χρήση της Υπηρεσίας προκύπτει όταν ο
              Εγγεγραμμένος Χρήστης:
            </Typography>
            <ul
              style={{
                paddingLeft: '20px',
                display: 'flex',
                margin: 0,
                flexDirection: 'column',
                gap: '7px',
                color: '#313D53',
              }}
            >
              <li>
                <Typography variant="body2MediumL" p={0} color={'#313D53'}>
                  Χρησιμοποιεί τον Λογαριασμό του για την καταγραφή αγορών που πραγματοποιούνται από άλλα άτομα και όχι
                  από τον ίδιο.
                </Typography>
              </li>
              <li>
                <Typography variant="body2MediumL" color={'#313D53'}>
                  Πραγματοποιεί αγορές που έχουν σκοπό την εξαπάτηση του Συνεργαζόμενου Καταστήματος ή την κατάχρηση
                  κάποιας προσφοράς, όπως για παράδειγμα αγορές με ψεύτικα στοιχεία ή στοιχεία άλλου ατόμου, η μη τήρηση
                  των όρων του Συνεργαζόμενου Καταστήματος κλπ.
                </Typography>
              </li>
              <li>
                <Typography variant="body2MediumL" color={'#313D53'}>
                  Χρησιμοποιεί αθέμιτα μέσα και τεχνικές για την καταγραφή αγορών, όπως για παράδειγμα spyware, adware,
                  malware, robots, forced clicks, automatic openings, automatic cookie dropping ή cookie stuffing.
                </Typography>
              </li>
            </ul>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Το Pigogo δεν θα έχει την υποχρέωση να πιστώσει οποιαδήποτε Επιστροφή σε Λογαριασμό που παραβιάζει τους
              παρόντες όρους. Επιπλέον, στην περίπτωση της αθέμιτης χρήσης, το Pigogo διατηρεί το δικαίωμα να διαγράψει
              Επιστροφές που έχουν ήδη πιστωθεί στον Λογαριασμό του χρήστη.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            11. ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            H διαχείριση των δεδομένων των επισκεπτών της ιστοσελίδας καθώς και των Εγγεγραμμένων Χρηστών της Υπηρεσίας,
            διέπεται από την{' '}
            <a href="/privacy" style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }}>
              Πολιτική Απορρήτου
            </a>{' '}
            του Pigogo.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            12. ΔΙΚΑΙΩΜΑΤΑ ΠΝΕΥΜΑΤΙΚΗΣ ΙΔΙΟΚΤΗΣΙΑΣ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Όλο το περιεχόμενο των ιστοσελίδων, που αναρτάται από το Pigogo, συμπεριλαμβανομένων εικόνων, γραφικών,
              φωτογραφιών, σχεδίων, κειμένων και παρεχόμενων υπηρεσιών αποτελούν πνευματική ιδιοκτησία του Pigogo.
              Επίσης τα ονόματα, εικόνες, λογότυπα και διακριτικά γνωρίσματα που αντιπροσωπεύουν το Pigogo και τις
              ιστοσελίδες του, είναι αποκλειστικά σήματα και διακριτικά γνωρίσματα του Pigogo.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              H εμφάνισή και έκθεση τους στο www.pigogo.gr και σε λοιπές ιστοσελίδες του Pigogo δεν θα πρέπει κατά
              κανένα τρόπο να εκληφθεί ως μεταβίβαση ή εκχώρηση άδειας ή δικαιώματος χρήσης τους.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            13. ΕΠΙΦΥΛΑΞΗ ΔΙΚΑΙΩΜΑΤΩΝ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Το Pigogo διατηρεί το δικαίωμα να:
            </Typography>
            <ul
              style={{
                paddingLeft: '20px',
                display: 'flex',
                margin: 0,
                flexDirection: 'column',
                gap: '7px',
                color: '#313D53',
              }}
            >
              <li>
                <Typography variant="body2MediumL" p={0} color={'#313D53'}>
                  Τροποποιεί και να ανανεώνει μονομερώς τους παρόντες όρους. Επιπρόσθετα, το Pigogo διατηρεί το δικαίωμα
                  να προσθέσει, αλλάξει, αναστείλει ή διακόψει οποιαδήποτε πλευρά της Υπηρεσίας ανά πάσα στιγμή. Το
                  Pigogo αναλαμβάνει την υποχρέωση να αναρτά τους ανανεωμένους όρους στην ιστοσελίδα της Υπηρεσίας.
                </Typography>
              </li>
              <li>
                <Typography variant="body2MediumL" color={'#313D53'}>
                  Απενεργοποιεί τους Λογαριασμούς των Εγγεγραμμένων Χρηστών οι οποίοι δεν έχουν συνδεθεί στην Υπηρεσία
                  για διάστημα μεγαλύτερο των 12 μηνών. Το Pigogo δεν θα έχει την υποχρέωση να πιστώσει οποιαδήποτε
                  Επιστροφή σε Λογαριασμό που έχει απενεργοποιηθεί.
                </Typography>
              </li>
              <li>
                <Typography variant="body2MediumL" color={'#313D53'}>
                  Απενεργοποιεί ή και να διαγράφει τους Λογαριασμούς των Εγγεγραμμένων Χρηστών οι οποίοι αθετούν,
                  παραβιάζουν ή δεν πληρούν τους παρόντες όρους. Σε αυτή την περίπτωση, το Pigogo δεν θα έχει την
                  υποχρέωση πληρωμής οποιασδήποτε Επιστροφής στους εν λόγω χρήστες.
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            14. ΠΕΡΙΟΡΙΣΜΟΣ ΕΥΘΥΝΗΣ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Η Υπηρεσία, η χρήση της και τα αποτελέσματα από τη χρήση της παρέχονται «ως έχουν» και δεν παρέχονται από
              το Pigogo εγγυήσεις ποιότητας ή άλλες εγγυήσεις οποιουδήποτε είδους για την Υπηρεσία.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              To Pigogo δεν θα φέρει ευθύνη για (i) οποιαδήποτε έμμεση, ειδική, συμπτωματική ή επακόλουθη απώλεια φήμης
              που προκύπτει από τη χρήση ή την ανικανότητα χρήσης της Υπηρεσίας ή οποιωνδήποτε πληροφοριών στην
              ιστοσελίδα της Υπηρεσίας ή οποιασδήποτε ιστοσελίδας μέσω υπερσυνδέσμου, συμπεριλαμβάνοντας, χωρίς
              περιορισμούς, χαμένα κέρδη, διακοπή εργασιών, απώλεια δεδομένων του Εγγεγραμμένου Χρήστη, ακόμα και αν το
              Pigogo έχει ενημερωθεί για την πιθανότητα τέτοιων ζημιών ή (ii) οποιαδήποτε απαίτηση αποδοτέα σε λάθη,
              παραλείψεις ή αλλες ανακρίβειες στην ιστοσελίδα της Υπηρεσίας ή σε οποιαδήποτε ιστοσελίδα με την οποία
              συνδέεται η ιστοσελίδα της Υπηρεσίας μέσω υπερσυνδέσμου.
            </Typography>{' '}
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              To Pigogo δεν φέρει καμία ευθύνη ή υπαιτιότητα σε σχέση με τα Συνεργαζόμενα Καταστήματα που αποτελούν
              κομμάτι της Υπηρεσίας. Καμία εγγύηση δεν μπορεί να δοθεί αναφορικά στη λειτουργία ή στην ποιότητα των
              καταστημάτων αυτών.
            </Typography>{' '}
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Συμφωνείτε να απαλλάξετε το Pigogo και τα στελέχη του, τους διευθυντές του, υπαλλήλους, αντιπροσώπους κλπ.
              από οποιαδήποτε υπαιτιότητα και ευθύνη απώλειας, ζημιών, τραυματισμού ή εξόδων (συμπεριλαμβανομένων
              νομικών εξόδων) που έμμεσα ή άμεσα προκύπτουν από ή συσχετίζονται με οτιδήποτε σχετικό με αυτούς τους
              όρους χρήσης.
            </Typography>
          </Box>
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default TermsAndConditions;
