import { Box, Typography, useMediaQuery } from '@mui/material';
import CustomContainer from './CustomContainer';
import React, { FC } from 'react';
import { theme } from 'components';
import AlertMoreMobile from '../assets/png/alertMoreMobile.png';
import AlertMoreLg from '../assets/png/alertMoreLg.png';
import { ReactComponent as Check } from '../assets/svgs/check_circle_color.svg';

interface Props {
  bgColor: string;
  checkBackground: string;
}

const AlertMore: FC<Props> = ({ bgColor, checkBackground }) => {
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const smToMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const chooseMoreImage = () => {
    if (downSm) {
      return AlertMoreMobile;
    }
    if (downLg) {
      return AlertMoreLg;
    }
    if (upLg) {
      return AlertMoreLg;
    }
  };

  return (
    <Box height={downMd ? '' : '656px'} sx={{ backgroundColor: bgColor }} position={'relative'}>
      <CustomContainer height={'100%'} display="flex">
        <Box
          height={'100%'}
          sx={{
            width: '100%',
            display: 'flex',
            gap: downLg ? 6 : 13,
            justifyContent: 'center',
            paddingTop: downSm || smToMd ? '32px' : '',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width={smToMd ? '100%' : '496px'}
            height={'100%'}
            justifyContent="center"
            gap={4}
          >
            <Typography variant="smallB" component="h2" color="#1D2532">
              Στο Pigogo ALERT επίσης θα βρεις...
            </Typography>
            <Box display="flex" flexDirection="column" gap={3}>
              <Box bgcolor={checkBackground} display="flex" gap={2} alignItems="start">
                <Box
                  color={'#E9688A'}
                  sx={{
                    width: '32px',
                    height: '32px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Check />
                </Box>
                <Typography variant="largeRegular" component="p" color="#313D53">
                  Τα πιο δημοφιλή καταστήματα του Pigogo για να κάνεις ακόμα πιο γρήγορα και εύκολα τις αγορές σου σε
                  αυτά.
                </Typography>
              </Box>
              <Box bgcolor={checkBackground} display="flex" gap={2} alignItems="start">
                <Box
                  color={'#E9688A'}
                  sx={{
                    width: '32px',
                    height: '32px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Check />
                </Box>
                <Typography variant="largeRegular" component="p" color="#313D53">
                  Τις TOP προσφορές και κουπόνια από τα οποία μπορείς να επωφεληθείς κάνοντας τις αγορές σου.
                </Typography>
              </Box>
              <Box bgcolor={checkBackground} display="flex" gap={2} alignItems="start">
                <Box
                  color={'#E9688A'}
                  sx={{
                    width: '32px',
                    height: '32px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Check />
                </Box>
                <Typography variant="largeRegular" component="p" color="#313D53">
                  Τις πιο σημαντικές πληροφορίες του λογαριασμού σου, όπως την επιστροφή χρημάτων που έχεις συγκεντρώσει
                  κ.α.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box hidden={downMd} display={'flex'} alignSelf={'center'}>
            <img alt={'pigogoApp'} src={chooseMoreImage()} />
          </Box>
        </Box>
      </CustomContainer>

      <Box hidden={!downMd} display={'flex'} justifyContent={'center'} pb={4} pl={3} pr={3} sx={{ paddingTop: '48px' }}>
        <img alt={'pigogoApp'} src={chooseMoreImage()} />
      </Box>
    </Box>
  );
};

export default AlertMore;
