import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react';
import CustomHeader from '../components/CustomHeader';
import React, { useRef } from 'react';
import Layout from '../components/Navigation/Layout';
import { theme } from 'components';
import QuestionSection from '../components/Home/QuestionSection';
import PigogoFooter from '../components/PigogoFooter';
import CustomContainer from '../components/CustomContainer';
import AlertDownloadLg from '../assets/png/alertDownloadLg.png';
import AlertStep1 from '../assets/png/alertDownloadStep1.png';
import AlertStep2 from '../assets/png/alertDownloadStep2.png';
import AlertStep3 from '../assets/png/alertDownloadStep3.png';
import AlertMore from '../components/AlertMore';
import TopBannerImage from '../components/TopBannerImage';
import StepSectionAlert from '../components/StepSectionAlert';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';

const AlertDownload = () => {
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    setTimeout(() => {
      history.action !== 'PUSH' &&
        contentRef.current &&
        contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    }, 10);
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <Box>
            {location.pathname === '/alert-download' && (
              <Helmet>
                <title>Pigogo ALERT! | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                <meta
                  name="description"
                  content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                />
              </Helmet>
            )}
            {downMd && (
              <IonHeader class="ion-no-border">
                <IonToolbar
                  style={{
                    '--background': '#FFFFFF',
                    '--border-width': 0,
                    paddingTop: '0 + --ion-safe-area-top',
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                    <IonButtons slot="start">
                      <IonBackButton
                        className="backBtn"
                        text=""
                        style={{
                          '--color': '#313D53',
                          '--padding-start': 0,
                          '--padding-end': 0,
                          '--icon-font-size': '24px',
                          '--border-radius': 0,
                          '--min-height': '24px',
                          '--min-width': '24px',
                          width: '24px',
                          height: '24px',
                          minWidth: '24px',
                          maxHeight: '24px',
                          display: 'inline-flex',
                        }}
                        icon={backArrow}
                        defaultHref={'/'}
                      />
                    </IonButtons>
                    <Typography component="h2" variant="body2" color={'#313D53'}>
                      Pigogo ALERT!
                    </Typography>
                    <Box minWidth={'24px'}></Box>
                  </Box>
                </IonToolbar>
              </IonHeader>
            )}
          </Box>
          <TopBannerImage image={AlertDownloadLg} title={'Συγχαρητήρια!'} shape={false}>
            Μόλις εγκατέστησες το Pigogo ALERT στον browser του υπολογιστή σου!
          </TopBannerImage>

          <Box bgcolor="#F7F7F8">
            <StepSectionAlert />
          </Box>
          <AlertMore bgColor={'#FEF6F1'} checkBackground={'#FEF6F1'} />
          <QuestionSection hasExtraPadding />
          <PigogoFooter />
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default AlertDownload;
