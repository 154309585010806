import { Box, Chip, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { ReactComponent as Close } from '../../assets/svgs/vectors/close.svg';
import { theme } from 'components';

type Props = {
  selectedCategories: { label: string; slugName: string }[];
  handleRemoveCategory: (category: string) => void;
};
const CategoriesChipList: FC<Props> = ({ selectedCategories, handleRemoveCategory }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {selectedCategories.map((category, index) => (
        <Chip
          size={'medium'}
          deleteIcon={<Close />}
          label={category.label}
          key={`${category.label}-${index}`}
          onDelete={() => handleRemoveCategory(category.slugName)}
          sx={{
            fontSize: mdDown ? '14px' : '12px',
            fontWeight: 500,
            lineHeight: '1.33',
            backgroundColor: '#EAECEE',
            color: '#313D53',
            padding: mdDown ? '8px 12px 8px 12px' : '4px 8px 4px 12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
            borderRadius: '32px',
            minHeight: mdDown ? '40px' : '32px',
            '& .MuiChip-deleteIcon': {
              width: '24px',
              height: '24px',
              margin: '0',
              color: '#313D53',
              padding: mdDown ? '0' : '3px',
            },
            '& .MuiChip-label': {
              paddingRight: '0px',
              paddingLeft: '0px',
            },
          }}
        />
      ))}
    </>
  );
};

export default CategoriesChipList;
