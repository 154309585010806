import { GenericResponse } from '../models/GenericResponse';
import { ShopCategory } from '../models/ShopCategory';

const extractData = <T extends object>(
  response: GenericResponse<T> | T,
): {
  responseData: T;
  nextCursor: string | undefined | null;
  categories: ShopCategory[] | undefined;
  totalCount: number;
} => {
  let responseData: T;
  let nextCursor: string | undefined | null;
  let categories: ShopCategory[] | undefined;
  let totalCount: number;

  if ('data' in response) {
    responseData = response.data;
  } else {
    responseData = response;
  }

  if ('next_cursor' in response) {
    nextCursor = response.next_cursor;
  } else {
    nextCursor = undefined;
  }
  if ('categories' in response) {
    categories = response.categories ?? [];
  } else {
    categories = undefined;
  }
  if ('total_count' in response) {
    totalCount = response.total_count ?? 1;
  } else {
    totalCount = 0;
  }

  return { responseData, nextCursor, categories, totalCount };
};

export { extractData };
