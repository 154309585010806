import { createSlice } from '@reduxjs/toolkit';
import { Authentication } from '../types/Authentication';
import Cookies from 'js-cookie';
import { isWeb } from '../../utils/device';

const prepareInitialState = () => {
  const web = isWeb();
  let accessToken;
  let refreshToken;
  let sessionLoggedIn;

  if (!web) {
    accessToken = localStorage.getItem('pigogoAccessToken');
    refreshToken = localStorage.getItem('pigogoRefreshToken');
  } else {
    accessToken = Cookies.get('pigogoAccessToken');
    refreshToken = Cookies.get('pigogoRefreshToken');

    if (Cookies.get('impersonateAccessToken')) {
      accessToken = Cookies.get('impersonateAccessToken');
    }

    sessionLoggedIn = Cookies.get('sessionLoggedIn');
  }

  return {
    data: {
      accessToken: accessToken ? accessToken : '',
      refreshToken: refreshToken ? refreshToken : '',
      user: undefined,
      firstLogin: false,
      seenAlert: true,
      sessionLoggedIn:
        sessionLoggedIn && sessionLoggedIn !== '' && !Cookies.get('impersonateAccessToken')
          ? sessionLoggedIn.toLowerCase() === 'true'
          : false,
    },
    meta: {
      loading: false,
      error: '',
      success: '',
    },
  };
};

const initialState: Authentication = prepareInitialState();

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.data.user = action.payload;
    },
    setFirstLogin: (state, action) => {
      state.data.firstLogin = action.payload.isFirst;
    },
    setSeenAlert: (state, action) => {
      state.data.seenAlert = action.payload.seenAlert;
    },
    setAccessToken: (state, action) => {
      state.data.accessToken = action.payload.token;
      if (action.payload.rememberMe) {
        Cookies.set('pigogoAccessToken', action.payload.token, { expires: 365 });
        localStorage.setItem('pigogoAccessToken', action.payload.token);
      } else {
        Cookies.set('pigogoAccessToken', action.payload.token);
        localStorage.setItem('pigogoAccessToken', action.payload.token);
      }
    },
    setSessionLoggedIn: (state, action) => {
      state.data.sessionLoggedIn = action.payload.sessionLoggedIn;
      Cookies.set('sessionLoggedIn', action.payload.sessionLoggedIn, {
        expires: action.payload.rememberMe ? 5 * 365 : undefined,
      });
      localStorage.setItem('sessionLoggedIn', action.payload.sessionLoggedIn);
    },
    setRefreshToken: (state, action) => {
      state.data.refreshToken = action.payload.refresh_token;
      Cookies.set('pigogoRefreshToken', action.payload.refresh_token, { expires: 365 });
      localStorage.setItem('pigogoRefreshToken', action.payload.refresh_token);
    },
    clearUser: (state) => {
      state.data.accessToken = '';
      state.data.user = undefined;
      state.data.refreshToken = '';
      state.data.sessionLoggedIn = false;
      state.data.firstLogin = false;
      state.data.seenAlert = true;
      Cookies.remove('pigogoAccessToken');
      Cookies.remove('pigogoRefreshToken');
      Cookies.remove('sessionLoggedIn');
      Cookies.remove('XSRF-TOKEN');
      Cookies.remove('is_user', { httpOnly: true });
      localStorage.removeItem('pigogoAccessToken');
      localStorage.removeItem('pigogoRefreshToken');
      localStorage.removeItem('sessionLoggedIn');
    },
  },
});

export const { setAccessToken, setUser, setRefreshToken, clearUser, setFirstLogin, setSeenAlert, setSessionLoggedIn } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
