import { Box, BoxProps, styled, Typography, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import el from 'moment/locale/el';
import { ReactComponent as Arrow_right_white } from './../../assets/svgs/vectors/arrow_right_white.svg';
import { Assets, PigogoChip, PigogoNavigationIconButton, theme } from 'components';
import { Coupon } from '../../models/Coupon';
import { getColorDate } from '../../utils/getColorDate';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { Shop } from '@mui/icons-material';
import { isWeb } from '../../utils/device';
import { Browser } from '@capacitor/browser';

const StyleBox = styled(Box)(({ theme }) => ({
  background: '#FEF6F8',
  borderRadius: 24,
  padding: '40px 32px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

type StoreCardProps = {
  title: string;
  data: Coupon[];
  logout: boolean;
  setRedirectModal?: () => void;
  setLogin: () => void;
  footerRedirLink?: (val: string) => void;
};

const PigogoCouponCard: FC<StoreCardProps & BoxProps> = ({
  title,
  data,
  logout,
  setLogin,
  setRedirectModal,
  footerRedirLink,
}) => {
  moment.updateLocale('el', el);
  const dispatch = useDispatch();
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const isCapacitorApp = !isWeb();

  const [codeView, setCodeView] = useState<Array<boolean>>([]);

  useEffect(() => {
    let copyArray: boolean[] = [];
    if (data.length !== 0) {
      const max = data.length;
      copyArray = Array(max).fill(false);
      setCodeView(copyArray);
    }
  }, [data]);

  const handleClickCoupon = (event: any, index: number) => {
    if (logout) {
      setLogin();
    } else {
      const copyArray = [...codeView];
      copyArray[index] = !codeView[index];
      setCodeView(copyArray);
    }
    event.stopPropagation();
  };

  const handleCopyCoupon = (event: any, code?: string) => {
    if (code) {
      navigator.clipboard.writeText(code);

      const params = { value: true, msg: 'Αντιγράφηκε στο πρόχειρο', severity: 'infoBlue' };
      dispatch(setSnackBar(params));
    }

    event.stopPropagation();
  };

  const renderNoData = () => {
    return (
      <Box mt={downSm ? 0 : -3} maxWidth={'calc(100% - 48px)'}>
        <Typography variant="subtitle1SmallL" component="p" color={'#1D2532'}>
          Δεν υπάρχουν διαθέσιμα κουπόνια αυτή τη στιγμή.
        </Typography>
        <Typography variant="subtitle1SmallL" component="p" color={'#1D2532'}>
          Πρόσθεσε το κατάστημα στα Αγαπημένα σου και θα ενημερωθείς μόλις έχει διαθέσιμα κουπόνια.
        </Typography>
      </Box>
    );
  };

  const viewCode = (index: number) => {
    return (
      <Box display={'flex'} alignItems={'center'} className="chipBig">
        <PigogoChip
          dotted
          label={'Δες τον κωδικό'}
          inputProps={{
            style: {
              height: '48px',
              background: 'unset',
            },
          }}
          onClick={(e: any) => {
            handleClickCoupon(e, index);
            if (logout) {
              footerRedirLink && footerRedirLink(data[index].url);
            }
          }}
        />
      </Box>
    );
  };

  const renderCoupon = (coupon: Coupon) => {
    return (
      <Box gap={2} display={'flex'} alignItems={'center'}>
        <Box className="chipBig">
          <PigogoChip
            dotted
            label={coupon.code}
            endIcon={<Assets.Copy />}
            onClick={(e: any) => {
              handleCopyCoupon(e, coupon.code);
            }}
            inputProps={{
              style: {
                height: '56px',
                background: 'unset',
              },
            }}
          />
        </Box>
        <Box>
          {!isCapacitorApp && (
            <a href={coupon.url} target="_blank">
              <PigogoNavigationIconButton
                simple
                icon={<Arrow_right_white />}
                handleClick={() => {
                  setRedirectModal && setRedirectModal();
                }}
              />
            </a>
          )}
          {isCapacitorApp && (
            <PigogoNavigationIconButton
              simple
              icon={<Arrow_right_white />}
              handleClick={() => {
                setRedirectModal && setRedirectModal();
                if (isCapacitorApp) {
                  Browser.open({ url: coupon.url });
                } else {
                  window.open(coupon.url, '_blank');
                }
              }}
            />
          )}
        </Box>
      </Box>
    );
  };

  const renderDataList = () => {
    return data.map((coupon, index) => (
      <Box
        key={index}
        display={'flex'}
        alignItems={tablet ? 'flex-start' : 'center'}
        justifyContent={'space-between'}
        flexDirection={tablet ? 'column' : 'row'}
        paddingBottom={2}
        gap={3}
        id={coupon.id.toString()}
        sx={{
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box>
            <Typography variant={'body2'} color={'#1D2532'}>
              {coupon.title}
            </Typography>
          </Box>
          <Box>
            <Typography variant={'body2MediumL'} color={'#1D2532'} component="p">
              {coupon.description}
            </Typography>
          </Box>
          {coupon.end_date && (
            <Box>
              <Typography variant={'caption'} color={getColorDate(coupon.expiration_class)}>
                έως {moment(coupon.end_date).format('LL')}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          {coupon.code && !logout && renderCoupon(coupon)}
          {coupon.code && logout && viewCode(index)}
        </Box>
      </Box>
    ));
  };

  const renderData = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        sx={{
          '&>:nth-of-type(n)': {
            borderBottom: '1px solid #EAECEE',
          },
          '&> :last-child': {
            borderBottom: 'none',
          },
        }}
      >
        {renderDataList()}
      </Box>
    );
  };

  return (
    <>
      <Box marginTop={2}></Box>
      <StyleBox>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <Typography variant="large900" component="h3" color={'#1D2532'}>
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '48px',
              height: '48px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {<Assets.TicketPink />}
          </Box>
        </Box>
        {data.length === 0 ? renderNoData() : renderData()}
      </StyleBox>
    </>
  );
};

export default PigogoCouponCard;
