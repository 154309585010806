import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Header from './Header';
import HowItWorks from './HowItWorks';
import SaverMeter from './SaverMeter';
import DontForget from './DontForget';
import WarningMessage from './WarningMessage';
import { theme } from 'components';
import { Helmet } from 'react-helmet-async';

const Overview = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box pt={mdDown ? 2 : undefined} display="flex" flexDirection="column" gap={2}>
        {!mdDown && <WarningMessage />}
        {location.pathname === '/user/overview' && (
          <Helmet>
            <title>Σύνοψη - Ο λογαριασμός μου | Pigogo</title>
            <meta
              name="description"
              content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
            />
          </Helmet>
        )}
        <Box display="flex" flexDirection="column" gap={mdDown ? 3 : 8} pb={mdDown ? 0 : 15}>
          <Header />
          <HowItWorks />
          <SaverMeter />
          <DontForget />
        </Box>
      </Box>
    </>
  );
};

export default Overview;
