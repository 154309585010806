import React, { useEffect } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { PigogoButton, theme } from 'components';
import { ReactComponent as PigogoBlog } from '../../assets/svgs/logo/dark/outlined.svg';
import BlogCard from './BlogCard';
import CustomContainer from '../CustomContainer';
import { useBlogQuery } from '../../redux/api/queries/blogQuery';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const BlogSection = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: blogs, isLoading: blogLoad, isError: errorBlog } = useBlogQuery({ limit: 3 });

  useEffect(() => {
    if (errorBlog) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά με τα δεδομένα blog!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorBlog]);

  return (
    <CustomContainer
      pt={smDown ? 4 : 10}
      pb={smDown ? 4 : 10}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={2} pb={6}>
        <Typography variant="smallB" component="h2" color={'#1D2532'}>
          Pigogo Blog
        </Typography>
        <Typography variant="body2MediumL" component="p" textAlign="center" color={'#1D2532'}>
          Όλα τα τελευταία νέα του Pigogo
        </Typography>
      </Box>

      <Box display={'flex'} gap={4} pb={8} flexWrap={'wrap'} justifyContent={'center'}>
        {blogLoad &&
          [1, 1, 1].map((item, index) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
              <BlogCard loading />
            </Box>
          ))}
        {!blogLoad &&
          blogs &&
          blogs.map((item: any, index: number) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
              <BlogCard title={item.categories[0]} subtitle={item.title} content={item.excerpt} url={item.link} />
            </Box>
          ))}
      </Box>

      <Box width={'327px'} maxWidth={'100%'}>
        <a
          className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth pigogoButton--icon"
          href={'https://www.pigogo.gr/blog/'}
        >
          <PigogoBlog />
          <span>Δες το pigogo blog</span>
        </a>
      </Box>
    </CustomContainer>
  );
};

export default BlogSection;
