import React from 'react';
import { ReactComponent as Find } from '../../assets/svgs/illustrations/search.svg';
import { ReactComponent as Symbol } from '../../assets/svgs/firstSlideSymbol.svg';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';

const FirstSlide = () => {
  const narrowScreenExcl = useMediaQuery(theme.breakpoints.down('sm'));
  const xxsScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const narrowScreen = narrowScreenExcl || xxsScreen;

  return (
    <Box
      p={3}
      gap={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: '#FFFFFF' }}
      position="relative"
    >
      <Box position="absolute" top={20} zIndex={0}>
        <Symbol />
      </Box>
      <Box zIndex={1} style={{ paddingTop: '20px' }}>
        <Find width={160} height={160} />
      </Box>
      <Box display="flex" zIndex={1} flexDirection="column" gap={2}>
        <Typography px={narrowScreen ? 2 : 6} textAlign="center" variant="h4">
          Βρες το κατάστημα
        </Typography>
        <Typography px={narrowScreen ? 2 : 6} textAlign="center" variant="mediumH6R">
          Αναζήτησε το κατάστημα από το οποίο θέλεις να αγοράσεις και κάνε κλικ στην επιλογή «Μεταφορά στο κατάστημα».
        </Typography>
      </Box>
    </Box>
  );
};

export default FirstSlide;
