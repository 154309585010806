import React, { FC } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { PigogoCheckbox, PigogoTextArea } from 'components';
import DatePicker from '../../DatePicker';
import TimePicker from '../../TimePicker';
import { theme } from 'components';
import { CancellationDetails, CreateClaimStepThree } from '../../../models/CreateClaimStepThree';

const cancellationDetailsOptions = [
  {
    value: CancellationDetails.noChange,
    label: 'Εκτελέστηκε κανονικά η παραγγελία μου, δεν υπήρξε κάποια αλλαγή ή επιστροφή',
  },
  {
    value: CancellationDetails.change,
    label: 'Εκτελέστηκε κανονικά η παραγγελία μου, υπήρξε αλλαγή ή επιστροφή σε 1 ή περισσότερα είδη',
  },
  { value: CancellationDetails.other, label: 'Άλλο' },
];

const BpIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  backgroundColor: '#ffffff',
  boxShadow: 'inset 0 0 0 1px #838B98, inset 0 -1px 0 #838B98',

  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow: 'inset 0 0 0 1px #E9688A, inset 0 -1px 0 #E9688A',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
}));

interface Props {
  stepThreeState: CreateClaimStepThree;
  setStepThreeState: (stepThreeState: CreateClaimStepThree) => void;
  formErrors: any;
  setFormErrors: (arg0: any) => void;
}

const StepThreeCancelledContent: FC<Props> = ({ stepThreeState, setStepThreeState, formErrors, setFormErrors }) => {
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDateChange = (newValue: Date | null) => {
    setFormErrors({ ...formErrors, confirmation_date: false });
    setStepThreeState({ ...stepThreeState, confirmation_date: newValue });
  };

  const handleTimeChange = (newValue: Date | null) => {
    setFormErrors({ ...formErrors, confirmation_time: false });
    setStepThreeState({ ...stepThreeState, confirmation_time: newValue });
  };

  const handlePaymentTypeChange = (event: React.ChangeEvent<any>) => {
    setFormErrors({ ...formErrors, cancellation_details_id: false });
    setStepThreeState({ ...stepThreeState, cancellation_details_id: event.target.value as CancellationDetails });
  };

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Typography variant="mediumM" color={'#1D2532'} component="h4" id="purchaseInfo">
          Σχετικά με την αγορά σου
        </Typography>
        <FormControl>
          <RadioGroup
            sx={{ display: 'flex', gap: mobile ? 3 : 4 }}
            aria-labelledby="purchaseInfo"
            onChange={handlePaymentTypeChange}
            defaultValue={stepThreeState.cancellation_details_id ?? null}
          >
            {cancellationDetailsOptions.map((option, index) => (
              <FormControlLabel
                sx={{ margin: 0 }}
                key={index}
                value={option.value}
                control={
                  <Radio sx={{ padding: 0 }} color="secondary" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />
                }
                label={
                  <Typography variant="mediumM" pl={'26px'} color={'#313D53'}>
                    {option.label}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
        {formErrors.cancellation_details_id && (
          <Typography variant={'caption'} color={'#F75676'} pl={3} component="p">
            Το πεδίο είναι υποχρεωτικό.
          </Typography>
        )}
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={3} maxWidth={'720px'}>
        <Typography variant="mediumM" color={'#1D2532'} component="h4">
          Επιπλέον σχόλια
        </Typography>
        <PigogoTextArea
          inputProps={{ multiline: true, rows: 5, fullWidth: true }}
          value={stepThreeState.user_note}
          setValue={(value: any) => setStepThreeState({ ...stepThreeState, user_note: value })}
          placeholder="Εδώ μπορείς να αναφέρεις οτιδήποτε θεωρείς χρήσιμο για τη συγκεκριμένη αγορά."
        />
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="mediumM" color={'#1D2532'} component="h4">
          Επιβεβαιωτικό email αγοράς από το κατάστημα
        </Typography>
        <Box display="flex" flexDirection={smDown ? 'column' : 'row'} gap={2}>
          <DatePicker
            error={formErrors.confirmation_date}
            width="316px"
            placeholder="HH/MM/EEEE"
            value={stepThreeState.confirmation_date}
            handleChange={handleDateChange}
            label="Ημερομηνία λήψης"
            validateFutureDate
          />
          <TimePicker
            error={formErrors.confirmation_time}
            width="316px"
            placeholder="ΩΩ/ΛΛ"
            value={stepThreeState.confirmation_time}
            handleChange={handleTimeChange}
            label="Ώρα λήψης"
          />
        </Box>
      </Box>
      <Box>
        <FormControlLabel
          sx={{
            margin: 0,
            '& .MuiCheckbox-root': {
              width: '24px',
              height: '24px',
              marginRight: 0,
            },
            '& .MuiCheckbox-root.Mui-checked': {
              outlineOffset: '-4px',
              outlineWidth: '4px',
            },
          }}
          control={
            <PigogoCheckbox
              checked={!stepThreeState.has_confirmation_email}
              setValue={() => {
                setFormErrors({ ...formErrors, confirmation_email: false });
                setStepThreeState({
                  ...stepThreeState,
                  has_confirmation_email: !stepThreeState.has_confirmation_email,
                });
              }}
              inputProps={{ color: 'secondary', sx: { padding: 0 } }}
            />
          }
          label={
            <Typography variant="mediumM" pl={3} color={'#1D2532'}>
              Δεν έχω λάβει email επιβεβαίωσης από το κατάστημα
            </Typography>
          }
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={3} maxWidth={'720px'}>
        <Typography variant="mediumM" color={'#1D2532'} component="h4">
          Αντίγραψε εδώ (copy paste) το περιεχόμενο του email
        </Typography>
        <PigogoTextArea
          inputProps={{
            onFocus: () => {
              setFormErrors({ ...formErrors, confirmation_email: false });
            },
            sx: {
              '& .MuiTextField-root': {
                margin: theme.spacing(1),
                minHeight: '208px',
              },
              '& .MuiInputBase-inputMultiline': {
                height: '100% !important',
              },
              '& .MuiInputBase-multiline': {
                height: '208px',
              },
              '& textarea': {
                height: '100%',
              },
              width: '100%',
              minHeight: '208px',
            },
            rows: 4,
            disabled: !stepThreeState.has_confirmation_email,
          }}
          error={formErrors.confirmation_email}
          value={stepThreeState.confirmation_email}
          setValue={(value: any) => {
            setFormErrors({ ...formErrors, confirmation_email: false });
            setStepThreeState({ ...stepThreeState, confirmation_email: value });
          }}
          placeholder="Αντιγράφοντας εδώ το email που έλαβες, αυξάνεις έτσι τις πιθανότητες να εγκριθεί το αίτημά σου!"
        />
        {formErrors.confirmation_email && (
          <Typography variant={'caption'} color={'#F75676'} pl={3} component="p" sx={{ marginTop: -2 }}>
            Το πεδίο είναι υποχρεωτικό.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default StepThreeCancelledContent;
