import { useAllCategoriesQuery } from '../redux/api/queries/categoriesQuery';
import React, { FC, useCallback } from 'react';
import CategoryStores from './StoresListing/CategoryStores';
import SelectedStore from './SelectedStore';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { isWeb } from '../utils/device';
import { useMediaQuery } from '@mui/material';
import { theme } from 'components';

const MiddlePage: FC<RouteComponentProps> = ({ match }) => {
  const pathname = match.path;

  const { data: allCategories, isLoading: loadingCategories } = useAllCategoriesQuery(
    { filter: 'parents' },
    { refetchOnMountOrArgChange: false },
  );

  const pathNameSplit = useCallback(() => {
    const split = pathname.split('/');

    if (split.length > 2) {
      return null;
    }
    return split[1];
  }, [pathname]);

  const isCategoryPage = useCallback(() => {
    if (allCategories) {
      const foundCategory = allCategories.data.find((category) => category.slug.name === pathNameSplit());
      return !!foundCategory;
    }
  }, [allCategories, pathNameSplit]);

  const renderMethod = () => {
    if (loadingCategories) {
      return null;
    }
    if (pathNameSplit() === null) {
      return null;
    }

    if (isCategoryPage()) {
      return <CategoryStores categorySlugName={pathNameSplit() || ''} />;
    }

    return <SelectedStore shopSlugName={pathNameSplit() || ''} />;
  };

  return renderMethod();
};

export default MiddlePage;
