import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Saver } from '../assets/svgs/savers/saver.svg';
import { ReactComponent as Expert } from '../assets/svgs/savers/expert.svg';
import { ReactComponent as Smart } from '../assets/svgs/savers/smart.svg';
import { ReactComponent as Guru } from '../assets/svgs/savers/guru.svg';
import { ReactComponent as Super } from '../assets/svgs/savers/super.svg';
import { GamificationLevel } from '../models/GamificationLevel';

interface Props {
  saver: GamificationLevel;
}

const ExtractSaver: FC<Props> = ({ saver }) => {
  switch (saver) {
    case 10:
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Saver />
          <Typography variant="subtitle1" component="p" color="#FC9E5F">
            Saver
          </Typography>
        </Box>
      );
    case 20:
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Smart />
          <Typography variant="subtitle1" component="p" color="#4FD69C">
            Smart Saver
          </Typography>
        </Box>
      );
    case 30:
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Expert />
          <Typography variant="subtitle1" component="p" color="#37D5F2">
            Expert Saver
          </Typography>
        </Box>
      );
    case 40:
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Super />
          <Typography variant="subtitle1" component="p" color="#F75676">
            Super Saver
          </Typography>
        </Box>
      );
    case 50:
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Guru />
          <Typography variant="subtitle1" component="p" color="#9456F7">
            Guru Saver
          </Typography>
        </Box>
      );
    default:
      return null;
  }
};

export default ExtractSaver;
