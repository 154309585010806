import { Box, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Bulb } from '../../../assets/svgs/bulbPink.svg';
import { ReactComponent as Cart } from '../../../assets/svgs/cart_color.svg';
import { ReactComponent as ArrowRight } from '../../../assets/svgs/arrowRight.svg';
import React, { FC, ReactNode } from 'react';
import { useIonRouter } from '@ionic/react';
import { theme } from 'components';
import { Link } from 'react-router-dom';

const Card: FC<{
  icon: ReactNode;
  backgroundColor: string;
  title: string;
  subtitle: string;
  label: string;
  to: string;
}> = ({ icon, backgroundColor, title, subtitle, label, to }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Link className="boxLink" style={{ backgroundColor }} to={to}>
      <Box
        sx={{
          width: '32px',
          height: '32px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#E9688A',
        }}
      >
        {icon}
      </Box>

      <Typography variant="large900" component="h4" color={'#1D2532'} mt="2px">
        {title}
      </Typography>
      <Typography variant="body2MediumM" component="p" color="#313D53">
        {subtitle}
      </Typography>
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="body2MediumM" color={'#1D2532'}>
          {label}
        </Typography>
        <Box
          sx={{ width: '24px', height: '24px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <ArrowRight />
        </Box>
      </Box>
    </Link>
  );
};

const DontForget = () => {
  const router = useIonRouter();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant={'large900'} component="h3" color={'#1D2532'}>
        Μην ξεχάσεις
      </Typography>
      <Box display="flex" gap={2} flexDirection={smDown ? 'column' : 'row'}>
        <Card
          icon={<Bulb />}
          label="Δες τα tips"
          backgroundColor="#FEF6F8"
          title="Tips για την επιστροφή"
          subtitle="Δες 5 tips για να καταγράφονται οι αγορές σου στο Pigogo"
          to="/return-tips"
        />
        <Card
          icon={<Cart />}
          label="Δες τα αιτήματα"
          backgroundColor="#F7F7F8"
          title="Δεν βρίσκεις την αγορά σου;"
          subtitle="Μια αγορά σου ακυρώθηκε ή το ποσό σου φαίνεται λάθος;"
          to="/user/claims"
        />
      </Box>
    </Box>
  );
};

export default DontForget;
