import { Box, Typography, useMediaQuery, Link } from '@mui/material';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import React, { useRef } from 'react';
import { Assets, theme } from 'components';
import CustomHeader from '../components/CustomHeader';
import Layout from '../components/Navigation/Layout';
import PigogoFooter from '../components/PigogoFooter';
import DownloadSection from '../components/Home/DownloadSection';
import ContentCard from '../components/ContentCard';
import Stores from '../assets/svgs/illustrations/store.svg';
import OnlineBuy from '../assets/svgs/illustrations/online_buy.svg';
import TopOffers from '../assets/svgs/illustrations/top_offers.svg';
import Account from '../assets/svgs/illustrations/account.svg';
import SearchStore from '../assets/svgs/illustrations/search_store.svg';
import Favorite from '../assets/svgs/illustrations/favorite_stores.svg';
import CustomContainer from '../components/CustomContainer';
import QuestionSection from '../components/Home/QuestionSection';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';

const storesArray = [
  {
    icon: Stores,
    title: 'Συνεργαζόμενα Καταστήματα',
    description: 'Ανακάλυψε όλα τα καταστήματα που συνεργάζονται με το Pigogo',
  },
  {
    icon: OnlineBuy,
    title: 'Online Αγορές',
    description: 'Πραγματοποίησε εύκολα και γρήγορα τις online αγορές σου',
  },
  {
    icon: TopOffers,
    title: 'Top Προσφορές',
    description: 'Βρες τις top προσφορές των καταστημάτων του Pigogo',
  },
  {
    icon: Account,
    title: 'Ο λογαριασμός μου',
    description: 'Δες τις αγορές σου και διαχειρίσου τα στοιχεία του λογαριασμού σου',
  },
  {
    icon: SearchStore,
    title: 'Αναζήτηση Καταστήματος',
    description: 'Αναζήτησε οποιοδήποτε κατάστημα και δες αν συνεργάζεται με το Pigogo',
  },
  {
    icon: Favorite,
    title: 'Αγαπημένα Καταστήματα',
    description: 'Διαχειρίσου τη λίστα των αγαπημένων σου καταστημάτων',
  },
];

const MobileApp = () => {
  const desktop = isPlatform('desktop');

  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (history.action !== 'PUSH') {
      contentRef.current && contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    } else {
      contentRef.current && contentRef.current.scrollToTop();
    }
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  const getDownloadPlay = () => {
    if (mobile) {
      return <Assets.GooglePlayBadgeMedium />;
    } else {
      return <Assets.GooglePlayBadgeBig />;
    }
  };

  const getDownloadAppstore = () => {
    if (mobile) {
      return <Assets.AppStoreBadgeMedium />;
    } else {
      return <Assets.AppStoreBadgeBig />;
    }
  };

  const footerSection = () => {
    if (upMd) {
      return <>{<PigogoFooter />}</>;
    } else {
      return <></>;
    }
  };

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <Box>
            <CustomHeader />
            {(mobile || tablet) && (
              <IonHeader class="ion-no-border">
                <IonToolbar
                  style={{
                    '--background': '#FFFFFF',
                    '--border-width': 0,
                    paddingTop: '0 + --ion-safe-area-top',
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                    <IonButtons slot="start">
                      <IonBackButton
                        className="backBtn"
                        text=""
                        style={{
                          '--color': '#313D53',
                          '--padding-start': 0,
                          '--padding-end': 0,
                          '--icon-font-size': '24px',
                          '--border-radius': 0,
                          '--min-height': '24px',
                          '--min-width': '24px',
                          width: '24px',
                          height: '24px',
                          minWidth: '24px',
                          maxHeight: '24px',
                          display: 'inline-flex',
                        }}
                        icon={backArrow}
                        defaultHref={'/'}
                      />
                    </IonButtons>
                    <Typography component="h2" variant="body2" color={'#313D53'}>
                      Pigogo app
                    </Typography>
                    <Box minWidth={'24px'}></Box>
                  </Box>
                </IonToolbar>
              </IonHeader>
            )}
            {location.pathname === '/mobile-app' && (
              <Helmet>
                <title>Pigogo mobile app | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                <meta
                  name="description"
                  content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                />
              </Helmet>
            )}
          </Box>
          <DownloadSection
            readMore={false}
            title={'Pigogo mobile app'}
            subtitle={
              <span>
                Κατέβασε την εφαρμογή του Pigogo στο κινητό σου. <br /> Διαθέσιμη σε <strong>Google Play</strong> και
                <strong> App Store</strong>.
              </span>
            }
          />
          <CustomContainer pb={0} pt={tablet ? 8 : 10}>
            <Box display="flex" flexDirection="column" gap={2} alignItems="center">
              <Typography color="#1D2532" variant="smallB" component="h2">
                Περιεχόμενο εφαρμογής
              </Typography>
              <Typography variant="body2MediumL" component="p" textAlign={'center'} color={'#1D2532'}>
                Κάνε τις αγορές σου από το Pigogo mobile app και κέρδισε επιστροφή χρημάτων όπου και αν βρίσκεσαι.
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center" pt={6}>
              {storesArray.map((store, idx) => (
                <ContentCard key={idx} icon={store.icon} title={store.title} description={store.description} />
              ))}
            </Box>
            <Box mt={6} mx={'auto'} width={'1024px'} maxWidth={'100%'}>
              <Box
                gap={mobile ? 4 : 0}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                bgcolor="#FCE5D8"
                borderRadius="16px"
                padding={mobile ? '16px' : '16px 16px 16px 32px'}
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="largeRegularAll" component="p" color={'#1D2532'}>
                    Κατέβασε τώρα την εφαρμογή σε
                  </Typography>
                </Box>
                <Box display="flex" flexWrap={mobile ? 'wrap' : 'nowrap'} gap={2}>
                  <Box height={mobile ? 56 : 64}>
                    <Link
                      href="https://play.google.com/store/apps/details?id=gr.pigogo.android"
                      underline="none"
                      sx={{ display: 'inline-block' }}
                    >
                      {getDownloadPlay()}
                    </Link>
                  </Box>
                  <Box height={mobile ? 56 : 64}>
                    <Link
                      href="https://apps.apple.com/us/app/pigogo/id6475775568"
                      underline="none"
                      sx={{ display: 'inline-block' }}
                    >
                      {getDownloadAppstore()}
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box pt={mobile ? 8 : 10}>
              <QuestionSection />
            </Box>
          </CustomContainer>
          {footerSection()}
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default MobileApp;
