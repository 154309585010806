import { getPlatforms } from '@ionic/react';

const isIos = () => {
  const platforms = getPlatforms();

  const isIos = platforms.includes('ios');
  const isCapacitor = platforms.includes('capacitor');

  return isIos && isCapacitor;
};

const isWeb = () => {
  const platforms = getPlatforms();

  const isDesktop = platforms.includes('desktop');
  const isCapacitor = platforms.includes('capacitor');
  const isMobileWeb = platforms.includes('mobileweb');

  return (isDesktop || isMobileWeb) && !isCapacitor;
};

export { isWeb, isIos };
