import * as React from 'react';
import { FC, useState } from 'react';
import { Box, Button, Fade, Popover, PopoverProps, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import ShopsHeaderContent from './ShopsHeaderContent';
import OffersHeaderContent from './OffersHeaderContent';
import { GenericRequest } from '../../../models/GenericRequest';
import { Savings } from '../../../models/Savings';
import { Promo } from '../../../models/Promo';
import { ShopCategory } from '../../../models/ShopCategory';
import { ReactComponent as ChevronDown } from '../../../assets/svgs/chevronDown.svg';

const StyledPopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    TransitionComponent={Fade}
    transitionDuration={500}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(4),
    boxShadow: 'none',
    overflow: 'hidden',
    marginTop: '-1px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    [theme.breakpoints.up('xl')]: {
      width: '1312px',
    },
    width: '1312px',
  },
  '& .MuiBackdrop-root': {
    background: '#1D2532',
    opacity: '0.64 !important',
  },
}));

interface Props {
  allCategories?: GenericRequest<ShopCategory[]>;
  promos?: GenericRequest<Promo[]>;
  savings?: GenericRequest<Savings[]>;
  title: string;
  anchorEl: HTMLElement | null;
  setAnchorEl: (menuAnchorEl: HTMLElement | null) => void;
  loading: boolean;
  refTo?: any;
  container?: any;
  setResetFilters?: (value: boolean) => void;
}

const MenuPopover: FC<Props> = ({
  title,
  anchorEl,
  setAnchorEl,
  refTo,
  allCategories,
  promos,
  savings,
  loading,
  container,
  setResetFilters,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (title === 'Καταστήματα') {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(refTo.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        disableRipple
        color={'inherit'}
        disabled={loading}
        onClick={handleClick}
        sx={{
          height: '56px',
          borderRadius: 0,
          '&:active': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
          '& .MuiButton-endIcon': {
            marginLeft: '8px',
            marginRight: 0,
            width: '24px',
            height: '24px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'transform .2s ease',
          },
          '&.Mui-active .MuiButton-endIcon': {
            transform: 'rotate(180deg)',
          },
          borderLeft: anchorEl ? '1px solid #ECEFF4' : '1px solid transparent',
          borderRight: anchorEl ? '1px solid #ECEFF4' : '1px solid transparent',
          padding: '16px',
          //borderBottom: anchorEl ? '2px solid #FFFFFF' : undefined,
        }}
        endIcon={<ChevronDown color="primary" />}
        className={anchorEl ? 'Mui-active' : ''}
      >
        <Typography variant="body2" component="span">
          {loading ? <Skeleton sx={{ px: 6 }} /> : title}
        </Typography>
      </Button>
      <StyledPopover container={container} anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {title === 'Καταστήματα' ? (
          <ShopsHeaderContent
            allCategories={allCategories}
            promos={promos}
            handleClose={handleClose}
            setResetFilters={setResetFilters}
          />
        ) : (
          <OffersHeaderContent savings={savings} handleClose={handleClose} />
        )}
      </StyledPopover>
    </Box>
  );
};

export default MenuPopover;
