import { Box, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import birth from '../assets/png/birth.png';
import { BirthCake } from '../assets';

const StyleBox = styled(Box)(() => ({
  position: 'relative',
  borderRadius: '16px',
  background: 'linear-gradient(180deg, rgba(49, 61, 83, 0.4) 0%, rgba(232, 129, 156, 0.4) 100%)',
  width: '343px',
  height: '206px',
}));

const StyleBoxFillImg = styled(Box)(() => ({
  position: 'absolute',
  zIndex: '99',
  top: 0,
  right: 0,
  height: '100%',
  width: '100%',
}));

const StyleBoxBirth = styled(Box)(() => ({
  position: 'absolute',
  zIndex: '99',
  top: 0,
  right: 5,
}));

const PigogoBirthdayCard = () => {
  return (
    <StyleBox>
      <StyleBoxFillImg>
        <img src={birth} style={{ width: '100%', height: '100%', borderRadius: '16px' }} />
      </StyleBoxFillImg>
      <StyleBoxBirth>
        <BirthCake />
      </StyleBoxBirth>
      <Box style={{ position: 'absolute', padding: 24, width: '100%', height: '100%', zIndex: '100' }}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Typography variant={'smallR'} style={{ color: '#ffff' }}>
              Έχουμε <span style={{ fontWeight: 600 }}>γενέθλια </span>
            </Typography>
            <Typography variant={'smallR'} style={{ color: '#ffff' }}>
              και τα γιορτάζουμε
            </Typography>
            <Typography variant={'smallR'} style={{ color: '#ffff' }}>
              μαζί σου!
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </StyleBox>
  );
};

export default PigogoBirthdayCard;
