import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { theme } from '../index';

const CustomNavigationButton = styled(IconButton)(({ theme }) => ({
  color: 'var(--text)',
  position: 'relative',
  background: theme.palette.secondary.main,
  boxShadow: 'none',
  border: '8px solid #FDF0F3',
  '&:hover': {
    border: '8px solid #e9dde0',
  },
  '&:focus': {
    border: '8px solid #FDF0F3',
    outline: '2px solid rgba(60, 104, 200, 0.5)',
  },
  '&:disabled': {
    background: 'var(--disabled)',
  },
}));

interface Props {
  icon: ReactNode;
  simple?: boolean;
  handleClick: (value: any) => void;
}
const PigogoNavigationIconButton: FC<Props> = ({ simple, icon, handleClick }) => {
  if (simple) {
    return (
      <IconButton
        style={{ padding: 0 }}
        onClick={handleClick}
        sx={{ background: theme.palette.secondary.main }}
        disableRipple
      >
        {icon}
      </IconButton>
    );
  }
  return (
    <CustomNavigationButton disableRipple onClick={handleClick}>
      {icon}
    </CustomNavigationButton>
  );
};

export default PigogoNavigationIconButton;
