import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import PigogoSymbolWrapper from '../PigogoSymbolWrapper';
import CustomContainer from '../CustomContainer';

const TopBanner = () => {
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PigogoSymbolWrapper typeOfWrapper="faq">
      <CustomContainer py={smDown ? 4 : 10} maxHeight={'352px'}>
        <Box
          gap={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={smDown ? 'flex-start' : 'center'}
        >
          {!smDown && (
            <Box>
              <Typography variant="smallB" component="h2" color={'#1D2532'}>
                Δημιουργία αιτήματος
              </Typography>
            </Box>
          )}
          <Box textAlign={smDown ? 'start' : 'center'} maxWidth={'800px'}>
            <Typography variant="body2MediumL" component="p" color={'#1D2532'}>
              Συμπλήρωσε όλα τα στοιχεία που αφορούν το ηλεκτρονικό κατάστημα, το είδος του αιτήματος, καθώς και τα
              στοιχεία της αγοράς. Κάθε αίτημα μπορεί να αφορά μόνο ένα κατάστημα.
            </Typography>
          </Box>
        </Box>
      </CustomContainer>
    </PigogoSymbolWrapper>
  );
};

export default TopBanner;
