import React, { useRef } from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { IonItem, IonList, useIonRouter, IonIcon, useIonViewDidEnter } from '@ionic/react';
import { ReactComponent as Megaphone } from '../../assets/Megaphone.svg';
import { useAllCategoriesQuery } from '../../redux/api/queries/categoriesQuery';
import { chevronForward } from 'ionicons/icons';
import { useHistory } from 'react-router';

const PreSearchMobile = () => {
  const router = useIonRouter();
  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const {
    data: allCategories,
    isLoading: allCategoriesLoading,
    isFetching: allCategoriesFetching,
  } = useAllCategoriesQuery({ filter: 'parents' }, { refetchOnMountOrArgChange: false });

  useIonViewDidEnter(() => {
    if (localStorage.getItem('reset-menu-scroll') === '1') {
      contentRef.current && contentRef.current.scrollTo(0, 0);
    }
    localStorage.removeItem('reset-menu-scroll');
  });

  return (
    <>
      <style>
        {`
         .scrollContainerY {
          overflow-y: auto;
          -ms-overflow-style: none !important;
          scrollbar-width: none !important;
          max-height: 99%;
         }
          .scrollContainer::-webkit-scrollbar {
            display: none !important;
          }
        `}
      </style>
      <Box>
        <Button
          onClick={(event: any) => {
            event.preventDefault();
            localStorage.removeItem(`offers`);
            history.push('/offers');
          }}
          fullWidth
          sx={{ borderRadius: '56px', height: '56px', backgroundColor: '#FCE5D8', justifyContent: 'flex-start' }}
          endIcon={<ArrowForward style={{ position: 'absolute', right: 15, top: 18 }} />}
        >
          <Box display="flex" px={'18px'} alignItems="center" gap={'10px'}>
            <Megaphone color="primary" />
            <Typography variant="body2">Hot προσφορές</Typography>
          </Box>
        </Button>
      </Box>
      <Box className={'focus-on-search scrollContainerY'} ref={contentRef}>
        <Box mt={1.5} mb={9}>
          <IonList style={{ '--ion-background-color': '#FFFFFF', paddingTop: 0 }}>
            {(allCategoriesLoading || allCategoriesFetching || !allCategories?.data.length) &&
              [1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                <IonItem
                  key={index}
                  style={{
                    '--height': '32px',
                    '--padding-start': 0,
                    '--inner-padding-end': 0,
                    '--border-color': '#EAECEE',
                    '--ion-color-base': '#313D53',
                  }}
                  detail={true}
                  button
                >
                  <Typography py={2} color="primary" variant="body2">
                    <Skeleton sx={{ px: 12 }} />
                  </Typography>
                </IonItem>
              ))}
            {!(allCategoriesLoading || allCategoriesFetching) &&
              allCategories?.data.length &&
              allCategories?.data.map((category, index) => (
                <IonItem
                  key={index}
                  routerDirection={'none'}
                  routerLink={`/${category.slug.name}`}
                  onClick={(event: any) => {
                    event.preventDefault();
                    localStorage.removeItem(`category-${category.slug.name}`);
                    history.push(`/${category.slug.name}`);
                  }}
                  style={{
                    '--height': '32px',
                    '--padding-start': 0,
                    '--inner-padding-end': 0,
                    '--border-color': '#EAECEE',
                    '--ion-color-base': '#313D53',
                  }}
                  detail={false}
                  button
                >
                  <Typography py={2} color="primary" variant="body2">
                    {category.label}
                  </Typography>
                  <IonIcon
                    color="#313D53"
                    slot="end"
                    icon={chevronForward}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '24px',
                      height: '24px',
                      margin: 0,
                      padding: 0,
                      '--ion-color-base': '#313D53',
                    }}
                  ></IonIcon>
                </IonItem>
              ))}
            {!(allCategoriesLoading || allCategoriesFetching) && allCategories?.data.length && (
              <IonItem
                key={allCategories ? allCategories.totalCount : 0}
                routerDirection={'none'}
                routerLink={'/stores'}
                onClick={(event: any) => {
                  event.preventDefault();
                  localStorage.removeItem(`stores`);
                  history.push('/stores');
                }}
                style={{
                  '--height': '32px',
                  '--padding-start': 0,
                  '--inner-padding-end': 0,
                  '--border-color': '#EAECEE',
                  '--ion-color-base': '#313D53',
                }}
                detail={false}
                button
              >
                <Typography py={2} color="primary" variant="body2">
                  Όλα τα καταστήματα
                </Typography>
                <IonIcon
                  color="#313D53"
                  slot="end"
                  icon={chevronForward}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '24px',
                    height: '24px',
                    margin: 0,
                    padding: 0,
                    '--ion-color-base': '#313D53',
                  }}
                ></IonIcon>
              </IonItem>
            )}
          </IonList>
        </Box>
      </Box>
    </>
  );
};

export default PreSearchMobile;
