import React, { FC } from 'react';
import { ReactComponent as Unchecked } from '../../assets/svgs/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../assets/svgs/checked.svg';
import { Box, ButtonBase, Card, Typography, useMediaQuery } from '@mui/material';
import { PigogoCheckboxCircle } from 'components';
import { theme } from 'components';

interface Props {
  title: string;
  subtitle: string;
  checked: boolean;
  handleChecked: () => void;
}

const ClaimCard: FC<Props> = ({ title, subtitle, checked = false, handleChecked }) => {
  return (
    <Card
      sx={{
        backgroundColor: checked ? '#F7F7F8' : 'white',
        padding: 0,
        border: checked ? '2px solid #E9688A' : '2px solid #EAECEE',
        borderRadius: '24px',
        display: 'flex',
        alignItems: 'center',
        width: '688px',
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxSizing: 'border-box',
      }}
      elevation={0}
    >
      <ButtonBase
        onClick={() => handleChecked()}
        sx={{ display: 'block', textAlign: 'initial', padding: '24px 24px 24px 32px' }}
      >
        <Box display="flex" gap={4} alignItems="center">
          <Box
            sx={{
              width: '24px',
              minWidth: '24px',
              height: '24px',
              display: 'inlineFlex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PigogoCheckboxCircle
              inputProps={{
                disableRipple: true,
                color: 'secondary',
                icon: <Unchecked />,
                checkedIcon: <CheckedIcon />,
              }}
              checked={checked ?? false}
              setValue={() => handleChecked()}
            />
          </Box>

          <Box display="flex" gap={1} flexDirection="column">
            <Typography variant="mediumM" component="p" color={'#313D53'}>
              {title}
            </Typography>
            <Typography variant="body2MediumR" component="p" color={'#6F7787'}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
    </Card>
  );
};

export default ClaimCard;
