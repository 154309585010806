import React, { ReactNode, FC } from 'react';
import { Box, BoxProps, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import { ReactComponent as TopLeftPink } from '../assets/svgs/illustrations/favourites/topLeft_symbol_pink.svg';
import { ReactComponent as TopRightPink } from '../assets/svgs/illustrations/favourites/symbolWhite.svg';
import { ReactComponent as BottomPink } from '../assets/svgs/illustrations/favourites/symbolPink2.svg';
import { ReactComponent as BottomWhiteLeft } from '../assets/svgs/illustrations/favourites/symbolWhite2.svg';
import { ReactComponent as BottomWhiteRight } from '../assets/svgs/illustrations/favourites/symbolWhite3.svg';
import { ReactComponent as SymbolFooter } from '../assets/svgs/illustrations/footer/symbolFooter.svg';
import { ReactComponent as SymbolFooter2 } from '../assets/svgs/illustrations/footer/symbolFooter2.svg';
import { ReactComponent as TellAFriendSymbol } from '../assets/svgs/tell-a-friend-symbol.svg';
import { ReactComponent as SymbolPink1 } from '../assets/svgs/illustrations/symbolPink1.svg';
import { ReactComponent as SymbolLightPink } from '../assets/svgs/illustrations/symbolLightPink.svg';

interface Props {
  children: ReactNode;
  typeOfWrapper: 'faq' | 'favourites' | 'footer' | 'returnTips' | 'tell-a-friend' | '404' | 'resetPassword' | '';
}

const PigogoSymbolWrapper: FC<Props & BoxProps> = ({ children, typeOfWrapper, ...rest }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const extractSymbols = () => {
    switch (typeOfWrapper) {
      case 'faq':
      case 'favourites':
        // if (mdDown) {
        //   return;
        // }
        return (
          <>
            <Box position="absolute" top="0px" left="0px">
              <TopLeftPink />
            </Box>
            {!mdDown && (
              <>
                <Box position="absolute" top="0px" right="0px">
                  <TopRightPink />
                </Box>
                <Box position="absolute" bottom="-5px" left="15%">
                  <BottomWhiteLeft />
                </Box>
                <Box position="absolute" bottom="-4px" left="30%">
                  <BottomPink />
                </Box>
                <Box position="absolute" bottom="-4px" left="60%">
                  <BottomPink />
                </Box>
                <Box position="absolute" bottom="-5px" left="72%">
                  <BottomWhiteRight />
                </Box>
              </>
            )}
          </>
        );
      case 'footer':
        if (mdDown) {
          return;
        }
        return (
          <>
            <Box position="absolute" top="0px" left="0px">
              <TopLeftPink />
            </Box>
            <Box position="absolute" top="0px" right="0px">
              <TopRightPink />
            </Box>
            <Box position="absolute" bottom="-90px" left="7%">
              <SymbolFooter2 />
            </Box>
            <Box position="absolute" bottom="-10px" left="17%">
              <SymbolFooter />
            </Box>
            <Box position="absolute" bottom="-4px" right="15%">
              <SymbolFooter2 />
            </Box>
            <Box position="absolute" bottom="45px" right="4%">
              <SymbolFooter />
            </Box>
          </>
        );
      case 'returnTips':
        if (mdDown) {
          return;
        }
        return (
          <Box position="absolute" top="0px" right="0px">
            <TopRightPink />
          </Box>
        );
      case 'tell-a-friend':
        return (
          <Box position="absolute" top="0px" left="0px">
            <TellAFriendSymbol />
          </Box>
        );
      case '404':
        return (
          <>
            <Box position="absolute" top="0px" left="0px">
              <TopLeftPink />
            </Box>
            {!mdDown && (
              <>
                <Box position="absolute" top="0px" right="0px">
                  <TopRightPink />
                </Box>
                <Box position="absolute" bottom="-5px" left="15%">
                  <BottomWhiteLeft />
                </Box>
                <Box position="absolute" bottom="-4px" left="30%">
                  <BottomPink />
                </Box>
                <Box position="absolute" bottom="-4px" left="60%">
                  <BottomPink />
                </Box>
                <Box position="absolute" bottom="-5px" left="72%">
                  <BottomWhiteRight />
                </Box>
              </>
            )}
          </>
        );
      case 'resetPassword':
        return (
          <>
            <Box position="absolute" top="0px" left="0px">
              <SymbolPink1 />
            </Box>
            <Box position="absolute" top="0px" right="0px">
              <SymbolLightPink />
            </Box>
            <Box position="absolute" bottom="-5px" left="15%">
              <BottomWhiteLeft />
            </Box>
            <Box position="absolute" bottom="-4px" left="30%">
              <BottomPink />
            </Box>
            <Box position="absolute" bottom="-4px" left="60%">
              <BottomPink />
            </Box>
            <Box position="absolute" bottom="-5px" left="72%">
              <BottomWhiteRight />
            </Box>
          </>
        );
    }
  };

  const extractColor = () => {
    switch (typeOfWrapper) {
      case 'faq':
      case 'resetPassword':
      case '404':
      case 'favourites':
      case 'tell-a-friend':
        return 'secondary.contrastText';
      case 'footer':
        return 'secondary.main';
      default:
        return undefined;
    }
  };

  return (
    <Box position="relative" sx={{ backgroundColor: extractColor() }} {...rest}>
      {extractSymbols()}
      <Box position="relative" zIndex={1000}>
        {children}
      </Box>
    </Box>
  );
};

export default PigogoSymbolWrapper;
