import React, { FC } from 'react';
import { ReactComponent as SaverInactive } from '../../../../assets/svgs/savers/large/saverInactive.svg';
import { ReactComponent as SmartInactive } from '../../../../assets/svgs/savers/large/smartInactive.svg';
import { ReactComponent as ExpertInactive } from '../../../../assets/svgs/savers/large/expertInactive.svg';
import { ReactComponent as SuperInactive } from '../../../../assets/svgs/savers/large/superInactive.svg';
import { ReactComponent as GuruInactive } from '../../../../assets/svgs/savers/large/guruInactive.svg';
import { GamificationLevelString } from '../../../../models/User';
import { Box, Typography } from '@mui/material';

interface Props {
  saver: GamificationLevelString;
}

const ExtractInactiveLevel: FC<Props> = ({ saver }) => {
  const icon = () => {
    switch (saver) {
      case GamificationLevelString.saver:
        return <SaverInactive />;
      case GamificationLevelString.smart:
        return <SmartInactive />;
      case GamificationLevelString.expert:
        return <ExpertInactive />;
      case GamificationLevelString.super:
        return <SuperInactive />;
      case GamificationLevelString.guru:
        return <GuruInactive />;
      default:
        return null;
    }
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={1}>
      {icon()}
      <Typography sx={{ color: '#D6D8DD' }} variant="largeMedium">
        {saver}
      </Typography>
    </Box>
  );
};

export default ExtractInactiveLevel;
