import React from 'react';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Success } from '../../assets/svgs/illustrations/feedback/success.svg';
import { PigogoButton } from 'components';
import { useIonRouter } from '@ionic/react';
import { theme } from 'components';
import CustomContainer from '../CustomContainer';

const SuccessMessage = () => {
  const router = useIonRouter();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CustomContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={smDown ? 1 : 3}
        px={!mdDown ? 11 : 0}
        pb={!smDown ? 15 : 5}
      >
        <Success />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ backgroundColor: '#FEF6F8', width: '848px', maxWidth: '100%' }}
          px={smDown ? '24px' : '96px'}
          py={smDown ? '24px' : '48px'}
          gap={2}
          borderRadius="24px"
        >
          <Typography variant="body1" textAlign="center" color={'#1D2532'} component="h3">
            Το αίτημά σου καταχωρήθηκε!
          </Typography>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2MediumM" textAlign="center" color={'#313D53'} component="p">
              Ο έλεγχος των αιτημάτων πραγματοποιείται σε συνεργασία με τα ηλεκτρονικά καταστήματα και μπορεί να
              διαρκέσει έως και λίγες εβδομάδες.{' '}
            </Typography>
            <Typography variant="body2MediumM" textAlign="center" color={'#313D53'} component="p">
              Θα σε ενημερώσουμε αμέσως μόλις η διαδικασία ολοκληρωθεί ή αν χρειαστούμε οποιαδήποτε επιπλέον πληροφορία
              από εσένα.
            </Typography>
          </Box>
          <Typography variant="body2MediumM" textAlign="center" color={'#313D53'} mt={0.5} component="p">
            Μπορείς να παρακολόυθείς την εξέλιξη του αιτήματός σου απο τη σελίδα Αιτήματα του λογαριασμού σου
          </Typography>
          <Box display="flex" pt={smDown ? 1 : 6} alignItems="center">
            <PigogoButton
              sx={{ width: mdDown ? '295px' : '327px' }}
              variation="secondary"
              text="Δες τα αιτήματά σου"
              onClick={() => router.push('/user/claims')}
            />
          </Box>
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default SuccessMessage;
