import { Chip, ChipProps, SvgIcon, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import theme from '../theme';

interface Props {
  disabled?: boolean;
  variant?: 'outlined' | 'filled' | undefined;
  size?: 'small' | 'medium';
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  dotted?: boolean;
  width?: number | string | 'auto';
  startIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  endIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  label?: string;
  onClick?: any;
  onClickCopy?: () => void;
  inputProps?: ChipProps;
}

const PigogoChip: FC<Props> = ({
  disabled,
  variant = 'filled',
  size = 'medium',
  width = 'auto',
  color,
  startIcon,
  dotted,
  endIcon,
  onClick,
  onClickCopy,
  label,
  inputProps,
}) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleAction = (event: any) => {
    if (label) {
      navigator.clipboard.writeText(label);

      onClickCopy && onClickCopy();
    }
    event.stopPropagation();
  };

  return (
    <Chip
      {...inputProps}
      className="pigogoChip"
      disabled={disabled}
      color={color}
      sx={{
        py: size === 'medium' ? 2 : undefined,
        px: size === 'medium' ? 1 : undefined,
        borderRadius: '48px',
        border: dotted ? '1px dashed #313D53' : undefined,
        height: size === 'medium' ? '64px' : '32px',
        width: width === 'auto' ? 'auto' : width,
        '&.MuiChip-filledSecondary': {
          padding: '4px 12px 4px 8px',
          display: 'inline-flex',
          alignItems: 'center',
          gap: 0.5,
        },
        '.chipBig &': {
          padding: '14px 24px',
          borderWidth: '1px',
        },
        '& .MuiChip-icon': {
          margin: '0 !important',
          width: '24px',
          height: '24px',
          padding: '2px',
        },
        '& .MuiChip-label': {
          fontSize: matches ? '12px' : '14px',
          color: '#313D53',
          fontWeight: 500,
          padding: 0,
        },
        '& .MuiChip-deleteIcon': {
          marginLeft: '16px',
          marginRight: 0,
        },
        '.chipBig & .MuiChip-deleteIcon': {
          marginLeft: '16px',
          marginRight: 0,
          width: '24px',
          height: '24px',
          pointerEvents: 'none',
        },
        '&.MuiChip-filledSecondary .MuiChip-label': {
          color: '#ffffff',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '1.6',
        },
      }}
      variant={variant}
      icon={startIcon}
      deleteIcon={endIcon && <SvgIcon color="secondary">{endIcon}</SvgIcon>}
      label={label}
      onDelete={endIcon && handleAction}
      onClick={onClick}
    />
  );
};

export default PigogoChip;
