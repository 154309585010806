import React, { FC } from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Purchase } from '../../../models/Purchase';

import { CreateClaimStepTwo } from '../../../models/CreateClaimStepTwo';
import StepTwoDesktopRow from './StepTwoDesktopRow';

const headCells = ['Ημερομηνία', 'Ποσό', 'Επιστροφή', 'Κατάσταση'];

interface Props {
  cancelledPurchases?: Purchase[];
  stepTwoState: CreateClaimStepTwo;
  setStepTwoState: (stepTwoState: CreateClaimStepTwo) => void;
  loading: boolean;
}

const StyledTableRow = styled(TableRow)(() => ({
  height: 44,
}));

const StyledTableCell = styled(TableCell)(() => ({
  paddingLeft: '24px',
}));

const StepTwoTableDesktop: FC<Props> = ({ cancelledPurchases, stepTwoState, setStepTwoState, loading }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="mediumM" color={'#1D2532'} component="h4">
        Επίλεξε την αγορά σου από τη λίστα με τις Ακυρωμένες
      </Typography>
      <Box border="1px solid #EAECEE" borderRadius={'24px'}>
        <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '25px' }}>
          <Table aria-label="simple table" sx={{ borderRadius: '24px' }}>
            <TableHead sx={{ backgroundColor: '#F7F7F8' }}>
              <StyledTableRow>
                <StyledTableCell sx={{ pr: 0 }} />
                {headCells.map((headCell, index: number) => (
                  <StyledTableCell align="left" key={index}>
                    <Typography variant="captionSB" color="#6F7787">
                      {headCell}
                    </Typography>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {loading && [1, 1, 1, 1].map((item, index) => <StepTwoDesktopRow key={index} loading />)}
              {!loading &&
                cancelledPurchases?.map((purchase, index: number) => (
                  <StepTwoDesktopRow
                    key={index}
                    purchase={purchase}
                    stepTwoState={stepTwoState}
                    setStepTwoState={setStepTwoState}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default StepTwoTableDesktop;
