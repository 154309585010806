import { Box, Button, Paper, Skeleton, styled, Typography } from '@mui/material';
import CustomContainer from '../../CustomContainer';
import PigogoSearch from './Search/PigogoSearch';
import React, { FC, useEffect, useRef, useState } from 'react';
import { PigogoButton, PigogoIconButton, PigogoLinkButton, RichTooltip, theme } from 'components';
import { useIonRouter, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLazyGetUserDataQuery } from '../../../redux/api/queries/userQueries';
import { useLazyFavouritesQuery } from '../../../redux/api/queries/favouritesQuery';
import { useAppSelector } from '../../../redux/hooks';
import AccountMenu from './AccountMenu';
import SearchContent from './Search/SearchContent';
import SearchPopover from './Search/SearchPopover';
import FavContent from './Favourite/FavContent';
import { ReactComponent as Heart } from '../../../assets/svgs/heart.svg';
import { ReactComponent as PigogoLogo } from '../../../assets/PigogoLogo.svg';
import FavouritesPopover from './Favourite/FavouritesPopover';
import useDebounce from '../../../hooks/useDebounce';
import { useSearchQuery } from '../../../redux/api/queries/searchQueries';
import { ModalType } from './Header';
import { ReactComponent as All } from '../../../assets/svgs/illustrations/avatar/default_all_small.svg';
import { ReactComponent as Man } from '../../../assets/svgs/illustrations/avatar/default_boy_small.svg';
import { ReactComponent as Woman } from '../../../assets/svgs/illustrations/avatar/default_girl_small.svg';
import { ReactComponent as ChevronDown } from '../../../assets/svgs/chevronDown.svg';
import LinkSearch from './Search/LinkSearch';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router';

const StyledButton = styled(Button)(() => ({
  padding: 0,
  cursor: 'pointer',
  '&:hover': {
    boxShadow: 'none',
    background: `none`,
  },
  '&:active': {
    background: `none`,
  },
  '&:focus': {
    background: 'none',
  },
}));

type TopBarProps = {
  container?: any;
  manageModals: (modalType?: ModalType) => void;
  searchKeyword?: string;
  setSearchKeyword?: (value: string) => void;
  setRedirectModal?: (value: boolean) => void;
  setShopName?: (value: string) => void;
  setShopImage?: (value: any) => void;
};
const TopBar: FC<TopBarProps> = ({
  container,
  manageModals,
  setSearchKeyword,
  searchKeyword,
  setRedirectModal,
  setShopName,
  setShopImage,
}) => {
  const route = useIonRouter();

  const pathName = route.routeInfo.pathname;

  const small = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const extraLgDown = useMediaQuery(theme.breakpoints.down('extraLg'));
  const extraLgUp = useMediaQuery(theme.breakpoints.up('extraLg'));

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [search, setSearch] = useState<string>('');
  const [link, setLink] = useState<boolean>(false);

  const [openUserPopover, setOpenUserPopover] = useState<boolean>(false);
  const [openSearchPopover, setOpenSearchPopover] = useState<boolean>(false);
  const [openFavouritesTooltip, setOpenFavouritesTooltip] = useState<boolean>(false);
  const [openFavouritesPopover, setOpenFavouritesPopover] = useState<boolean>(false);
  const [redirectsToFavourites, setRedirectsToFavourites] = useState<boolean>(false);

  const [triggerUser, { data: userData, isLoading: userDataLoading }] = useLazyGetUserDataQuery();
  const [triggerFavourites, { data: allFavourites, isLoading: favouritesLoading }] = useLazyFavouritesQuery();

  const searchPopoverAnchorRef: any = useRef();
  const userMenuPopoverAnchorRef: any = useRef();

  let favHover = false;

  const debouncedSearch = useDebounce(search, 500);

  const { isFetching: searchResultsLoading, data: searchResults } = useSearchQuery(
    {
      keyword: debouncedSearch ?? undefined,
      limit: debouncedSearch !== '' ? 6 : undefined,
    },
    { skip: debouncedSearch === '' },
  );

  useIonViewWillEnter(() => {
    setRedirectsToFavourites(false);
    setOpenFavouritesPopover(false);
    setOpenFavouritesTooltip(false);

    if (!!accessToken || sessionLoggedIn) {
      triggerFavourites({ limit: 10 });
    }
  });

  useIonViewWillLeave(() => {
    setSearch('');
    setOpenSearchPopover(false);
  });

  useEffect(() => {
    if (!!accessToken || sessionLoggedIn) {
      triggerUser();
      triggerFavourites({ limit: 10 });
    }
  }, [triggerFavourites, triggerUser, accessToken, sessionLoggedIn]);

  useEffect(() => {
    if (searchKeyword) {
      setOpenSearchPopover(false);
    }
  }, [searchKeyword]);

  const handleClickFav = async () => {
    if (openFavouritesPopover) {
      setOpenFavouritesPopover(false);
    } else {
      setOpenFavouritesPopover(true);
    }
    // to close other popovers
    manageModals();
  };

  const handleUserPopover = () => {
    setOpenSearchPopover(false);
    setOpenUserPopover(true);
  };

  const getIcon = () => {
    if (userData && userData.user_icon_id === 1) {
      return <Man />;
    } else if (userData && userData.user_icon_id === 2) {
      return <Woman />;
    } else {
      return <All />;
    }
  };

  const onFavouritesHover = () => {
    favHover = true;
    setTimeout(() => {
      if (favHover) {
        if (allFavourites?.data.length === 0) {
          setOpenFavouritesTooltip(true);
          return;
        }
        handleClickFav();
      }
    }, 400);
  };

  const onFavouritesLeave = () => {
    favHover = false;
    if (allFavourites?.data.length === 0) {
      setOpenFavouritesTooltip(false);
      return;
    }
    if (openFavouritesPopover) {
      handleClickFav();
    }
  };

  const authButtons = () => {
    if (!((!!accessToken && accessToken !== '') || sessionLoggedIn)) {
      return (
        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} ml={extraLgUp ? '2px' : 0}>
          <PigogoLinkButton
            text="Σύνδεση"
            typographyProps={{ variant: 'body2MediumM' }}
            handleClick={() => {
              // to close other popovers
              if (openSearchPopover) {
                setOpenSearchPopover(false);
              }
              if (link) {
                setLink(false);
              }
              if (openFavouritesPopover) {
                setOpenFavouritesPopover(false);
              }

              manageModals('login');
            }}
            buttonProps={{
              sx: { width: '114px', height: '40px', padding: '8px 24px', fontSize: '16px', fontWeight: 500 },
            }}
          />
          <PigogoButton
            text="Εγγραφή"
            variation="secondary"
            typographyProps={{ variant: 'button' }}
            onClick={() => manageModals('register')}
            sx={{ width: '132px', height: '40px', padding: '8px 32px', borderRadius: '0px 32px 32px 32px' }}
          />
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={3} minWidth={'260px'}>
          {Cookies.get('impersonateAccessToken') && (
            <a href={process.env['REACT_APP_ADMIN_RETURN'] ?? 'https://backend.pigogo.gr/admin/return'}>
              <PigogoLinkButton
                text="Stop Impersonation"
                typographyProps={{ variant: 'button', color: 'red' }}
                handleClick={() => {
                  return;
                }}
                buttonProps={{
                  sx: { width: '200px', height: '40px', padding: '8px 24px', fontSize: '16px', fontWeight: 500 },
                }}
              />
            </a>
          )}
          {favouritesLoading ? (
            <Skeleton variant="circular" width="22px" height="22px" />
          ) : (
            <Box onMouseLeave={onFavouritesLeave} onMouseOver={onFavouritesHover} sx={{ cursor: 'pointer' }}>
              <RichTooltip
                open={openFavouritesTooltip && !redirectsToFavourites}
                color="dark"
                placement="bottom"
                onClose={() => {
                  //empty
                }}
                content={
                  <Box display="flex" justifyContent="center">
                    <Typography color={'white'} variant={'caption'}>
                      Δεν έχεις αγαπημένα καταστήματα
                    </Typography>
                  </Box>
                }
              >
                <Box>
                  <PigogoIconButton
                    icon={<Heart />}
                    handleClick={() => {
                      setRedirectsToFavourites(true);
                      setOpenFavouritesPopover(false);
                      setOpenFavouritesTooltip(false);
                      setTimeout(() => {
                        route.push('/favourites');
                      }, 750);
                    }}
                  />
                </Box>
              </RichTooltip>
            </Box>
          )}
          <StyledButton
            disableRipple
            disabled={userDataLoading}
            className="topbarLink"
            sx={{ padding: 0, display: 'flex', alignItems: 'center', gap: 1 }}
            onClick={(e) => {
              e.persist();
              handleUserPopover();
            }}
          >
            {userDataLoading ? <Skeleton variant="circular" sx={{ p: 2 }} /> : getIcon()}
            <Typography variant="captionSB" color={'#313D53'}>
              {userDataLoading ? (
                <Skeleton sx={{ px: 6 }} />
              ) : (
                `επιστροφή: ${userData?.total_tracked_cashback_amount ?? '0,00 €'}`
              )}
            </Typography>
            <Box
              sx={{
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform .2s ease',
                transform: openUserPopover ? 'rotate(180deg)' : 'none',
              }}
            >
              <ChevronDown color="primary" />
            </Box>
          </StyledButton>
        </Box>
      );
    }
  };

  const renderPigogoButton = () => {
    if (pathName.includes('/user') && small) {
      return;
    }
    if (!small) {
      return (
        <Box sx={{ margin: mdDown ? '0 auto' : '0' }}>
          <a className="logoLink" href={'/'}>
            <PigogoLogo />
          </a>
        </Box>
      );
    }
  };

  if (pathName.includes('/user') && small) {
    return null;
  }

  return (
    <Paper elevation={0} sx={{ position: 'relative', zIndex: (theme) => theme.zIndex.modal + 4, borderRadius: 0 }}>
      <CustomContainer
        height={!small ? '80px' : 'auto'}
        display={'flex'}
        alignItems="center"
        justifyContent={smDown ? 'center' : 'space-between'}
        gap={extraLgUp ? 3 : 6}
      >
        {renderPigogoButton()}
        {!small && (
          <Box
            flexGrow={1}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            ref={searchPopoverAnchorRef}
            maxWidth={extraLgDown ? 538 : 640}
          >
            <PigogoSearch
              link={link}
              search={search}
              setLink={setLink}
              setSearch={setSearch}
              searchPopoverOpen={openSearchPopover}
              setSearchKeyword={setSearchKeyword}
              setSearchPopover={(value: boolean) => {
                setOpenSearchPopover(value);
              }}
            />
          </Box>
        )}
        {!small && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height={'100%'}
            minWidth={'260px'}
            ref={userMenuPopoverAnchorRef}
          >
            {authButtons()}
          </Box>
        )}
      </CustomContainer>
      <AccountMenu
        userData={userData}
        isOpen={openUserPopover}
        anchorEl={userMenuPopoverAnchorRef.current}
        handleDismiss={() => setOpenUserPopover(false)}
      />
      <SearchPopover
        islinksearch={link ? 'true' : 'false'}
        container={container}
        open={link}
        anchorEl={searchPopoverAnchorRef.current}
        onClose={() => {
          setLink(false);
        }}
      >
        <LinkSearch
          closePopover={() => {
            setLink(false);
          }}
          setRedirectModal={setRedirectModal}
          setShopName={setShopName}
          setShopImage={setShopImage}
        />
      </SearchPopover>
      <SearchPopover
        islinksearch={'false'}
        container={container}
        open={openSearchPopover}
        anchorEl={searchPopoverAnchorRef.current}
        onClose={() => {
          setOpenSearchPopover(false);
        }}
      >
        <SearchContent
          link={link}
          search={debouncedSearch}
          searchResults={searchResults}
          searchResultsLoading={searchResultsLoading}
          closeSearchByLink={() => {
            setOpenSearchPopover(false);
            setLink(false);
          }}
          closeSearch={() => {
            setOpenSearchPopover(false);
          }}
          setAnchorEl={() => {
            setOpenFavouritesPopover(false);
            manageModals();
          }}
          setSearchKeyword={setSearchKeyword}
        />
      </SearchPopover>
      {allFavourites?.totalCount !== undefined && allFavourites?.totalCount >= 1 && (
        <FavouritesPopover
          container={container}
          open={openFavouritesPopover}
          anchorEl={userMenuPopoverAnchorRef.current}
          onMouseEnter={async () => {
            if (!redirectsToFavourites) {
              setOpenFavouritesPopover(true);
            }
          }}
          onMouseLeave={async () => {
            await setTimeout(() => {
              setOpenFavouritesPopover(false);
            }, 200);
          }}
          onClose={async () => {
            await setTimeout(() => {
              setOpenFavouritesPopover(false);
            }, 200);
          }}
        >
          <FavContent
            loading={favouritesLoading}
            allFavourites={allFavourites}
            close={async () => {
              await setTimeout(() => {
                setOpenFavouritesPopover(false);
              }, 200);
            }}
          />
        </FavouritesPopover>
      )}
    </Paper>
  );
};

export default TopBar;
