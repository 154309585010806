import React, { FC } from 'react';
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Payment } from '../../../models/Payment';
import PaymentsDesktopRow from './PaymentsDektopRow';
import { PaymentSort } from '../../../redux/api/types/enum/PaymentSort';
import { ReactComponent as ChevronDown } from '../../../assets/svgs/chevron_down_light.svg';
import { theme } from 'components';

const headCells = [
  { label: 'Ημερομηνία', id: PaymentSort.date },
  { label: 'Ποσό', id: PaymentSort.cashback },
  { label: 'Τρόπος πληρωμής', id: PaymentSort.payment_method_id },
  { label: 'Λογαριασμός', id: PaymentSort.payment_details },
];

const StyledTableCell = styled(TableCell)(() => ({
  padding: '14px 24px',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '1',
  color: '#6F7787',
}));

interface Props {
  isLoading: boolean;
  payments?: Payment[];
  orderBy: PaymentSort;
  order: 'asc' | 'desc';
  setOrder: (order: 'asc' | 'desc') => void;
  setOrderBy: (orderBy: PaymentSort) => void;
}

const PaymentsTableDesktop: FC<Props> = ({ payments, isLoading, order, setOrder, orderBy, setOrderBy }) => {
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: PaymentSort) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: PaymentSort) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <TableContainer>
      <Table aria-label="payments table">
        <TableHead sx={{ backgroundColor: '#F7F7F8' }}>
          <TableRow>
            {headCells.map((headCell) => (
              <StyledTableCell
                sx={{ width: lgUp ? '25%' : 'auto' }}
                size="small"
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  IconComponent={ChevronDown}
                  active={orderBy === headCell.id}
                  onClick={createSortHandler(headCell.id)}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  sx={{ '& .MuiTableSortLabel-icon': { width: '24px', marginRight: 0 } }}
                >
                  <Typography variant="captionSB" color="#6F7787">
                    {headCell.label}
                  </Typography>
                </TableSortLabel>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && payments?.map((row, index) => <PaymentsDesktopRow key={index} row={row} />)}
          {isLoading &&
            [...(payments as any), 1, 1, 1, 1]?.map((row, index) => (
              <PaymentsDesktopRow key={index} row={row || undefined} isLoading={row === 1} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentsTableDesktop;
