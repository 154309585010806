import React, { FC, ReactNode } from 'react';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';

interface Props {
  title: string;
  img: ReactNode;
  children: ReactNode;
}

const TellAFriendCard: FC<Props> = ({ title, img, children }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box border="2px solid #EAECEE" borderRadius="24px">
      <Box display="flex" flexDirection="column">
        <Box p={smDown ? '24px 16px' : '24px 32px'} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={2}>
            {img}
            <Typography color={'#313D53'} variant={'subtitle1'} component="h3">
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box padding={smDown ? '24px 16px' : '24px 32px 32px 32px'} display="flex" flexDirection="column" gap={4}>
        {children}
      </Box>
    </Box>
  );
};

export default TellAFriendCard;
