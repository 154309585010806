import React, { FC } from 'react';
import { Box, FormControl, Select, styled, Typography } from '@mui/material';
import MultipleDropdownItem from './MultipleDropdownItem';
import SvgIcon from '@mui/material/SvgIcon';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '280px',
      borderRadius: 8,
      marginTop: '8px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.02), 0px 12px 25px rgba(0, 0, 0, 0.08)',
      overflow: 'hidden',
      width: '200px',
    },
  },
};

const StyledSelect = styled(Select)({
  [`& fieldset`]: {
    borderRadius: '30px',
  },
  '&.MuiSelect-outlined': {
    '&.Mui-focused': {
      border: '1px solid black',
    },
  },
  '& .MuiSvgIcon-root': {
    right: '16px',
  },
  '& [aria-expanded="true"] ~ .MuiSvgIcon-root': {
    transform: 'rotate(180deg)',
  },
  '& .MuiOutlinedInput-input': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5.5px 33px 5.75px 16px !important',
    height: '40px',
    '&.Mui-focused': {
      borderWidth: '1px',
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
});

interface Props {
  label: string;
  claims?: boolean;
  error?: boolean;
  renderValue?: (elems: number) => string;
  purchases?: boolean;
  fullWidth?: boolean;
  width?: number | string;
  size?: 'small' | 'medium';
  options: { label: string; value: any }[];
  optionsChecked: { label: string; value: any }[];
  setOptionsChecked: (shopsChecked: { label: string; value: any }[]) => void;
}

const ArrowDown = () => (
  <SvgIcon sx={{ position: 'absolute', top: 'calc(50% - 0.5em)', pointerEvents: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z"
      fill="#838B98"
    />
  </SvgIcon>
);

const MultipleDropdown: FC<Props> = ({
  claims = false,
  purchases = false,
  width = 300,
  label,
  error,
  renderValue,
  fullWidth,
  size = 'small',
  options = [],
  optionsChecked,
  setOptionsChecked,
}) => {
  return (
    <FormControl error={error} variant="outlined" size={size} sx={{ width: fullWidth ? '100%' : width }}>
      <style>
        {`
        .MuiMenu-list {
          padding-top: 0;
          padding-bottom: 0;
          max-height: 280px;
          overflow-y: auto;
          border-radius: 8px;
        }
        `}
      </style>
      <StyledSelect
        multiple
        displayEmpty
        MenuProps={MenuProps}
        IconComponent={ArrowDown}
        inputProps={{ style: { fontSize: '14px' } }}
        value={optionsChecked.map((option) => option.label)}
        renderValue={(selected: any) => {
          if (selected.length !== 0) {
            return renderValue ? (
              <Typography variant={'subtitle2'} component="p" fontWeight={600} color={'#313D53'}>
                {renderValue(selected.length)}
              </Typography>
            ) : (
              <Typography variant={'subtitle2'} component="p" fontWeight={600} color={'#313D53'}>
                {selected.length === 1 ? `Επιλέξατε ${selected.length} πεδίο` : `Επιλέξατε ${selected.length} πεδία`}
              </Typography>
            );
          }
          return (
            <Typography variant={'subtitle2'} color={'#313D53'} fontWeight={600}>
              {label}
            </Typography>
          );
        }}
      >
        {options.map((option, index) => (
          <MultipleDropdownItem
            key={index}
            option={option}
            claims={claims}
            purchases={purchases}
            selectedOptions={optionsChecked}
            setSelectedOptions={(options) => setOptionsChecked(options)}
          />
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default MultipleDropdown;
