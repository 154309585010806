import React, { FC } from 'react';
import { Button, ButtonProps, styled, Typography, TypographyProps } from '@mui/material';

const ButtonStyled = styled(Button)(({ theme }) => ({
  justifyContent: 'start',
  color: theme.palette.primary.contrastText,
  borderRadius: '32px',
  background: theme.palette.primary.main,
  padding: '16px 24px',
  boxShadow: 'none',
  variant: 'contained',
  '&:hover': {
    boxShadow: 'none',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), ${theme.palette.primary.dark}`,
  },
  '&:active': {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), ${theme.palette.primary.light}`,
  },
  '&:focus': {
    background: theme.palette.primary.light,
    boxShadow: '0 0 0 4px rgba(60, 104, 200, 0.5)',
  },
  '&:disabled': {
    background: 'rgba(0, 0, 0, 0.12)',
  },
  '&.MuiButton-outlined': {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'none',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
    '&:active': {
      background: 'rgba(0, 0, 0, 0.12)',
    },
    '&:focus': {
      boxShadow: '0 0 0 3px rgba(60, 104, 200, 0.5)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.12)',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  '& .MuiButton-startIcon': {
    marginLeft: 0,
  },
  '& .MuiButton-endIcon': {
    marginRight: 0,
  },
}));

interface Props {
  text: string;
  typographyProps?: TypographyProps;
}

const PigogoSmallButton: FC<Props & ButtonProps> = ({ text, typographyProps, ...rest }) => {
  return (
    <ButtonStyled {...rest}>
      <Typography {...typographyProps}>{text}</Typography>
    </ButtonStyled>
  );
};

export default PigogoSmallButton;
