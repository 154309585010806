import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { PigogoButton, theme } from 'components';
import useMediaQuery from '@mui/material/useMediaQuery';
import illustrationSmall from '../../assets/png/pigogoOnTheGoMobile.png';
import illustrationTablet from '../../assets/png/pigogoOnTheGoTablet.png';
import illustrationXl from '../../assets/png/pigogoOnTheGo1920.png';
import { ReactComponent as GooglePlay } from '../../assets/svgs/searchApp/googlePlayWhite.svg';
import { ReactComponent as AppStore } from '../../assets/svgs/searchApp/appStoreWhite.svg';
import CustomContainer from '../CustomContainer';
import { useIonRouter } from '@ionic/react';
import { Link } from 'react-router-dom';

const StyleBoxText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    paddingTop: '0',
    paddingBottom: '0',
    width: '456px',
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('lg')]: {
    width: '406px',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '32px',
    paddingBottom: '24px',
    width: '456px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '343px',
    maxWidth: '100%',
  },
}));

type DownloadSectionProps = {
  title: string;
  subtitle: any;
  readMore: boolean;
};

const DownloadSection: FC<DownloadSectionProps> = ({ title, subtitle, readMore }) => {
  const router = useIonRouter();

  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const mdToXl = useMediaQuery(theme.breakpoints.between('md', 'xl'));

  const chooseImage = () => {
    if (downSm) {
      return illustrationSmall;
    }
    if (downMd) {
      return illustrationTablet;
    }
    // if (mdToXl) {
    //   return illustrationLg;
    // }

    return illustrationXl;
  };

  const pigogoMobileAppText = () => {
    return (
      <StyleBoxText gap={downSm ? 4 : 6}>
        <Box gap={3} display="flex" flexDirection="column">
          <Typography variant="h3" component="h2" color={'#1D2532'}>
            {title}
          </Typography>
          <Typography variant="largeL" component="p" color={'#1D2532'} lineHeight={'28px'}>
            {subtitle}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={3} sx={{ width: '384px', maxWidth: '100%' }}>
          <Box sx={{ display: 'flex' }} gap={2}>
            <a
              className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth pigogoButton--icon"
              href={'https://play.google.com/store/apps/details?id=gr.pigogo.android'}
              target="_blank"
            >
              <GooglePlay />
              Google Play
            </a>
            <a
              className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth pigogoButton--icon"
              href={'https://apps.apple.com/us/app/pigogo/id6475775568'}
              target="_blank"
            >
              <AppStore />
              App Store
            </a>
          </Box>
          {readMore && (
            <>
              <Link className="pigogoButton pigogoButton--outlined" to={'/mobile-app'}>
                Διάβασε περισσότερα
              </Link>
            </>
          )}
        </Box>
      </StyleBoxText>
    );
  };

  return (
    <Box height={downMd ? '' : '576px'} sx={{ backgroundColor: '#FCE5D8' }} position={'relative'}>
      <CustomContainer sx={{ height: '100%' }}>
        <Box sx={{ height: '100%' }}>{pigogoMobileAppText()}</Box>
      </CustomContainer>
      <Box hidden={downMd} position={'absolute'} top={0} left={'calc(50% - 100px)'} width={'667px'} height={'100%'}>
        <img
          alt={'pigogoApp'}
          src={chooseImage()}
          style={{ position: 'absolute', width: 'auto', height: '100%', maxWidth: 'inherit' }}
        />
      </Box>
      <Box hidden={!downMd} display={'flex'} justifyContent={'center'}>
        <img alt={'pigogoApp'} src={chooseImage()} style={{ width: '100%', height: 'auto' }} />
      </Box>
    </Box>
  );
};

export default DownloadSection;
