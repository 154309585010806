import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { PigogoButton, PigogoCardsCarousel, theme } from 'components';
import Layout from '../components/Navigation/Layout';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react';
import CustomHeader from '../components/CustomHeader';
import WinCard from '../components/Home/WinCard';
import { ReactComponent as Step1 } from '../assets/svgs/illustrations/alert-step1.svg';
import { ReactComponent as Step2 } from '../assets/svgs/illustrations/alert-step2.svg';
import { ReactComponent as Step3 } from '../assets/svgs/illustrations/alert-step3.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useAlertTestimonialsQuery } from '../redux/api/queries/alertTestimonialsQuery';
import MemberCard from '../components/Home/MemberCard';
import { StateCallBack } from 'react-multi-carousel';
import MemberSection from '../components/Home/MemberSection';
import { ReactComponent as StarFilled } from '../assets/svgs/stars/light/filled.svg';
import { ReactComponent as HalfStar } from '../assets/svgs/stars/light/half.svg';
import { ReactComponent as Chrome } from '../assets/svgs/searchApp/chromeWhite.svg';
import { ReactComponent as Firefox } from '../assets/svgs/searchApp/firefoxWhite.svg';
import detectBrowser from '../utils/browserDetection';
import CustomContainer from '../components/CustomContainer';
import AlertBanner from '../assets/svgs/alert_banner.svg';
import QuestionSection from '../components/Home/QuestionSection';
import PigogoFooter from '../components/PigogoFooter';
import { ReactComponent as Check } from '../assets/svgs/check_circle_color.svg';
import AlertBannerLg from '../assets/png/alertBannerLg.png';
import AlertHelpMobile from '../assets/png/alertHelpMobile.png';
import AlertHelpLg from '../assets/png/alertHelpLg.png';
import AlertMoreLg from '../assets/png/alertMoreLg.png';
import AlertMoreMobile from '../assets/png/alertMoreMobile.png';
import AlertBannerMobile from '../assets/png/alertBannerMobile.png';
import AlertRectangle from '../assets/svgs/illustrations/alert_rectangle.svg';
import AlertBottomRectangle from '../assets/svgs/illustrations/alert_bottom_rectangle.svg';
import AlertMore from '../components/AlertMore';
import { setSnackBar, setStateSnackBar } from '../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';

const browser = detectBrowser();

const StyleBoxText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    paddingTop: '96px',
    paddingBottom: '96px',
    width: '496px',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '32px',
    paddingBottom: '48px',
    width: '100%',
  },
}));

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1281 },
    items: 4,
    slidesToSlide: 4,
  },
  lg: {
    breakpoint: { max: 1280, min: 961 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 960, min: 601 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Alert = () => {
  const dispatch = useDispatch();
  const { data: members, isError: errorMembers, isLoading } = useAlertTestimonialsQuery();
  const [centeredIndex, setCenteredIndex] = useState(1);

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (history.action !== 'PUSH') {
      contentRef.current && contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    } else {
      contentRef.current && contentRef.current.scrollToTop();
    }
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  useEffect(() => {
    if (errorMembers) {
      dispatch(setSnackBar({ value: true, msg: 'Η φόρτωση των σχολίων απέτυχε.', severity: 'error' }));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorMembers]);

  const afterChange = (previousSlide: number, state: StateCallBack) => {
    const _currentSlide = state.currentSlide;
    const _offsetCenter = Math.floor(state.slidesToShow / 2);
    const centerElemIndex = _currentSlide + _offsetCenter;
    setCenteredIndex(centerElemIndex);
  };

  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const smToMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const chooseImage = () => {
    if (downSm) {
      return AlertBannerMobile;
    }
    if (downLg) {
      return AlertBannerLg;
    }
    if (upLg) {
      return AlertBannerLg;
    }
  };
  const chooseHelpImage = () => {
    if (downSm) {
      return AlertHelpMobile;
    }
    if (downLg) {
      return AlertHelpLg;
    }
    if (upLg) {
      return AlertHelpLg;
    }
  };

  const pigogoAlertBox = () => {
    return (
      <StyleBoxText>
        <Box gap={3} display="flex" flexDirection="column">
          <Typography color="white" variant="h3" component="h2">
            Pigogo ALERT!
          </Typography>

          <Typography color="white" variant="largeL" component="p" lineHeight="28px" sx={{ maxWidth: '416px' }}>
            Κατέβασε το Pigogo ALERT στον browser σου! Θα σε ειδοποιεί κάθε φορά που επισκέπτεσαι κατάστημα του Pigogo
            και με 1 μόνο κλικ θα κερδίζεις επιστροφή χρημάτων (χωρίς καν να μπαίνεις στο site του Pigogo)!
          </Typography>

          <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
            <Box display="flex" gap={'6px'}>
              <StarFilled />
              <StarFilled />
              <StarFilled />
              <StarFilled />
              <HalfStar />
            </Box>
            <Typography color="white" variant="subtitle2" component="p">
              το έχουν κατεβάσει 5.000+ χρήστες
            </Typography>
          </Box>

          {!downSm && (
            <Box display={'flex'} gap={2} justifyContent={'space-between'} mt={3}>
              {(browser === 'Chrome' || browser === 'other') && (
                <PigogoButton
                  fullWidth
                  variation="secondary"
                  text={browser === 'Chrome' ? `Εγκατάσταση στον Chrome` : `Google Chrome`}
                  startIcon={<Chrome />}
                  sx={{ width: '327px' }}
                  onClick={() =>
                    window.open(
                      'https://chrome.google.com/webstore/detail/pigogo-alert/adhfocpeiaaklpfbaipnjehejfbghjeg?hl=el',
                      '_blank',
                    )
                  }
                />
              )}
              {(browser === 'Firefox' || browser === 'other') && (
                <PigogoButton
                  fullWidth
                  variation="secondary"
                  startIcon={<Firefox />}
                  sx={{ width: '327px' }}
                  text={`Εγκατάσταση στον Firefox`}
                  onClick={() => window.open('https://addons.mozilla.org/el/firefox/addon/pigogo-alert/', '_blank')}
                />
              )}
            </Box>
          )}
        </Box>
      </StyleBoxText>
    );
  };

  const stepsSection = () => {
    return (
      <CustomContainer>
        <Box
          display="flex"
          flexDirection="column"
          gap={6}
          paddingBottom={downSm ? '32px' : '80px'}
          paddingTop={downSm ? '32px' : '80px'}
        >
          <Box display="flex" flexDirection="column" justifyContent={'center'} alignItems="center" gap={2}>
            <Typography component="h2" color="#1D2532" variant="smallB" textAlign={downMd ? 'center' : 'left'}>
              Τα βήματα
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography textAlign="center" color="primary" variant="body2MediumL" component="p">
                Ένα - δύο - τρία. Τόσο εύκολα!
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            gap={4}
            justifyContent="center"
            flexWrap={'nowrap'}
            flexDirection={downMd ? 'column' : 'row'}
            alignItems={downMd ? 'center' : 'inherit'}
          >
            <Box width={'320px'}>
              <WinCard
                background={'#D6D8DD'}
                icon={<Step1 />}
                subtitle="Κατέβασε το Pigogo ALERT! Είναι δωρεάν."
                fontFamily={'largeRegular'}
              />
            </Box>
            <Box width={'320px'}>
              <WinCard
                background={'#FBE1E8'}
                icon={<Step2 />}
                subtitle={`Κλικ στο "Θέλω επιστροφή χρημάτων!"`}
                fontFamily={'largeRegular'}
              />
            </Box>
            <Box width={'320px'}>
              <WinCard
                background={'#FCE5D8'}
                icon={<Step3 />}
                subtitle="Συγχαρητήρια! Η αγορά σου καταγράφηκε."
                fontFamily={'largeRegular'}
              />
            </Box>
          </Box>
        </Box>
      </CustomContainer>
    );
  };

  const helpSection = () => {
    return (
      <Box display="flex" height={'100%'}>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          justifyContent="center"
          height={'100%'}
          width={downMd ? '100%' : '496px'}
        >
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="smallB" component="h2" color="#1D2532" textAlign={downMd ? 'center' : 'left'}>
              Πώς με βοηθάει
            </Typography>
            <Typography variant="largeRegular" component="p" color="#313D53">
              Κάθε φορά που επισκέπτεσαι ένα ηλεκτρονικό κατάστημα, το Pigogo ALERT ελέγχει αν το συγκεκριμένο κατάστημα
              συνεργάζεται με το Pigogo.
            </Typography>
            <Typography variant="largeRegular" component="p" color="#313D53">
              Εφόσον το κατάστημα είναι συνεργαζόμενο, εμφανίζεται αυτόματα ένα ενημερωτικό μήνυμα πάνω δεξιά στην οθόνη
              του υπολογιστή σου.
            </Typography>
            <Typography variant="largeRegular" component="p" color="#313D53">
              Κάνοντας κλικ στο κουμπί «Θέλω επιστροφή χρημάτων» λίγο πριν την αγορά σου, κερδίζεις επιστροφή που
              καταγράφεται στον λογαριασμό σου, χωρίς καν να χρειαστεί να μπεις στο site του Pigogo!
            </Typography>
          </Box>
          <Box bgcolor="#FFFFFF" p={2} display="flex" gap={2} alignItems="start" borderRadius={2}>
            <Box
              color={'#E9688A'}
              sx={{
                width: '32px',
                height: '32px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Check />
            </Box>
            <Typography variant="mediumH6R" component="p" color="#313D53">
              Εσύ ψωνίζεις και εμείς φροντίζουμε να κερδίζεις επιστροφή χρημάτων!
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const membersSection = () => {
    return (
      <Box bgcolor="#F5F7F9" pb={10}>
        <Box display="flex" pt={10} justifyContent="center" width={'1024px'} maxWidth={'100%'} mx={'auto'}>
          {!downMd &&
            members &&
            members.data.slice(0, 3).map((item: any, index: any) => (
              <Box
                px={index % 2 ? (downLg ? 2 : 4) : 0}
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={'312px'}
                width={downLg ? 'calc(33.33% - 10.66px)' : 'calc(33.33% - 21.33px)'}
              >
                <MemberCard
                  border={index === centeredIndex}
                  description={item.attributes.testimonial}
                  memberName={item.attributes.name}
                  memberSince={item.attributes.memberSince}
                  rating
                />
              </Box>
            ))}
        </Box>
        {downMd && members && (
          <PigogoCardsCarousel responsive={responsive} afterChange={afterChange}>
            {members.data.slice(0, 3).map((item, index) => (
              <Box
                px={index % 2 ? 2 : 0}
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={'312px'}
              >
                <MemberCard
                  border={index === centeredIndex}
                  description={item.attributes.testimonial}
                  memberName={item.attributes.name}
                  memberSince={item.attributes.memberSince}
                  rating
                />
              </Box>
            ))}
          </PigogoCardsCarousel>
        )}

        <Box display="flex" alignItems="center" flexDirection="column" pt={downMd ? 12 : 6}>
          <Typography color="#313D53" variant="h5" fontWeight="700">
            το έχουν κατεβάσει 5.000+ χρήστες
          </Typography>
          <Box display="flex" gap={'6px'} pt="19px">
            <StarFilled />
            <StarFilled />
            <StarFilled />
            <StarFilled />
            <HalfStar />
          </Box>
        </Box>
        {!downMd && (
          <Box display="flex" flexDirection="column" alignItems="center" gap={3} pt={4}>
            <Box display={'flex'} gap={2} justifyContent={'space-between'}>
              {(browser === 'Chrome' || browser === 'other') && (
                <PigogoButton
                  variation="secondary"
                  text={browser === 'other' ? `Google Chrome` : `Εγκατάσταση στον Chrome`}
                  startIcon={<Chrome />}
                  sx={{ width: '327px' }}
                  onClick={() =>
                    window.open(
                      'https://chrome.google.com/webstore/detail/pigogo-alert/adhfocpeiaaklpfbaipnjehejfbghjeg?hl=el',
                      '_blank',
                    )
                  }
                />
              )}
              {(browser === 'Firefox' || browser === 'other') && (
                <PigogoButton
                  variation="secondary"
                  startIcon={<Firefox />}
                  sx={{ width: '327px' }}
                  text={`Εγκατάσταση στον Firefox`}
                  onClick={() => window.open('https://addons.mozilla.org/el/firefox/addon/pigogo-alert/', '_blank')}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const footerSection = () => {
    if (upMd) {
      return <>{<PigogoFooter />}</>;
    } else {
      return <></>;
    }
  };

  return (
    <IonPage>
      <IonContent scrollEvents={!isLoading} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <Box>
            {location.pathname === '/alert' && (
              <Helmet>
                <title>Pigogo ALERT! | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                <meta
                  name="description"
                  content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                />
              </Helmet>
            )}
            <CustomHeader />
            {downMd && (
              <IonHeader class="ion-no-border">
                <IonToolbar
                  style={{
                    '--background': '#FFFFFF',
                    '--border-width': 0,
                    paddingTop: '0 + --ion-safe-area-top',
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                    <IonButtons slot="start">
                      <IonBackButton
                        className="backBtn"
                        text=""
                        style={{
                          '--color': '#313D53',
                          '--padding-start': 0,
                          '--padding-end': 0,
                          '--icon-font-size': '24px',
                          '--border-radius': 0,
                          '--min-height': '24px',
                          '--min-width': '24px',
                          width: '24px',
                          height: '24px',
                          minWidth: '24px',
                          maxHeight: '24px',
                          display: 'inline-flex',
                        }}
                        icon={backArrow}
                        defaultHref={'/'}
                      />
                    </IonButtons>
                    <Typography component="h2" variant="body2" color={'#313D53'}>
                      Pigogo ALERT!
                    </Typography>
                    <Box minWidth={'24px'}></Box>
                  </Box>
                </IonToolbar>
              </IonHeader>
            )}
          </Box>
          <Box height={downMd ? '' : '576px'} sx={{ backgroundColor: '#1D2532' }} position={'relative'}>
            <CustomContainer>
              <Box>{pigogoAlertBox()}</Box>
            </CustomContainer>
            <Box hidden={downLg} display={'flex'} position={'absolute'} top={0} left={'calc(50% + 420px)'}>
              <img alt={'pigogoApp'} src={AlertRectangle} />
            </Box>
            <Box hidden={downLg} display={'flex'} position={'absolute'} bottom={0} left={'calc(50% - 210px)'}>
              <img alt={'pigogoApp'} src={AlertBottomRectangle} />
            </Box>
            <Box hidden={downMd} display={'flex'} position={'absolute'} top={60} bottom={0} left={'calc(50% - 100px)'}>
              <img alt={'pigogoApp'} src={chooseImage()} />
            </Box>
            <Box hidden={!downMd} display={'flex'} justifyContent={'center'}>
              <img alt={'pigogoApp'} src={chooseImage()} />
            </Box>
          </Box>
          {stepsSection()}
          <Box height={downMd ? '' : '656px'} sx={{ backgroundColor: '#FEF6F1' }} position={'relative'}>
            <CustomContainer height={'100%'}>
              <Box
                height={'100%'}
                sx={{
                  display: 'flex',
                  gap: downLg ? 6 : 13,
                  justifyContent: 'center',
                  paddingTop: downSm || smToMd ? '32px' : '',
                }}
              >
                {helpSection()}
                <Box hidden={downMd} display={'flex'} alignSelf={'center'}>
                  <img alt={'pigogoApp'} src={chooseHelpImage()} />
                </Box>
              </Box>
            </CustomContainer>

            <Box
              hidden={!downMd}
              display={'flex'}
              justifyContent={'center'}
              pb={4}
              pl={3}
              pr={3}
              sx={{ paddingTop: '48px' }}
            >
              <img alt={'pigogoApp'} src={chooseHelpImage()} />
            </Box>
          </Box>
          <AlertMore bgColor={'#FFFFFF'} checkBackground={'FFFFFF'} />
          {membersSection()}
          <QuestionSection hasExtraPadding />
          {footerSection()}
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default Alert;
