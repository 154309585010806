import React, { FC } from 'react';
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const PigogoRecentsCarousel: FC<Props> = ({ children }) => {
  return (
    <Box pl={2}>
      <style>
        {`
         .scrollContainer {
          overflow-y: hidden;
          overflow-x: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
         }
          .scrollContainer::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      <Box className="scrollContainer" position="relative">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default PigogoRecentsCarousel;
