import { pigogoApi } from '../Api';
import { GenericRequest } from '../../../models/GenericRequest';
import { FAQCategory } from '../../../models/FAQCategory';
import { FAQData } from '../../../models/FAQData';

const faqsApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllFaqCategories: builder.query<GenericRequest<FAQCategory[]>, void>({
      query: () => ({
        method: 'GET',
        url: '/static-content/faq-categories',
      }),
      transformResponse(response: FAQCategory[], meta) {
        return {
          data: response,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')),
          nextCursor: undefined,
        };
      },
    }),
    faq: builder.query<
      GenericRequest<FAQData[]>,
      { categoryId?: string; keyword?: string; limit?: number; offset?: number }
    >({
      query: ({ categoryId, keyword, limit, offset }) => ({
        method: 'GET',
        url: '/static-content/faq',
        params: { categoryId, keyword, limit, offset },
      }),
      transformResponse(response: FAQData[]) {
        return {
          data: response,
          totalCount: response.length,
          nextCursor: undefined,
        };
      },
    }),
  }),
});

export const { useGetAllFaqCategoriesQuery, useFaqQuery, useLazyFaqQuery } = faqsApi;
