import { useIonRouter } from '@ionic/react';
import { Button, Skeleton, styled, TableCell, TableRow, Typography, Box } from '@mui/material';
import { ReactComponent as ChevronRight } from '../../../assets/svgs/chevronRight.svg';
import moment from 'moment';
import { Claim } from '../../../models/Claims';
import { FC } from 'react';
import ExtractClaimStatus from '../../ExtractClaimStatus';
import ExtractClaimType from '../../ExtractClaimType';
import { Link } from 'react-router-dom';

interface Props {
  row?: Claim;
  isLoading?: boolean;
  onRowClick?: (claim: Claim) => void;
}

const StyledTableCell = styled(TableCell)(() => ({
  padding: '16px 12px',
  height: '72px',
  minWidth: '147px',
  boxSizing: 'border-box',
  '&:nth-of-type(1)': {
    paddingLeft: '24px',
  },
  '&:nth-of-type(3)': {
    minWidth: '107px',
  },
  '&:nth-of-type(4)': {
    minWidth: '135px',
  },
  '&:nth-of-type(5)': {
    minWidth: '141px',
  },
  '&:last-child': {
    paddingRight: '24px',
  },
}));

const ClaimsDesktopRow: FC<Props> = ({ row, onRowClick, isLoading }) => {
  const router = useIonRouter();

  return (
    <TableRow
      onClick={row && onRowClick ? () => onRowClick(row) : undefined}
      hover
      sx={{
        overflow: 'hidden',
        '&:children': {
          borderLeft: 0,
          borderRight: 0,
        },
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        cursor: 'pointer',
        '&:last-child td:first-of-type': {
          borderBottomLeftRadius: '24px',
        },
        '&:last-child td:last-child': {
          borderBottomRightRadius: '24px',
        },
      }}
    >
      <StyledTableCell>
        <Typography variant="caption" color={'#313D53'}>
          {isLoading ? <Skeleton width={80} sx={{ height: '20px' }} /> : moment(row?.created_at).format('DD/MM/YYYY')}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        {row?.shop_status_id === 3 ? (
          <Link className="btnLink--table" to={`/${row?.shop_slug.name}`}>
            {isLoading ? <Skeleton width={80} sx={{ height: '20px' }} /> : row?.shop_name}
          </Link>
        ) : (
          <Typography variant="body2MediumM" textAlign={'start'} color={'#313D53'}>
            {isLoading ? <Skeleton width={80} sx={{ height: '20px' }} /> : row?.shop_name}
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2MediumM" color={'#313D53'} textAlign={'start'} sx={{ display: 'inline-block' }}>
          {isLoading ? <Skeleton width={60} sx={{ height: '20px', marginTop: '8px' }} /> : row?.order_amount}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="caption" textAlign={'start'} sx={{ display: 'inline-block' }}>
          {isLoading ? (
            <Skeleton width={60} sx={{ height: '20px' }} />
          ) : (
            <ExtractClaimType claim={row?.claim_type_id} variant={'caption'} />
          )}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="caption" textAlign={'start'} sx={{ display: 'inline-block' }}>
          {isLoading ? (
            <Skeleton width={80} sx={{ height: '20px' }} />
          ) : (
            <ExtractClaimStatus claim={row?.claim_status_id} variant={'caption'} />
          )}
        </Typography>
      </StyledTableCell>
      <TableCell sx={{ minWidth: '34px', padding: 0 }}>
        <Box
          sx={{ width: '24px', height: '24px', display: 'inlineFlex', alignItems: 'center', justifyContent: 'center' }}
        >
          <ChevronRight />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ClaimsDesktopRow;
