import React, { FC } from 'react';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { Box, IconButton, styled, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Success } from '../../../assets/svgs/illustrations/feedback/successSmile.svg';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { theme } from 'components';

interface Props {
  open: boolean;
  title?: string;
  subTitle?: string;
  height?: string;
  handleClose: (value: boolean) => void;
}

const SuccessPasswordModal: FC<Props> = ({
  open,
  height = '336px',
  subTitle,
  handleClose,
  title = 'Ο κωδικός άλλαξε με επιτυχία!',
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <IonModal
      mode={'ios'}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => handleClose(false)}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '12px',
            height: '48px',
            boxSizing: 'border-box',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleClose(false)}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box sx={{ paddingInline: smDown ? '24px 12px' : '32px 20px', paddingBottom: smDown ? '8px' : '36px' }}>
          <Box
            gap={2}
            display="flex"
            height={'100%'}
            alignItems="center"
            textAlign={'center'}
            flexDirection="column"
            justifyContent="center"
          >
            <Box>
              <Success />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Typography variant={'body1'} color={'#313D53'} component="h2">
                {title}
              </Typography>
            </Box>
            {subTitle && (
              <Box>
                <Typography variant={'body2MediumR'} color={'#313D53'}>
                  {subTitle}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default SuccessPasswordModal;
