import { useEffect } from 'react';
import { theme } from 'components';
import useQuery from '../../hooks/useQuery';
import CustomContainer from '../CustomContainer';
import { Box, Typography, useMediaQuery } from '@mui/material';

const PrivacyPolicy = () => {
  const query = useQuery();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const findId = query.get('cookies');
    const element = document.getElementById('cookies');
    if (findId) {
      setTimeout(() => {
        element?.scrollIntoView();
      }, 500);
    }
  }, [query]);

  return (
    <CustomContainer>
      <Box display="flex" flexDirection="column" gap={5} pl={desktop ? 4 : 0} pr={desktop ? 4 : 0}>
        <Typography variant="body2MediumL" component="p" color={'#313D53'}>
          Κατά την πλοήγησή σας στο www.pigogo.gr αλλά και τη χρήση των υπηρεσιών μας, το Pigogo συλλέγει έναν
          περιορισμένο αριθμό από προσωπικές αλλά και ανώνυμες πληροφορίες από εσάς με στόχο να παρέχει αναβαθμισμένες
          υπηρεσίες (προσωποποιημένες σελίδες και ενημέρωση, πρόσβαση στο ιστορικό αγορών κλπ.), να μπορεί να εκπληρώνει
          τις πληρωμές, να επικοινωνεί με τους χρήστες κλπ. Τα προσωπικά δεδομένα που συλλέγονται επεξεργάζονται βάσει
          της ελληνικής νομοθεσίας καθώς και σύμφωνα με τα ακόλουθα:
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            1. ΤΙ ΣΤΟΙΧΕΙΑ ΣΥΛΛΕΓΟΥΜΕ
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            1. Ανώνυμα στοιχεία
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Κάθε επισκέπτης μπορεί να περιηγηθεί στις σελίδες του Pigogo ανώνυμα και χωρίς να παρέχει οποιαδήποτε
            προσωπική πληροφορία. Τα μόνα στοιχεία που συλλέγονται για αυτόν είναι κάποιες ανώνυμες πληροφορίες όπως η
            IP διεύθυνση του υπολογιστή του, η ημέρα και ώρα της επίσκεψης και άλλες παρεμφερείς μη προσωπικές
            πληροφορίες. Τα στοιχεία αυτά χρησιμοποιούνται για την εξασφάλιση της εύρυθμης λειτουργίας του Pigogo και
            δεν περιέχουν κανένα προσωπικό στοιχείο για τους επισκέπτες.
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#1D2532'}>
            2. Προσωπικά δεδομένα
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Για να είναι δυνατή η λειτουργία της υπηρεσίας, κατά τη διαδικασία εγγραφής συλλέγονται τα εξής προσωπικά
            δεδομένα: Όνομα, Επώνυμο και E-mail. Εθελοντικά, μετά την εγγραφή ο χρήστης μπορεί να δηλώσει στον
            λογαριασμό του περισσότερα στοιχεία όπως: Ημερομηνία γέννησης, Φύλο και Στοιχεία Πληρωμής (αριθμό ΙΒΑΝ και
            Όνομα Δικαιούχου ή Viva Wallet account).
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            2. ΠΩΣ ΧΡΗΣΙΜΟΠΟΙΟΥΝΤΑΙ ΤΑ ΠΡΟΣΩΠΙΚΑ ΣΑΣ ΣΤΟΙΧΕΙΑ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Το Pigogo σε καμία περίπτωση δεν μοιράζεται τα προσωπικά σας στοιχεία με τρίτους. Τα στοιχεία σας
              χρησιμοποιούνται μόνο για τη λειτουργία της υπηρεσίας.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Κατά τη διαδικασία αγοράς από ένα συνεργαζόμενο κατάστημα, θα σας ζητηθεί να παράσχετε κάποια προσωπικά
              στοιχεία που απαιτούνται για την ολοκλήρωση της αγοράς. Η πολιτική απορρήτου που διέπει τη χρήση των
              στοιχείων αυτών εναπόκειται στο κάθε συνεργαζόμενο κατάστημα. Το Pigogo δεν ελέγχει και δεν φέρει καμία
              ευθύνη για τον τρόπο με τον οποίο χρησιμοποιούν τα στοιχεία σας τα συνεργαζόμενα καταστήματα.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            3. ΠΩΣ ΑΣΦΑΛΙΖΟΝΤΑΙ ΤΑ ΠΡΟΣΩΠΙΚΑ ΣΑΣ ΔΕΔΟΜΕΝΑ
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Όλα τα προσωπικά σας δεδομένα είναι διαθέσιμα μόνο σε εσάς και προστατεύονται μέσω του συνδυασμού E-mail /
              κωδικού που έχετε ορίσει εσείς ή μέσω της ασφαλούς σύνδεσης από το κοινωνικό δίκτυο της επιλογής σας.
              Κανείς δε μπορεί να έχει πρόσβαση στα δεδομένα σας χωρίς να γνωρίζει τον συνδυασμό αυτόν. Επιπλέον, ο
              κωδικός σας αποθηκεύεται στη βάση δεδομένων του Pigogo μόνο αφού έχει κρυπτογραφηθεί με ισχυρή, μονόδρομη
              κρυπτογράφηση το οποίο πρακτικά σημαίνει πως ούτε καν το Pigogo γνωρίζει ποιος είναι ο κωδικός σας
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Επιπλέον, το Pigogo χρησιμοποιεί την - ευρέως αποδεκτή ως ασφαλέστερη - τεχνολογία κρυπτογράφησης TLS
              (Transport Layer Security) σε όλες τις σελίδες του και ως εκ τούτου σε όλες τις συναλλαγές που
              περιλαμβάνουν αποστολή προσωπικών δεδομένων μέσω του Διαδικτύου. Κανένα προσωπικό στοιχείο σας δεν
              ανταλλάσσεται μεταξύ του Pigogo και του υπολογιστή σας πριν κρυπτογραφηθεί και μάλιστα με ισχυρή
              κρυπτογράφηση έως 256-bit.
            </Typography>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Τέλος, το Pigogo διαθέτει ψηφιακό πιστοποιητικό ασφαλείας υπογεγραμμένο από την Comodo, μια ηγέτιδα
              εταιρεία στον τομέα της ασφάλειας των συναλλαγών στο Διαδίκτυο. Μέσω του πιστοποιητικού αυτού, ο browser
              του πελάτη μπορεί αυτόματα να ελέγξει ότι έχει πράγματι συνδεθεί με τον πιστοποιημένο server του Pigogo
              και να εξακριβώσει την αυθεντικότητα της προέλευσης και την εγκυρότητα των δεδομένων που λαμβάνει.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            4. ΔΙΑΓΡΑΦΗ ΔΕΔΟΜΕΝΩΝ
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Σε κάθε περίπτωση και για οποιονδήποτε λόγο διατηρείτε το δικαίωμα να ζητήσετε την οριστική διαγραφή των
            προσωπικών στοιχείων που διατηρούμε για εσάς, όπως αυτά που συμπληρώσατε κατά την εγγραφή σας στην υπηρεσία
            (Όνομα, Επώνυμο και E-mail) ή και κατά τη χρήση της (Ημερομηνία γέννησης, Φύλο και Στοιχεία Πληρωμής). Το
            αίτημα για τη διαγραφή των προσωπικών σας στοιχείων μπορεί να υποβληθεί είτε μέσα από τη{' '}
            <a href="/contact" style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }}>
              φόρμα επικοινωνίας
            </a>{' '}
            του site μας, είτε μέσω e-mail στο help@pigogo.gr.
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            Καθότι η διαγραφή των προσωπικών σας στοιχείων επιφέρει αυτόματα την πλήρη και οριστική διαγραφή του
            λογαριασμού σας από την υπηρεσία του Pigogo, πλέον δεν δικαιούστε το οποιοδήποτε ποσό επιστροφής χρημάτων
            που πιθανώς έχετε συγκεντρώσει ή οποιαδήποτε άλλα πλεονεκτήματα προσφέρει η υπηρεσία μας στους
            εγγεγραμμένους χρήστες της.
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={1} id={'cookies'}>
          <Typography variant="mediumM" component="h3" color={'#1D2532'}>
            5. COOKIES
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="body2MediumL" component="p" color={'#313D53'}>
              Όπως οι περισσότερες ιστοσελίδες, το Pigogo χρησιμοποιεί cookies για την εύρυθμη λειτουργία της
              ιστοσελίδας του.
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                1. Τι είναι τα cookies
              </Typography>

              <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                Τα cookies είναι μικρά αρχεία με δεδομένα τα οποία αποστέλλονται από μια ιστοσελίδα και αποθηκεύονται
                στον υπολογιστή του επισκέπτη. Χρησιμοποιούνται για την αποθήκευση κάποιων επιλογών ή και για την
                αποθήκευση του session id του επισκέπτη με το οποίο δίνεται η δυνατότητα στην ιστοσελίδα να «θυμάται»
                ότι κάποιος πελάτης έχει συνδεθεί και να μην ζητάει τα στοιχεία σύνδεσης σε κάθε σελίδα που
                επισκέπτεται. Τα cookies σε καμία περίπτωση δε βλάπτουν το σύστημα του χρήστη. Επιπλέον, τα cookies που
                χρησιμοποιεί το Pigogo δεν περιέχουν καμία απολύτως προσωπική πληροφορία για τον χρήστη.
              </Typography>

              <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                2. Τι cookies χρησιμοποιούμε
              </Typography>
              <Box display="flex" flexDirection="column" gap={3}>
                <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                  i) Απολύτως απαραίτητα cookies
                </Typography>
                <Box>
                  <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                    Πρόκειται για cookies χωρίς τα οποία δεν είναι δυνατή η χρήση της υπηρεσίας καθώς είναι απαραίτητα
                    για την εκτέλεση βασικών λειτουργιών όπως η σύνδεση των χρηστών, η ασφαλής υποβολή στοιχείων και η
                    απονομή των εγγραφών μέσω της υπηρεσίας Tell a friend.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                    ii) Cookies προτιμήσεων
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                    Αυτά τα cookies αποθηκεύουν τις προτιμήσεις του χρήστη κατά την περιήγηση στο Pigogo. Συγκεκριμένα,
                    χρησιμοποιούμε cookies προτιμήσεων για να αποθηκεύσουμε την επιλογή μη εμφάνισης της ενημέρωσης για
                    τη χρήση των cookies.
                  </Typography>
                </Box>
                <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                  iii) Cookies analytics
                </Typography>
                <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                  Τα cookies analytics μας βοηθάνε να εξάγουμε στοιχεία για τον τρόπο χρήσης του site από τους χρήστες,
                  με σκοπό τη βελτίωση των υπηρεσιών μας.
                </Typography>
              </Box>
              <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                3. Cookies συνεργατών
              </Typography>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                  Κατά την πλοήγησή σας στο Pigogo, ενδέχεται να λάβετε cookies από συνεργάτες του Pigogo. Τα cookies
                  αυτά χρησιμοποιούνται αφενός για την καταγραφή των αγορών στα συνεργαζόμενα καταστήματα, αφετέρου για
                  την απονομή των εγγραφών μέσω της υπηρεσίας Tell a friend.
                </Typography>
                <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                  Κατά την επίσκεψή σας σε ένα από τα συνεργαζόμενα καταστήματα, ενδέχεται να λάβετε cookies από το
                  κατάστημα αυτό. Η πολιτική απορρήτου που διέπει τα cookies αυτά εναπόκειται στο κάθε συνεργαζόμενο
                  κατάστημα. Το Pigogo δεν ελέγχει και δεν φέρει καμία ευθύνη για τον τρόπο με τον οποίο χρησιμοποιούν
                  cookies τα συνεργαζόμενα καταστήματα.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default PrivacyPolicy;
