import { Box, Typography, useMediaQuery } from '@mui/material';
import { PigogoTextField, RichTooltip, theme } from 'components';
import { ReactComponent as InfoOutlined } from '../../../assets/svgs/info.svg';
import React, { FC, useState } from 'react';

const initialToolTip = { openIban: false, openBenef: false };

type BeneficiaryFieldProp = {
  beneficiary: string;
  errorBeneficiary?: boolean;
  setBeneficiary: (beneficiary: string) => void;
  errorMessage: () => string;
  inputProps?: any;
};
const BeneficiaryField: FC<BeneficiaryFieldProp> = ({
  beneficiary,
  errorBeneficiary,
  setBeneficiary,
  errorMessage,
  inputProps,
}) => {
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState<boolean>(false);

  const MouseOver = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const MouseOut = () => {
    setOpen(false);
  };
  return (
    <Box display={'flex'} alignItems={'center'} gap={2}>
      <Box width={mobile ? '271px' : '316px'}>
        <PigogoTextField
          fullWidth
          size={'medium'}
          label="Δικαιούχος"
          value={beneficiary}
          error={errorBeneficiary}
          setValue={(value: string) => setBeneficiary(value)}
          helperText={errorMessage()}
          inputProps={inputProps ? inputProps : undefined}
        />
      </Box>
      <Box
        onMouseOver={MouseOver}
        onClick={MouseOver}
        onMouseOut={MouseOut}
        sx={{
          width: '24px',
          height: '24px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          cursor: 'pointer',
          alignSelf: 'flex-start',
          marginTop: 2,
          position: 'relative',
          zIndex: '999',
          '&:before': {
            content: '""',
            width: 'calc(100% + 12px)',
            height: 'calc(100% + 12px)',
            position: 'absolute',
            top: '-6px',
            left: '-6px',
            zIndex: '999',
          },
        }}
      >
        <RichTooltip
          content={
            <Typography variant={'caption'} component="p">
              Συμπλήρωσε το όνομα του δικαιούχου, όπως αυτό εμφανίζεται στην τράπεζά σου.
            </Typography>
          }
          open={open}
          placement="bottom"
          color="dark"
          onClose={() => setOpen(false)}
        >
          <InfoOutlined />
        </RichTooltip>
      </Box>
    </Box>
  );
};

export default BeneficiaryField;
