import { Box, Typography } from '@mui/material';
import CustomContainer from './CustomContainer';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from 'components';
import stepLeft from '../assets/svgs/step_left.svg';
import stepRight from '../assets/svgs/step_right.svg';
import AlertStep1 from '../assets/png/alertDownloadStep1.png';
import AlertStep2 from '../assets/png/alertDownloadStep2.png';
import AlertStep3 from '../assets/png/alertDownloadStep3.png';

const StepSection = () => {
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box
      bgcolor={'#F7F7F8'}
      pt={downMd ? '32px' : '80px'}
      pb={downMd ? '32px' : '80px'}
      display={'flex'}
      flexDirection={'column'}
      gap={downMd ? 4 : 8}
    >
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          gap={2}
          width={'672px'}
          maxWidth={'100%'}
          margin={'0 auto 48px'}
        >
          <Typography variant="smallB" component="h2" color="#1D2532">
            Πώς με βοηθάει;
          </Typography>
          <Typography variant="body2MediumL" component="b" color="#1D2532">
            Θα σε ειδοποιεί κάθε φορά που επισκέπτεσαι κατάστημα του Pigogo και με 1 μόνο κλικ θα κερδίζεις επιστροφή
            χρημάτων!
          </Typography>
        </Box>
        <CustomContainer
          display="flex"
          gap={!downMd ? 8 : 2}
          px={!downLg ? 0 : 2}
          maxWidth={'1024px'}
          flexDirection={downMd ? 'column' : 'unset'}
        >
          <Box
            display="flex"
            justifyContent="center"
            position={'relative'}
            width={smUp && downMd ? '550px' : 'inherit'}
            mx={smUp && downMd ? 'auto' : '0'}
          >
            {!downSm && (
              <img
                alt=""
                src={stepLeft}
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '-88px', zIndex: '-1' }}
              />
            )}
            {downSm && (
              <img
                alt=""
                src={stepLeft}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  zIndex: '-1',
                }}
              />
            )}
            <img alt={'pigogoApp'} src={AlertStep1} width={'462px'} style={{ maxWidth: '100%' }} />
          </Box>
          <Box display="flex" alignItems="center" width={!downMd ? '498px' : '100%'}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              borderLeft={'2px solid #E9688A'}
              height={'fit-content'}
              py={2}
              pl={4}
              pr={downSm ? 2 : '62px'}
              ml={2}
              mr={2}
              width={'466px'}
              maxWidth={'100%'}
            >
              <Typography variant="mediumRAll" component="h2" color="#E9688A">
                Βήμα 1
              </Typography>
              <Typography variant="body2MediumR" component="p" color="#313D53">
                Μόλις επισκεφτείς κατάστημα του Pigogo, ένα ενημερωτικό μήνυμα θα εμφανιστεί πάνω δεξιά στην οθόνη του
                υπολογιστή σου.
              </Typography>
            </Box>
          </Box>
        </CustomContainer>
      </Box>
      <Box>
        <CustomContainer
          display="flex"
          gap={!downMd ? 8 : 2}
          px={!downLg ? 0 : 2}
          maxWidth={'1024px'}
          flexDirection={downMd ? 'column' : 'unset'}
        >
          <Box display="flex" alignItems="center" width={!downMd ? '498px' : '100%'}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              borderLeft={'2px solid #E9688A'}
              height={'fit-content'}
              py={2}
              pl={4}
              pr={downSm ? 2 : '62px'}
              ml={2}
              mr={2}
              width={'466px'}
              maxWidth={'100%'}
            >
              <Typography variant="mediumRAll" component="h2" color="#E9688A">
                Βήμα 2
              </Typography>
              <Typography variant="body2MediumR" component="p" color="#313D53">
                Κάνεις κλικ στο κουμπί «Θέλω επιστροφή χρημάτων» και προχωράς στην αγορά σου.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            position={'relative'}
            width={smUp && downMd ? '550px' : 'inherit'}
            mx={smUp && downMd ? 'auto' : '0'}
            order={downMd ? '-1' : 'inherit'}
          >
            {!downSm && (
              <img
                alt=""
                src={stepRight}
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '-88px',
                  zIndex: '-1',
                }}
              />
            )}
            {downSm && (
              <img
                alt=""
                src={stepRight}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  zIndex: '-1',
                }}
              />
            )}
            <img alt={'pigogoApp'} src={AlertStep2} width={'462px'} style={{ maxWidth: '100%' }} />
          </Box>
        </CustomContainer>
      </Box>
      <Box>
        <CustomContainer
          display="flex"
          gap={!downMd ? 8 : 2}
          px={!downLg ? 0 : 2}
          maxWidth={'1024px'}
          flexDirection={downMd ? 'column' : 'unset'}
        >
          <Box
            display="flex"
            justifyContent="center"
            position={'relative'}
            width={smUp && downMd ? '550px' : 'inherit'}
            mx={smUp && downMd ? 'auto' : '0'}
          >
            {!downSm && (
              <img
                alt=""
                src={stepLeft}
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '-88px', zIndex: '-1' }}
              />
            )}
            {downSm && (
              <img
                alt=""
                src={stepLeft}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  zIndex: '-1',
                }}
              />
            )}
            <img alt={'pigogoApp'} src={AlertStep3} width={'462px'} style={{ maxWidth: '100%' }} />
          </Box>

          <Box display="flex" alignItems="center" width={!downMd ? '498px' : '100%'}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              borderLeft={'2px solid #E9688A'}
              height={'fit-content'}
              py={2}
              pl={4}
              pr={downSm ? 2 : '62px'}
              ml={2}
              mr={2}
              width={'466px'}
              maxWidth={'100%'}
            >
              <Typography variant="mediumRAll" component="h2" color="#E9688A">
                Βήμα 3
              </Typography>
              <Typography variant="body2MediumR" component="p" color="#313D53">
                Η αγορά σου θα καταγραφεί κανονικά (χωρίς καν να χρειαστεί να μπεις στο site του Pigogo).
              </Typography>
            </Box>
          </Box>
        </CustomContainer>
      </Box>
    </Box>
  );
};

export default StepSection;
