import * as React from 'react';
import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Lottie from 'react-lottie';
import * as loaderAnimData from '../assets/pigogoLoader.json';
import { FC } from 'react';

type Props = {
  open: boolean;
};

const loadingAnimOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimData,
};

const Loading: FC<Props> = ({ open }) => {
  return (
    <Backdrop sx={{ color: '#1D2532', zIndex: (theme) => theme.zIndex.drawer + 9999 }} open={open}>
      <Box width={32} height={32}>
        <Lottie options={loadingAnimOptions} />
      </Box>
    </Backdrop>
  );
};

export default Loading;
