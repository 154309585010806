import { pigogoApi } from '../Api';

const tellAFriendApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    tellAFriend: builder.mutation<void, { email: string; message_content: string }>({
      query: (body) => ({
        method: 'POST',
        url: '/users/actions/send-tell-a-friend/email',
        body: body,
      }),
    }),
  }),
});

export const { useTellAFriendMutation } = tellAFriendApi;
