import React, { FC } from 'react';
import { Box, Divider, Typography } from '@mui/material';

interface Props {
  biggerPadding?: boolean;
}

const PurchaseStatusInfo: FC<Props> = ({ biggerPadding }) => {
  return (
    <Box
      display="flex"
      pl={biggerPadding ? 4 : 3}
      pr={biggerPadding ? '20px' : 3}
      flexDirection="column"
      maxWidth="416px"
    >
      <Box pb={'16.5px'} display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1SmallM" color="#6F7787">
          Σε αναμονή
        </Typography>
        <Typography variant="subtitle1SmallM" component="p" color={'#1D2532'}>
          Η αγορά καταγράφηκε και αναμένεται επιβεβαίωσή της από το κατάστημα. Η ταχύτητα πληρωμής διαφέρει ανά
          κατάστημα.
        </Typography>
      </Box>
      <Divider style={{ border: '1px solid #EAECEE', height: 0 }} />
      <Box pb={'16.5px'} pt={'16.5px'} display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1SmallM" color="#C07848">
          Προς πληρωμή
        </Typography>
        <Typography variant="subtitle1SmallM" component="p" color={'#1D2532'}>
          Το κατάστημα έχει πληρώσει το Pigogo για την αγορά σου και το ποσό επιστροφής μπορεί να πληρωθεί σε σένα από
          το Pigogo.
        </Typography>
      </Box>
      <Divider style={{ border: '1px solid #EAECEE', height: 0 }} />
      <Box pb={'16.5px'} pt={'16.5px'} display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1SmallM" color="#3CA377">
          Πληρωμένη
        </Typography>
        <Typography variant="subtitle1SmallM" component="p" color={'#1D2532'}>
          Το ποσό επιστροφής από την αγορά σου έχει κατατεθεί ήδη σε εσένα από το Pigogo.
        </Typography>
      </Box>
      <Divider style={{ border: '1px solid #EAECEE', height: 0 }} />
      <Box pt={'16.5px'} display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1SmallM" color="#BC415A">
          Ακυρωμένη
        </Typography>
        <Typography variant="subtitle1SmallM" component="p" color={'#1D2532'}>
          Η αγορά ακυρώθηκε από το κατάστημα.
        </Typography>
      </Box>
    </Box>
  );
};

export default PurchaseStatusInfo;
