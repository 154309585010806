import { ElementType, FC } from 'react';
import { Typography } from '@mui/material';
import { ClaimTypeId } from '../models/ClaimTypeId';

interface Props {
  claim?: ClaimTypeId;
  variant: 'caption' | 'h4' | 'subtitle1SmallR' | 'largeB' | 'large900';
  component?: ElementType<any>;
  fontWeight?: number;
  color?: string;
}

const ExtractClaimType: FC<Props> = ({ claim, variant, fontWeight, component, color = '#313D53' }) => {
  switch (claim) {
    case 1:
      return (
        <Typography
          variant={variant}
          component={component ? component : 'p'}
          color={color}
          fontWeight={fontWeight ? fontWeight : undefined}
        >
          Μη καταγραφή αγοράς
        </Typography>
      );
    case 2:
      return (
        <Typography
          variant={variant}
          component={component ? component : 'p'}
          color={color}
          fontWeight={fontWeight ? fontWeight : undefined}
        >
          Λάθος ποσό καταγραφής
        </Typography>
      );
    case 3:
      return (
        <Typography
          variant={variant}
          component={component ? component : 'p'}
          color={color}
          fontWeight={fontWeight ? fontWeight : undefined}
        >
          Ακυρωμένη αγορά
        </Typography>
      );
    default:
      return null;
  }
};

export default ExtractClaimType;
