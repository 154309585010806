import { pigogoApi } from '../Api';
import { User, UserData } from '../../../models/User';

const userApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<
      { access_token: string; refresh_token?: string; resource_created?: boolean; uid?: number },
      { body: User }
    >({
      query: ({ body }) => ({
        url: `/users`,
        method: 'POST',
        body: { ...body },
      }),
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          return;
        }
      },
    }),

    updateUser: builder.mutation<UserData, any>({
      query: (body) => {
        const formBody = [];
        const keys = Object.keys(body);
        for (const key of keys) {
          const value = body[key];
          if (value !== undefined) {
            formBody.push(`${key}=${value}`);
          }
        }

        const formBodyStr = formBody.join('&');
        return {
          url: '/users/self',
          method: 'PATCH',
          body: formBodyStr,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: ['user'],
    }),
    createUserClaim: builder.mutation<UserData, { body: any }>({
      query: ({ body }) => {
        const formBody = [];
        const keys: string[] = Object.keys(body);
        for (const key of keys) {
          const value = body[key];
          if (value !== undefined) {
            formBody.push(`${key}=${value}`);
          }
        }
        const formBodyStr = formBody.join('&');
        return {
          url: '/users/self/claims',
          method: 'POST',
          body: formBodyStr,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
      invalidatesTags: ['user', 'claims'],
    }),
    createUserClickout: builder.mutation<{ url: string }, { shop: string; product_link?: string }>({
      query: ({ shop, product_link }) => ({
        url: '/users/self/clickouts',
        method: 'POST',
        body: { shop, product_link },
      }),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useUpdateUserMutation,
  useCreateUserClaimMutation,
  useCreateUserClickoutMutation,
} = userApi;
