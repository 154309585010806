import React, { FC, useEffect, useState } from 'react';
import {
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonToolbar,
  useIonViewWillLeave,
} from '@ionic/react';
import { Box, Checkbox, FormControlLabel, Grid, Radio, Typography, IconButton } from '@mui/material';
import { PigogoButton } from 'components';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import { PaymentSort } from '../../redux/api/types/enum/PaymentSort';

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  order: 'asc' | 'desc';
  setOrder: (sort: 'asc' | 'desc') => void;
  sort: PaymentSort;
  setSort: (sort: PaymentSort) => void;
  paymentMethod: { label: string; value: 1 | 3 }[];
  setPaymentMethod: (paymentMethod: { label: string; value: 1 | 3 }[]) => void;
}

const paymentMethods: { label: string; value: 1 | 3 }[] = [
  { label: 'Κατάθεση σε τράπεζα', value: 1 },
  { label: 'Viva Wallet', value: 3 },
];

const sortingOptions = [
  { label: 'Λεπτομέρειες πληρωμής', value: PaymentSort.payment_details },
  { label: 'Αριθμός αγορών', value: PaymentSort.purchases_count },
  { label: 'Ποσό επιστροφής', value: PaymentSort.cashback },
  { label: 'Τρόπος πληρωμής', value: PaymentSort.payment_method_id },
  { label: 'Κατάσταση πληρωμής', value: PaymentSort.payment_status_id },
  { label: 'Ημερομηνία πληρωμής', value: PaymentSort.date },
];

const PaymentsFilterModal: FC<Props> = ({
  isOpen,
  setOpen,
  order,
  setOrder,
  sort,
  setSort,
  paymentMethod,
  setPaymentMethod,
}) => {
  const [orderState, setOrderState] = useState<'asc' | 'desc'>('desc');
  const [sortState, setSortState] = useState<PaymentSort>(PaymentSort.date);
  const [paymentMethodState, setPaymentMethodState] = useState<{ label: string; value: 1 | 3 }[]>([]);

  useIonViewWillLeave(() => {
    setOrderState('asc');
    setSortState(PaymentSort.date);
    setPaymentMethodState([]);
  });

  useEffect(() => {
    //setOrderState(order);
    setSortState(sort);
    setPaymentMethodState(paymentMethod);
  }, [order, sort, paymentMethod, isOpen]);

  const handleSaveChanges = () => {
    setOrder(orderState);
    setSort(sortState);
    setPaymentMethod(paymentMethodState);
    setOpen(false);
  };

  const handlePaymentMethodState = (label: string, value: 1 | 3) => {
    const find = paymentMethodState.find((state) => state.value === value);
    if (find) {
      setPaymentMethodState(paymentMethodState.filter((e) => e.label !== label));
    } else {
      const newCategories = [...paymentMethodState, { value: value, label: label }];
      setPaymentMethodState(newCategories);
    }
  };

  const getClassification = () => {
    return (
      <IonGrid style={{ padding: 0 }}>
        <IonRow style={{ marginBottom: 16 }}>
          <Typography variant="body2MediumM" component="p" color={'#313D53'}>
            Ταξινόμηση
          </Typography>
        </IonRow>
        <IonRow style={{ marginBottom: 16 }}>
          <FormControlLabel
            label={'Νεότερη'}
            sx={{
              margin: 0,
              '& .MuiRadio-root': {
                padding: 0,
              },
              '& .MuiTypography-root': {
                fontWeight: 400,
                fontSize: 14,
                marginLeft: '24px',
                display: 'block',
                color: '#313D53',
              },
            }}
            control={
              <Radio color={'secondary'} checked={orderState === 'desc'} onChange={() => setOrderState('desc')} />
            }
          />
        </IonRow>
        <IonRow style={{ marginBottom: 16 }}>
          <FormControlLabel
            label={'Παλαιότερη'}
            sx={{
              margin: 0,
              '& .MuiRadio-root': {
                padding: 0,
              },
              '& .MuiTypography-root': {
                fontWeight: 400,
                fontSize: 14,
                marginLeft: '24px',
                display: 'block',
                color: '#313D53',
              },
            }}
            control={<Radio color={'secondary'} checked={orderState === 'asc'} onChange={() => setOrderState('asc')} />}
          />
        </IonRow>
      </IonGrid>
    );
  };

  const getSortingOptions = () => {
    return (
      <IonGrid>
        <IonRow>
          <Typography variant="body2MediumM">Ταξινόμηση κατά</Typography>
        </IonRow>
        <IonRow>
          <Grid item xs={12} container spacing={1}>
            {sortingOptions.map((option, index) => (
              <Grid item xs={12} key={index}>
                <FormControlLabel
                  label={option.label}
                  sx={{
                    '& .MuiTypography-root': {
                      fontWeight: 400,
                      fontSize: 14,
                    },
                  }}
                  control={
                    <Radio
                      color={'secondary'}
                      checked={sortState === option.value}
                      onChange={() => setSortState(option.value)}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </IonRow>
      </IonGrid>
    );
  };

  const getPaymentMethod = () => {
    return (
      <IonGrid style={{ padding: 0, marginTop: 24 }}>
        <IonRow style={{ marginBottom: 16 }}>
          <Typography variant="body2MediumM" component="p" color={'#313D53'}>
            Τρόπος πληρωμής
          </Typography>
        </IonRow>
        <IonRow style={{ marginBottom: 12 }}>
          <Grid item xs={12} container sx={{ gap: '12px' }}>
            {paymentMethods.map((option, index) => (
              <Grid item xs={12} key={index}>
                <FormControlLabel
                  label={option.label}
                  sx={{
                    margin: '-10px 0 0',
                    gap: '20px',
                    '& .MuiTypography-root': {
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: '1.71',
                      color: '#313D53',
                    },
                  }}
                  control={
                    <Checkbox
                      color={'secondary'}
                      onChange={() => handlePaymentMethodState(option.label, option.value)}
                      checked={paymentMethodState.some((state) => state.label === option.label)}
                      sx={{
                        '&': {
                          padding: 0,
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                          border: '1px solid #838B98',
                          borderRadius: '4px',
                          color: 'transparent',
                          position: 'relative',
                          top: '0',
                        },
                        '&.Mui-checked': {
                          borderColor: '#E9688A',
                          outline: '1px solid #E9688A',
                          outlineOffset: '-2px',
                        },
                      }}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </IonRow>
      </IonGrid>
    );
  };
  return (
    <IonModal mode={'ios'} isOpen={isOpen} canDismiss={true} onDidDismiss={() => setOpen(false)}>
      <IonHeader class="ion-no-border">
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
          <Box display="flex" alignItems="center" justifyContent={'space-between'} pt={4} px={'27px'}>
            <Typography variant="body2" component="h2" color={'#313D53'}>
              Φίλτρα & Ταξινόμηση
            </Typography>
            <IconButton
              disableRipple
              sx={{
                position: 'absolute',
                right: 24,
                top: 36,
                padding: 0,
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => setOpen(false)}
            >
              <Close />
            </IconButton>
          </Box>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid style={{ padding: 0 }}>
          <Grid container style={{ paddingInline: 32 }}>
            <Grid item xs={12} container style={{ paddingTop: 24 }}>
              {getClassification()}
            </Grid>
            {/* <Grid container item xs={12} justifyContent={'center'}>
              {getSortingOptions()}
            </Grid> */}
            <Grid container item xs={12} justifyContent={'center'}>
              {getPaymentMethod()}
            </Grid>
          </Grid>
        </IonGrid>
      </IonContent>
      <IonFooter style={{ padding: 16, boxSizing: 'border-box', display: 'inline-flex' }}>
        <Grid container alignItems={'center'} justifyContent={'center'} style={{ paddingBottom: '16px' }}>
          <Grid item xs={12} p={1}>
            <PigogoButton
              fullWidth
              text={`Επιλογή`}
              sx={{ backgroundColor: '#1D2532' }}
              onClick={() => handleSaveChanges()}
            />
          </Grid>
        </Grid>
      </IonFooter>
    </IonModal>
  );
};

export default PaymentsFilterModal;
