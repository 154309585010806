import { Skeleton, styled, TableCell, TableRow, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';
import { theme } from 'components';
import { Payment } from '../../../models/Payment';
import ExtractPaymentMethod from '../../ExtractPaymentMethod';

interface Props {
  row?: Payment;
  isLoading?: boolean;
}

const StyledTableCell = styled(TableCell)(() => ({
  padding: '16px 24px',
  height: '72px',
}));

const PaymentsDesktopRow: FC<Props> = ({ row, isLoading }) => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableRow
      hover
      sx={{
        overflow: 'hidden',
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        '&:last-child td:first-of-type': {
          borderBottomLeftRadius: '24px',
        },
        '&:last-child td:last-child': {
          borderBottomRightRadius: '24px',
        },
      }}
    >
      <StyledTableCell>
        <Typography variant="caption" color={'#313D53'}>
          {isLoading ? <Skeleton width={80} sx={{ height: '20px' }} /> : moment(row?.date).format('DD/MM/YYYY')}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2MediumM" color={'#313D53'}>
          {isLoading ? <Skeleton width={60} sx={{ height: '20px' }} /> : row?.amount}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        {isLoading ? (
          <Skeleton width={80} sx={{ height: '20px' }} />
        ) : (
          <ExtractPaymentMethod saver={row?.payment_method_id} mobile={mobile} />
        )}
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2MediumM" color={'#313D53'}>
          {isLoading ? <Skeleton width={80} sx={{ height: '20px' }} /> : row?.payment_account}
        </Typography>
      </StyledTableCell>
    </TableRow>
  );
};

export default PaymentsDesktopRow;
