import React, { FC } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { Claim } from '../../../models/Claims';
import ClaimsMobileRow from './ClaimsMobileRow';

interface Props {
  claims?: Claim[];
  isLoading?: boolean;
  onRowClick?: (claim: Claim) => void;
}

const ClaimsTableMobile: FC<Props> = ({ claims, isLoading, onRowClick }) => {
  return (
    <TableContainer>
      <Table aria-label="claims table">
        <TableBody>
          {isLoading && [1, 1, 1, 1].map((item, index) => <ClaimsMobileRow key={index} isLoading />)}
          {!isLoading && claims?.map((row, index) => <ClaimsMobileRow key={index} row={row} onRowClick={onRowClick} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClaimsTableMobile;
