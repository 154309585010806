import { FC } from 'react';
import { Typography } from '@mui/material';
import { PaymentMethodId } from '../models/PaymentMethodId';

interface Props {
  saver?: PaymentMethodId;
  mobile: boolean;
}

const ExtractPaymentMethod: FC<Props> = ({ saver, mobile }) => {
  switch (saver) {
    case 1:
      return (
        <Typography variant={mobile ? 'subtitle1SmallR' : 'caption'} component="p" color="#6F7787">
          Κατάθεση σε τράπεζα
        </Typography>
      );
    case 3:
      return (
        <Typography variant={mobile ? 'subtitle1SmallR' : 'caption'} component="p" color="#6F7787">
          Viva Wallet
        </Typography>
      );
    default:
      return null;
  }
};

export default ExtractPaymentMethod;
