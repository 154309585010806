declare const window: Window & { dataLayer: any[] };

interface DataLayerObject {
  event: string;
  eventData?: object;
}

const pushToDataLayer = (obj: DataLayerObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export const createGenericEvent = (event: string, data?: object) => {
  const transformedData = data ?? ({} as any);
  transformedData['event'] = event;

  pushToDataLayer(transformedData);
};
