import { Skeleton, styled, Switch, SwitchProps } from '@mui/material';
import React, { FC } from 'react';

const SwitchCustomized = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.success,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 28 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface Props {
  loading?: boolean;
  value?: boolean;
  setValue?: (value: any) => void;
  inputProps?: SwitchProps;
  disabled?: boolean;
}

const PigogoSwitch: FC<Props> = ({ value, setValue, inputProps, disabled, loading }) => {
  return loading ? (
    <Skeleton variant="rectangular" width="50px" height="26px" sx={{ borderRadius: '14px' }} />
  ) : (
    <SwitchCustomized {...inputProps} checked={value} disabled={disabled} onChange={setValue} />
  );
};

export default PigogoSwitch;
