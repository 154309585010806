import React, { FC, ReactNode } from 'react';
import { Box, IconButton, IconButtonProps, styled } from '@mui/material';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 100,
  border: '4px solid #FDF0F3',
  background: theme.palette.primary.main,
  boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
  '&:hover': {
    filter: 'brightness(85%)',
  },
  '&:active': {
    filter: 'brightness(85%)',
  },
  '&:focus': {
    boxShadow: '0 0 0 3px rgba(60, 104, 200, 0.5)',
  },
  '&:disabled': {
    background: 'var(--disabled)',
  },
}));

interface Props {
  icon: ReactNode;
  textIcon: ReactNode;
  props?: IconButtonProps;
}

const PigogoDownloadButton: FC<Props> = ({ icon, textIcon, props }) => {
  return (
    <CustomIconButton {...props} disableRipple>
      <Box display="flex" px={1} gap={1} alignItems="center">
        {icon}
        {textIcon}
      </Box>
    </CustomIconButton>
  );
};

export default PigogoDownloadButton;
