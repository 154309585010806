import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';

const StyleBox = styled(Box)(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  width: '330px',
  height: '372px',
  borderRadius: '16px',
  border: '2px solid #F7BA97',
  boxSizing: 'border-box',
  textAlign: 'center',
  gap: theme.spacing(3),
}));

type Props = {
  icon: string;
  title: string;
  description: string;
};

const ContentCard: FC<Props> = ({ icon, title, description }) => {
  return (
    <StyleBox>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box width={128} height={128}>
          <img src={icon} alt={title} />
        </Box>
        <Box>
          <Typography variant="h4" color={'#1D2532'}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="largeL" component="p" color={'#313D53'}>
          {description}
        </Typography>
      </Box>
    </StyleBox>
  );
};

export default ContentCard;
