import React, { FC } from 'react';
import { styled, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { ReactComponent as Clock } from '../assets/svgs/DatePicker.svg';
import { ReactComponent as ClockRed } from '../assets/svgs/datePickerRed.svg';

/*
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontWeight: 500,
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#313D53',
  },
  '& .MuiInputLabel-root': {
    left: '10px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '56px',
    padding: '16px 24px',

    '&.Mui-focused fieldset': {
      border: '1px solid #313D53',
      padding: '16px 24px',
    },
    fieldset: {
      padding: '16px 24px',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '0',
  },
  '& .MuiFormHelperText-root': {
    marginTop: '8px !important',
  },
}));
*/

interface Props {
  value: Date | null;
  handleChange: (value: Date | null) => void;
  label: string;
  width?: string;
  placeholder?: string;
  error?: boolean;
}

const TimePicker: FC<Props> = ({ value, handleChange, label, width, placeholder, error = false }) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{
        fieldHoursPlaceholder: () => 'ΩΩ',
        fieldMinutesPlaceholder: () => 'ΛΛ',
      }}
    >
      <DesktopTimePicker
        slots={{
          openPickerIcon: error ? ClockRed : Clock,
        }}
        slotProps={{
          textField: {
            error: error,
            helperText: error && 'Το πεδίο είναι υποχρεωτικό.',
            sx: {
              '& .MuiInputBase-root': {
                color: '#1D2532',
                fontSize: '14px',
                fontWeight: 500,
              },
              '& .MuiFormLabel-root': {
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '24px',
                color: error ? '#F75676' : '#313D53',
              },
              '& .MuiInputLabel-root': {
                left: '6px',
                paddingInline: '4px',
                background: '#ffffff',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '56px',
                padding: '16px 24px',
                height: '56px',
                '&.Mui-focused fieldset': {
                  border: error ? '1px solid #F75676' : '1px solid #313D53',
                  padding: '16px 24px',
                },
                fieldset: {
                  padding: '16px 24px',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: '0',
              },
              '& .MuiFormHelperText-root': {
                marginTop: '8px !important',
                marginLeft: '24px',
              },
              '& fieldset legend': {
                width: 0,
              },
            },
          },
        }}
        ampm={true}
        label={label}
        value={value}
        format="HH:mm"
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
};

export default TimePicker;
