import React, { FC, useState } from 'react';
import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { Purchase } from '../../../models/Purchase';
import PurchasesDesktopRow from './PurchasesDesktopRow';
import PurchaseStatusModal from '../PurchaseStatusInfoModal';
import { PurchaseSort } from '../../../redux/api/types/enum/PurchaseSort';
import { ReactComponent as ChevronDown } from '../../../assets/svgs/chevron_down_light.svg';
import { ReactComponent as Info } from '../../../assets/svgs/info_light.svg';

const headCells = [
  { label: 'Ημερομηνία', id: PurchaseSort.purchase_date },
  { label: 'Κατάστημα', id: PurchaseSort.shop_name },
  { label: 'Ποσό', id: PurchaseSort.purchase_amount },
  { label: 'Επιστροφή', id: PurchaseSort.cashback_amount },
];

const StyledTableCell = styled(TableCell)(() => ({
  padding: '6.5px 16px 7px 24px',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '1',
  color: '#6F7787',
  height: '44px',
}));

interface Props {
  purchases?: Purchase[];
  isLoading: boolean;
  order: 'asc' | 'desc';
  setOrder: (order: 'asc' | 'desc') => void;
  orderBy: PurchaseSort;
  setOrderBy: (orderBy: PurchaseSort) => void;
  onRowClick: (purchase: Purchase) => void;
}

const PurchasesTableDesktop: FC<Props> = ({
  purchases,
  order,
  isLoading,
  setOrder,
  orderBy,
  setOrderBy,
  onRowClick,
}) => {
  const [infoModal, setInfoModal] = useState<boolean>(false);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: PurchaseSort) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: PurchaseSort) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="purchases table">
          <TableHead sx={{ backgroundColor: '#F7F7F8' }}>
            <TableRow>
              {headCells.map((headCell, index: number) => (
                <StyledTableCell
                  size="small"
                  align="left"
                  padding={index !== 0 ? 'none' : undefined}
                  key={index}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    sx={{ '& .MuiTableSortLabel-icon': { width: '24px', marginRight: 0 } }}
                    IconComponent={ChevronDown}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Typography variant="captionSB" color="#6F7787">
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell size="small">
                <Box display="flex" alignItems="center">
                  <Typography variant="captionSB" color="#6F7787">
                    Κατάσταση
                  </Typography>
                  <IconButton size="small" disableRipple onClick={() => setInfoModal(true)}>
                    <Info />
                  </IconButton>
                </Box>
              </StyledTableCell>
              <TableCell sx={{ padding: '0 24px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              [...(purchases as any), 1, 1, 1, 1]?.map((row, index) => (
                <PurchasesDesktopRow key={index} row={row || undefined} isLoading={row === 1} />
              ))}
            {!isLoading &&
              purchases?.map((row, index) => <PurchasesDesktopRow key={index} row={row} onRowClick={onRowClick} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <PurchaseStatusModal isOpen={infoModal} setOpen={setInfoModal} />
    </>
  );
};

export default PurchasesTableDesktop;
