import { IonHeader, IonToolbar } from '@ionic/react';
import React, { FC } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useAppSelector } from '../redux/hooks';
import { useDispatch } from 'react-redux';
import { ReactComponent as PigogoLogo } from '../assets/PigogoLogo.svg';
import { theme } from 'components';
import { isIos } from '../utils/device';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const CustomHeader = () => {
  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const history = useHistory();

  return tablet && !(!!accessToken || sessionLoggedIn) && history.location.pathname !== '/intro' ? (
    <IonHeader mode={isIos() ? 'ios' : undefined} class="ion-no-border">
      <IonToolbar
        style={{
          '--padding-bottom': 0,
          '--padding-start': 0,
          '--padding-end': 0,
          '--min-height': 0,
          '--border-width': 0,
          '--border-style': 0,
          '--background': '#ffff',
          paddingLeft: 0,
          paddingTop: '0 + --ion-safe-area-top',
        }}
      >
        <Link
          to={'/'}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
            borderBottom: '1px solid #DADCE0',
          }}
        >
          <PigogoLogo />
        </Link>
      </IonToolbar>
    </IonHeader>
  ) : null;
};

export default CustomHeader;
