import React, { FC, useState } from 'react';
import { styled, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ReactComponent as Calendar } from '../assets/svgs/calendar.svg';
import { ReactComponent as CalendarRed } from '../assets/svgs/calendarRed.svg';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { DateValidationError } from '@mui/x-date-pickers';
import moment from 'moment';

/*
const StyledTextField = styled(TextField)(({ theme, error }) => ({
  '& .MuiInputBase-root': {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontWeight: 500,
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#313D53',
  },
  '& .MuiInputLabel-root': {
    left: '10px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '56px',
    borderColor: !error ? theme.palette.primary.dark : '#F75676',
    padding: '16px 24px',

    '&.Mui-focused fieldset': {
      border: error ? '1px solid #F75676' : '1px solid #313D53',
      padding: '16px 24px',
    },
    fieldset: {
      padding: '16px 24px',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '0',
  },
  '& .MuiFormHelperText-root': {
    marginTop: '8px !important',
  },
}));
*/

interface Props {
  value: Date | null;
  handleChange: (value: Date | null) => void;
  label: string;
  width?: string;
  placeholder?: string;
  error?: boolean;
  validateFutureDate?: boolean;
  overSixMonths?: boolean;
}

const DatePicker: FC<Props> = ({
  value,
  handleChange,
  label,
  width,
  placeholder,
  error = false,
  validateFutureDate = false,
  overSixMonths = false,
}) => {
  const [internalError, setInternalError] = useState<boolean>(false);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{
        fieldYearPlaceholder: (params) => {
          return 'ΕEEE';
        },
        fieldMonthPlaceholder: (params) => {
          return 'MM';
        },
        fieldDayPlaceholder: () => {
          return 'HH';
        },
      }}
    >
      <DesktopDatePicker
        slots={{
          openPickerIcon: internalError || error || overSixMonths ? CalendarRed : Calendar,
        }}
        slotProps={{
          textField: {
            error: internalError || error || overSixMonths,
            helperText:
              (internalError && 'Η ημερομηνία δεν είναι σωστή.') ||
              (error && !overSixMonths && 'Το πεδίο είναι υποχρεωτικό.') ||
              (overSixMonths && 'Συμπλήρωσε πιο πρόσφατη ημερομηνία.'),
            placeholder: placeholder,
            sx: {
              '& .MuiInputBase-root': {
                color: overSixMonths ? '#F75676' : '#1D2532',
                fontSize: '14px',
                fontWeight: 500,
              },
              '& .MuiFormLabel-root': {
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '24px',
                color: internalError || error || overSixMonths ? '#F75676' : '#313D53',
              },
              '& .MuiInputLabel-root': {
                left: '6px',
                paddingInline: '4px',
                background: '#ffffff',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '56px',
                borderColor: !(internalError || error || overSixMonths) ? '#1D2532' : '#F75676',
                padding: '16px 24px',
                height: '56px',
                '&.Mui-focused fieldset': {
                  border: internalError || error || overSixMonths ? '1px solid #F75676' : '1px solid #313D53',
                  padding: '16px 24px',
                },
                fieldset: {
                  padding: '16px 24px',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: '0',
              },
              '& .MuiFormHelperText-root': {
                marginTop: '8px !important',
                marginLeft: '24px',
              },
              '& fieldset legend': {
                width: 0,
              },
            },
          },
        }}
        label={label}
        format="DD/MM/YYYY"
        value={value}
        onChange={(date) => {
          handleChange(date);
          setInternalError(false);
        }}
        onError={(newError) => {
          if (newError === 'disableFuture') {
            setInternalError(true);
          } else if (newError === 'invalidDate') {
            setInternalError(true);
          }
        }}
        disableFuture={validateFutureDate}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
