import { Box, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';
import { ReactComponent as ChevronRight } from '../../../assets/svgs/chevronRight.svg';
import { Purchase } from '../../../models/Purchase';
import ExtractPurchaseStatus from '../../ExtractPurchasesStatus';

interface Props {
  row?: Purchase;
  isLoading?: boolean;
  onRowClick?: (purchase: Purchase) => void;
}

const PurchasesMobileRow: FC<Props> = ({ row, isLoading, onRowClick }) => {
  return (
    <TableRow
      hover
      onClick={row && onRowClick ? () => onRowClick(row) : undefined}
      sx={{
        height: '76px',
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }}
    >
      <TableCell sx={{ paddingLeft: 0 }}>
        <Box>
          <Typography variant="body2" fontWeight={700} color={'#313D53'} lineHeight="24px" component="p">
            {isLoading ? <Skeleton /> : row?.shop_name}
          </Typography>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Typography variant="subtitle1SmallR" fontWeight={400} color={'#6F7787'} lineHeight="24px" component="p">
              {moment(row?.date).format('DD/MM/YYYY')}
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ paddingLeft: 0, paddingRight: 1 }} align="right">
        <Box>
          <Typography variant="body2" fontWeight={700} color={'#313D53'} lineHeight="24px" component="p">
            {isLoading ? <Skeleton /> : row?.cashback_amount}
          </Typography>
          {isLoading ? <Skeleton /> : <ExtractPurchaseStatus saver={row?.purchase_status_id || 0} />}
        </Box>
      </TableCell>
      {!isLoading && (
        <TableCell
          sx={{ padding: 0, width: '24px', textAlign: 'right', verticalAlign: 'top', paddingBlock: '18px 16px' }}
        >
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <ChevronRight />
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

export default PurchasesMobileRow;
