import React, { FC } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { PigogoButton, theme } from 'components';
import MoneyReturnIcon from '../../../assets/png/AlertModal.png';
import { ReactComponent as StarFilled } from '../../../assets/svgs/stars/fillColor/starFilled.svg';
import { ReactComponent as HalfStar } from '../../../assets/svgs/stars/fillColor/halfStar.svg';
import { ReactComponent as Chrome } from '../../../assets/svgs/searchApp/chromeWhite.svg';
import { ReactComponent as Firefox } from '../../../assets/svgs/searchApp/firefoxWhite.svg';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';

interface Props {
  open: boolean;
  handleClose: () => void;
  browser?: 'Chrome' | 'Firefox' | 'other';
}

const AlertModal: FC<Props> = ({ open, handleClose, browser }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <IonModal
      mode={'ios'}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => handleClose()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '12px',
            height: '48px',
            boxSizing: 'border-box',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: smDown ? '0' : '16px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={2}>
            <img src={MoneyReturnIcon} alt="" />
            <Box display={'flex'} flexDirection={'column'} gap={0.5} alignItems={'center'}>
              <Typography variant="h4" component="h2" textAlign="center" color={'#313D53'}>
                Επιστροφή χρημάτων με 1 κλικ;
              </Typography>
              <Typography variant="body2MediumR" component="p" textAlign="center" color={'#313D53'}>
                Το browser extension που θα λατρέψεις. Δοκίμασέ το! Εγκατέστησέ το στον browser του υπολογιστή σου.
              </Typography>
            </Box>

            {/* <Box display={'flex'} flexDirection={'column'} gap={0.5} alignItems={'center'}>
              <Box alignItems="center" pt={4} display="flex" color={'#E9688A'}>
                <StarFilled />
                <StarFilled />
                <StarFilled />
                <StarFilled />
                <HalfStar />
              </Box>
              <Typography variant="captionM" component="p" color={'#313D53'}>
                το έχουν κατεβάσει 5.000+ χρήστες
              </Typography>
            </Box> */}

            {browser !== 'other' ? (
              <PigogoButton
                fullWidth
                variation="secondary"
                text={`Εγκατάσταση στον ${browser}`}
                onClick={() => {
                  if (browser === 'Chrome') {
                    window.open(
                      'https://chrome.google.com/webstore/detail/pigogo-alert/adhfocpeiaaklpfbaipnjehejfbghjeg?hl=el',
                      '_blank',
                    );
                  } else {
                    window.open('https://addons.mozilla.org/el/firefox/addon/pigogo-alert/', '_blank');
                  }

                  handleClose();
                }}
                startIcon={browser === 'Chrome' ? <Chrome /> : <Firefox />}
              />
            ) : (
              <Box display={'flex'} flexDirection={mdDown ? 'column' : 'row'} gap={2} width={'100%'}>
                <PigogoButton
                  fullWidth
                  startIcon={<Chrome />}
                  text={`Google Chrome`}
                  variation="secondary"
                  onClick={() => {
                    window.open(
                      'https://chrome.google.com/webstore/detail/pigogo-alert/adhfocpeiaaklpfbaipnjehejfbghjeg?hl=el',
                      '_blank',
                    );
                    handleClose();
                  }}
                />
                <PigogoButton
                  fullWidth
                  variation="secondary"
                  startIcon={<Firefox />}
                  text={`Mozilla Firefox`}
                  onClick={() => {
                    window.open('https://addons.mozilla.org/el/firefox/addon/pigogo-alert/', '_blank');
                    handleClose();
                  }}
                />
              </Box>
            )}
            {browser !== 'other' ? (
              <Typography
                color="primary"
                onClick={handleClose}
                sx={{
                  textDecoration: 'underline',
                  textDecorationColor: 'primary',
                  textUnderlineOffset: '5px',
                  cursor: 'pointer',
                }}
                variant="buttonSmallM"
              >
                Θα το κάνω αργότερα
              </Typography>
            ) : (
              <Typography variant="captionM" component="p" color={'#6F7787'}>
                To Pigogo ALERT δεν υποστηρίζεται από τον browser που χρησιμοποιείς
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default AlertModal;
