import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Layout } from '../types/Layout';

const initialState: Layout = {
  data: {
    messageSnackbar: '',
    severity: 'error',
  },
  meta: {
    loading: false,
    error: false,
  },
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    setLoad: (state, action: PayloadAction<boolean>) => {
      state.meta.loading = action.payload;
    },
    setSnackBar: (state, action: PayloadAction<{ value: boolean; msg: string; severity?: any }>) => {
      state.data.messageSnackbar = action.payload.msg;
      state.meta.error = action.payload.value;
      if (action.payload.severity) {
        state.data.severity = action.payload.severity;
      }
    },
    setStateSnackBar: (state, action: PayloadAction<boolean>) => {
      state.meta.error = action.payload;
    },
  },
});

export const { setLoad, setSnackBar, setStateSnackBar } = layoutSlice.actions;
export default layoutSlice.reducer;
