import React, { FC } from 'react';
import Results from '../Navigation/Header/Search/Results';
import LinkSearchMobile from './LinkSearchMobile';
import NoResultsMobile from './NoResultsMobile';
import PreSearchMobile from './PreSearchMobile';
import { AllShop } from '../../models/AllShop';
import { GenericRequest } from '../../models/GenericRequest';
import { Box, Button, Divider } from '@mui/material';
import { ReactComponent as LinkVertical } from '../../assets/svgs/link_Vertical_blue.svg';
import Recommended from '../Navigation/Header/Search/Recomended';
import Suggested from '../Navigation/Header/Search/Suggested';

interface Props {
  search: string;
  link: boolean;
  setLink: (link: boolean) => void;
  searchResults: GenericRequest<AllShop[]> | undefined;
  searchResultsLoading: boolean;
  searchFocused?: boolean;
  redirectModal?: boolean;
  image?: any;
  name?: string;
  setRedirectModal?: (val: boolean) => void;
  setImage?: (val: any) => void;
  setName?: (val: string) => void;
}

const SearchContentMobile: FC<Props> = ({
  link,
  setLink,
  search,
  searchResults,
  searchResultsLoading,
  searchFocused,
  redirectModal,
  image,
  name,
  setRedirectModal,
  setImage,
  setName,
}) => {
  if (link) {
    return (
      <LinkSearchMobile
        link={link}
        setLink={setLink}
        redirectModal={redirectModal}
        image={image}
        name={name}
        setRedirectModal={setRedirectModal}
        setImage={setImage}
        setName={setName}
      />
    );
  } else if (search === '' && !searchFocused) {
    return <PreSearchMobile />;
  } else if (search === '' && searchFocused) {
    return (
      <Box
        className={'focus-on-search'}
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        sx={{ height: '100%', overflowY: 'auto' }}
      >
        <Recommended />
        <Suggested />
      </Box>
    );
  } else if ((search !== '' && searchResults?.data.length !== 0) || searchResultsLoading) {
    return (
      <Box className={'scrollContainerY'} display={'flex'} flexDirection={'column'} gap={2}>
        <style>
          {`
         .scrollContainerY {
          overflow-y: auto;
          -ms-overflow-style: none !important;
          scrollbar-width: none !important;
          max-height: 99%;
         }
          .scrollContainer::-webkit-scrollbar {
            display: none !important;
          }
          `}
        </style>
        <Results searchId={search} searchResults={searchResults} searchResultsLoading={searchResultsLoading} />

        <Box width={'95%'}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="column" gap={1} justifyContent={'start'}>
          <Box>
            <Button
              size="small"
              startIcon={<LinkVertical />}
              onClick={() => setLink(true)}
              sx={{ color: '#3C68C8', fontSize: '14px', fontWeight: 400 }}
            >
              Αναζήτηση με link προιόντος
            </Button>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return <NoResultsMobile />;
  }
};

export default SearchContentMobile;
