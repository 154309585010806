import React, { FC } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PigogoCustomDot, PigogoIconButton, theme } from 'components';
import { StepThree, StepTwo, StepOne } from './WaltkthroughModalContent';
import { Box, Container, IconButton } from '@mui/material';
import { ReactComponent as ChevronLeft } from '../../../assets/svgs/chevronLeft.svg';
import { ReactComponent as ChevronRight } from '../../../assets/svgs/chevronRight.svg';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1 },
    items: 1,
    slidesToSlide: 1,
  },
};

interface Props {
  open: boolean;
  handleClose: () => void;
}

const ButtonGroup = ({ next, previous, ...rest }: any) => {
  return (
    <Box position="relative" width="100%">
      {rest.carouselState.currentSlide !== 0 && (
        <PigogoIconButton
          props={{
            sx: {
              left: 0,
              top: -300,
              width: '40px',
              height: '40px',
              position: 'absolute',
              padding: 0,
              background: 'transparent',
              color: '#313D53',
            },
          }}
          icon={<ChevronLeft />}
          handleClick={() => previous()}
        />
      )}
      {rest.carouselState.currentSlide !== 2 && (
        <PigogoIconButton
          props={{
            sx: {
              right: 0,
              top: -300,
              width: '40px',
              height: '40px',
              position: 'absolute',
              background: 'transparent',
              padding: 0,
              color: '#313D53',
            },
          }}
          icon={<ChevronRight />}
          handleClick={() => next()}
        />
      )}
    </Box>
  );
};

const WalkthroughModal: FC<Props> = ({ open, handleClose }) => {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <IonModal
      mode={'ios'}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => handleClose()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '12px',
            height: '48px',
            boxSizing: 'border-box',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: '0',
          }}
        >
          <Box
            sx={{
              padding: '0px 0px 32px 0px',
              alignItems: matches ? 'flex-start' : 'center',
              height: matches ? '100%' : 'initial',
            }}
            display="flex"
            justifyContent="center"
          >
            <Container maxWidth="lg" sx={{ paddingInline: matches ? 0 : 2, height: matches ? '100%' : 'initial' }}>
              <Box padding="1px" position="relative" sx={{ height: matches ? '100%' : 'initial' }}>
                <style>
                  {`
                  @media only screen and (max-width: 600px) {
                    .walkthroughtCarousel ~ .react-multi-carousel-dot-list{
                      bottom: 24px;
                    }
                  }
                  `}
                </style>
                <Carousel
                  showDots
                  ssr={false}
                  arrows={false}
                  swipeable={true}
                  draggable={true}
                  autoPlay={false}
                  keyBoardControl={true}
                  responsive={responsive}
                  renderDotsOutside={true}
                  transitionDuration={500}
                  shouldResetAutoplay={false}
                  renderButtonGroupOutside={true}
                  customDot={<PigogoCustomDot />}
                  containerClass="carousel-container walkthroughtCarousel"
                  partialVisible={matchesMd || matches}
                  customButtonGroup={matches ? undefined : <ButtonGroup sx={{ bottom: '56px' }} />}
                >
                  <StepOne />
                  <StepTwo />
                  <StepThree />
                </Carousel>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default WalkthroughModal;
