import React from 'react';
import { Box, Typography, Button, useMediaQuery, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { theme } from 'components';

const PaymentsFillPaymentMethodsBox = () => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const padding = smDown ? '16px 10px' : '32px 80px';

  return (
    <Box display="flex" flexDirection="column" gap={1} alignItems="center" p={padding}>
      {smDown && <Divider sx={{ width: '100%', mb: 2 }} />}
      <Typography variant="subtitle1" color="#313D53">
        Συμπλήρωσες τα στοιχεία πληρωμής σου;
      </Typography>
      <Box pb={2}>
        <Typography textAlign="center" variant="subtitle1SmallR" color="#313D53" component="p">
          Δες τους διαθέσιμους τρόπους πληρωμής και συμπλήρωσε τα στοιχεία σου για να πληρωθείς!
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" pt={smDown ? 0 : 2} style={{ width: '100%' }}>
        <Link
          to="/user/payments-options"
          className="pigogoButton pigogoButton--outlined"
          style={{ width: '100%', maxWidth: '327px' }}
        >
          Στοιχεία πληρωμής
        </Link>
      </Box>
      {smDown && (
        <Box py={3}>
          <Button component="a" href="/faq">
            <Typography variant="buttonSmallM" fontWeight={500} sx={{ textDecoration: 'underline' }}>
              Διάβασε τις Συχνές Ερωτήσεις
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PaymentsFillPaymentMethodsBox;
