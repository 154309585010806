export const palette = {
  orange: {
    light: '#FEF6F1',
    main: '#FCE5D8',
    dark: ' #F7BA97',
    contrastText: '#313D53',
  },
  blue: {
    light: '#F5F7F9',
    main: '#8B9DBB',
    dark: '#5E77A1',
    contrastText: '#fff',
  },
  primary: {
    light: '#2C374B',
    main: '#313D53',
    dark: '#1D2532',
    contrastText: '#EAECEE',
  },
  secondary: {
    light: '#D25E7C',
    main: '#E9688A',
    dark: '#8C3E53',
    contrastText: '#FDF0F3',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.81)',
    //Below is the accent color
    disabled: '#8B8B8B',
  },
  error: {
    light: '#FDCCD6',
    main: '#F75676',
    dark: '#BC415A',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#FED8CF',
    main: '#FC7C5F',
    dark: '#C05E48',
    contrastText: '#FFFFFF',
  },
  info: {
    light: '#C3F2FB',
    main: '#37D5F2',
    dark: '#2AA2B8',
  },
  success: {
    light: '#CAF3E1',
    main: '#4FD69C',
    dark: '#3CA377',
  },
  background: {
    default: '#F5F7F9',
  },
};
