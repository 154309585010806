import React, { ReactNode } from 'react';
import { Box, Snackbar, styled } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const AlertStyled = styled(MuiAlert)(() => ({
  color: 'var(--text)',
  borderRadius: '16px',
  maxWidth: '340px !important',
  fontSize: '16px',
  fontWeight: '500',
  background: '#FFFFFF',
}));

interface Props {
  open: boolean;
  setOpen?: (open: boolean) => void;
  upperContent: ReactNode;
  lowerContent: ReactNode;
}

const PigogoStickyMessage: React.FC<Props> = ({ open, setOpen, upperContent, lowerContent }) => {
  const handleClose = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <AlertStyled icon={false} elevation={2}>
        <Box p={3} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={3}>
          {upperContent}
          {lowerContent}
        </Box>
      </AlertStyled>
    </Snackbar>
  );
};

export default PigogoStickyMessage;
