import { Box, Skeleton, styled, Typography, useMediaQuery } from '@mui/material';
import React, { FC, useState } from 'react';
import { useIonViewDidEnter } from '@ionic/react';
import RichTooltip from './RichTooltip';
import { HeartBig, HeartBlack } from '../assets';
import PigogoButton from './PigogoButton';
import PigogoDiscount from './PigogoDiscount';
import { theme } from '../index';
import { getPlatforms } from '@ionic/react';

const StyleBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  border: '4px solid #FCE5D8',
  borderRadius: '24px',
  padding: 24,
  background: '#fff',
  height: '352px',
  width: '100%',
}));

const StyleImgBox = styled(Box)(() => ({
  position: 'relative',
  height: 64,
  width: 128,
}));

interface Props {
  loading?: boolean;
  logout?: boolean;
  cashbackCount?: boolean;
  logo?: string;
  discount?: string;
  deleteDisc?: string;
  favourite?: boolean;
  setFavourite: () => void;
  handleClick: () => void;
  url?: string;
  loggedIn?: boolean;
}

const PigogoStoreCard: FC<Props> = ({
  loading,
  logout,
  cashbackCount,
  logo,
  discount,
  deleteDisc,
  favourite,
  setFavourite,
  handleClick,
  url,
  loggedIn = true,
}) => {
  const [open, setOpen] = useState(false);

  const [favTooltipState, setFavTooltipState] = useState<boolean>(!!favourite);

  const mouseOver = () => {
    isOnlyDesktop() && setOpen((previousOpen) => !previousOpen);
  };

  const isWeb = () => {
    const platforms = getPlatforms();

    const isDesktop = platforms.includes('desktop');
    const isCapacitor = platforms.includes('capacitor');
    const isMobileWeb = platforms.includes('mobileweb');

    return (isDesktop || isMobileWeb) && !isCapacitor;
  };

  const isOnlyDesktop = () => {
    const platforms = getPlatforms();

    const isDesktop = platforms.includes('desktop');
    const isCapacitor = platforms.includes('capacitor');
    const isMobileWeb = platforms.includes('mobileweb');

    return isDesktop && !isMobileWeb && !isCapacitor;
  };

  const mouseOut = () => {
    setOpen(false);
    setTimeout(() => {
      setFavTooltipState(!!favourite);
    }, 300);
  };

  const favouriteFunc = () => {
    setFavourite();
    return;
  };

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyleBox onMouseOut={mouseOut}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <StyleImgBox>
          {loading ? (
            <Skeleton
              animation="wave"
              style={{ background: '#EAECEE', borderRadius: 16, width: '100%', height: '100px' }}
            />
          ) : (
            <img alt={'logo'} src={logo} style={{ borderRadius: 8, width: '100%', height: '100%' }} />
          )}
        </StyleImgBox>

        <Box onClick={favouriteFunc} onMouseOver={mouseOver} sx={{ cursor: 'pointer' }}>
          {loading && (
            <Skeleton
              animation="wave"
              sx={{
                background: '#EAECEE',
                borderRadius: '50%',
                width: mdDown ? '40px' : '48px',
                height: mdDown ? '40px' : '48px',
              }}
            />
          )}
          {!loading && (
            <RichTooltip
              content={
                <Typography variant="caption" component="p">
                  {logout
                    ? 'Κάνε εγγραφή για να μπορείς να προσθέτεις στα αγαπημένα'
                    : !favTooltipState
                    ? 'Προσθήκη στα αγαπημένα σου καταστήματα'
                    : 'Αφαίρεση από τα αγαπημένα σου καταστήματα'}
                </Typography>
              }
              open={open}
              placement="top"
              color="dark"
              onClose={() => setOpen(false)}
            >
              <Box
                sx={{
                  width: mdDown ? '40px' : '48px',
                  height: mdDown ? '40px' : '48px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                {favourite ? <HeartBlack /> : <HeartBig />}
              </Box>
            </RichTooltip>
          )}
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <Typography variant={'h3'} style={{ color: '#E9688A' }}>
              {loading ? (
                <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px', width: '124px' }} />
              ) : cashbackCount ? (
                `έως ${discount}`
              ) : (
                `${discount}`
              )}
            </Typography>
            {deleteDisc && !loading && (
              <Box>
                <PigogoDiscount discount={deleteDisc} />
              </Box>
            )}
          </Box>

          <Box>
            <Typography variant={'buttonSmallM'} style={{ color: '#E9688A' }}>
              {loading ? (
                <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} />
              ) : (
                'επιστροφή χρημάτων'
              )}
            </Typography>
          </Box>
        </Box>
        <Box>
          {loading && (
            <Skeleton
              animation="wave"
              style={{ background: '#EAECEE', borderRadius: '16px' }}
              width={'100%'}
              height={'56px'}
            />
          )}
          {!loading && isWeb() && loggedIn && (
            <a href={url} target="_blank">
              <PigogoButton
                fullWidth
                variation={'secondary'}
                text={'Μεταφορά στο κατάστημα'}
                typographyProps={{ color: '#ffff' }}
                onClick={() => handleClick()}
              />
            </a>
          )}
          {!loading && (!isWeb() || !loggedIn) && (
            <PigogoButton
              fullWidth
              variation={'secondary'}
              text={'Μεταφορά στο κατάστημα'}
              typographyProps={{ color: '#ffff' }}
              onClick={() => handleClick()}
            />
          )}
        </Box>
      </Box>
    </StyleBox>
  );
};

export default PigogoStoreCard;
