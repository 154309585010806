import React, { FC, useEffect, useState } from 'react';
import { Box, Icon, Typography, useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import { ClaimTypeId } from '../../../models/ClaimTypeId';
import StepThreeContent from './StepThreeContent';
import StepThreeCancelledContent from './StepThreeCancelledContent';
import { CancellationDetails, CreateClaimStepThree } from '../../../models/CreateClaimStepThree';
import { ReactComponent as Warning } from '../../../assets/svgs/redWarning.svg';
import dayjs from 'dayjs';

interface Props {
  shopSlugName: string;
  typeOfClaim: ClaimTypeId | 0;
  stepThreeContent: CreateClaimStepThree;
  setStepThreeContent: (content: CreateClaimStepThree) => void;
  handleSubmit: (data: CreateClaimStepThree, otherPaymentType: string) => void;
  submitLoading?: boolean;
}

const initialStepThreeState = {
  order_amount: '',
  has_confirmation_email: true,
  cancellation_details_id: CancellationDetails.def,
  confirmation_email: '',
  order_number: '',
  has_coupon_code: false,
  purchase_datetime: '',
  user_note: '',
  order_coupon_code: '',
  payment_type: '',
  cashback_category_id: [] as string[],
  confirmation_date: null,
  confirmation_time: null,
};

const initialFormErrors = {
  confirmation_email: false,
  order_number: false,
  order_amount: false,
  order_coupon_code: false,
  payment_type: false,
  cashback_category: false,
  confirmation_date: false,
  confirmation_time: false,
  cancellation_details_id: false,
};

const StepThree: FC<Props> = ({
  handleSubmit,
  stepThreeContent,
  typeOfClaim,
  setStepThreeContent,
  shopSlugName,
  submitLoading = false,
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [formErrors, setFormErrors] = useState<any>(initialFormErrors);
  const [otherPaymentType, setOtherPaymentType] = useState<string>('');
  const [stepThreeState, setStepThreeState] = useState<CreateClaimStepThree>(stepThreeContent);

  useEffect(() => {
    setStepThreeContent(stepThreeState);
  }, [stepThreeState]);

  useEffect(() => {
    setStepThreeState(stepThreeContent);
  }, [stepThreeContent]);

  const handleClickSubmit = () => {
    const calculateErrors = {
      confirmation_email: false,
      order_number: false,
      order_amount: false,
      order_coupon_code: false,
      payment_type: false,
      cashback_category: false,
      confirmation_date: false,
      confirmation_time: false,
      cancellation_details_id: false,
    };
    if (typeOfClaim !== ClaimTypeId.cancelled_purchase) {
      if (stepThreeState.order_amount === '' || /^[0,]+$/.test(stepThreeState.order_amount)) {
        calculateErrors.order_amount = true;
      }
      if (stepThreeState.order_number === '') {
        calculateErrors.order_number = true;
      }
      if (stepThreeState.payment_type === '' || (stepThreeState.payment_type === 'other' && otherPaymentType === '')) {
        calculateErrors.payment_type = true;
      }
      if (stepThreeState.has_confirmation_email && stepThreeState.confirmation_email === '') {
        calculateErrors.confirmation_email = true;
      }
      if (stepThreeState.has_coupon_code && stepThreeState.order_coupon_code === '') {
        calculateErrors.order_coupon_code = true;
      }
      if (stepThreeState.cashback_category_id.length === 0 && shopSlugName !== 'tell-a-friend') {
        calculateErrors.cashback_category = true;
      }
    } else {
      if (stepThreeState.cancellation_details_id === CancellationDetails.def) {
        calculateErrors.cancellation_details_id = true;
      }
      if (stepThreeState.has_confirmation_email) {
        if (
          stepThreeState.confirmation_date === null ||
          dayjs(stepThreeState.confirmation_date).isBefore(dayjs().startOf('day').subtract(6, 'month')) ||
          dayjs(stepThreeState.confirmation_date).isAfter(dayjs().endOf('day'))
        ) {
          calculateErrors.confirmation_date = true;
        }
      }
      if (stepThreeState.has_confirmation_email && stepThreeState.confirmation_time === null) {
        calculateErrors.confirmation_time = true;
      }
      if (stepThreeState.has_confirmation_email && stepThreeState.confirmation_email === '') {
        calculateErrors.confirmation_email = true;
      }
    }
    const keys: string[] = Object.keys(calculateErrors);
    const hasErrors = keys.some((key) => calculateErrors[key as keyof typeof calculateErrors]);
    setFormErrors(calculateErrors);
    setStepThreeContent(stepThreeState);
    if (!hasErrors) handleSubmit(stepThreeState, otherPaymentType);
  };

  return (
    <Box pl={!mdDown ? 10 : 0} display={'flex'} flexDirection={'column'} gap={3} pb={mdDown ? 5 : '120px'}>
      {typeOfClaim !== ClaimTypeId.cancelled_purchase ? (
        <StepThreeContent
          formErrors={formErrors}
          shopSlugName={shopSlugName}
          setFormErrors={setFormErrors}
          stepThreeState={stepThreeState}
          otherPaymentType={otherPaymentType}
          setStepThreeState={setStepThreeState}
          setOtherPaymentType={setOtherPaymentType}
        />
      ) : (
        <StepThreeCancelledContent
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          stepThreeState={stepThreeState}
          setStepThreeState={setStepThreeState}
        />
      )}
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        {JSON.stringify(initialFormErrors) !== JSON.stringify(formErrors) && !submitLoading && (
          <Box display={'flex'} gap={2} pl={2} pt={'16px'}>
            <Icon>
              <Warning />
            </Icon>
            <Typography variant={'body2MediumM'} color={'#F75676'} component="p" role="alert">
              Συμπλήρωσε τα υποχρεωτικά πεδία.
            </Typography>
          </Box>
        )}
        <Box>
          <PigogoButton
            simpleButton
            variant={'contained'}
            text="Καταχώρηση αιτήματος"
            typographyProps={{ fontSize: '14px', fontWeight: '500' }}
            sx={{ borderRadius: '32px', paddingRight: 3, paddingLeft: 3 }}
            onClick={() => handleClickSubmit()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StepThree;
