import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import DeleteModalUser from './DeleteModalUser';
import React, { useEffect, useState } from 'react';
import SuccessDeleteModal from './SuccessDeleteModal';
import { emailValid } from '../../../utils/validation';
import ChangePasswordModal from './ChangePasswordModal';
import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { UpdateUserParams } from '../../../redux/api/types/UpdateUserParams';
import { useGetUserDataQuery } from '../../../redux/api/queries/userQueries';
import SuccessPasswordModal from '../../Navigation/Modals/SuccessPasswordModal';
import { useUpdateUserMutation } from '../../../redux/api/mutations/userMutations';
import { setLoad, setSnackBar, setStateSnackBar } from '../../../redux/slices/layoutSlice';
import { Box, Button, Divider, IconButton, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as SuccessGoogle } from '../../../assets/svgs/login/successGoogle.svg';
import { ReactComponent as All } from '../../../assets/svgs/illustrations/avatar/default_all.svg';
import { ReactComponent as Man } from '../../../assets/svgs/illustrations/avatar/default_boy.svg';
import { ReactComponent as SuccessFacebook } from '../../../assets/svgs/login/successFacebook.svg';
import { ReactComponent as Woman } from '../../../assets/svgs/illustrations/avatar/default_girl.svg';
import { ReactComponent as SuccessApple } from '../../../assets/svgs/login/successApple.svg';
import useDebounce from '../../../hooks/useDebounce';
import {
  PigogoLink,
  PigogoSelectableButton,
  PigogoSmallButton,
  PigogoTextField,
  PigogoDateField,
  theme,
} from 'components';
import dayjs, { Dayjs } from 'dayjs';
import WarningMessage from '../Overview/WarningMessage';
import { Helmet } from 'react-helmet-async';

const genderArray = [
  { label: 'Προτιμώ να μη δηλώσω', id: 0 },
  { label: 'Άνδρας', id: 1 },
  { label: 'Γυναίκα', id: 2 },
  { label: 'null', id: null },
];

const initialInfo = {
  id: 0,
  name: '',
  surname: '',
  birth: '',
  email: '',
  password: '1234567',
  gender: genderArray[3],
  icon: 0,
  errorEmail: false,
  errorPassword: false,
  showPassword: false,
};

const initialModals = {
  deleteUser: false,
  changePassword: false,
  successDelete: false,
  successPass: false,
};

const PersonalInfo = () => {
  const dispatch = useDispatch();

  const debounceDelay = 1000;

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { data: userData, isLoading: userDataLoading } = useGetUserDataQuery();
  const [updateUser, { isLoading: updateLoading, reset: resetUser, error: updateError }] = useUpdateUserMutation();

  const [modals, setModals] = useState(initialModals);
  const [personal, setPersonal] = useState(initialInfo);
  const [personalSaved, setPersonalSaved] = useState(initialInfo);
  const [birthdayError, setBirthdayError] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<boolean>(false);

  const [isDebouncingBirthday, setIsDebouncingBirthday] = useState<boolean>(false);
  const birthdayDebounced = useDebounce(personal.birth, debounceDelay);
  const [leaving, setLeaving] = useState<boolean>(false);

  useIonViewDidEnter(() => {
    setLeaving(false);
  });

  useIonViewDidLeave(() => {
    setLeaving(true);
  });

  useEffect(() => {
    if (leaving) {
      resetUser();
    }
  }, [leaving]);

  useEffect(() => {
    dispatch(setLoad(updateLoading));
  }, [updateLoading]);

  useEffect(() => {
    if (updateError) {
      const params = { value: true, msg: 'Το αίτημα ενημέρωσης χρήστη απέτυχε!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [updateError]);

  useEffect(() => {
    if (userData) {
      const {
        id = 0,
        email = '',
        first_name = '',
        last_name = '',
        birthday = '',
        gender_id = null,
        user_icon_id = 0,
      } = userData ? userData : {};

      setPersonal({
        ...personal,
        id: id,
        name: first_name,
        surname: last_name,
        birth: birthday === null ? '' : moment(birthday).format('DD/MM/YYYY'),
        email: email,
        icon: user_icon_id,
        gender: gender_id != null ? genderArray[gender_id] : genderArray[3],
      });

      setPersonalSaved({
        ...personalSaved,
        id: id,
        name: first_name,
        surname: last_name,
        birth: birthday === null ? '' : moment(birthday).format('DD/MM/YYYY'),
        email: email,
        icon: user_icon_id,
        gender: gender_id != null ? genderArray[gender_id] : genderArray[3],
      });
    }
  }, [userData]);

  useEffect(() => {
    setEmailError(false);
    if (!emailValid(personal.email)) {
      setEmailError(true);
    }
  }, [personal.email]);

  useEffect(() => {
    setIsDebouncingBirthday(false);
    if (!moment(birthdayDebounced, 'DD/MM/YYYY').isValid() && birthdayDebounced !== '') {
      setBirthdayError(true);
      return;
    }
    if (moment(birthdayDebounced, 'DD/MM/YYYY').isAfter(moment().startOf('day'))) {
      setBirthdayError(true);
      return;
    }
    if (moment(birthdayDebounced, 'DD/MM/YYYY').isBefore(moment().startOf('day').subtract(110, 'years'))) {
      setBirthdayError(true);
      return;
    }

    setBirthdayError(false);
    return;
  }, [birthdayDebounced]);

  useEffect(() => {
    setBirthdayError(false);
  }, [personal.birth]);

  useEffect(() => {
    setEmailError(false);
    if (updateError && (updateError as any).data && (updateError as any).data.email) {
      setEmailError(true);
    }
  }, [updateError]);

  const changeStateGender = (value: number) => {
    setPersonal({ ...personal, gender: genderArray[value] });
  };

  const disableUpdate = () => {
    return (
      (personal.name && personal.name.length == 1) ||
      (personal.surname && personal.surname.length == 1) ||
      !emailValid(personal.email) ||
      birthdayError ||
      isDebouncingBirthday
    );
  };

  const emailErrorMsg = () => {
    if (emailError && !emailValid(personal.email)) {
      return 'To email δεν είναι έγκυρο';
    }

    if (emailError && updateError && (updateError as any).data && (updateError as any).data.email) {
      return 'Υπάρχει ήδη εγγεγραμμένος χρήστης με αυτό το email.';
    }

    return '';
  };

  const finalCheck = () => {
    const personalString = JSON.stringify(personal);
    const personalSavedString = JSON.stringify(personalSaved);
    const areEqual = personalString === personalSavedString;
    return areEqual || disableUpdate();
  };

  const handleSubmitForm = () => {
    const dateFormatTest = /\d\d\/\d\d\/\d\d\d\d/;

    const data: UpdateUserParams = {
      email: personal.email,
      first_name: personal.name,
      last_name: personal.surname,
      birthday: personal.birth !== '' ? moment(personal.birth, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      gender_id: personal.gender.id,
      user_icon_id: personal.icon,
    };

    if (dateFormatTest.test(personal.birth) || personal.birth === '') {
      updateUser(data);
    } else {
      setBirthdayError(true);
    }
  };

  const accountDetails = () => {
    return (
      <Box>
        {!smDown && (
          <>
            <Box display="flex" alignItems={'center'} pb={3}>
              <Typography variant="body1" color={'#1D2532'}>
                Στοιχεία λογαριασμού
              </Typography>
            </Box>
            <Divider sx={{ marginLeft: '-24px', marginRight: '-24px' }} />
          </>
        )}

        <Box display="flex" flexDirection="column" gap={3} alignItems="start" pt={smDown ? 0 : 4} pb={smDown ? 0 : 2}>
          <Box>
            <Typography variant="mediumM" color={'#1D2532'} component="h3">
              Προσωπικά στοιχεία
            </Typography>
          </Box>
          {userDataLoading ? (
            <Box
              display="flex"
              flexDirection={smDown ? 'column' : 'row'}
              flexWrap={'wrap'}
              rowGap={3}
              columnGap={2}
              width={'100%'}
            >
              <Skeleton
                variant="rectangular"
                width="316px"
                height="56px"
                sx={{ borderRadius: '30px', maxWidth: '100%' }}
              />
              <Skeleton
                variant="rectangular"
                width="316px"
                height="56px"
                sx={{ borderRadius: '30px', maxWidth: '100%' }}
              />
              <Skeleton
                variant="rectangular"
                width="316px"
                height="56px"
                sx={{ borderRadius: '30px', maxWidth: '100%' }}
              />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection={smDown ? 'column' : 'row'}
              flexWrap={'wrap'}
              rowGap={3}
              columnGap={2}
              maxWidth={'100%'}
            >
              <Box width={'316px'} maxWidth={'100%'}>
                <PigogoTextField
                  fullWidth
                  label="‘Ονομα"
                  value={personal.name}
                  error={personal.name ? personal.name.length == 1 : false}
                  helperText={personal.name && personal.name.length === 1 ? 'Τουλάχιστον 2 χαρακτήρες' : ''}
                  setValue={(value: string) => {
                    setPersonal({ ...personal, name: value });
                  }}
                />
              </Box>
              <Box width={'316px'} maxWidth={'100%'}>
                <PigogoTextField
                  fullWidth
                  label="Επίθετο"
                  value={personal.surname}
                  error={personal.surname !== null ? personal.surname.length == 1 : false}
                  helperText={personal.surname && personal.surname.length === 1 ? 'Τουλάχιστον 2 χαρακτήρες' : ''}
                  setValue={(value: string) => {
                    setPersonal({ ...personal, surname: value });
                  }}
                />
              </Box>
              <Box width={'316px'} maxWidth={'100%'}>
                <PigogoDateField
                  value={personal.birth !== '' ? dayjs(personal.birth, 'DD/MM/YYYY') : undefined}
                  label="Ημ/νία γέννησης (προαιρετικό)"
                  error={birthdayError}
                  helperText={birthdayError ? 'Η ημερομηνία γέννησης δεν είναι σωστή.' : ''}
                  setValue={(value: string) => {
                    setIsDebouncingBirthday(true);
                    setPersonal({ ...personal, birth: value === null ? '' : value });
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const gender = () => {
    return (
      <Box display="flex" flexDirection="column" alignItems="start" pt={1} gap={2} pb={1}>
        <Typography variant="mediumM" color={'#1D2532'} component="h3">
          Φύλο (προαιρετικό)
        </Typography>
        <Box display="flex" flexDirection={smDown ? 'column' : 'row'} gap={'8px'}>
          <Box
            display="flex"
            flexDirection={smDown ? 'row' : undefined}
            gap={'8px'}
            textAlign={smDown ? 'start' : undefined}
          >
            {userDataLoading ? (
              <>
                <Skeleton variant="rectangular" height="50px" width="83px" sx={{ borderRadius: '58px' }} />
                <Skeleton variant="rectangular" height="50px" width="83px" sx={{ borderRadius: '58px' }} />
              </>
            ) : (
              <>
                <PigogoSelectableButton
                  buttonProps={{ width: 83, padding: '14px 16px', height: '48px', borderRadius: '88px' }}
                  typoProps={{ variant: 'subtitle1SmallR', color: '#313D53' }}
                  selectedCategory={personal.gender.label}
                  text={'Γυναίκα'}
                  handleClick={() => changeStateGender(2)}
                />
                <PigogoSelectableButton
                  buttonProps={{ width: 81, padding: '14px 16px', height: '48px' }}
                  typoProps={{ variant: 'subtitle1SmallR', color: '#313D53' }}
                  selectedCategory={personal.gender.label}
                  text={'Άνδρας'}
                  handleClick={() => changeStateGender(1)}
                />
              </>
            )}
          </Box>
          {userDataLoading ? (
            <Skeleton variant="rectangular" height="50px" width="183px" sx={{ borderRadius: '58px' }} />
          ) : (
            <PigogoSelectableButton
              buttonProps={{ width: 189, padding: '14px 16px', height: '48px' }}
              typoProps={{ variant: 'subtitle1SmallR', color: '#313D53' }}
              selectedCategory={personal.gender.label}
              text={'Προτιμώ να μη δηλώσω'}
              handleClick={() => changeStateGender(0)}
            />
          )}
        </Box>
      </Box>
    );
  };

  const profilePhoto = () => {
    return (
      <Box display="flex" flexDirection="column" alignItems="start" pt={1} gap={2} pb={1}>
        <Typography variant="mediumM" component="h3" textAlign={smDown ? 'start' : undefined} color={'#1D2532'}>
          Επίλεξε ένα εικονίδιο για το προφίλ σου
        </Typography>
        <Box display="flex" flexDirection="row" gap={2}>
          {userDataLoading ? (
            <>
              <Skeleton height="64px" width="64px" variant="circular" />
              <Skeleton height="64px" width="64px" variant="circular" />
              <Skeleton height="64px" width="64px" variant="circular" />
            </>
          ) : (
            <>
              <IconButton onClick={() => setPersonal({ ...personal, icon: 0 })} sx={{ padding: 0 }}>
                <All
                  style={{
                    outline: personal.icon === 0 ? '2px solid #1D2532' : undefined,
                    outlineOffset: '-2px',
                    boxShadow: '0px 2px 4px rgba(29, 37, 50, 0.16)',
                    borderRadius: '50%',
                    background: ' #FDF0F3',
                  }}
                />
              </IconButton>
              <IconButton onClick={() => setPersonal({ ...personal, icon: 1 })} sx={{ padding: 0 }}>
                <Man
                  style={{
                    outline: personal.icon === 1 ? '2px solid #1D2532' : undefined,
                    outlineOffset: '-2px',
                    boxShadow: '0px 2px 4px rgba(29, 37, 50, 0.16)',
                    borderRadius: '50%',
                    background: ' #FDF0F3',
                  }}
                />
              </IconButton>
              <IconButton onClick={() => setPersonal({ ...personal, icon: 2 })} sx={{ padding: 0 }}>
                <Woman
                  style={{
                    outline: personal.icon === 2 ? '2px solid #1D2532' : undefined,
                    outlineOffset: '-2px',
                    boxShadow: '0px 2px 4px rgba(29, 37, 50, 0.16)',
                    borderRadius: '50%',
                    background: ' #FDF0F3',
                  }}
                />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getAppleLogin = () => {
    return (
      <Box display={'flex'} alignItems={'start'} gap={2}>
        <Typography variant={'subtitle2'} color={'#1D2532'}>
          {personal.email}
        </Typography>
        <SuccessApple />
      </Box>
    );
  };

  const getGoogleLogin = () => {
    return (
      <Box display={'flex'} alignItems={'start'} gap={2}>
        <Typography variant={'subtitle2'} color={'#1D2532'}>
          {personal.email}
        </Typography>
        <SuccessGoogle />
      </Box>
    );
  };

  const getFacebookLogin = () => {
    return (
      <Box display={'flex'} alignItems={'start'} gap={2}>
        <Typography variant={'subtitle2'} color={'#1D2532'}>
          {personal.email}
        </Typography>
        <SuccessFacebook />
      </Box>
    );
  };

  const getFieldsLogin = () => {
    return (
      <Box display="flex" flexDirection={smDown ? 'column' : 'row'} gap={2} maxWidth={'100%'}>
        {userDataLoading ? (
          <Box display="flex" flexDirection={smDown ? 'column' : 'row'} gap={2}>
            <Skeleton
              variant="rectangular"
              width="316px"
              height="56px"
              sx={{ borderRadius: '30px', maxWidth: '100%' }}
            />
            <Skeleton
              variant="rectangular"
              width="316px"
              height="56px"
              sx={{ borderRadius: '30px', maxWidth: '100%' }}
            />
          </Box>
        ) : (
          <Box display="flex" flexDirection={smDown ? 'column' : 'row'} flexWrap={'wrap'} gap={2} width={'100%'}>
            <Box width={'316px'} maxWidth={'100%'}>
              <PigogoTextField
                fullWidth
                label="Email"
                type={'email'}
                value={personal.email}
                error={emailError}
                helperText={emailErrorMsg()}
                setValue={(value: string) => setPersonal({ ...personal, email: value })}
              />
            </Box>
            <Box display="flex" flexDirection={'column'} width={'316px'} gap={1} textAlign={'start'} maxWidth={'100%'}>
              <Box width={'316px'} maxWidth={'100%'}>
                <PigogoTextField
                  fullWidth
                  readonly={true}
                  type={'password'}
                  label="Κωδικός πρόσβασης"
                  value={personal.password}
                  setValue={(value: string) => setPersonal({ ...personal, password: value })}
                />
              </Box>
              <Box pl={3}>
                <PigogoLink
                  color={'#313D53'}
                  colorText={'#313D53'}
                  text={'Αλλαγή κωδικού'}
                  typoProps={{ variant: 'captionM' }}
                  handleClick={() => setModals({ ...modals, changePassword: true })}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const loginDetails = () => {
    return (
      <Box display="flex" flexDirection="column" alignItems="start" pt={1} gap={3} pb={1}>
        <Typography variant="mediumM" component="h3" color={'#1D2532'}>
          Στοιχεία εισόδου
        </Typography>
        {userData?.login_type_id === 1 && getFieldsLogin()}
        {userData?.login_type_id === 2 && getFacebookLogin()}
        {userData?.login_type_id === 3 && getGoogleLogin()}
        {userData?.login_type_id === 5 && getAppleLogin()}
      </Box>
    );
  };

  return (
    <Box pb={mdDown ? '96px' : '136px'} pt={mdDown ? '32px' : undefined}>
      {location.pathname === '/user/personal-info' && (
        <Helmet>
          <title>Στοιχεία λογαριασμού - Ο λογαριασμός μου | Pigogo</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
        </Helmet>
      )}
      {userData?.user_warning_message && (
        <Box display="flex" flexDirection="column" mt={mdDown ? -2 : undefined} mb={2}>
          <WarningMessage />
        </Box>
      )}
      <Box
        gap={3}
        display={'flex'}
        textAlign="center"
        borderRadius="24px"
        flexDirection={'column'}
        border="2px solid #EAECEE"
        p={smDown ? '24px 16px' : '24px'}
      >
        {accountDetails()}
        {gender()}
        {profilePhoto()}
        {loginDetails()}
        <Box textAlign={'start'}>
          <PigogoSmallButton
            type={'submit'}
            disabled={finalCheck()}
            text="Αποθήκευση αλλαγών"
            onClick={handleSubmitForm}
            typographyProps={{ variant: 'subtitle2' }}
            sx={{ height: 56, padding: '16px, 24px, 16px, 24px' }}
          />
        </Box>
      </Box>

      <Box pt={smDown ? 4 : 7} pl={smDown ? '10px' : 2}>
        <Button onClick={() => setModals({ ...modals, deleteUser: true })}>
          <Typography
            variant={'captionM'}
            color="primary"
            sx={{ textDecoration: 'underline', textDecorationColor: '#FCE5D8' }}
          >
            Διαγραφή λογαριασμού
          </Typography>
        </Button>
      </Box>
      <DeleteModalUser
        open={modals.deleteUser}
        data={{ fullname: `${personal.name} ${personal.surname}`, email: personal.email, userId: personal.id }}
        handleClose={() => setModals({ ...modals, deleteUser: false })}
        accept={() => setModals({ ...modals, deleteUser: false, successDelete: true })}
      />
      <SuccessDeleteModal
        open={modals.successDelete}
        handleClose={() => setModals({ ...modals, successDelete: false })}
      />
      <ChangePasswordModal
        open={modals.changePassword}
        handleClose={() => setModals({ ...modals, changePassword: false })}
        accept={() => setModals({ ...modals, successPass: true, changePassword: false })}
      />
      <SuccessPasswordModal
        height={'264px'}
        open={modals.successPass}
        handleClose={(value) => setModals({ ...modals, successPass: value })}
      />
    </Box>
  );
};

export default PersonalInfo;
