import { ClaimTypeId } from '../models/ClaimTypeId';

export const extractCreateClaimType = (claimTypeId: ClaimTypeId) => {
  switch (claimTypeId) {
    case 1:
      return 'Δεν έχει καταγραφεί η αγορά μου';
    case 2:
      return 'Βλέπω λάθος ποσό επιστροφής';
    case 3:
      return 'Η αγορά μου ακυρώθηκε λανθασμένα';
    default:
      return '';
  }
};
