import React, { FC } from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { PigogoLink, PigogoPromoCard, theme } from 'components';
import { useIonRouter } from '@ionic/react';
import { ShopCategory } from '../../../models/ShopCategory';
import { Promo } from '../../../models/Promo';
import { GenericRequest } from '../../../models/GenericRequest';
import { Link } from 'react-router-dom';
import { isWeb } from '../../../utils/device';
import { Browser } from '@capacitor/browser';

interface Props {
  allCategories?: GenericRequest<ShopCategory[]>;
  promos?: GenericRequest<Promo[]>;
  handleClose: () => void;
  setResetFilters?: (value: boolean) => void;
}

const ShopsHeaderContent: FC<Props> = ({ handleClose, allCategories, promos, setResetFilters }) => {
  const router = useIonRouter();

  const isCapacitorApp = !isWeb();

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box maxHeight={'469px'} boxSizing={'border-box'}>
      <Box
        display={'flex'}
        flexWrap={'wrap'}
        alignItems={'flex-start'}
        alignContent={'flex-start'}
        justifyContent={'space-between'}
        gap={4}
      >
        <Box width={'100%'} display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
          <Box
            rowGap={2}
            columnGap={8}
            width={'60%'}
            display={'flex'}
            flexWrap={'wrap'}
            maxHeight={'280px'}
            flexDirection={'column'}
          >
            {allCategories?.data.map((category: ShopCategory, index: number) => (
              <Box key={index}>
                <Link
                  className="btnLink btnLink--menu"
                  to={'/' + category.slug.name}
                  onClick={() => {
                    if ('/' + category.slug.name === router.routeInfo.pathname) {
                      setResetFilters && setResetFilters(true);
                    }
                    handleClose();
                  }}
                >
                  {category.label}
                </Link>
              </Box>
            ))}
            {allCategories && allCategories.data.length === 0 && (
              <Typography variant={'button'}>Δεν υπάρχουν κατηγορίες</Typography>
            )}
          </Box>

          <Box width={'304px'}>
            {promos && (
              <Link className="storeCard" to={promos.data[0].url}>
                <PigogoPromoCard
                  productType
                  logo={promos.data[0].image}
                  color={promos.data[0].color}
                  title={promos?.data[0]?.tile_title}
                  onClick={(e: any) => {
                    if (promos.data[0].url.includes('blog/')) {
                      e.preventDefault();
                      if (isCapacitorApp) {
                        Browser.open({ url: 'https://www.pigogo.gr' + promos.data[0].url || '' });
                      } else {
                        window.open('https://www.pigogo.gr' + promos.data[0].url);
                      }
                      return;
                    } else {
                      if (
                        router.routeInfo.pathname.includes('/promo') ||
                        router.routeInfo.pathname.includes('/stores')
                      ) {
                        setResetFilters && setResetFilters(true);
                      }
                      handleClose();
                    }
                  }}
                  call_to_action={!mdDown ? promos.data[0].call_to_action : promos.data[0].call_to_action_mobile}
                />
              </Link>
            )}
          </Box>
        </Box>

        <Box width={'100%'}>
          <Link
            className="btnLink btnLink--underline btnLink--blue"
            to={`/stores`}
            onClick={() => {
              router.routeInfo.pathname === '/stores' && !!setResetFilters && setResetFilters(true);
              handleClose();
            }}
          >
            Όλα τα καταστήματα
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ShopsHeaderContent;
