import { Savings } from '../../../models/Savings';
import { pigogoApi } from '../Api';
import { ListingParams } from '../types/ListingParams';
import { GenericRequest } from '../../../models/GenericRequest';
import { GenericResponse } from '../../../models/GenericResponse';
import { extractData } from '../../../utils/extractData';

const savingsApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    savings: builder.query<GenericRequest<Savings[]>, ListingParams>({
      query: (args) => {
        const { promo, category, nextCursor, discount, limit, sort, no_promo, featured = true, fillFeatured } = args;
        return {
          url: '/savings',
          params: { promo, category, cursor: nextCursor, limit, discount, sort, no_promo, featured, fillFeatured },
        };
      },
      async transformResponse(response: GenericResponse<Savings[]> | Savings[], meta) {
        const { responseData, nextCursor, totalCount } = extractData<Savings[]>(response);
        await Promise.all(
          responseData?.map((saving: Savings) => {
            return new Promise((res) => {
              const shopImage = new Image();
              shopImage.src = saving.shop.image as string;
              res(shopImage);
            });
          }),
        );
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-Total-Count')) || totalCount,
          nextCursor: nextCursor,
        };
      },
      providesTags: ['savings'],
    }),
    totalSavingsOfCategory: builder.query<
      any,
      { menuCategoryId: string; category?: string; discount?: string; total_count?: string; featured?: boolean }
    >({
      query: ({ menuCategoryId, category, discount, total_count = 'true', featured = true }) => ({
        method: 'GET',
        url: `/menu-categories/${menuCategoryId}/savings`,
        params: { category, discount, total_count, featured },
      }),
      transformResponse(response: any, meta) {
        const totalCount = Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || response?.total_count;
        return { totalCount };
      },
    }),
  }),
});

export const { useSavingsQuery, useLazySavingsQuery, useLazyTotalSavingsOfCategoryQuery } = savingsApi;
