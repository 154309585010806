import { Box, BoxProps, IconButton, Skeleton, styled, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { RichTooltip } from 'components';
import { ReactComponent as Info } from '../../assets/svgs/info_light_color.svg';
import { ReactComponent as Speed } from '../../assets/svgs/speed.svg';
import { ReactComponent as Up } from '../../assets/svgs/vectors/up.svg';
import { ReactComponent as Down } from '../../assets/svgs/vectors/down.svg';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  boxSizing: 'border-box',
  background: '#F7F7F8',
  borderRadius: 24,
  padding: '24px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
}));

type StoreCardProps = {
  week: string;
  percent: string;
  speedTitle: string;
  loading?: boolean;
  mobile?: boolean;
};

const InformationStore: FC<StoreCardProps & BoxProps> = ({ week, percent, speedTitle, loading, mobile }) => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [showList, setShowList] = useState<boolean>(false);

  const MouseOver1 = (event: React.MouseEvent<HTMLElement>) => {
    setOpen1((previousOpen) => !previousOpen);
  };
  const MouseOver2 = (event: React.MouseEvent<HTMLElement>) => {
    setOpen2((previousOpen) => !previousOpen);
  };
  const MouseOver3 = (event: React.MouseEvent<HTMLElement>) => {
    setOpen3((previousOpen) => !previousOpen);
  };

  const MouseOut1 = () => {
    setOpen1(false);
  };
  const MouseOut2 = () => {
    setOpen2(false);
  };
  const MouseOut3 = () => {
    setOpen3(false);
  };

  const speed = parseInt(speedTitle, 10);

  // Determine display value and unit based on speed
  let trackingSpeed = `${speed} λεπτά`;
  if (speed > 60) {
    const hours = Math.round(speed / 60);
    trackingSpeed = `${hours} ώρες`;
  }

  const Information = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Box>
              <Typography variant="largeB" component="p" color={'#1D2532'}>
                {loading ? (
                  <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px', width: '80px' }} />
                ) : (
                  trackingSpeed
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="captionSB" component="p" sx={{ color: '#838B98' }}>
                {loading ? (
                  <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px', width: '120px' }} />
                ) : (
                  'Ταχύτητα καταγραφής'
                )}
              </Typography>
            </Box>
          </Box>
          <Box justifyContent={'flex-end'} onMouseOver={MouseOver1} onMouseOut={MouseOut1}>
            {loading ? (
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'20px'} />
            ) : (
              <RichTooltip
                content={
                  <Typography variant="caption" component="p">
                    Ο μέσος χρόνος που απαιτείται μέχρι να εμφανιστεί μία αγορά στη σελίδα Αγορές
                  </Typography>
                }
                open={open1}
                placement="top"
                color="dark"
                onClose={() => setOpen1(false)}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: '#1D2532',
                  }}
                >
                  <Info />
                </Box>
              </RichTooltip>
            )}
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Box>
              <Typography variant="largeB" component="p" color={'#1D2532'}>
                {loading ? (
                  <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px', width: '80px' }} />
                ) : (
                  `${percent} %`
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="captionSB" component="p" sx={{ color: '#838B98' }}>
                {loading ? (
                  <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px', width: '120px' }} />
                ) : (
                  'Ακρίβεια καταγραφής'
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: '#1D2532',
            }}
            onMouseOver={MouseOver2}
            onMouseOut={MouseOut2}
          >
            {loading ? (
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'20px'} />
            ) : (
              <RichTooltip
                content={
                  <Typography variant="caption" component="p">
                    Το ποσοστό των αγορών που καταγράφονται επιτυχώς και για τις οποίες οι χρήστες κερδίζουν επιστροφή
                    χρημάτων
                  </Typography>
                }
                open={open2}
                placement="top"
                color="dark"
                onClose={() => setOpen2(false)}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: '#1D2532',
                  }}
                >
                  <Info />
                </Box>
              </RichTooltip>
            )}
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Box>
              <Typography variant="largeB" component="p" color={'#1D2532'}>
                {loading ? (
                  <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px', width: '80px' }} />
                ) : (
                  `${week} εβδ.`
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="captionSB" component="p" sx={{ color: '#838B98' }}>
                {loading ? (
                  <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px', width: '120px' }} />
                ) : (
                  'Ταχύτητα πληρωμής'
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: '#1D2532',
            }}
            onMouseOver={MouseOver3}
            onMouseOut={MouseOut3}
          >
            {loading ? (
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'20px'} />
            ) : (
              <RichTooltip
                content={
                  <Typography variant="caption" component="p">
                    Ο μέσος χρόνος που απαιτείται μέχρι η αγορά να αλλάξει κατάσταση σε "Προς πληρωμή"
                  </Typography>
                }
                open={open3}
                placement="top"
                color="dark"
                onClose={() => setOpen3(false)}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: '#1D2532',
                  }}
                >
                  <Info />
                </Box>
              </RichTooltip>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <StyleBox>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        onClick={() => {
          if (window.innerWidth >= 960) {
            return;
          } else {
            setShowList(!showList);
          }
        }}
      >
        {loading ? (
          <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'100%'} />
        ) : (
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Box
              sx={{
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!loading && <Speed />}
            </Box>
            <Typography variant="subtitle1SmallM" component="p" color={'#313D53'}>
              Στατιστικά καταστήματος
            </Typography>
          </Box>
        )}
        {mobile && !loading && (
          <Box justifyContent={'flex-end'}>
            <IconButton>{showList ? <Up /> : <Down />}</IconButton>
          </Box>
        )}
      </Box>

      {showList && Information()}
      {!mobile && Information()}
    </StyleBox>
  );
};

export default InformationStore;
