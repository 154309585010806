import { pigogoApi } from '../Api';
import { User, UserData } from '../../../models/User';
import { GenericRequest } from '../../../models/GenericRequest';
import { Payment } from '../../../models/Payment';
import { Purchase } from '../../../models/Purchase';
import { Clickout } from '../../../models/Clickout';
import { Claim } from '../../../models/Claims';
import { PaymentParams } from '../types/PaymentParams';
import { PurchaseParams } from '../types/PurchaseParams';
import { ClaimParams } from '../types/ClaimParams';
import { GenericResponse } from '../../../models/GenericResponse';
import { extractData } from '../../../utils/extractData';

const userApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    // GET /user
    getUserData: builder.query<UserData, void>({
      query: () => ({
        method: 'GET',
        url: `/users/self`,
      }),
      providesTags: ['user'],
    }),

    // GET user claims
    getUserClaims: builder.query<GenericRequest<Claim[]>, ClaimParams>({
      query: (args) => {
        const { nextCursor, date, limit, shop, status, order, sort } = args;
        return {
          method: 'GET',
          url: `/users/self/claims`,
          params: { cursor: nextCursor, date, limit, shop, status, order, sort },
        };
      },
      transformResponse(response: GenericResponse<Claim[]> | Claim[], meta) {
        const { responseData, nextCursor, totalCount } = extractData<Claim[]>(response);
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
      providesTags: ['claims'],
    }),

    // GET user claims count per shop
    getClaimsCountPerShop: builder.query<{ totalCount: number }, { shop: string; total_count?: string }>({
      query: ({ shop, total_count = true }) => {
        return {
          method: 'GET',
          url: `/users/self/claims`,
          params: { shop, total_count },
        };
      },
      transformResponse(response: GenericResponse<Claim[]> | Claim[], meta) {
        const { totalCount } = extractData<Claim[]>(response);
        return {
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT') || totalCount),
        };
      },
    }),

    // GET user claims shops
    getUserClaimsShops: builder.query<GenericRequest<Claim[]>, void>({
      query: () => {
        return {
          method: 'GET',
          url: `/users/self/claims`,
          params: { column: 'shops' },
        };
      },
      transformResponse(response: GenericResponse<Claim[]> | Claim[], meta) {
        const { responseData, nextCursor, totalCount } = extractData<Claim[]>(response);
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
      providesTags: ['claimsShops'],
    }),

    // GET user clickouts
    getUserClickouts: builder.query<GenericRequest<any>, { order?: 'asc' | 'desc'; type?: string }>({
      query: ({ order, type }) => ({
        method: 'GET',
        url: `/users/self/clickouts`,
        params: { order, type },
      }),
      transformResponse(
        response: {
          clickouts: Clickout[];
          shops?: { name: string; id: number; slug_name: string; tracking_speed?: number }[];
          totalCount?: number;
        },
        meta,
      ) {
        return {
          shops: response.shops,
          nextCursor: undefined,
          data: response.clickouts,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || response.totalCount || 0,
        };
      },
      providesTags: ['clickouts'],
    }),

    //get user payments
    getUserPayments: builder.query<GenericRequest<Payment[]>, PaymentParams>({
      query: (args) => {
        const { nextCursor, date, limit, order, payment_option, sort } = args;
        return {
          method: 'GET',
          url: '/users/self/payments',
          params: { cursor: nextCursor, date, limit, order, payment_option, sort },
        };
      },
      transformResponse(response: GenericResponse<Payment[]>, meta) {
        const { responseData, nextCursor, totalCount } = extractData<Payment[]>(response);
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
      providesTags: ['payments'],
    }),

    // get user purchases
    getUserPurchases: builder.query<GenericRequest<Purchase[]>, PurchaseParams>({
      query: ({ nextCursor, date, limit, order, shop, status, sort }) => ({
        method: 'GET',
        url: '/users/self/purchases',
        params: { cursor: nextCursor, date, limit, order, shop, status, sort },
      }),
      transformResponse(response: GenericResponse<Purchase[]>, meta) {
        const { responseData, nextCursor, totalCount } = extractData<Purchase[]>(response);
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
      providesTags: ['purchases'],
    }),

    // get user cancel purchases (claims step two)
    getUserCanceledPurchases: builder.query<GenericRequest<Purchase[]>, PurchaseParams>({
      query: ({ nextCursor, date, limit, order, shop, sort }) => ({
        method: 'GET',
        url: '/users/self/purchases',
        params: { cursor: nextCursor, date, limit, order, shop, status: 2, sort, last_months: true },
      }),
      transformResponse(response: GenericResponse<Purchase[]>, meta) {
        const { responseData, nextCursor, totalCount } = extractData<Purchase[]>(response);
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
      providesTags: ['purchases'],
    }),

    // get user purchases shops
    getUserPurchasesShops: builder.query<GenericRequest<Purchase[]>, void>({
      query: () => {
        return {
          method: 'GET',
          url: `/users/self/purchases`,
          params: { column: 'shops' },
        };
      },
      transformResponse(response: GenericResponse<Purchase[]>, meta) {
        const { responseData, nextCursor, totalCount } = extractData<Purchase[]>(response);
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
      providesTags: ['purchasesShops'],
    }),

    // get count of purchases for each shop
    getPurchasesCountPerShop: builder.query<{ totalCount: number }, { shop: string; total_count?: string }>({
      query: ({ shop, total_count = 'true' }) => {
        return {
          method: 'GET',
          url: `/users/self/purchases`,
          params: { shop, total_count },
        };
      },
      transformResponse(response: { total_count: number }, meta) {
        return {
          totalCount: Number(response.total_count),
        };
      },
    }),

    // get user canceled purchases shops (claims step two)
    getCanceledPurchasesShops: builder.query<GenericRequest<Purchase[]>, void>({
      query: () => {
        return {
          method: 'GET',
          url: `/users/self/purchases`,
          params: { column: 'shops', status: 2, last_months: true },
        };
      },
      transformResponse(response: GenericResponse<Purchase[]>, meta) {
        const { responseData, nextCursor, totalCount } = extractData<Purchase[]>(response);
        return {
          data: responseData,
          totalCount: Number(meta?.response?.headers.get('X-TOTAL-COUNT')) || totalCount,
          nextCursor: nextCursor,
        };
      },
      providesTags: ['purchasesShops'],
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useLazyGetUserDataQuery,
  useLazyGetUserClaimsQuery,
  useLazyGetUserClickoutsQuery,
  useLazyGetUserPurchasesQuery,
  useLazyGetUserCanceledPurchasesQuery,
  useLazyGetUserPaymentsQuery,
  useGetUserPurchasesQuery,
  useGetUserClaimsShopsQuery,
  useGetUserPurchasesShopsQuery,
  useGetCanceledPurchasesShopsQuery,
  useLazyGetPurchasesCountPerShopQuery,
  useLazyGetClaimsCountPerShopQuery,
} = userApi;
