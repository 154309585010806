import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ArrowCircle } from '../assets';
import theme from '../theme';
import ReactCardFlip from 'react-card-flip';

const StyleBox = styled(Box)(({ theme }) => ({
  height: '372px',
  position: 'relative',
  borderRadius: '16px',
  border: '2px solid #ECEFF4',
  padding: '24px',
  boxSizing: 'border-box',
  background: theme.palette.error.contrastText,
}));

const StyleBoxYellow = styled(Box)(() => ({
  height: '372px',
  borderRadius: '16px',
  background: '#FEF6F1',
  border: '2px solid #F7BA97',
  boxSizing: 'border-box',
  padding: '24px',
}));

interface Props {
  img: string;
  title: string;
  subTitle: string;
  description: string;
}

const PigogoFlipCard: FC<Props> = (props) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [flipped, setFlipped] = useState<boolean>(false);

  const backSideRef = useRef<HTMLElement | null>(null);

  const firstBox = () => {
    return (
      <StyleBox onClick={() => setFlipped(true)}>
        <Box
          gap={3}
          height={'100%'}
          display={'flex'}
          textAlign={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <Box width={128} height={128}>
              <img src={props.img} alt={props.title} />
            </Box>
            <Box>
              <Typography variant="h4" color={'#1D2532'}>
                {props.title}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="largeL" component="p" color={'#313D53'}>
              {props.subTitle}
            </Typography>
          </Box>
        </Box>
        <Box
          left={0}
          right={0}
          bottom={'24px'}
          display={'flex'}
          position={'absolute'}
          justifyContent={'center'}
          onClick={() => setFlipped(true)}
        >
          <ArrowCircle />
        </Box>
      </StyleBox>
    );
  };

  const secondBox = () => {
    return (
      <StyleBoxYellow onClick={() => setFlipped(false)} ref={backSideRef}>
        <Box display={'flex'} flexDirection={'column'} textAlign={'center'} gap={3}>
          <Typography variant="h4" color={'#1D2532'}>
            {props.title}
          </Typography>
          <Typography variant="body2MediumL" component="p" color={'#313D53'}>
            {props.description}
          </Typography>
        </Box>
      </StyleBoxYellow>
    );
  };
  return (
    <Box
      onMouseLeave={() => {
        setTimeout(() => {
          !mdDown && setFlipped(false);
        }, 600);
      }}
      onMouseEnter={() => !mdDown && setFlipped(true)}
    >
      <ReactCardFlip isFlipped={flipped} flipSpeedBackToFront={1} flipSpeedFrontToBack={1}>
        {firstBox()}
        {secondBox()}
      </ReactCardFlip>
    </Box>
  );
};

export default PigogoFlipCard;
