import { GamificationLevelString } from '../../../../models/User';

const ExtractSaverColor = (saver: GamificationLevelString) => {
  switch (saver) {
    case GamificationLevelString.saver:
      return '#FC9E5F';
    case GamificationLevelString.smart:
      return '#4FD69C';
    case GamificationLevelString.expert:
      return '#37D5F2';
    case GamificationLevelString.super:
      return '#F75676';
    case GamificationLevelString.guru:
      return '#9456F7';
    default:
      return null;
  }
};

export default ExtractSaverColor;
