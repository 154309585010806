import React, { FC } from 'react';
import PigogoSymbolWrapper from '../../PigogoSymbolWrapper';
import { Box, Typography } from '@mui/material';
import CustomContainer from '../../CustomContainer';

interface TopBannerProps {
  title: string;
}

const TopBanner: FC<TopBannerProps> = ({ title }) => {
  return (
    <PigogoSymbolWrapper typeOfWrapper="404">
      <CustomContainer maxWidth="md">
        <Box display="flex" py={10} flexDirection="column" justifyContent="center" alignItems={'center'}>
          <Typography variant="smallB" component="h2" color={'#1D2532'}>
            {title}
          </Typography>
        </Box>
      </CustomContainer>
    </PigogoSymbolWrapper>
  );
};

export default TopBanner;
