import { Box, Typography, useMediaQuery, List, ListItem } from '@mui/material';
import { theme } from 'components';
import { ReactComponent as Rocket } from '../../../assets/rocket.svg';
import { ReactComponent as ArrowRight } from '../../../assets/svgs/arrowRight.svg';
import { useGetUserDataQuery } from '../../../redux/api/queries/userQueries';
import { useIonRouter } from '@ionic/react';
import { Link } from 'react-router-dom';

const HowItWorks = () => {
  const router = useIonRouter();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: userData } = useGetUserDataQuery();

  return userData && userData.gamification_level_id === 0 ? (
    <Box
      gap={2}
      width="100%"
      display="flex"
      p={mobile ? 3 : 4}
      borderRadius="16px"
      justifyContent="space-between"
      sx={{ backgroundColor: '#FEF6F1' }}
      flexDirection={mobile ? 'column' : 'row'}
      mt={mobile ? -1 : -4}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="largeMedium" component="h3" color={'#313D53'}>
            Πώς λειτουργεί
          </Typography>
          <List disablePadding={true}>
            <ListItem sx={{ padding: 0 }}>
              <Typography variant="body2MediumR" color={'#313D53'}>
                <b>Βήμα 1:</b> Βρες το κατάστημα στο Pigogo
              </Typography>
            </ListItem>
            <ListItem sx={{ padding: 0 }}>
              <Typography variant="body2MediumR" color={'#313D53'}>
                <b>Βήμα 2:</b> Κάνε την αγορά σου απο το κατάστημα
              </Typography>
            </ListItem>
            <ListItem sx={{ padding: 0 }}>
              <Typography variant="body2MediumR" color={'#313D53'}>
                <b>Βήμα 3:</b> Συγχαρητήρια! Η αγορά σου καταγράφηκε!
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Link className="iconLink" to={'/how-it-works'}>
            <Typography variant="body2MediumM" color={'#1D2532'}>
              Δες περισσότερα
            </Typography>
            <ArrowRight />
          </Link>
        </Box>
      </Box>

      <Rocket />
    </Box>
  ) : null;
};

export default HowItWorks;
