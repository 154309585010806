import { Box, InputAdornment, Skeleton, Typography, useMediaQuery } from '@mui/material';
import TellAFriendCard from './TellAFriendCard';
import React, { useEffect } from 'react';
import { ReactComponent as LinkVertical } from '../../../assets/svgs/linkVertical.svg';
import { PigogoSmallButton, PigogoTextField, theme } from 'components';
import { ReactComponent as Send } from '../../../assets/svgs/illustrations/tellAFriend/send.svg';
import { useReferralQuery } from '../../../redux/api/queries/tellAFriendQuery';
import { useAppDispatch } from '../../../redux/hooks';
import { setSnackBar } from '../../../redux/slices/layoutSlice';

const ShareWithLink = () => {
  const dispatch = useAppDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data: referralData,
    error: referralError,
    isError: isErrorReferral,
    isLoading: referralLoading,
  } = useReferralQuery();

  useEffect(() => {
    if (isErrorReferral) {
      dispatch(setSnackBar({ msg: 'Δεν μπορέσαμε να βρούμε το referral link!', severity: 'error', value: true }));
    } else {
      dispatch(setSnackBar({ msg: '', severity: '', value: false }));
    }
  }, [isErrorReferral, referralError]);

  const handleLinkClick = (link: string) => {
    navigator.clipboard.writeText(link);
    dispatch(setSnackBar({ value: true, msg: 'To link αντιγράφηκε με επιτυχία', severity: 'infoBlue' }));
  };

  return (
    <TellAFriendCard title="Μέσω referral link" img={<Send style={{ width: '64px', height: '64px' }} />}>
      <Typography color="#6F7787" variant="body2MediumR" component="p">
        Στείλε το παρακάτω link στους φίλους σου με όποιον τρόπο θέλεις (Messenger, Viber, WhatsApp, SMS, e-mail
        κ.ο.κ.). Αν οι φίλοι σου τελικά εγγραφούν στο Pigogo (μέσω του link σου), αυτό θα καταγραφεί στον λογαριασμό
        σου!
      </Typography>
      <Box display="flex" flexDirection={mobile ? 'column' : 'row'} py={mobile ? 1 : 0} gap={2}>
        {referralLoading && (
          <Skeleton width="316px" height="56px" variant="rectangular" sx={{ borderRadius: '30px' }} />
        )}
        {!referralLoading && referralData && (
          <PigogoTextField
            fullWidth={mobile}
            value={referralData?.referral_link}
            sx={{
              minWidth: !mobile ? '316px' : undefined,
              '& .MuiOutlinedInput-input': {
                fontWeight: 400,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkVertical />
                </InputAdornment>
              ),
            }}
          />
        )}
        <Box>
          <PigogoSmallButton
            sx={{ px: 3 }}
            text="Αντιγραφή Link"
            disabled={referralLoading}
            onClick={() => handleLinkClick(referralData?.referral_link ?? '')}
            typographyProps={{ variant: 'subtitle1SmallM', lineHeight: '24px' }}
          />
        </Box>
      </Box>
    </TellAFriendCard>
  );
};

export default ShareWithLink;
