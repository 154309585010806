export const validation = (nameSur: string) => {
  return /[{Greek}[α-ωΑ-Ω^A-Za-z0-9]{2,}/g.test(nameSur);
};

export const msgValid = (msg: string) => {
  return /.{6,}/.test(msg);
};

const emailRegex =
  /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/;

export const emailValid = (email: string) => {
  return emailRegex.test(email);
};
