import { Checkbox, FormControlLabel, MenuItem, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import {
  useLazyGetClaimsCountPerShopQuery,
  useLazyGetPurchasesCountPerShopQuery,
} from '../../redux/api/queries/userQueries';

const StyledMenuItem = styled(MenuItem)({
  '&': {
    padding: 0,
    whiteSpace: 'normal',
  },
  '&.Mui-selected': {
    backgroundColor: '#EAECEE',
  },
  '& .MuiFormControlLabel-root': {
    margin: 0,
    padding: '16px 24px',
    alignItems: 'flex-start',
    width: '100%',
  },
});

interface PropsItem {
  claims?: boolean;
  purchases?: boolean;
  option: { label: string; value: string };
  selectedOptions: { label: string; value: string }[];
  setSelectedOptions: (optionsChecked: { label: string; value: string }[]) => void;
}

const MultipleDropdownItem: FC<PropsItem> = ({
  option,
  claims = false,
  purchases = false,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [purchaseCountTrigger, { data: purchasesCountData, isLoading: isLoadingPurchases, isError: isErrorPurchases }] =
    useLazyGetPurchasesCountPerShopQuery();

  const [claimsCountTrigger, { data: claimsCountData, isLoading: isLoadingClaims, isError: isErrorClaims }] =
    useLazyGetClaimsCountPerShopQuery();

  useEffect(() => {
    if (claims) {
      claimsCountTrigger({ shop: option.value });
    }
    if (purchases) {
      purchaseCountTrigger({ shop: option.value });
    }
  }, [claims, purchases, option.value, claimsCountTrigger, purchaseCountTrigger]);

  const handleOptionChange = (label: string, value: string) => {
    const find = selectedOptions.find((selectedOption) => selectedOption.value === value);
    if (find) {
      setSelectedOptions(selectedOptions.filter((e) => e.value !== value));
    } else {
      const newCategories = [...selectedOptions, { label: label.toString(), value: value }];
      setSelectedOptions(newCategories);
    }
  };

  const formLabel = () => {
    if (!claims && !purchases) {
      return option.label;
    }

    const loading = isLoadingClaims || isLoadingPurchases;
    const error = isErrorClaims || isErrorPurchases;

    const count = purchasesCountData?.totalCount || claimsCountData?.totalCount || 0;
    const countText = loading ? '...' : error ? 'N/A' : count;

    return `${option.label} (${countText})`;
  };

  return (
    <StyledMenuItem key={option.value} value={option.label}>
      <FormControlLabel
        label={formLabel()}
        sx={{
          '& .MuiTypography-root': {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '1.71',
            color: '#313D53',
          },
        }}
        control={
          <Checkbox
            color={'secondary'}
            checked={selectedOptions.some((currentOption) => currentOption.value === option.value)}
            onChange={() => handleOptionChange(option.label, option.value)}
            sx={{
              '&': {
                padding: 0,
                width: '20px',
                height: '20px',
                marginRight: '8px',
                border: '1px solid #838B98',
                borderRadius: '4px',
                color: 'transparent',
                position: 'relative',
                top: '2px',
              },
              '&.Mui-checked': {
                borderColor: '#E9688A',
                outline: '1px solid #E9688A',
                outlineOffset: '-2px',
              },
            }}
          />
        }
      />
    </StyledMenuItem>
  );
};

export default MultipleDropdownItem;
