import React, { FC } from 'react';
import { Box, Dialog, IconButton, styled, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Success } from '../../../assets/svgs/illustrations/pigogoSad.svg';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { theme } from 'components';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '480px',
    borderRadius: '16px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      width: '343px',
    },
  },
}));
interface Props {
  open: boolean;
  title?: string;
  subTitle?: string;
  height?: string;
  handleClose: (value: boolean) => void;
}

const TellAFriendModal: FC<Props> = ({
  open,
  height = '336px',
  subTitle = 'Για να προσκαλέσετε τους φίλους σας, θα πρέπει πρώτα να συμπληρώσετε το ονοματεπώνυμό σας στα στοιχεία του λογαριασμού σας.',
  handleClose,
  title = 'Αποτυχία αποστολής πρόσκλησης',
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CustomDialog open={open} onClose={() => handleClose(false)}>
      <IconButton
        disableRipple
        className="iconBtn"
        onClick={() => handleClose(false)}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
          padding: 0,
          width: '24px',
          height: '24px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Close />
      </IconButton>
      <Box
        display={'flex'}
        boxSizing={'border-box'}
        justifyContent={'center'}
        paddingTop={mdDown ? 8 : 9}
        paddingLeft={mdDown ? 3 : 4}
        paddingRight={mdDown ? 3 : 4}
        paddingBottom={mdDown ? 4 : 6}
        width={mdDown ? 'auto' : '480px'}
        height={mdDown ? 'auto' : height}
      >
        <Box
          gap={2}
          display="flex"
          height={'100%'}
          alignItems="center"
          textAlign={'center'}
          flexDirection="column"
          justifyContent="center"
        >
          <Box>
            <Success />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant={'largeMedium'} color={'#313D53'} fontWeight={700}>
              {title}
            </Typography>
          </Box>
          {subTitle && (
            <Box>
              <Typography variant={'body2MediumR'} color={'#313D53'}>
                {subTitle}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default TellAFriendModal;
