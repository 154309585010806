import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { pigogoApi } from './api/Api';
import authenticationReducer from './slices/authenticationSlice';
import layoutReducer from './slices/layoutSlice';

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    layout: layoutReducer,
    [pigogoApi.reducerPath]: pigogoApi.reducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(pigogoApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export default store;
