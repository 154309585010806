import { Box, BoxProps, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Assets } from 'components';
import { Link } from 'react-router-dom';

const StyleBox = styled(Link)(({ theme }) => ({
  boxSizing: 'border-box',
  background: '#FEF6F1',
  color: '#E9688A',
  borderRadius: 56,
  padding: '16px 24px',
  cursor: 'pointer',
  textDecoration: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

type StoreCardProps = {
  url: string;
};

const TipsButton: FC<StoreCardProps & BoxProps> = ({ url }) => {
  return (
    <StyleBox to={url}>
      <Box component="span" display="flex" alignItems={'center'} gap={1}>
        <Box
          component="span"
          sx={{ width: '24px', height: '24px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Assets.Union />
        </Box>

        <Typography component="span" variant="subtitle1SmallM" color={'#E9688A'} sx={{ display: 'inline-block' }}>
          Tips για την επιστροφή
        </Typography>
      </Box>
      <Box
        component="span"
        sx={{ width: '24px', height: '24px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Assets.ArrowRightPink />
      </Box>
    </StyleBox>
  );
};

export default TipsButton;
