import React, { FC } from 'react';
import PigogoIconButton from './PigogoIconButton';
import { Box, IconButton } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import theme from '../theme/index';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChevronLeft, ChevronRight, Dot, DotUnchecked } from '../assets';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ButtonGroup = ({
  next,
  previous,
  currentSlide,
  totalItems,
  slidesToShow,
}: {
  next?: any;
  previous?: any;
  currentSlide?: any;
  totalItems?: any;
  slidesToShow?: any;
}) => {
  return (
    <Box position="relative" width="100%">
      <PigogoIconButton
        props={{ sx: { left: -50, top: -150, position: 'absolute' } }}
        icon={<ChevronLeft />}
        handleClick={() => previous()}
      />
      <PigogoIconButton
        props={{ sx: { right: -50, top: -150, position: 'absolute' } }}
        icon={<ChevronRight />}
        handleClick={() => next()}
      />
    </Box>
  );
};

const PigogoCustomDot = ({ onClick, active }: { onClick?: any; active?: boolean }) => {
  return active ? (
    <IconButton
      size="small"
      sx={{ bottom: -20, padding: '0 3px', backgroundColor: '#FFFFFF' }}
      disableRipple
      onClick={() => onClick()}
    >
      <Dot />
    </IconButton>
  ) : (
    <IconButton
      size="small"
      sx={{ bottom: -20, padding: '0 3px', backgroundColor: '#FFFFFF' }}
      disableRipple
      onClick={() => onClick()}
    >
      <DotUnchecked />
    </IconButton>
  );
};

interface Props {
  children: React.ReactNode;
}

const IntroCarousel: FC<Props> = ({ children }) => {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Box position="relative">
      <Carousel
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={matches ? undefined : <ButtonGroup />}
        customDot={<PigogoCustomDot />}
        swipeable={true}
        partialVisible={matchesMd || matches}
        renderDotsOutside={true}
        draggable={true}
        showDots
        responsive={responsive}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
      >
        {children}
      </Carousel>
    </Box>
  );
};

export default IntroCarousel;
