import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, IconButtonProps } from '@mui/material';

const CustomFloatingIconButton = styled(IconButton)(({ theme }) => ({
  color: 'var(--text)',
  background: 'var(--color)',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.16))',
  '&:hover': {
    filter: 'brightness(85%)',
  },
  '&:active': {
    filter: 'brightness(85%)',
  },
  '&:focus': {
    outline: '2px solid rgba(60, 104, 200, 0.5)',
  },
  '&:disabled': {
    background: 'var(--disabled)',
  },
}));

const primaryColor = {
  '--color': '#313D53',
} as React.CSSProperties;

const orangeColor = {
  '--color': '#FCE5D8',
} as React.CSSProperties;

interface Props {
  props?: IconButtonProps;
  background: 'primary' | 'orange';
  icon?: ReactNode;
  handleClick: (value: any) => void;
}

const PigogoFloatingIconButton: FC<Props> = ({ props, icon, background, handleClick }) => {
  const getVar = () => {
    if (background === 'primary') {
      return primaryColor;
    } else {
      if (background === 'orange') {
        return orangeColor;
      }
    }
  };

  return (
    <CustomFloatingIconButton style={getVar()} disableRipple {...props} onClick={handleClick}>
      {icon}
    </CustomFloatingIconButton>
  );
};

export default PigogoFloatingIconButton;
