import React, { useEffect, useState } from 'react';
import { IonText, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { PigogoButton, PigogoDropdown, PigogoTextArea, PigogoTextField, theme } from 'components';
import { ReactComponent as Success } from '../../assets/svgs/illustrations/successNew.svg';
import { Box, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import { ContactParams } from '../../redux/api/types/ContactParams';
import { emailValid, msgValid, validation } from '../../utils/validation';
import { usePostContactMutation } from '../../redux/api/mutations/contactMutation';
import { useDispatch } from 'react-redux';
import { setLoad, setSnackBar } from '../../redux/slices/layoutSlice';
import { useGetUserDataQuery } from '../../redux/api/queries/userQueries';

const StyleSuccessBox = styled(Box)(() => ({
  opacity: 1,
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  borderRadius: '16px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
    right: '5px',
  },
  [theme.breakpoints.up('md')]: {
    width: '496px',
    padding: '26px 32px 32px 32px',
  },
}));

const StyleBox = styled(Box)(() => ({
  gap: '16px',
  display: 'flex',
  paddingTop: '24px',
  alignItems: 'center',
  flexDirection: 'column',
  borderSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '496px',
  },
}));

const list = [
  'Γενικές πληροφορίες',
  'Θέματα πληρωμών',
  'Θέματα καταγραφής αγορών',
  'Είμαι ηλεκτρονικό κατάστημα',
  'Άλλο',
];

const ContactTextFields = () => {
  const dispatch = useDispatch();

  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [
    postContact,
    { isError: errorContact, isSuccess: successContact, isLoading: loadingButton, reset: resetContact },
  ] = usePostContactMutation();
  const { data: userData, isLoading: isUserLoading, isSuccess: isUserSuccess } = useGetUserDataQuery();

  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [themeContact, setThemeContact] = useState('');
  const [errorName, setErrorName] = useState<string>('');
  const [errorList, setErrorList] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [leaving, setLeaving] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<string>('');
  const [email, setEmail] = useState<string>(userData && userData.email ? userData.email : '');
  const [nameSur, setNameSur] = useState<string>(
    userData && userData.full_name && userData.full_name !== '' ? userData.full_name : '',
  );

  useIonViewDidEnter(() => {
    setLeaving(false);
  });

  useIonViewDidLeave(() => {
    setLeaving(true);

    setEmail(userData && userData.email ? userData.email : '');
    setNameSur(userData && userData.full_name ? userData.full_name : '');
    setMsg('');
    setThemeContact('');

    setErrorName('');
    setErrorMsg('');
    setErrorEmail('');
    setErrorList('');

    setSuccess(false);
  });

  useEffect(() => {
    setEmail(userData && userData.email ? userData.email : '');
    setNameSur(userData && userData.full_name ? userData.full_name : '');
  }, [userData, isUserLoading, isUserSuccess]);

  useEffect(() => {
    if (leaving) {
      resetContact();
    }
  }, [leaving, resetContact]);

  useEffect(() => {
    dispatch(setLoad(loadingButton));
  }, [dispatch, loadingButton]);

  useEffect(() => {
    if (successContact) {
      setSuccess(true);
    }
  }, [successContact]);

  useEffect(() => {
    if (errorContact) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά!', severity: 'error' };
      dispatch(setSnackBar(params));
    }
  }, [errorContact]);

  const handleClick = () => {
    setErrorName('');
    setErrorMsg('');
    setErrorEmail('');
    setErrorList('');

    if (!nameSur) {
      setErrorName('Το πεδίο είναι υποχρεωτικό.');
    } else if (!validation(nameSur)) {
      setErrorName('Το ονοματεπώνυμο πρέπει να αποτελείται από τουλάχιστον 2 χαρακτήρες.');
    }

    if (!email.trim()) {
      setErrorEmail('Το πεδίο είναι υποχρεωτικό.');
    } else if (!emailValid(email.trim())) {
      setErrorEmail('To email δεν φαίνεται να είναι σωστό.');
    }

    if (!list.includes(themeContact)) {
      setErrorList('Διάλεξε το θέμα που αφορά το μήνυμά σου.');
    }

    if (!msgValid(msg) || !msg) {
      setErrorMsg('Το μήνυμα πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες.');
    }

    if (
      nameSur &&
      validation(nameSur) &&
      msg &&
      msgValid(msg) &&
      emailValid(email.trim()) &&
      list.includes(themeContact)
    ) {
      const params: ContactParams = { name: nameSur, email: email.trim(), subject: themeContact, message: msg };
      postContact({ body: params });
    }
  };

  const successCard = () => {
    return (
      <StyleSuccessBox>
        <Box style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Success />
        </Box>
        <Box
          gap={2}
          display="flex"
          flexDirection="column"
          sx={{ backgroundColor: '#FEF6F8', borderRadius: '24px', padding: smDown ? '24px' : '48px' }}
        >
          <Box textAlign="center">
            <IonText style={{ fontWeight: 700, fontSize: 20, lineHeight: '24px', color: '#1D2532' }}>
              Ευχαριστούμε για το μήνυμά σου!
            </IonText>
          </Box>
          <Box textAlign="center">
            <IonText
              style={{ textalign: 'center', fontWeight: 500, fontSize: 16, lineHeight: '24px', color: '#313D53' }}
            >
              Θα φροντίσουμε να έχεις απάντησή μας εντός 24 ωρών. Μην ξεχάσεις να ελέγξεις και τον φάκελο ανεπιθύμητης
              αλληλογραφίας (Spam/Junk)!
            </IonText>
          </Box>
        </Box>
      </StyleSuccessBox>
    );
  };

  return (
    <Box>
      <Typography variant="body1" component="h3" pl={1} color={'#1D2532'}>
        Φόρμα επικοινωνίας
      </Typography>
      {success && successCard()}
      <StyleBox sx={{ opacity: success ? '10%' : 'unset' }}>
        <PigogoTextField
          inputProps={{ disabled: success }}
          fullWidth={true}
          value={nameSur}
          setValue={(value: string) => {
            setNameSur(value);
            setErrorName('');
          }}
          label="Ονοματεπώνυμο"
          error={!!errorName}
          helperText={errorName ? errorName : undefined}
        />

        <PigogoTextField
          fullWidth
          inputProps={{ disabled: success }}
          label={'Em\u200Aail'}
          value={email}
          setValue={(value: string) => {
            setEmail(value);
            setErrorEmail('');
          }}
          error={!!errorEmail}
          helperText={errorEmail ? errorEmail : undefined}
        />

        <PigogoDropdown
          fullWidth
          disabled={success}
          options={list}
          size={'medium'}
          value={themeContact}
          setSelected={(value: string) => {
            setThemeContact(value);
            setErrorList('');
          }}
          label="Επίλεξε θέμα"
          error={!!errorList}
          helperText={errorList ? errorList : undefined}
        />

        <PigogoTextArea
          inputProps={{ rows: 1, fullWidth: true, disabled: success }}
          value={msg}
          setValue={(value: string) => {
            setMsg(value);
            setErrorMsg('');
          }}
          label="Γράψε το μήνυμα ή την ερώτησή σου"
          error={!!errorMsg}
          helperText={errorMsg ? errorMsg : undefined}
        />

        <Box textAlign={'start'} width={'100%'}>
          <PigogoButton
            disabled={success}
            text={'Αποστολή'}
            onClick={handleClick}
            variation={'secondary'}
            sx={{ width: mobile ? '100%' : '327px' }}
          />
        </Box>
      </StyleBox>
    </Box>
  );
};

export default ContactTextFields;
