import { Box, BoxProps, styled, Typography, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { ArrowRight } from '../assets';
import theme from '../theme';

const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '16px',
  background: '#ECEFF4',
  flexDirection: 'column',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    minHeight: '240px',
    padding: '32px 24px',
    gap: theme.spacing(0),
  },
  [theme.breakpoints.up('md')]: {
    width: '496px',
    height: '293px',
    gap: '13px',
    padding: '72px 104px 72px 48px',
    maxWidth: '100%',
  },
}));

interface Props {
  title: string;
  subTitle: string;
  linkText: string;
  onClick: () => void;
}

const PigogoEntryPoint: FC<Props & BoxProps> = ({ title, subTitle, linkText, onClick, ...rest }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyleBox onClick={onClick} {...rest}>
      <Box display={'flex'} flexDirection={'column'} gap={mdDown ? 1 : 2}>
        <Typography variant="large900" component="h3" color="#1D2532">
          {title}
        </Typography>
        <Box minHeight={'72px'} sx={{ marginTop: mdDown ? '8px' : 0 }}>
          <Typography variant="buttonSmallM" component="p" color="#313D53" letterSpacing={0}>
            {subTitle}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} textAlign={'end'} alignItems={'center'} gap={1}>
        <Typography variant="buttonSmallM" color="#1D2532" letterSpacing={0}>
          {linkText}
        </Typography>
        <Box
          sx={{ width: '24px', height: '24px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <ArrowRight />
        </Box>
      </Box>
    </StyleBox>
  );
};

export default PigogoEntryPoint;
