import React, { useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import ClaimStep from './ClaimStep';
import StepOne from './StepContent/StepOne';
import StepThree from './StepContent/StepThree';
import StepTwo from './StepContent/StepTwo';
import { ClaimTypeId } from '../../models/ClaimTypeId';
import { extractCreateClaimType } from '../../utils/extractCreateClaimType';
import { useCreateUserClaimMutation } from '../../redux/api/mutations/userMutations';
import SuccessMessage from './SuccessMessage';
import { theme } from 'components';
import moment from 'moment';
import { CancellationDetails, CreateClaimStepThree } from '../../models/CreateClaimStepThree';
import { useAppDispatch } from '../../redux/hooks';
import { setLoad, setSnackBar } from '../../redux/slices/layoutSlice';
import { IClaimCreate } from '../../models/IClaimCreate';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import CustomContainer from '../CustomContainer';
import { Shop } from '@mui/icons-material';

const initialStepThreeState = {
  user_note: '',
  order_amount: '',
  payment_type: '',
  order_number: '',
  order_coupon_code: '',
  purchase_datetime: '',
  confirmation_email: '',
  has_coupon_code: false,
  cashback_category_id: [] as string[],
  confirmation_date: null,
  confirmation_time: null,
  has_confirmation_email: true,
  cancellation_details_id: CancellationDetails.def,
};

const initialStepTwoState = {
  shop_id: '',
  slugName: '',
  selectedShop: '',
  selectedDate: '',
  selectedTime: '',
  purchase_id: '',
  clickout_id: '',
  selectedMonth: '',
  months: [] as string[],
};

const ClaimStepContainer = () => {
  const dispatch = useAppDispatch();

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [currentStep, setCurrentStep] = useState(0);
  const [typeOfClaim, setTypeOfClaim] = useState<ClaimTypeId>(0);
  const [shop, setShop] = useState('');
  const [stepTwoContent, setStepTwoContent] = useState(initialStepTwoState);
  const [stepThreeContent, setStepThreeContent] = useState<CreateClaimStepThree>(initialStepThreeState);

  const [leaving, setLeaving] = useState(false);

  const [createClaim, { isLoading, isSuccess, isError, error, reset }] = useCreateUserClaimMutation();

  const scrollToRef: any = useRef(null);

  useEffect(() => {
    setStepTwoContent(initialStepTwoState);
    setStepThreeContent(initialStepThreeState);
    stepThreeContent.cashback_category_id.length = 0;
  }, [typeOfClaim]);

  useEffect(() => {
    setStepThreeContent(initialStepThreeState);
    stepThreeContent.cashback_category_id.length = 0;
  }, [shop]);

  useEffect(() => {
    if (isSuccess) {
      scrollToRef?.current?.scrollIntoView();
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(setLoad(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      dispatch(setSnackBar({ msg: 'Το αίτημα δεν καταχωρήθηκε!', severity: 'error', value: true }));
    } else {
      dispatch(setSnackBar({ msg: '', severity: '', value: false }));
    }
  }, [isError]);

  useEffect(() => {
    if (leaving) {
      reset();
      setCurrentStep(0);
      setTypeOfClaim(0);
      setStepTwoContent(initialStepTwoState);
      setStepThreeContent(initialStepThreeState);
      stepThreeContent.cashback_category_id.length = 0;
    }
  }, [leaving]);

  useIonViewWillEnter(() => {
    setLeaving(false);
  });
  useIonViewWillLeave(() => {
    setLeaving(true);
  });

  const handleSubmitClaim = (data: CreateClaimStepThree, otherPaymentType: string) => {
    let body: IClaimCreate;

    if (typeOfClaim === 3) {
      body = {
        claim_type_id: typeOfClaim,
        user_note: data.user_note,
        shop_id: parseInt(stepTwoContent.shop_id),
        confirmation_email: data.confirmation_email,
        has_confirmation_email: data.has_confirmation_email,
        cancellation_details_id: data.cancellation_details_id,
        confirmation_time: data.confirmation_time ? moment(data.confirmation_time).format('HH:mm') : '',
        confirmation_date: data.confirmation_time ? moment(data.confirmation_date).format('DD-MM-YYYY') : '',
        purchase_id: stepTwoContent.purchase_id ? parseInt(stepTwoContent.purchase_id) : 0,
      };
    } else {
      body = {
        user_note: data.user_note,
        claim_type_id: typeOfClaim,
        order_number: data.order_number,
        order_amount: Number(data.order_amount.replace(',', '.')),
        has_coupon_code: data.has_coupon_code,
        clickout_id: stepTwoContent.clickout_id,
        shop_id: parseInt(stepTwoContent.shop_id),
        order_coupon_code: data.order_coupon_code,
        confirmation_email: data.confirmation_email,
        cashback_category_id: data.cashback_category_id.join(','),
        has_confirmation_email: data.has_confirmation_email,
        cancellation_details_id: data.cancellation_details_id,
        confirmation_time: data.confirmation_time ? moment(data.confirmation_time).format('HH:mm') : '',
        confirmation_date: data.confirmation_time ? moment(data.confirmation_date).format('DD-MM-YYYY') : '',
        payment_type: data.payment_type === 'other' ? otherPaymentType : data.payment_type,
        purchase_id: stepTwoContent.purchase_id ? parseInt(stepTwoContent.purchase_id) : 0,
      };
    }

    createClaim({ body });
  };

  const renderStepsContent = (step: number) => {
    if (currentStep !== step) {
      return;
    }

    switch (currentStep) {
      case 0:
        return <StepOne typeOfClaim={typeOfClaim} setTypeOfClaim={setTypeOfClaim} setCurrentStep={setCurrentStep} />;
      case 1:
        return (
          <StepTwo
            typeOfClaim={typeOfClaim}
            setShop={setShop}
            setCurrentStep={setCurrentStep}
            stepTwoContent={stepTwoContent}
            setStepTwoContent={setStepTwoContent}
          />
        );
      case 2:
        return (
          <StepThree
            typeOfClaim={typeOfClaim}
            handleSubmit={handleSubmitClaim}
            stepThreeContent={stepThreeContent}
            shopSlugName={stepTwoContent.slugName}
            setStepThreeContent={setStepThreeContent}
            submitLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };

  const renderSteps = () => {
    return (
      <CustomContainer>
        <Box display={'flex'} flexDirection={'column'}>
          <ClaimStep
            index={1}
            title={'Είδος αιτήματος'}
            highlighted={currentStep >= 0}
            subtitle={extractCreateClaimType(typeOfClaim)}
            handleEdit={currentStep > 0 ? () => setCurrentStep(0) : undefined}
            currentStep={currentStep}
          >
            {renderStepsContent(0)}
          </ClaimStep>
          <ClaimStep
            index={2}
            highlighted={currentStep >= 1}
            title={
              typeOfClaim === 3
                ? 'Επιλογή ηλεκτρονικού καταστήματος και αγοράς'
                : 'Στοιχεία επίσκεψης στο ηλεκτρονικό κατάστημα'
            }
            handleEdit={currentStep > 1 ? () => setCurrentStep(1) : undefined}
            currentStep={currentStep}
          >
            {renderStepsContent(1)}
          </ClaimStep>
          <ClaimStep index={3} highlighted={currentStep >= 2} title={'Στοιχεία αγοράς'} currentStep={currentStep}>
            {renderStepsContent(2)}
          </ClaimStep>
        </Box>
      </CustomContainer>
    );
  };

  const renderMain = () => {
    if (isSuccess) {
      return (
        <Box ref={scrollToRef}>
          <SuccessMessage />
        </Box>
      );
    }

    return renderSteps();
  };

  return (
    <Box pt={mdDown ? 4 : 10} pb={currentStep !== 2 ? '120px' : undefined}>
      {renderMain()}
    </Box>
  );
};

export default ClaimStepContainer;
