import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import React from 'react';

const TellAFriendTerms = () => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box display={'flex'} flexDirection={'column'} padding={mobile ? '24px 16px 0' : '24px 32px 0'}>
      <Box>
        <Typography variant="body1" color={'#313D53'} component="h3">
          Όροι & προϋποθέσεις
        </Typography>
      </Box>
      <Box>
        <ul style={{ paddingLeft: '18px', display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: 0 }}>
          <li style={{ color: '#313D53' }}>
            <Typography variant="body2MediumL" color={'#313D53'}>
              Τα 5€ θα πιστώνονται στον λογαριασμό σου μόνον εφόσον ο φίλος που σύστησες εγγραφεί μέσω του email που
              έστειλες ή του link που βρήκες σε αυτή τη σελίδα.
            </Typography>
          </li>
          <li style={{ color: '#313D53' }}>
            <Typography variant="body2MediumL" color={'#313D53'}>
              Για να εγκριθούν τα χρήματα, θα πρέπει να ολοκληρωθεί η εγγραφή καθώς και 2 ή παραπάνω αγορές, οι οποίες
              θα εγκριθούν από τα αντίστοιχα Συνεργαζόμενα Καταστήματα και θα εξασφαλίζουν στον φίλο σου συνολική
              επιστροφή χρημάτων τουλάχιστον 3€, μέσα σε 12 μήνες από την ημερομηνία εγγραφής του.
            </Typography>
          </li>
          <li style={{ color: '#313D53' }}>
            <Typography variant="body2MediumL" color={'#313D53'}>
              Δεν θα αποδίδεται προμήθεια αν ο φίλος που σύστησες είχε ήδη λογαριασμό στο Pigogo.
            </Typography>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default TellAFriendTerms;
