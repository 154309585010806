import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { FC } from 'react';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '12px',
    fontWeight: 400,
    color: '#FFFFFF',
    fontSize: '12px',
    maxWidth: '216px',
    lineHeight: '16px',
    borderRadius: '8px',
    backgroundColor: '#1D2532',
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: '16px !important',
  },
}));

type PigogoTooltipProps = {
  children: any;
};
const PigogoTooltip: FC<PigogoTooltipProps & TooltipProps> = ({ children, ...rest }) => {
  return <StyledTooltip {...rest}>{children}</StyledTooltip>;
};

export default PigogoTooltip;
