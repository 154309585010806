import { FC } from 'react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';

const StyleBox = styled(Box)(({ theme }) => ({
  padding: '24px 16px 40px 16px',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  borderRadius: '16px',
  width: '330px',
  height: '408px',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

interface Props {
  icon: string;
  title: string;
  description: string;
  color: string;
}

const SaverCard: FC<Props> = ({ icon, title, description, color }) => {
  return (
    <StyleBox>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={icon} />
        </Box>
        <Box textAlign="center">
          <Typography color={color} variant="h4" component="h3">
            {title}
          </Typography>
        </Box>
      </Box>
      <Box textAlign="center">
        <Typography variant="body2MediumR" component="p" color={'#313D53'}>
          {description}
        </Typography>
      </Box>
    </StyleBox>
  );
};

export default SaverCard;
