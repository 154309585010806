import React, { FC } from 'react';
import { Box, BoxProps, Skeleton, styled, Typography } from '@mui/material';

const StyleHorizontalBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  height: '80px',
  background: '#fff',
  borderRadius: '24px',
  display: 'inline-flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  transition: 'border-color .35s ease',
  border: `4px solid ${theme.palette.orange.main}`,
  [theme.breakpoints.down('md')]: {
    borderRadius: '16px',
    border: `2px solid ${theme.palette.orange.main}`,
    height: '76px',
  },
  '&:hover': {
    borderColor: `${theme.palette.orange.dark}`,
  },
}));

interface Props {
  shop?: any;
  loading?: boolean;
  boxProps?: BoxProps;
  animation?: 'pulse' | 'wave' | false;
  onClick?: () => void;
  cname?: string;
}

const PigogoHorizontalProductCard: FC<Props> = ({
  cname = 'border-hover',
  shop,
  onClick,
  loading,
  animation = 'pulse',
  boxProps,
}) => {
  return (
    <StyleHorizontalBox
      {...boxProps}
      onClick={onClick ? () => onClick() : undefined}
      sx={{ cursor: loading ? undefined : 'pointer' }}
      className={`${cname} focus-on-search`}
    >
      <Box className={'focus-on-search'} display={'flex'} gap={1} alignItems={'center'} flexGrow={1}>
        <Box className={'focus-on-search'} display={'flex'} height={'48px'} width={'96px'} flexShrink={0}>
          {loading ? (
            <Skeleton
              animation={animation}
              variant="rectangular"
              className={'focus-on-search'}
              sx={{
                width: '88px',
                height: '48px',
                borderRadius: '8px',
                background: '#FEF6F1',
                flexShrink: 0,
              }}
            />
          ) : (
            <img
              alt={'logo'}
              className={'focus-on-search'}
              src={shop?.image}
              style={{ borderRadius: 8, width: '100%', height: '100%', objectFit: 'contain' }}
            />
          )}
        </Box>
        <Box flexGrow={1} className={'focus-on-search'}>
          <Typography
            className={'focus-on-search'}
            sx={{ color: '#313D53' }}
            variant={'subtitle1SmallR'}
            component="h3"
          >
            {loading ? (
              <Skeleton
                className={'focus-on-search'}
                animation={animation}
                variant="text"
                height="24px"
                width="100%"
                sx={{ transform: 'none' }}
              />
            ) : (
              shop?.name
            )}
          </Typography>
        </Box>
      </Box>
      {loading ? (
        <Box display={'flex'} className={'focus-on-search'} alignItems={'center'}>
          <Skeleton
            animation={animation}
            className={'focus-on-search'}
            height="24px"
            width="24px"
            sx={{ transform: 'none', borderRadius: '8px', background: '#E9688A' }}
          />
        </Box>
      ) : (
        <Box
          className={'focus-on-search'}
          gap={0}
          display={'flex'}
          textAlign={'end'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Box className={'focus-on-search'} display={'flex'} gap={1} justifyContent={'flex-end'} alignItems={'center'}>
            <Typography
              className={'focus-on-search'}
              variant="subtitle1"
              component="p"
              color={'#838B98'}
              sx={{ textDecorationLine: 'line-through' }}
            >
              {shop?.strikedCashback ? `${shop?.strikedCashback}${shop?.strikedCurrency}` : ''}
            </Typography>
            <Typography className={'focus-on-search'} color={'secondary'} variant={'body2'}>
              {`${shop ? (shop.cashback_categories_count > 1 ? 'έως' : '') : ''} ${shop?.cashback}${shop?.currency}`}
            </Typography>
          </Box>
          <Typography className={'focus-on-search'} color={'secondary'} variant={'subtitle1SmallR'} component="p">
            επιστροφή
          </Typography>
        </Box>
      )}
    </StyleHorizontalBox>
  );
};

export default PigogoHorizontalProductCard;
