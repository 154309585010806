import React, { FC, useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs, { Dayjs } from 'dayjs';
import { DateValidationError } from '@mui/x-date-pickers';

interface Props {
  label?: string;
  value?: Dayjs | undefined;
  bold?: boolean;
  setValue?: (value: string) => void;
  setError?: (value: boolean) => void;
  size?: 'small' | 'medium';
  helperText?: string | any;
  error?: boolean;
  readonly?: boolean;
}

const PigogoDateField: FC<Props> = ({
  label,
  value,
  setValue,
  setError,
  size,
  helperText,
  error,
  readonly = false,
  ...rest
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{
        fieldYearPlaceholder: (params) => {
          return 'ΕEEE';
        },
        fieldMonthPlaceholder: (params) => {
          return 'MM';
        },
        fieldDayPlaceholder: () => {
          return 'HH';
        },
      }}
    >
      <DateField
        sx={{
          '& .Mui-error': {
            color: error ? '#F75676' : '#313D53',
          },
          '& .MuiInputLabel-root': {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            paddingLeft: '4px',
            paddingRight: '4px',
            backgroundColor: '#ffffff',
            color: error ? '#F75676' : '#313D53',
            top: 0,
            left: '6px',
            '&.Mui-focused': {
              top: 0,
              color: error ? '#F75676' : '#313D53',
            },
          },
          '& .MuiFormLabel-root': {
            color: error ? '#F75676' : '#313D53',
          },
          '& .MuiInputLabel-shrink': {
            color: error ? '#F75676' : '#313D53',
            '&.Mui-focused': {
              color: error ? '#F75676' : '#313D53',
            },
          },
          '& .MuiInputLabel-root.Mui-focused': {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            paddingLeft: '4px',
            paddingRight: '4px',
            backgroundColor: '#ffffff',
            color: error ? '#F75676' : '#313D53',
          },
          '& .MuiOutlinedInput-input': {
            padding: '0px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            color: error ? '#F75676' : '#313D53',
          },
          '& .MuiInputBase-input': {
            color: error ? '#F75676' : '#313D53',
          },
          '& .MuiInputBase': {
            color: error ? '#F75676' : '#313D53',
          },
          '& .MuiOutlinedInput-root': {
            display: 'flex',
            height: '56px',
            padding: '16px 24px',
            borderRadius: '56px',

            '&.Mui-focused fieldset': {
              paddingLeft: '18px',
              border: error ? '1px solid #F75676' : '1px solid #313D53',
            },
            fieldset: {
              legend: {
                span: {
                  padding: label ? '0' : '0',
                },
              },
              paddingLeft: '18px',
              borderColor: error ? '#F75676' : '#838B98',
              fontSize: '14px',
              fontWeight: 600,
              span: {
                padding: '0',
              },
            },
          },
          '& .MuiInputAdornment-root': {
            width: '24px',
            height: '24px',
            display: 'flex',
            marginRight: '0',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .MuiFormHelperText-root': {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: error ? '#F75676' : '#313D53',
            margin: '8px 24px 0 24px',
          },
        }}
        value={value}
        format="DD/MM/YYYY"
        label={label}
        inputProps={{ inputMode: 'numeric' }}
        slotProps={{
          textField: { fullWidth: true, helperText: helperText },
        }}
        onChange={
          setValue
            ? (newDate) => {
                setValue(newDate ? newDate.format('DD/MM/YYYY') : '');
              }
            : undefined
        }
        helperText={helperText}
      />
    </LocalizationProvider>
  );
};

export default PigogoDateField;
