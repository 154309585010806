import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { IonContent, IonPage } from '@ionic/react';
import ForgotPassword from '../components/authenticate/ForgotPassword';
import Layout from '../components/Navigation/Layout';
import CustomHeader from '../components/CustomHeader';
import { useMediaQuery } from '@mui/material';
import { theme } from 'components';

const NotLoggedInLayout: FC = () => {
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const renderMethod = () => {
    if (!smallScreen) {
      return <Redirect to={'/'} />;
    }

    return (
      <IonPage>
        <CustomHeader />
        <IonContent>
          <Layout>
            <Switch>
              <Route exact path="/authenticate/forgotPassword" component={ForgotPassword} />
              <Route
                exact
                path="/authenticate"
                render={() => {
                  return <Redirect to={'/authenticate/register'} />;
                }}
              />
            </Switch>
          </Layout>
        </IonContent>
      </IonPage>
    );
  };

  return renderMethod();
};

export default NotLoggedInLayout;
