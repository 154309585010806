import { Box, Typography } from '@mui/material';
import { PigogoLineProgress, PigogoLink } from 'components';
import { normalise } from '../../utils/normaliseLinear';
import React, { useEffect } from 'react';
import { IonRouterLink, useIonRouter } from '@ionic/react';
import useQuery from '../../hooks/useQuery';

interface Props {
  currentCount: number;
  total: number;
  hasNextPage?: boolean;
  loadMore: () => void;
  nextPage?: string | null;
}

const Pagination: React.FC<Props> = ({ currentCount, total, hasNextPage = true, loadMore, nextPage }) => {
  const params = useQuery();
  const router = useIonRouter();

  useEffect(() => {
    if (hasNextPage && !!nextPage) {
      params.set('page', nextPage);
    }
  }, [nextPage]);

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box paddingBottom={2}>
        <Typography fontSize={14} lineHeight={'16px'} fontWeight={600} color={'#313D53'}>
          {currentCount} από τα {total}
        </Typography>
      </Box>
      <Box>
        <PigogoLineProgress
          linearProps={{
            valueBuffer: 125,
            sx: { width: 256 },
            variant: 'determinate',
            value: normalise(currentCount, total),
          }}
        />
      </Box>
      {currentCount <= total && hasNextPage && (
        <Box paddingTop={4} sx={{ display: currentCount >= total ? 'none' : 'block' }}>
          <a
            href={`${router.routeInfo.pathname}?${params}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            rel={'next'}
          >
            <PigogoLink
              color={'#313D53'}
              colorText={'#313D53'}
              handleClick={loadMore}
              text={'Δείξε μου περισσότερα'}
              disabled={currentCount >= total}
              typoProps={{
                fontSize: '16px !important',
                lineHeight: '24px !important',
                fontWeight: '500 !important',
                color: '#313D53',
              }}
            />
          </a>
        </Box>
      )}
    </Box>
  );
};

export default Pagination;
