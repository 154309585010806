import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import theme from '../theme';

const StyleHorizontalBox = styled(Box)(() => ({
  gap: 8,
  height: '72px',
  width: '255px',
  maxWidth: '100%',
  padding: 16,
  borderRadius: '16px',
  display: 'inline-flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  border: '2px solid #FCE5D8',
  background: theme.palette.blue.contrastText,
}));

const StyleImgHorizontalBox = styled(Box)(() => ({
  position: 'relative',
  height: 40,
  width: 80,
}));

interface Props {
  logo?: string;
  name: string;
  onClick: () => void;
}

const PigogoSmallHorizontalProduct: FC<Props> = (props) => {
  return (
    <StyleHorizontalBox onClick={() => props.onClick()}>
      <Box>
        <StyleImgHorizontalBox>
          <img alt={'logo'} src={props.logo} style={{ borderRadius: 8, width: '100%', height: '100%' }} />
        </StyleImgHorizontalBox>
      </Box>
      <Box>
        <Typography
          variant={'subtitle1SmallR'}
          component="p"
          color={'#313D53'}
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}
        >
          {props.name}
        </Typography>
      </Box>
    </StyleHorizontalBox>
  );
};

export default PigogoSmallHorizontalProduct;
