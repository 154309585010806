import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { palette } from './palette';
import { typography } from './typography';
import { components } from './componentsOverrides';

const theme: Theme = createTheme({
  palette: palette,
  components: components,
  breakpoints: {
    values: {
      xs: 0,
      xxs: 375,
      sm: 600,
      ssm: 768,
      md: 960,
      lg: 1280,
      extraLg: 1367,
      xl: 1920,
    },
  },
});

theme.typography = {
  ...theme.typography,
  fontFamily: ['Manrope', 'sans-serif'].join(','),

  // fontSize: 12,
  // htmlFontSize: 12,
  // fontWeightLight: 300,
  // fontWeightRegular: 400,
  // fontWeightMedium: 500,
  // fontWeightBold: 700,

  h1: {
    ...typography.h1,
    [theme.breakpoints.down('extraLg')]: {
      ...typography.h2,
    },
    [theme.breakpoints.down('md')]: {
      ...typography.h3,
    },
  },
  h2: {
    ...typography.h2,
    [theme.breakpoints.down('sm')]: {
      ...typography.h3,
    },
  },
  h3: {
    ...typography.h3,
    [theme.breakpoints.down('md')]: {
      ...typography.smallB,
    },
  },
  h4: {
    ...typography.h4,
    [theme.breakpoints.down('md')]: {
      ...typography.largeB,
    },
  },
  h5: {
    ...typography.h5,
    [theme.breakpoints.down('sm')]: {
      ...typography.h6,
    },
  },
  h6: { ...typography.h6 },
  body1: {
    ...typography.body1,
    [theme.breakpoints.down('sm')]: {
      ...typography.h6,
    },
  },
  body2: { ...typography.body2 },
  subtitle1: { ...typography.subtitle1 },
  subtitle2: { ...typography.subtitle2 },
  caption: { ...typography.caption },
  button: { ...typography.button },
  xLargeR: {
    ...typography.xLargeR,
    [theme.breakpoints.down('sm')]: {
      ...typography.largeR,
    },
  },
  largeR: {
    ...typography.largeR,
    [theme.breakpoints.down('sm')]: {
      ...typography.mediumR,
    },
  },
  mediumR: {
    ...typography.mediumR,
    [theme.breakpoints.down('sm')]: {
      ...typography.smallR,
    },
  },
  mediumRAll: {
    ...typography.mediumRAll,
  },
  h4All: {
    ...typography.h4All,
  },
  smallR: { ...typography.smallR },
  smallB: {
    ...typography.smallB,
    [theme.breakpoints.down('md')]: {
      ...typography.h4,
    },
  },
  largeXL: {
    ...typography.largeXL,
    [theme.breakpoints.down('sm')]: {
      ...typography.mediumL,
    },
  },
  largeL: {
    ...typography.largeL,
    // [theme.breakpoints.down('sm')]: {
    //   ...typography.mediumL,
    // },
  },
  largeRegular: {
    ...typography.largeRegular,
    [theme.breakpoints.down('sm')]: {
      ...typography.mediumH6R,
    },
  },
  largeRegularAll: {
    ...typography.largeRegularAll,
  },
  largeMedium: {
    ...typography.largeMedium,
    [theme.breakpoints.down('sm')]: {
      ...typography.mediumM,
    },
  },
  bodyLargeR: {
    ...typography.bodyLargeR,
    [theme.breakpoints.down('md')]: {
      ...typography.subtitle1SmallR,
    },
  },
  bodyLargeRMediumL: {
    ...typography.bodyLargeRMediumL,
    [theme.breakpoints.down('md')]: {
      ...typography.body2MediumL,
    },
  },
  largeB: { ...typography.largeB },
  large900: { ...typography.large900 },
  mediumM: { ...typography.mediumM },
  mediumH6R: { ...typography.mediumH6R },
  mediumL: { ...typography.mediumL },
  body2MediumR: { ...typography.body2MediumR },
  body2MediumM: { ...typography.body2MediumM },
  body2MediumL: { ...typography.body2MediumL },
  mediumSB: { ...typography.mediumSB },
  mediumSB26: { ...typography.mediumSB26 },
  mediumTitle: {
    ...typography.mediumTitle,
    [theme.breakpoints.down('md')]: {
      ...typography.largeB,
    },
  },
  subtitle1SmallM: { ...typography.subtitle1SmallM },
  subtitle1SmallR: { ...typography.subtitle1SmallR },
  subtitle1SmallRLS0: { ...typography.subtitle1SmallRLS0 },
  subtitle1SmallL: { ...typography.subtitle1SmallL },
  subtitle900: { ...typography.subtitle900 },
  inputLabel: { ...typography.inputLabel },
  captionM: { ...typography.captionM },
  captionSB: { ...typography.captionSB },
  buttonSmallM: { ...typography.buttonSmallM },
};

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    xxs: true;
    sm: true;
    ssm: true;
    md: true;
    lg: true;
    extraLg: true;
    xl: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: true;
    body1: true;
    body2: true;
    subtitle1: true;
    subtitle2: true;
    caption: true;
    button: true;
    xLargeR: true;
    largeR: true;
    mediumR: true;
    mediumSB: true;
    mediumSB26: true;
    smallR: true;
    smallB: true;
    largeXL: true;
    largeL: true;
    largeRegular: true;
    largeMedium: true;
    bodyLargeR: true;
    largeB: true;
    large900: true;
    mediumM: true;
    mediumH6R: true;
    mediumL: true;
    body2MediumR: true;
    body2MediumM: true;
    body2MediumL: true;
    subtitle1SmallM: true;
    subtitle1SmallR: true;
    subtitle1SmallRLS0: true;
    subtitle1SmallL: true;
    subtitle900: true;
    inputLabel: true;
    captionM: true;
    captionSB: true;
    buttonSmallM: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    orange: Palette['primary'];
    blue: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    orange: true;
  }
}

export default theme;
