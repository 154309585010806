import { Box, useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import React, { FC } from 'react';
import { ClaimTypeId } from '../../../models/ClaimTypeId';
import ClaimCard from '../ClaimCard';

interface Props {
  typeOfClaim: ClaimTypeId | 0;
  setCurrentStep: (currentStep: number) => void;
  setTypeOfClaim: (typeOfClaim: ClaimTypeId | 0) => void;
}

const StepOne: FC<Props> = ({ setCurrentStep, typeOfClaim, setTypeOfClaim }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box px={!mdDown ? 10 : 0} pb={5} display="flex" flexDirection="column" gap={2}>
      <ClaimCard
        checked={typeOfClaim === 1}
        title="Δεν έχει καταγραφεί η αγορά μου"
        subtitle="Έκανες αγορά σε συνεργαζόμενο κατάστημα αλλά δεν εμφανίζεται στις Αγορές σου"
        handleChecked={() => setTypeOfClaim(ClaimTypeId.unrecorded)}
      />
      <ClaimCard
        checked={typeOfClaim === 2}
        title="Βλέπω λάθος ποσό επιστροφής"
        subtitle="Έκανες αγορά σε συνεργαζόμενο κατάστημα αλλά το ποσό της επιστροφής σου φαίνεται λάθος"
        handleChecked={() => setTypeOfClaim(ClaimTypeId.wrong_cashback)}
      />
      <ClaimCard
        checked={typeOfClaim === 3}
        title="Η αγορά μου ακυρώθηκε λανθασμένα"
        subtitle={`Ενώ η αγορά σου εκτελέστηκε κανονικά, εμφανίζεται σε κατάσταση "Ακυρωμένη"`}
        handleChecked={() => setTypeOfClaim(ClaimTypeId.cancelled_purchase)}
      />
      <Box pt={1} pb={smDown ? 3 : 0}>
        <PigogoButton
          simpleButton
          text="Συνέχεια"
          variant={'contained'}
          disabled={typeOfClaim === 0}
          typographyProps={{ fontSize: '14px' }}
          sx={{ borderRadius: '32px', pl: 3, pr: 3 }}
          onClick={() => setCurrentStep(1)}
        />
      </Box>
    </Box>
  );
};

export default StepOne;
