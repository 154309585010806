import { FC, useEffect, useState } from 'react';
import { useIonRouter, useIonViewWillLeave } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { usePromosQuery } from '../../redux/api/queries/promosQueries';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { Box, useMediaQuery } from '@mui/material';
import PigogoBigPromoCard from '../PigogoBigPromoCard';
import useQuery from '../../hooks/useQuery';
import { theme } from 'components';
import { useHistory } from 'react-router';

const PromoBanner: FC<unknown> = () => {
  const params = useQuery();
  const dispatch = useDispatch();

  const history = useHistory();
  const path = history.location.pathname;

  const [promo, setPromo] = useState<string>('');

  const { data: promos, isError: errorPromos } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  useIonViewWillLeave(() => {
    setPromo('');
  });

  useEffect(() => {
    const promoLabel = path.includes('/promo') ? path.split('/').slice(-1)[0].split('?')[0] : null;
    if (promoLabel) {
      setPromo(promoLabel);
    } else {
      setPromo('');
    }
  }, [path, params]);

  useEffect(() => {
    if (errorPromos) {
      const params = { value: true, msg: 'Κάτι πήγε στραβά!', severity: 'error' };
      dispatch(setSnackBar(params));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorPromos]);

  const renderMethod = () => {
    if (promo && promos) {
      return (
        <Box paddingTop={mdDown ? 2 : 6}>
          <PigogoBigPromoCard
            img={promos.data.find((obj) => obj.label === promo)?.header_image || ''}
            title={promos.data.find((obj) => obj.label === promo)?.title || ''}
            subTitle={promos.data.find((obj) => obj.label === promo)?.subtitle || ''}
            description={promos.data.find((obj) => obj.label === promo)?.additional_information || ''}
          />
        </Box>
      );
    }
    return null;
  };

  return renderMethod();
};

export default PromoBanner;
