import { IconButton, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

const StyledNavigationIcon = styled(IconButton)({
  borderRadius: 1000,
  backgroundColor: 'common.white',
  padding: '3px 3px',
  borderColor: '#0063cc',
  '&:hover': {
    backgroundColor: 'common.white',
    boxShadow: '0px 4.66667px 4.66667px rgba(0, 0, 0, 0.05), 0px 4.66667px 39.6667px rgba(0, 0, 0, 0.07);',
  },
  '&:focus': {
    outline: '2px solid rgba(60, 104, 200, 0.5)',
  },
});

interface Props {
  children: ReactNode;
}

const PigogoNavigationIcon: FC<Props> = ({ children }) => {
  return <StyledNavigationIcon>{children}</StyledNavigationIcon>;
};

export default PigogoNavigationIcon;
