import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'components';
import { useGetUserDataQuery } from '../../../../redux/api/queries/userQueries';
import ExtractActiveLevel from './ExtractActiveLevel';

const GuruSaver = () => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: userData } = useGetUserDataQuery();

  const currentLvl = userData?.gamification_level_name;

  return currentLvl !== undefined ? (
    <Box
      display="flex"
      alignItems="center"
      px={6}
      py={mobile ? 4 : 5}
      gap={2}
      justifyContent="space-between"
      flexDirection="column"
      sx={{ boxSizing: 'border-box' }}
    >
      <ExtractActiveLevel saver={currentLvl} />
      <Typography variant="subtitle1SmallR" textAlign="center" component="p" color={'#313D53'}>
        Είσαι ο απόλυτος <strong>Guru</strong> της εξοικονόμησης χρημάτων!
      </Typography>
    </Box>
  ) : null;
};

export default GuruSaver;
