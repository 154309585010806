import { Box, Divider, Typography } from '@mui/material';

const PurchaseStatusInfo = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1SmallM" color="#6F7787" component="h3">
          Σε επεξεργασία
        </Typography>
        <Typography variant="subtitle1SmallM" color={'#1D2532'} component="p">
          Κάθε νέο αίτημα που καταχωρείται συνήθως ελέγχεται τόσο από την ομάδα του Pigogo όσο και από το ίδιο το
          κατάστημα, ενώ αυτή η διαδικασία μπορεί να διαρκέσει έως και λίγες εβδομάδες. Το Pigogo καταβάλει κάθε
          προσπάθεια ώστε τα αιτήματα να ελέγχονται και να εγκρίνονται από τα καταστήματα το συντομότερο δυνατό.
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1SmallM" color="#3CA377" component="h3">
          Εγκρίθηκε
        </Typography>
        <Typography variant="subtitle1SmallM" color={'#1D2532'} component="p">
          Το αίτημα έχει εγκριθεί και άρα η επιστροφή χρημάτων από τη συγκεκριμένη αγορά εμφανίζεται πλέον στη σελίδα
          των Αγορών.
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1SmallM" color="#BC415A" component="h3">
          Δεν εγκρίθηκε
        </Typography>
        <Typography variant="subtitle1SmallM" color={'#1D2532'} component="p">
          Το αίτημα δεν έχει εγκριθεί. Σε αυτές τις περιπτώσεις συνήθως υπάρχει αναλυτική αιτιολόγηση για τον λόγο που
          το συγκεκριμένο αίτημα δεν εγκρίθηκε.
        </Typography>
      </Box>
    </Box>
  );
};

export default PurchaseStatusInfo;
