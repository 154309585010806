import React, { FC, useEffect, useState } from 'react';
import { IonContent, IonHeader, IonModal, IonToolbar, useIonRouter } from '@ionic/react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { PigogoLogin, theme } from 'components';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/svgs/chevronLeft24.svg';
import TopBanner from '../../User/TermsAndConditions/TopBanner';
import TermsAndConditions from '../../User/TermsAndConditions';

interface Props {
  isOpen: boolean;
  closeTermsModal: () => void;
  setTermsModal: (val: boolean) => void;
}

const TermsAndConditionsModal: FC<Props> = ({ isOpen, setTermsModal, closeTermsModal }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <IonModal
      mode={'ios'}
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={closeTermsModal}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
    >
      <IonHeader class="ion-no-border">
        {smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
            <Box pr={1} pl={'5px'} display="flex" gap={2} alignItems="flex-start">
              <IconButton
                sx={{
                  padding: 0,
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                disableRipple
                onClick={closeTermsModal}
              >
                <ChevronLeft />
              </IconButton>
            </Box>
          </IonToolbar>
        )}
        {!smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '24px' }}>
            <IconButton
              disableRipple
              className="iconBtn"
              sx={{
                position: 'absolute',
                right: 12,
                top: 0,
                padding: 0,
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={closeTermsModal}
            >
              <Close />
            </IconButton>
          </IonToolbar>
        )}
      </IonHeader>

      <Box className="overscroll innerPopup overscroll--mobNoMTop innerPopup--mobLarge">
        <Box display={'flex'} justifyContent={'center'} sx={{ paddingInline: smDown ? '24px 12px' : '32px 20px' }}>
          <TopBanner title={'Όροι χρήσης'} />
          <Box pt={'104px'} pb={'162px'}>
            <TermsAndConditions />
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default TermsAndConditionsModal;
