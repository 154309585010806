import { Typography, useMediaQuery, Box } from '@mui/material';
import React from 'react';
import { theme } from 'components';

const FavouritesTableHeader = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      style={{
        borderRadius: '24px 24px 0px 0px',
        background: '#F7F7F8',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: mdDown ? 'space-evenly' : 'flex-start',
        paddingBottom: '12px',
        paddingTop: mdDown ? '16px' : '24px',
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ width: mdDown ? '41%' : '60.78%', paddingInline: mdDown ? 2 : 3 }}>
        <Typography variant="captionSB" component="h3" color="#6F7787">
          Κατάστημα
        </Typography>
      </Box>
      <Box
        sx={{
          width: mdDown ? '32.44%' : '19.60%',
          paddingInline: mdDown ? 0 : 3,
          textAlign: mdDown ? 'center' : 'left',
        }}
      >
        <Typography variant="captionSB" component="h3" color="#6F7787">
          Ειδοποιήσεις
        </Typography>
      </Box>
      <Box sx={{ width: mdDown ? '26.54%' : '19.60%' }} style={{ textAlign: 'center' }}>
        <Typography variant="captionSB" component="h3" color="#6F7787">
          {mdDown ? 'Αφαίρεση' : 'Αφαίρεση από Αγαπημένα'}
        </Typography>
      </Box>
    </Box>
  );
};

export default FavouritesTableHeader;
