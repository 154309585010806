import moment from 'moment';

export const getColorDate = (expClass: string) => {
  if (expClass === 'green') {
    return '#3CA377';
  } else if (expClass === 'yellow') {
    return '#C07848';
  } else {
    return '#BC415A';
  }
};
