import { msgValid } from '../../../utils/validation';
import React, { FC, useEffect, useState } from 'react';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { PigogoButton, PigogoTextField, theme } from 'components';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { useUpdateUserMutation } from '../../../redux/api/mutations/userMutations';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';

interface Props {
  open: boolean;
  accept: () => void;
  handleClose: () => void;
}

const initialPassword = { current: '', new: '', confirm: '' };
const error = { new: 'Τουλάχιστον 6 χαρακτήρες', confirm: 'O κωδικός δεν ειναι ίδιος' };

const ChangePasswordModal: FC<Props> = ({ open, handleClose, accept }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [updateUser, { isSuccess: successPassword, isLoading: loading, isError: errorUpdate, error: errorMessage }] =
    useUpdateUserMutation();

  const [password, setPassword] = useState(initialPassword);
  const [disable, setDisable] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<any>({});

  useEffect(() => {
    if (successPassword) {
      setPassword(initialPassword);
      accept();
    }
  }, [successPassword]);

  useEffect(() => {
    if (errorUpdate && errorMessage) {
      if ('data' in errorMessage) {
        setOldPassword(errorMessage.data);
      }
    }
  }, [errorUpdate]);

  useEffect(() => {
    if (!password.current || !password.new || !password.confirm) {
      setDisable(true);
    } else if (password.new !== password.confirm || !msgValid(password.new)) {
      setDisable(true);
    } else if (loading) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [password, loading]);

  const handleFormSubmit = () => {
    const data = {
      current_password: password.current,
      password: password.new,
      password_confirmation: password.confirm,
    };
    updateUser(data);
  };

  const closeModal = () => {
    setPassword(initialPassword);
    handleClose();
  };

  return (
    <IonModal
      mode={'ios'}
      style={
        !smDown
          ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' }
          : { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px', paddingInline: '16px' }
      }
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => closeModal()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '48px', boxSizing: 'border-box' }}>
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: smDown ? 4 : 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => closeModal()}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box sx={{ paddingInline: smDown ? '24px 12px' : '32px 20px', paddingBottom: '20px' }}>
          <Box display="flex" textAlign={'center'} flexDirection="column" gap={4} height={'100%'}>
            <Box display="flex" flexDirection="column" gap={4} width={'100%'}>
              <Typography variant={mdDown ? 'h6' : 'body1'} component="h2" color={'#313D53'}>
                Αλλαγή κωδικού
              </Typography>
              <Box display="flex" flexDirection="column" gap={3} width={'100%'}>
                <PigogoTextField
                  helperText={oldPassword.current_password ? 'Ο παλιός κωδικός δεν είναι σωστός' : ''}
                  onFocus={() => {
                    setOldPassword(false);
                  }}
                  error={!!oldPassword.current_password}
                  fullWidth
                  type={'password'}
                  value={password.current}
                  label="Κωδικός πρόσβασης"
                  autoComplete={'new-password'}
                  setValue={(value: string) => setPassword({ ...password, current: value })}
                />
                <PigogoTextField
                  onFocus={() => {
                    setOldPassword({});
                  }}
                  error={!!oldPassword.password}
                  fullWidth
                  type={'password'}
                  label="Νέος κωδικός"
                  value={password.new}
                  autoComplete={'new-password'}
                  helperText={
                    oldPassword.password
                      ? 'Ο καινούργιος κωδικός δεν μπορεί να είναι ίδιος με τον παλιό'
                      : !msgValid(password.new)
                      ? error.new
                      : ''
                  }
                  setValue={(value: string) => setPassword({ ...password, new: value })}
                />
                <PigogoTextField
                  fullWidth
                  type={'password'}
                  value={password.confirm}
                  autoComplete={'new-password'}
                  label="Επιβεβαίωση νέου κωδικού"
                  setValue={(value: string) => setPassword({ ...password, confirm: value })}
                  helperText={password.confirm && password.new !== password.confirm ? error.confirm : ''}
                />
              </Box>
            </Box>
            <Box display="flex" width={'100%'}>
              <PigogoButton
                fullWidth
                type={'submit'}
                disabled={disable}
                text={'Επιβεβαίωση'}
                variation={'secondary'}
                onClick={handleFormSubmit}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default ChangePasswordModal;
