import React, { FC, useEffect, useState, useRef } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import { ClaimTypeId } from '../../../models/ClaimTypeId';
import StepTwoContent from './StepTwoContent';
import StepTwoCancelledContent from './StepTwoCancelledContent';
import { CreateClaimStepTwo } from '../../../models/CreateClaimStepTwo';
import StoreNotYetTrackedPurchaseWarning from '../StoreNotYetTrackedPurchaseWarning';
import moment from 'moment-timezone';

interface Props {
  typeOfClaim: ClaimTypeId | 0;
  stepTwoContent: CreateClaimStepTwo;
  setShop: (shop: string) => void;
  setCurrentStep: (currentStep: number) => void;
  setStepTwoContent: (stepTwoContent: CreateClaimStepTwo) => void;
}
const StepTwo: FC<Props> = ({ setCurrentStep, typeOfClaim, setShop, stepTwoContent, setStepTwoContent }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [warnAboutTrackingSpeed, setWarnAboutTrackingSpeed] = useState<boolean>(false);
  const [shopName, setShopName] = useState<string>('');
  const [shopTrackingSpeed, setShopTrackingSpeed] = useState<number>(2);
  const [disableNextStepForYetUntrackedPurchase, setDisableNextStepForYetUntrackedPurchase] = useState<boolean>(false);

  const [stepTwoState, setStepTwoState] = useState<{
    selectedShop: string;
    months: string[];
    selectedMonth: string;
    selectedDate: string;
    selectedTime: string;
    shop_id: string;
    slugName: string;
    purchase_id: string;
    clickout_id: string;
  }>(stepTwoContent);

  const nextStepDisabled = () => {
    if (typeOfClaim === ClaimTypeId.cancelled_purchase) {
      return stepTwoState.shop_id === '' || stepTwoState.purchase_id === '';
    } else {
      /*prettier-ignore*/
      return stepTwoState.clickout_id === '' ||
        stepTwoState.selectedMonth === '' ||
        stepTwoState.selectedDate === '' ||
        stepTwoState.selectedTime === '';
    }
  };

  useEffect(() => {
    setDisableNextStepForYetUntrackedPurchase(false);
  }, [stepTwoState.selectedDate, stepTwoState.selectedTime]);

  const handleStepTwo = () => {
    const dateOfClickoutWithTrackingDiff = moment
      .tz(
        `${stepTwoState.selectedMonth.replace('-', `-${stepTwoState.selectedDate.split('-')[0]}-`)} ${
          stepTwoState.selectedTime
        }`,
        'M-D-YYYY h:mm:ss',
        'Europe/Athens',
      )
      .add(shopTrackingSpeed, 'minutes');

    if (
      typeOfClaim === ClaimTypeId.unrecorded &&
      dateOfClickoutWithTrackingDiff > moment() &&
      stepTwoState.shop_id !== '345'
    ) {
      setWarnAboutTrackingSpeed(true);
      setDisableNextStepForYetUntrackedPurchase(true);
    } else {
      setStepTwoContent(stepTwoState);
      setCurrentStep(2);
      setShop(stepTwoState.shop_id);
    }
  };

  return (
    <Box pl={!mdDown ? 10 : 0} pb={5} display="flex" flexDirection="column" gap={3}>
      {typeOfClaim !== ClaimTypeId.cancelled_purchase ? (
        <StepTwoContent
          stepTwoState={stepTwoState}
          setStepTwoState={setStepTwoState}
          typeOfClaim={typeOfClaim}
          setShopName={setShopName}
          setShopTrackingSpeed={setShopTrackingSpeed}
        />
      ) : (
        <StepTwoCancelledContent
          typeOfClaim={typeOfClaim}
          stepTwoState={stepTwoState}
          setStepTwoState={setStepTwoState}
        />
      )}
      <Box pb={smDown ? 3 : 0}>
        <PigogoButton
          simpleButton
          text="Συνέχεια"
          variant={'contained'}
          disabled={nextStepDisabled() || disableNextStepForYetUntrackedPurchase}
          typographyProps={{ fontSize: '14px' }}
          sx={{ borderRadius: '32px', paddingLeft: 3, paddingRight: 3 }}
          onClick={() => handleStepTwo()}
        />
      </Box>
      <StoreNotYetTrackedPurchaseWarning
        isOpen={warnAboutTrackingSpeed}
        setOpen={setWarnAboutTrackingSpeed}
        shopName={stepTwoState.selectedShop}
        shopTrackingSpeed={shopTrackingSpeed}
      />
    </Box>
  );
};

export default StepTwo;
