import { Box, BoxProps, Skeleton, styled, Typography, useMediaQuery } from '@mui/material';
import theme from '../theme';
import PigogoDiscount from './PigogoDiscount';
import React, { FC } from 'react';
import moment from 'moment';

const StyleBox = styled(Box)<BoxProps>((props) => ({
  position: 'relative',
  padding: '24px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '24px 24px 24px 0px',
  boxSizing: 'border-box',
  backgroundColor: '#EAECEE',
  transition: 'background-color .3s ease-out',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
    height: '332px',
  },
  height: '392px',
  '&:hover': {
    backgroundColor: '#D6D8DD',
  },
  '&:focus': {
    outline: 0,
  },
  '&:focus-visible': {
    outline: '2px dashed #D6D8DD',
    outlineOffset: '2px',
  },
}));

const StyleImgBox = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    height: 60,
    width: 120,
  },
  [theme.breakpoints.up('sm')]: {
    height: 64,
    width: 128,
  },
}));

interface Props {
  loading?: boolean;
  logo?: string;
  name?: string;
  subTitle?: string;
  date?: string;
  discount?: string;
  multiCashback?: boolean;
  fullwidth?: boolean;
  deleteDisc?: string;
  onClick?: () => void;
}

const PigogoOfferCard: FC<Props> = ({
  loading,
  logo,
  name,
  subTitle,
  date,
  discount,
  multiCashback,
  fullwidth,
  deleteDisc,
  onClick,
}) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyleBox onClick={onClick}>
      <Box display={'flex'} flexDirection={'column'} gap={matches ? '16px' : '20px'}>
        <Box>
          <StyleImgBox>
            {loading ? (
              <Skeleton
                variant="rectangular"
                animation={'wave'}
                style={{
                  height: '100%',
                  borderRadius: 16,
                  background: 'rgb(255 255 255 / 46%)',
                }}
              />
            ) : (
              <img
                alt={'logo'}
                src={logo}
                style={{
                  borderRadius: 8,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  backgroundColor: '#ffffff',
                }}
              />
            )}
          </StyleImgBox>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={smDown ? 0.5 : 1}>
          <Box>
            <Typography
              variant={matches ? 'subtitle1SmallL' : 'largeXL'}
              textAlign={'start'}
              sx={{ color: '#313D53' }}
              component="h3"
            >
              {loading ? (
                <Skeleton
                  width={'50%'}
                  animation={'wave'}
                  style={{ background: 'rgb(255 255 255 / 46%)', borderRadius: '16px' }}
                  sx={{
                    color: '#313D53',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: '3',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    maxWidth: '196px',
                  }}
                />
              ) : (
                name
              )}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant={matches ? 'subtitle1SmallM' : 'body2MediumM'}
              textAlign={'start'}
              component="p"
              sx={{
                color: '#313D53',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '4',
                display: '-webkit-box',
                overflow: 'hidden',
              }}
            >
              {loading ? (
                <Skeleton animation={'wave'} style={{ background: 'rgb(255 255 255 / 46%)', borderRadius: '16px' }} />
              ) : (
                subTitle
              )}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant={matches ? 'caption' : 'subtitle1SmallR'}
              textAlign={'start'}
              color={'#313D53'}
              component="p"
            >
              {loading ? (
                <Skeleton
                  animation={'wave'}
                  style={{ background: 'rgb(255 255 255 / 46%)', borderRadius: '16px' }}
                  width={'40%'}
                />
              ) : date ? (
                `έως ${moment(date).format('DD/MM/YYYY')}`
              ) : null}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display={'flex'} zIndex={1} flexDirection={matches ? 'column' : 'row'} gap={smDown ? 0.5 : 1}>
        {deleteDisc && (
          <Box>
            <PigogoDiscount discount={deleteDisc} small />
          </Box>
        )}
        <Box>
          <Typography
            variant={matches ? 'captionSB' : 'subtitle1'}
            textAlign={'start'}
            style={{ color: ' #E9688A' }}
            component="p"
          >
            {!loading && `${multiCashback ? 'έως' : ''} ${discount} επιστροφή`}
          </Typography>
        </Box>
      </Box>
      <Box
        left={'0'}
        bottom={'0'}
        width={'84px'}
        height={'64px'}
        position={'absolute'}
        sx={{ background: '#FFFFFF', borderTopRightRadius: '100px' }}
      ></Box>
    </StyleBox>
  );
};

export default PigogoOfferCard;
