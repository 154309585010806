import { Box, Grid, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Assets } from '../index';
import { PurpleSymbol } from '../assets';

const StyleIconsBox = styled(Box)(({ theme }) => ({
  border: '1px solid #EAECEE',
  borderRadius: '20px',
  padding: 16,
  [theme.breakpoints.down('sm')]: {
    height: '248px',
    width: '313px',
  },
  background: '#fff',
}));

const StyleSymbol = styled(Box)(({ theme }) => ({
  position: 'relative',
  right: '0px',
  textAlign: 'end',
  [theme.breakpoints.down('sm')]: {
    bottom: '2px',
  },
  [theme.breakpoints.up('sm')]: {
    bottom: '-70px',
  },
}));

const StyleBoxDiscount = styled(Box)(({ theme }) => ({
  position: 'relative',
  textAlign: 'end',
  [theme.breakpoints.down('sm')]: {
    bottom: '79px',
  },
  [theme.breakpoints.up('sm')]: {
    bottom: '0px',
  },
}));

interface Props {
  comment: string;
  name: string;
  date: string;
}

const PigogoHeroForm: FC<Props> = (props) => {
  return (
    <StyleIconsBox>
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: 400, fontSize: 16, padding: 14 }}>{props.comment}</Typography>
        </Grid>
      </Grid>
      <StyleSymbol>
        <PurpleSymbol />
      </StyleSymbol>
      <StyleBoxDiscount>
        <Grid container justifyContent={'end'}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: '24px', fontWeight: 400 }}>{props.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} style={{ color: ' #838B98', fontSize: '14px', fontWeight: 400 }}>
              μέλος από το ‘{props.date}
            </Typography>
          </Grid>
        </Grid>
      </StyleBoxDiscount>
    </StyleIconsBox>
  );
};

export default PigogoHeroForm;
