import { useEffect, useState } from 'react';

const useAppendData = (newData?: any[], appendNewData?: boolean) => {
  const [finalData, setFinalData] = useState<any[]>([]);

  useEffect(() => {
    if (newData) {
      if (appendNewData) {
        setFinalData((prevState) => {
          const incomingData: any[] = [...newData];
          const mutatedData: any[] = prevState.map((shop: any) => {
            const newDataIndex = incomingData.findIndex((shopState: any) => shopState.id === shop.id);
            if (newDataIndex !== -1) {
              const tempData: any = incomingData[newDataIndex];
              incomingData.splice(newDataIndex, 1);

              return { ...shop, ...tempData };
            }
            return shop;
          });

          return [...mutatedData, ...incomingData];
        });
      } else {
        setFinalData(newData);
      }
    }
  }, [newData]);

  return finalData;
};

export default useAppendData;
