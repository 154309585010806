import React, { useRef, useState } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonRouter,
  useIonViewDidEnter,
} from '@ionic/react';
import ContactIntro from '../components/ContactForm/ContactIntro';
import PigogoFooter from '../components/PigogoFooter';
import ContactDesktop from '../components/ContactForm/ContactDesktop';
import ContactMobile from '../components/ContactForm/ContactMobile';
import { Typography, useMediaQuery, Box } from '@mui/material';
import { theme } from 'components';
import Layout from '../components/Navigation/Layout';
import backArrow from '../assets/svgs/back_arrow.svg';
import ForgotModal from '../components/Navigation/Modals/ForgotModal';
import RegisterModal from '../components/Navigation/Modals/RegisterModal';
import LoginModal from '../components/Navigation/Modals/LoginModal';
import SuccessPasswordModal from '../components/Navigation/Modals/SuccessPasswordModal';
import useQuery from '../hooks/useQuery';
import { useAppSelector } from '../redux/hooks';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import CustomHeader from '../components/CustomHeader';

const ContactForm = () => {
  const desktop = isPlatform('desktop');

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const router = useIonRouter();
  const path = router.routeInfo.pathname;

  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [openForgot, setOpenForgot] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openRegister, setOpenRegister] = useState<boolean>(false);

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (history.action !== 'PUSH') {
      contentRef.current && contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    } else {
      contentRef.current && contentRef.current.scrollToTop();
    }
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <Box>
            <CustomHeader />
            {mdDown && (
              <IonHeader class="ion-no-border">
                <IonToolbar
                  style={{
                    '--background': '#FFFFFF',
                    '--border-width': 0,
                    paddingTop: '0 + --ion-safe-area-top',
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                    <IonButtons slot="start">
                      <IonBackButton
                        className="backBtn"
                        text=""
                        style={{
                          '--color': '#313D53',
                          '--padding-start': 0,
                          '--padding-end': 0,
                          '--icon-font-size': '24px',
                          '--border-radius': 0,
                          '--min-height': '24px',
                          '--min-width': '24px',
                          width: '24px',
                          height: '24px',
                          minWidth: '24px',
                          maxHeight: '24px',
                          display: 'inline-flex',
                        }}
                        icon={backArrow}
                        defaultHref={'/'}
                      />
                    </IonButtons>
                    <Typography component="h2" variant="body2" color={'#313D53'}>
                      Επικοινώνησε μαζί μας
                    </Typography>
                    <Box minWidth={'24px'}></Box>
                  </Box>
                </IonToolbar>
              </IonHeader>
            )}
            {location.pathname === '/contact' && (
              <Helmet>
                <title>Επικοινώνησε μαζί μας | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                <meta
                  name="description"
                  content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                />
              </Helmet>
            )}
          </Box>
          <ContactIntro />
          {mdDown ? (
            <ContactMobile openLoginModal={() => setOpenLogin(true)} />
          ) : (
            <ContactDesktop openLoginModal={() => setOpenLogin(true)} />
          )}
          <>{!mdDown && <PigogoFooter />}</>
          <ForgotModal isOpen={openForgot} setOpen={setOpenForgot} success={setOpenSuccess} />
          <SuccessPasswordModal
            title={'Ευχαριστούμε πολύ!'}
            subTitle={'Το link δημιουργίας νέου κωδικού έχει σταλεί στο email σου.'}
            open={openSuccess}
            handleClose={setOpenSuccess}
          />
          <RegisterModal
            isOpen={openRegister}
            setOpen={setOpenRegister}
            setLoginModal={setOpenLogin}
            userDismiss={() => {
              setOpenLogin(false);
              setOpenRegister(false);
            }}
            successCallback={() => {
              setOpenLogin(false);

              if (openRegister) {
                setTimeout(() => {
                  router.push(`/create-claim`);
                }, 500);
              }
            }}
          />
          <LoginModal
            message={''}
            isOpen={openLogin}
            setLoginModal={(value: boolean) => {
              setOpenLogin(value);
            }}
            dismiss={() => {
              setOpenLogin(false);

              if (openLogin) {
                setTimeout(() => {
                  router.push(`/create-claim`);
                }, 500);
              }
            }}
            userDismiss={() => {
              setOpenLogin(false);
            }}
            openRegisterModal={() => {
              setOpenRegister(true);
              setOpenLogin(false);
            }}
            openForgotModal={() => setOpenForgot(true)}
          />
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default ContactForm;
