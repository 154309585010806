import { Box, Button, Skeleton, Typography } from '@mui/material';
import React, { FC } from 'react';
import ExtractSaver from '../ExtractSaver';
import { ReactComponent as Gift } from '../../assets/svgs/gift.svg';
import { ReactComponent as ChevronRight } from '../../assets/svgs/chevronRight.svg';
import { Link } from 'react-router-dom';

const firstList = [
  {
    title: 'Σύνοψη',
    to: '/user/overview',
  },
  {
    title: 'Αγορές',
    to: '/user/purchases',
  },
  {
    title: 'Πληρωμές',
    to: '/user/payments',
  },
  {
    title: 'Αιτήματα',
    to: '/user/claims',
  },
];

const firstListMobile = [
  {
    title: 'Αγορές',
    to: '/user/purchases',
  },
  {
    title: 'Πληρωμές',
    to: '/user/payments',
  },
  {
    title: 'Αιτήματα',
    to: '/user/claims',
  },
];

const secondList = [
  {
    title: 'Στοιχεία λογαριασμού',
    to: '/user/personal-info',
  },
  {
    title: 'Στοιχεία πληρωμής',
    to: '/user/payments-options',
  },
  {
    title: 'Ρυθμίσεις επικοινωνίας',
    to: '/user/contact-options',
  },
];

type SidebarMenuProps = {
  userData: any;
  pathname: string;
  mobile?: boolean;
  userDataLoading: boolean;
  handleLogout: () => void;
  handleMenuClick: (pathname: string) => void;
};
const SidebarMenu: FC<SidebarMenuProps> = ({
  mobile,
  userData,
  pathname,
  userDataLoading,
  handleLogout,
  handleMenuClick,
}) => {
  const getSaverIcon = () => {
    if (userDataLoading) {
      return (
        <Typography variant="subtitle1">
          <Skeleton sx={{ width: '71px' }} />
        </Typography>
      );
    }

    if (userData.gamification_level_id !== undefined && userData.gamification_level_id !== 0) {
      return <ExtractSaver saver={userData?.gamification_level_id} />;
    }
  };

  return (
    <Box
      p={3}
      gap={3}
      display="flex"
      borderRadius="16px"
      flexDirection="column"
      height={'fit-content'}
      boxSizing={'border-box'}
      width={mobile ? '100%' : '264px'}
      border={mobile ? 'none' : '2px solid #EAECEE'}
    >
      {!mobile && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography
            variant="body2"
            color={'#313D53'}
            maxWidth={'272px'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
          >
            {userData?.first_name && userData?.last_name
              ? `${userData?.first_name} ${userData?.last_name}`
              : userData?.email}
          </Typography>

          {getSaverIcon()}
        </Box>
      )}

      {userData &&
        userData?.gamification_level_id !== undefined &&
        userData?.gamification_level_id !== 0 &&
        !mobile && <Box borderTop={'1px solid #EAECEE'}></Box>}

      <Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {mobile &&
            firstListMobile.map((item, index) => (
              <Link key={index} className="sidebarBtn" to={item.to} onClick={() => handleMenuClick(item.to)}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 'inherit',
                    letterSpacing: 'inherit',
                    lineHeight: 'inherit',
                  }}
                  fontWeight={pathname === item.to ? 700 : 'inherit'}
                >
                  {item.title}
                </Typography>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ChevronRight />
                </Box>
              </Link>
            ))}
          {!mobile &&
            firstList.map((item, index) => (
              <Link key={index} className="sidebarBtn" to={item.to} onClick={() => handleMenuClick(item.to)}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 'inherit',
                    letterSpacing: 'inherit',
                    lineHeight: 'inherit',
                  }}
                  fontWeight={pathname === item.to ? 700 : 'inherit'}
                >
                  {item.title}
                </Typography>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ChevronRight />
                </Box>
              </Link>
            ))}
        </Box>
      </Box>

      <Box borderTop={'1px solid #EAECEE'}></Box>
      <Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {secondList.map((item, index) => (
            <React.Fragment key={index}>
              <Link className="sidebarBtn" to={item.to} onClick={() => handleMenuClick(item.to)}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 'inherit',
                    letterSpacing: 'inherit',
                    lineHeight: 'inherit',
                  }}
                  fontWeight={pathname === item.to ? 700 : 'inherit'}
                >
                  {item.title}
                </Typography>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ChevronRight />
                </Box>
              </Link>
            </React.Fragment>
          ))}
        </Box>
      </Box>

      <Box borderTop={'1px solid #EAECEE'}></Box>

      <Box>
        <Link
          className="sidebarBtn tafBtn"
          to={'/user/tell-a-friend'}
          onClick={() => handleMenuClick('/user/tell-a-friend')}
        >
          <Typography
            component="span"
            variant="body2"
            fontWeight={pathname === '/user/tell-a-friend' ? 700 : undefined}
          >
            Tell a friend
          </Typography>
          <span style={{ display: 'inline-block', marginRight: '-4px', marginLeft: '8px' }}>
            <Gift />
          </span>
        </Link>
      </Box>

      <Box borderTop={'1px solid #EAECEE'}></Box>

      <Box>
        <Button className="sidebarBtn" onClick={handleLogout} sx={{ padding: 0, justifyContent: 'flex-start' }}>
          <Typography
            color="primary"
            variant="body2MediumM"
            sx={{ textDecoration: 'underline', textDecorationColor: '#FCE5D8', textUnderlineOffset: '5px' }}
          >
            Έξοδος
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default SidebarMenu;
