import React, { FC } from 'react';
import { ReactComponent as SaverActive } from '../../../../assets/svgs/savers/large/saverActive.svg';
import { ReactComponent as SmartActive } from '../../../../assets/svgs/savers/large/smartActive.svg';
import { ReactComponent as ExpertActive } from '../../../../assets/svgs/savers/large/expertActive.svg';
import { ReactComponent as SuperActive } from '../../../../assets/svgs/savers/large/superActive.svg';
import { ReactComponent as GuruActive } from '../../../../assets/svgs/savers/large/guruActive.svg';
import { GamificationLevelString } from '../../../../models/User';
import { Box, Typography } from '@mui/material';
import ExtractSaverColor from './ExtractSaverColor';

interface Props {
  saver: GamificationLevelString;
}

const ExtractActiveLevel: FC<Props> = ({ saver }) => {
  const icon = () => {
    switch (saver) {
      case GamificationLevelString.saver:
        return <SaverActive />;
      case GamificationLevelString.smart:
        return <SmartActive />;
      case GamificationLevelString.expert:
        return <ExpertActive />;
      case GamificationLevelString.super:
        return <SuperActive />;
      case GamificationLevelString.guru:
        return <GuruActive />;
      default:
        return null;
    }
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={1}>
      {icon()}
      <Typography sx={{ color: ExtractSaverColor(saver) }} variant="largeB">
        {saver}
      </Typography>
    </Box>
  );
};

export default ExtractActiveLevel;
