import { Avatar, Chip, styled, SvgIcon, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { FC } from 'react';
import theme from '../theme';

const IconStyled = styled(Avatar)(() => ({
  width: '32px',
  height: '32px',
  color: 'var(--text)',
  backgroundColor: 'var(--background)',
}));

const ChipStyled = styled(Chip)(() => ({
  '&.MuiChip-root': {
    fontSize: '16px',
    fontWeight: 500,
    padding: '4px 12px 4px 12px',
    color: 'var(--text)',
    backgroundColor: 'var(--background)',
    borderRadius: '1000px',
    variant: 'contained',
  },
  '& .MuiChip-label': {
    paddingRight: 0,
    paddingLeft: '6px',
    gap: 4,
  },
}));

const primaryVars = {
  '--background': '#E9688A',
  '--text': '#fff',
} as React.CSSProperties;

const secondaryVars = {
  '--background': '#313D53',
  '--text': '#fff',
} as React.CSSProperties;

const orangeVars = {
  '--background': '#FCE5D8',
  '--text': '#E9688A',
} as React.CSSProperties;

interface Props {
  disabled?: boolean;
  variant?: 'outlined' | 'filled' | undefined;
  size?: 'small' | 'medium';
  color?: 'primary' | 'secondary' | 'orange' | undefined;
  dotted?: boolean;
  startIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  endIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  label: string;
  onClick?: (event: any) => void;
  canShrink?: boolean;
}

const PigogoChipSmall: FC<Props> = ({
  disabled,
  variant = 'filled',
  size = 'medium',
  color,
  startIcon,
  dotted,
  endIcon,
  onClick,
  label,
  canShrink,
}) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleAction = () => {
    return;
  };

  const getVar = () => {
    if (color === 'primary') {
      return primaryVars;
    } else if (color === 'secondary') {
      return secondaryVars;
    } else {
      return orangeVars;
    }
  };

  const handleView = () => {
    if (matches && canShrink) {
      return <IconStyled style={getVar()}>{startIcon}</IconStyled>;
    } else {
      return (
        <ChipStyled
          disabled={disabled}
          size={size}
          sx={{
            '& .MuiChip-icon': {
              marginLeft: 0,
              [theme.breakpoints.down('sm')]: {
                marginRight: '-16px',
              },
            },
            borderRadius: '999px',
            border: dotted ? '2px dotted black' : undefined,
          }}
          style={getVar()}
          variant={variant}
          icon={
            startIcon && (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '-4px !important',
                }}
              >
                {startIcon}
              </Box>
            )
          }
          deleteIcon={endIcon && <SvgIcon>{endIcon}</SvgIcon>}
          label={matches ? '' : label}
          onDelete={endIcon && handleAction}
          onClick={onClick}
        />
      );
    }
  };

  return handleView();
};

export default PigogoChipSmall;
