import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from 'components';

interface Props {
  topIcon: ReactNode;
  bottomIcon: ReactNode;
  title: string;
  description: string;
}

const HowItWorksCard: FC<Props> = ({ topIcon, bottomIcon, title, description }) => {
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      gap={4}
      display="flex"
      bgcolor={'#FFFFFF'}
      borderRadius={'24px'}
      height={!downSm ? '248px' : ''}
      minHeight={downSm ? '384px' : ''}
      width={!downSm ? '496px' : '327px'}
      p={downSm ? '24px' : '24px 48px 24px 32px'}
      sx={{ flexWrap: downSm ? 'wrap' : 'unset' }}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box>
          <Typography variant="largeMedium" color="#313D53">
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2MediumR" color="#313D53">
            {description}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection={!downSm ? 'column' : 'row'} gap={2} borderRadius="12px">
        <Box>{topIcon}</Box>
        <Box>{bottomIcon}</Box>
      </Box>
    </Box>
  );
};

export default HowItWorksCard;
