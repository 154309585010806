import { Box, styled, Typography } from '@mui/material';
import howItWorks from '../assets/jpg/howItWorksLg.jpg';
import howItWorksSm from '../assets/jpg/howItWorksSm.jpg';
import CustomContainer from '../components/CustomContainer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from 'components';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react';
import CustomHeader from '../components/CustomHeader';
import React, { useRef } from 'react';
import Layout from '../components/Navigation/Layout';
import { ReactComponent as Bank } from '../assets/svgs/bankSm.svg';
import { ReactComponent as Viva } from '../assets/svgs/vivaSm.svg';
import { ReactComponent as Store } from '../assets/svgs/store.svg';
import { ReactComponent as CheckCircle } from '../assets/svgs/check-circle.svg';
import HowItWorksCard from '../components/HowItWorksCard';
import QuestionSection from '../components/Home/QuestionSection';
import AlertSection from '../components/Home/AlertSection';
import DownloadSection from '../components/Home/DownloadSection';
import PigogoFooter from '../components/PigogoFooter';
import StepSection from '../components/StepSection';
import TopBannerImage from '../components/TopBannerImage';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { isWeb } from '../utils/device';
import { Helmet } from 'react-helmet-async';

const StyleBoxText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    width: '496px',
    paddingTop: '162px',
    paddingBottom: '162px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const HowItWorks = () => {
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    if (history.action !== 'PUSH') {
      contentRef.current && contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    } else {
      contentRef.current && contentRef.current.scrollToTop();
    }
  });

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  const chooseImg = () => {
    if (downMd) {
      return howItWorksSm;
    } else {
      return howItWorks;
    }
  };

  const footerSection = () => {
    if (upMd) {
      return <>{<PigogoFooter />}</>;
    } else {
      return <></>;
    }
  };

  return (
    <IonPage>
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <Box>
            <CustomHeader />
            {downMd && (
              <IonHeader class="ion-no-border">
                <IonToolbar
                  style={{
                    '--background': '#FFFFFF',
                    '--border-width': 0,
                    paddingTop: '0 + --ion-safe-area-top',
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
                    <IonButtons slot="start">
                      <IonBackButton
                        className="backBtn"
                        text=""
                        style={{
                          '--color': '#313D53',
                          '--padding-start': 0,
                          '--padding-end': 0,
                          '--icon-font-size': '24px',
                          '--border-radius': 0,
                          '--min-height': '24px',
                          '--min-width': '24px',
                          width: '24px',
                          height: '24px',
                          minWidth: '24px',
                          maxHeight: '24px',
                          display: 'inline-flex',
                        }}
                        icon={backArrow}
                        defaultHref={'/'}
                      />
                    </IonButtons>
                    <Typography component="h2" variant="body2" color={'#313D53'}>
                      Πώς λειτουργεί
                    </Typography>
                    <Box minWidth={'24px'}></Box>
                  </Box>
                </IonToolbar>
              </IonHeader>
            )}
            {/* {!downSm && <CustomHeader />} */}
            {location.pathname === '/how-it-works' && (
              <Helmet>
                <title>Πώς λειτουργεί | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
                <meta
                  name="description"
                  content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                />
              </Helmet>
            )}
          </Box>
          <TopBannerImage image={chooseImg()} title={'Πώς λειτουργεί'} shape={true}>
            Με το Pigogo μπορείς να κερδίζεις επιστροφή χρημάτων κάθε φορά που ψωνίζεις online.
          </TopBannerImage>
          <StepSection />
          <Box bgcolor="#8C3E53">
            <CustomContainer>
              <Box
                gap={4}
                display="flex"
                flexWrap={'wrap'}
                justifyContent={'center'}
                pt={downSm ? '32px' : '80px'}
                pb={downSm ? '32px' : '80px'}
              >
                <HowItWorksCard
                  topIcon={<Store />}
                  title={'Τι γίνεται μετά;'}
                  bottomIcon={<CheckCircle />}
                  description={
                    'Εσύ δεν χρειάζεται να κάνεις απολύτως ΤΙΠΟΤΑ! Αμέσως μετά την καταγραφή της αγοράς σου, ξεκινάει η διαδικασία επιβεβαίωσης της αγοράς από το ίδιο το κατάστημα και μόλις γίνει αυτό, θα λάβεις την επιστροφή χρημάτων που δικαιούσαι από το Pigogo!'
                  }
                />
                <HowItWorksCard
                  topIcon={<Viva />}
                  bottomIcon={<Bank />}
                  title={'Πώς πληρώνομαι από το Pigogo;'}
                  description={`Μπορείς να πληρωθείς σε οποιονδήποτε ελληνικό τραπεζικό λογαριασμό (ΙΒΑΝ) ή στο Viva Wallet σου!
                    Το Pigogo δεν θα σε χρεώσει ποτέ για την υπηρεσία του. Είναι δωρεάν!`}
                />
              </Box>
            </CustomContainer>
          </Box>
          <>{!downSm && <AlertSection />}</>
          <QuestionSection />
          {isWeb() ? (
            <DownloadSection
              readMore
              title={'Pigogo mobile app'}
              subtitle={
                <span>
                  Κατέβασε την εφαρμογή του Pigogo στο κινητό σου. <br /> Διαθέσιμη σε <strong>Google Play</strong> και{' '}
                  <strong>App Store</strong>
                </span>
              }
            />
          ) : (
            <></>
          )}
          {footerSection()}
        </Layout>
      </IonContent>
    </IonPage>
  );
};

export default HowItWorks;
