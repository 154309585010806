import React, { FC, useEffect, useRef } from 'react';
import LinkSearch from './LinkSearch';
import NoResults from './NoResults';
import Recommended from './Recomended';
import Results from './Results';
import Suggested from './Suggested';
import { AllShop } from '../../../../models/AllShop';
import { GenericRequest } from '../../../../models/GenericRequest';
import { Box } from '@mui/material';
import { useIonViewWillLeave } from '@ionic/react';

interface Props {
  link: boolean;
  search: string;
  searchResultsLoading: boolean;
  searchResults: GenericRequest<AllShop[]> | undefined;
  closeSearchByLink: () => void;
  closeSearch: () => void;
  setAnchorEl: (anchorEl: HTMLInputElement | HTMLTextAreaElement | null) => void;
  setSearchKeyword?: (value: string) => void;
}

const SearchContent: FC<Props> = ({
  link,
  closeSearchByLink,
  closeSearch,
  search,
  setAnchorEl,
  searchResults,
  searchResultsLoading,
  setSearchKeyword,
}) => {
  const previousLink = useRef<boolean>(false);

  useIonViewWillLeave(() => (previousLink.current = link));

  if (search === '') {
    return (
      <Box
        className="overscroll"
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        sx={{ height: 'auto', overflow: 'auto', paddingBlock: 4, marginBlock: -4 }}
      >
        <Recommended />
        <Suggested />
      </Box>
    );
  } else if ((search !== '' && searchResults?.data.length !== 0) || searchResultsLoading) {
    return (
      <Box
        className="overscroll"
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        sx={{ height: 'auto', overflow: 'auto', paddingBlock: 4, marginBlock: -4 }}
      >
        <Results
          searchId={search}
          setAnchorEl={setAnchorEl}
          searchResults={searchResults}
          searchResultsLoading={searchResultsLoading}
          closeSearch={closeSearch}
          setSearchKeyword={setSearchKeyword}
        />
      </Box>
    );
  } else {
    return (
      <Box>
        <NoResults />
      </Box>
    );
  }
};

export default SearchContent;
