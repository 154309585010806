import { Button, Skeleton, styled, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import { Purchase } from '../../../models/Purchase';
import { ReactComponent as ChevronRight } from '../../../assets/svgs/chevronRight.svg';
import { useIonRouter } from '@ionic/react';
import React, { FC } from 'react';
import ExtractPurchaseStatus from '../../ExtractPurchasesStatus';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(() => ({
  padding: '0px 16px 0 24px',
}));

interface Props {
  row?: Purchase;
  onRowClick?: (purchase: Purchase) => void;
  isLoading?: boolean;
}

const PurchasesDesktopRow: FC<Props> = ({ row, onRowClick, isLoading }) => {
  const router = useIonRouter();

  const handleRowClick = (event: any, row: any) => {
    if (onRowClick) {
      onRowClick(row);
      event.stopPropagation();
    }
  };
  const handleShop = (event: any, shop?: string) => {
    router.push(`/${shop}`);
    event.stopPropagation();
  };

  return (
    <TableRow
      onClick={row && onRowClick ? () => onRowClick(row) : undefined}
      hover
      sx={{
        overflow: 'hidden',
        '&:children': {
          borderLeft: 0,
          borderRight: 0,
        },
        cursor: 'pointer',
        height: '72px',
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        '&:last-child td:first-of-type': {
          borderBottomLeftRadius: '24px',
        },
        '&:last-child td:last-child': {
          borderBottomRightRadius: '24px',
        },
      }}
    >
      <StyledTableCell>
        <Typography variant="caption" color={'#313D53'}>
          {isLoading ? <Skeleton width={80} sx={{ height: '20px' }} /> : moment(row?.date).format('DD/MM/YYYY')}
        </Typography>
      </StyledTableCell>
      <StyledTableCell
        sx={{ cursor: 'pointer' }}
        onClick={(e) => (row?.shop_status_id === 3 ? handleShop(e, row?.shop_slug.name) : undefined)}
      >
        {isLoading && (
          <Typography variant="body2MediumM" color={'#313D53'}>
            <Skeleton width={80} sx={{ height: '20px' }} />
          </Typography>
        )}
        {!isLoading && row?.shop_status_id === 3 && (
          <React.Fragment>
            <Link
              className="btnLink--table"
              to={`/${row?.shop_slug.name}`}
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              {isLoading ? <Skeleton width={80} sx={{ height: '20px' }} /> : row?.shop_name}
            </Link>
          </React.Fragment>
        )}
        {!isLoading && row?.shop_status_id !== 3 && (
          <Typography variant="body2MediumM" color={'#313D53'}>
            {isLoading ? <Skeleton width={80} sx={{ height: '20px' }} /> : row?.shop_name}
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2MediumM" color={'#313D53'}>
          {isLoading ? <Skeleton width={60} sx={{ height: '20px', marginTop: '8px' }} /> : row?.amount}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2MediumM" color={'#313D53'}>
          {isLoading ? <Skeleton width={60} sx={{ height: '20px', marginTop: '8px' }} /> : row?.cashback_amount}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2MediumM">
          {isLoading ? (
            <Skeleton width={80} sx={{ height: '20px' }} />
          ) : (
            <ExtractPurchaseStatus saver={row?.purchase_status_id || 0} />
          )}
        </Typography>
      </StyledTableCell>
      <TableCell
        sx={{ padding: '0 24px', cursor: 'pointer' }}
        onClick={row && onRowClick ? (e) => handleRowClick(e, row) : undefined}
      >
        <ChevronRight />
      </TableCell>
    </TableRow>
  );
};

export default PurchasesDesktopRow;
