import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Symbol } from '../../assets/svgs/thirdSlideSymbol.svg';
import { ReactComponent as Success } from '../../assets/svgs/illustrations/success.svg';
import { theme } from 'components';

const ThirdSlide = () => {
  const narrowScreenExcl = useMediaQuery(theme.breakpoints.down('sm'));
  const xxsScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const narrowScreen = narrowScreenExcl || xxsScreen;

  return (
    <Box
      p={3}
      gap={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: '#FFFFFF' }}
      position="relative"
    >
      <Box position="absolute" top={20} zIndex={0}>
        <Symbol />
      </Box>
      <Box zIndex={1} style={{ paddingTop: '20px' }}>
        <Success width={160} height={160} />
      </Box>
      <Box zIndex={1} display="flex" flexDirection="column" gap={2}>
        <Typography px={narrowScreen ? 1 : 3} textAlign="center" variant="h4">
          Συγχαρητήρια, η αγορά σου καταγράφηκε!
        </Typography>
        <Typography px={narrowScreen ? 1 : 6} textAlign="center" variant="mediumH6R">
          Η αγορά σου θα εμφανιστεί αυτόματα στον λογαριασμό σου στο Pigogo!
        </Typography>
      </Box>
    </Box>
  );
};

export default ThirdSlide;
