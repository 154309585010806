import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { PigogoButton, PigogoProductCard, PigogoSelectableButton, theme } from 'components';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as ShopsRectangle } from '../../assets/svgs/illustrations/shopsRectangle.svg';
import { useIonRouter } from '@ionic/react';
import { Sort } from '../../redux/api/types/enum/Sort';
import { useShopsQuery } from '../../redux/api/queries/shopQuery';
import { AllShop } from '../../models/AllShop';
import { useAllCategoriesQuery } from '../../redux/api/queries/categoriesQuery';
import { ShopCategory } from '../../models/ShopCategory';
import { useDeleteFavouriteMutation, usePostFavouriteMutation } from '../../redux/api/mutations/favouritesMutation';
import { useAppSelector } from '../../redux/hooks';
import { ListingParams } from '../../redux/api/types/ListingParams';
import { setSnackBar, setStateSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import HorizontalCategories from '../HorizontalCategories';
import CustomContainer from '../CustomContainer';
import { Link } from 'react-router-dom';

const dummyArray = [1, 1, 1, 1, 1, 1, 1, 1];

const ShopsSection = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const xlUp = useMediaQuery(theme.breakpoints.up('extraLg'));

  const { accessToken } = useAppSelector((state) => state.authentication.data);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [selectedCategory, setSelectedCategory] = useState<{ slugName: string | undefined; label: string }>({
    slugName: undefined,
    label: 'Όλες οι κατηγορίες',
  });

  const [loadArray, setLoadArray] = useState(dummyArray);
  const [filters, setFilters] = useState<ListingParams>({
    limit: 8,
    sort: Sort.P,
    category: undefined,
    nextCursor: undefined,
    no_promo: true,
  });

  const [postFavourite, { isError: errorPostFav }] = usePostFavouriteMutation();
  const [deleteFavourite, { isError: errorDeleteFav }] = useDeleteFavouriteMutation();
  const { data: shops, isFetching: shopsFetching, isError: errorShops } = useShopsQuery(filters);
  const {
    data: allCategories,
    isLoading: categoriesLoading,
    isError: errorCategories,
  } = useAllCategoriesQuery({ filter: 'parents' });

  useEffect(() => {
    if (errorCategories) {
      dispatch(setSnackBar({ value: true, msg: 'Κάτι πήγε στραβά με τις κατηγορίες.', severity: 'error' }));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorCategories]);

  useEffect(() => {
    if (errorShops) {
      dispatch(setSnackBar({ value: true, msg: 'Κάτι πήγε στραβά με τα καταστήματα.', severity: 'error' }));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorShops]);

  useEffect(() => {
    if (errorPostFav || errorDeleteFav) {
      dispatch(setSnackBar({ value: true, msg: 'Κάτι πήγε στραβά με τα αγαπημένα.', severity: 'error' }));
    } else {
      dispatch(setStateSnackBar(false));
    }
  }, [errorPostFav, errorDeleteFav]);

  useEffect(() => {
    if (smDown) {
      setLoadArray(dummyArray.slice(0, 4));
    } else {
      setLoadArray(dummyArray);
    }
  }, [smDown]);

  useEffect(() => {
    setFilters({ ...filters, category: selectedCategory.slugName });
  }, [selectedCategory]);

  const favourite = (userFavourite: boolean | null, id: number) => {
    if (userFavourite) {
      deleteFavourite(id);
    } else {
      postFavourite({ shop_id: id, notifications: 1 });
    }
  };

  const extractCategorySkeletons = () => {
    if (smDown) {
      return [1, 1].map((item, index) => (
        <Box key={index} px={1} display="flex" alignItems="center" justifyContent="center">
          <Skeleton
            variant="rectangular"
            sx={{
              height: '60px',
              width: '200px',
              borderRadius: '64px',
              padding: '20px 10px',
              border: '2px solid #ECEFF4',
            }}
          />
        </Box>
      ));
    } else if (mdDown) {
      return [1, 1, 1, 1].map((item, index) => (
        <Box key={index} px={1} display="flex" alignItems="center" justifyContent="center">
          <Skeleton
            variant="rectangular"
            sx={{
              height: '60px',
              width: '200px',
              borderRadius: '64px',
              padding: '20px 10px',
              border: '2px solid #ECEFF4',
            }}
          />
        </Box>
      ));
    } else {
      return [1, 1, 1, 1, 1, 1].map((item, index) => (
        <Box key={index} px={1} display="flex" alignItems="center" justifyContent="center">
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: '64px',
              height: '60px',
              width: '200px',
              border: '2px solid #ECEFF4',
              padding: '20px 10px',
            }}
          />
        </Box>
      ));
    }
  };

  const storeCards = () => {
    if ((shopsFetching && !shops) || (shopsFetching && shops)) {
      return loadArray.map((item, index: number) => (
        <Box
          key={index}
          sx={{
            width: {
              xs: 'calc(50% - 4px)',
              sm: 'calc(25% - 6px)',
              md: 'calc(25% - 12px)',
              extraLg: 'calc(25% - 18px)',
            },
          }}
        >
          <PigogoProductCard key={index} loading={true} logout={!(!!accessToken || sessionLoggedIn)} />
        </Box>
      ));
    }

    if (shops && shops.data && !shopsFetching) {
      return shops.data.map((shop: AllShop, index: number) => (
        <Box
          key={index}
          sx={{
            width: {
              xs: 'calc(50% - 4px)',
              sm: 'calc(25% - 6px)',
              md: 'calc(25% - 12px)',
              extraLg: 'calc(25% - 18px)',
            },
          }}
        >
          <a className="storeCard" href={`/${shop.slug.name}`}>
            <PigogoProductCard
              shop={shop}
              logout={true}
              loading={false}
              handleClick={(e: any) => {
                e.preventDefault();
                router.push(`/${shop.slug.name}`);
              }}
              setFavourite={() =>
                (!!accessToken || sessionLoggedIn) && shop.user_favourite !== null
                  ? favourite(shop.user_favourite, shop.id)
                  : ''
              }
            />
          </a>
        </Box>
      ));
    }

    if (shops && shops.data && shops.data.length === 0 && !shopsFetching) {
      return <Typography textAlign="center">Δεν υπάρχουν καταστήματα</Typography>;
    }
  };

  return (
    <Box
      pb={mdDown ? 4 : 10}
      position="relative"
      pt={mdDown ? 4 : 10}
      boxSizing={'border-box'}
      sx={{ backgroundColor: mdUp ? '#FCE5D8' : '#FFFFF' }}
    >
      <CustomContainer zIndex={2} display={'flex'} flexDirection={'column'} gap={6}>
        <Box gap={2} display="flex" flexDirection="column" alignItems={'center'}>
          <Box>
            <Typography textAlign="center" color={mdDown ? '#1D2532' : 'white'} variant="smallB" component="h2">
              Συνεργαζόμενα καταστήματα
            </Typography>
          </Box>
          {mdUp && (
            <Box>
              <Typography textAlign="center" variant="body2MediumL" component="p" color="white">
                Στο Pigogo θα βρεις πάνω από 400 επώνυμα ηλεκτρονικά καταστήματα.
              </Typography>
            </Box>
          )}
        </Box>

        {!allCategories?.data.length && !categoriesLoading && (
          <Box>
            <Typography color="common.white" textAlign="center" component="p" role="alert">
              Δεν υπάρχουν κατηγορίες
            </Typography>
          </Box>
        )}
        {(categoriesLoading || allCategories?.data.length) && (
          <Box>
            <HorizontalCategories>
              {!categoriesLoading && allCategories && (
                <Box display="flex" paddingRight={1} alignItems="center" justifyContent="center">
                  <PigogoSelectableButton
                    text={'Όλες οι κατηγορίες'}
                    selectedCategory={selectedCategory.label}
                    typoProps={{ color: '#313D53', fontSize: '16px', fontWeight: 500 }}
                    handleClick={() => setSelectedCategory({ slugName: undefined, label: 'Όλες οι κατηγορίες' })}
                  />
                </Box>
              )}
              {!categoriesLoading && allCategories
                ? allCategories.data.map((category: ShopCategory, index) => (
                    <Box key={index} paddingRight={1} display="flex" alignItems="center" justifyContent="center">
                      <PigogoSelectableButton
                        typoProps={{ color: '#313D53', fontSize: '16px', fontWeight: 500 }}
                        selectedCategory={selectedCategory.label}
                        text={category.label}
                        handleClick={() => setSelectedCategory({ slugName: category.slug.name, label: category.label })}
                      />
                    </Box>
                  ))
                : extractCategorySkeletons()}
            </HorizontalCategories>
          </Box>
        )}

        <Box display={'flex'} flexWrap={'wrap'} sx={{ gap: { xs: 1, md: 2, extraLg: 3 } }}>
          {storeCards()}
        </Box>

        <Box display={'flex'} justifyContent={'center'}>
          <Box width={'327px'} maxWidth={'100%'}>
            <Link className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth" to={'/stores'}>
              Όλα τα καταστήματα
            </Link>
          </Box>
        </Box>
      </CustomContainer>
      {mdUp && (
        <Box position="absolute" right="0px" top="0px" zIndex={1}>
          <ShopsRectangle />
        </Box>
      )}
    </Box>
  );
};

export default ShopsSection;
