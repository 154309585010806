import React, { FC } from 'react';
import { IonModal, useIonRouter, IonHeader, IonToolbar } from '@ionic/react';
import { Box, Icon, IconButton, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Click } from '../../assets/svgs/flipcards/click.svg';
import { ReactComponent as Info } from '../../assets/svgs/info.svg';
import { ReactComponent as ChevronLeft } from '../../assets/svgs/chevronLeft24.svg';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import { Browser } from '@capacitor/browser';
import { theme } from 'components';
import { isWeb } from '../../utils/device';
import { Link } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  setOpen: (b: boolean) => void;
}

const MissingClickModal: FC<Props> = ({ isOpen, setOpen }) => {
  const router = useIonRouter();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <IonModal
      mode={'ios'}
      isOpen={isOpen}
      canDismiss={true}
      onDidDismiss={() => setOpen(false)}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
    >
      <IonHeader class="ion-no-border">
        {smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '0 + --ion-safe-area-top' }}>
            <Box pr={1} pl={'5px'} py={2} display="flex" gap={2} alignItems="flex-start">
              <IconButton
                sx={{
                  padding: 0,
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                disableRipple
                onClick={() => setOpen(!isOpen)}
              >
                <ChevronLeft />
              </IconButton>
            </Box>
          </IonToolbar>
        )}
        {!smDown && (
          <IonToolbar style={{ '--background': '#FFFFFF', paddingTop: '12px', height: '24px' }}>
            <IconButton
              disableRipple
              sx={{
                position: 'absolute',
                right: 12,
                top: 0,
                padding: 0,
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => setOpen(!isOpen)}
            >
              <Close />
            </IconButton>
          </IonToolbar>
        )}
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap={2}
          pl={!smDown ? 4 : 2}
          pr={!smDown ? 3 : 2}
          pt={0}
          pb={!smDown ? 3 : 2}
        >
          <Box display="flex" justifyContent="center">
            <Click />
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <Typography textAlign="center" variant="body1" color={'#313D53'} component="h2">
              Γιατί δεν βρίσκω το κλικ μου;
            </Typography>
            <Typography textAlign="center" variant="body2MediumR" color={'#313D53'} component="p">
              Αν δεν βλέπεις το κατάστημα ή το κλικ σου, σημαίνει ότι δεν καταγράφηκε η επίσκεψή σου από το Pigogo προς
              το κατάστημα και κατά συνέπεια και το κλικ.{' '}
            </Typography>
            <Typography textAlign="center" variant="body2MediumR" color={'#313D53'} component="p">
              Αυτό συνήθως συμβαίνει όταν οι χρήστες ξεχνούν να κάνουν κλικ από το Pigogo με αποτέλεσμα να μην
              καταγράφονται οι αγορές τους.
            </Typography>
            <Typography textAlign="center" variant="body2MediumR" color={'#313D53'} component="p">
              Δυστυχώς, σε αυτές τις περιπτώσεις (που δεν έχει προηγηθεί κλικ), το Pigogo δεν δικαιούται προμήθεια απο
              το κατάστημα και άρα δεν μπορεί να αποδώσει την αντίστοιχη επιστροφή.
            </Typography>
          </Box>
          <Box
            p={2}
            gap="18.5px"
            display="flex"
            borderRadius="8px"
            alignItems="center"
            sx={{ backgroundColor: '#ECEFF4' }}
          >
            <Icon>
              <Info />
            </Icon>
            <Typography variant="captionM" color={'#313D53'}>
              Για περισσότερες πληροφορίες σχετικά με την καταγραφή των αγορών, ακολούθησε τα tips που θα βρεις{' '}
              {!isWeb() && (
                <Link to={'/return-tips'}>
                  <Typography
                    color="primary"
                    variant="captionM"
                    sx={{ textDecoration: 'underline', textDecorationColor: '#313D53', cursor: 'pointer' }}
                  >
                    εδώ!
                  </Typography>
                </Link>
              )}
              {isWeb() && (
                <Typography
                  color="primary"
                  variant="captionM"
                  sx={{ textDecoration: 'underline', textDecorationColor: '#313D53', cursor: 'pointer' }}
                  onClick={() => {
                    Browser.open({ url: '/return-tips' });
                  }}
                >
                  εδώ!
                </Typography>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default MissingClickModal;
