import React, { FC, useEffect } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ReactComponent as DotWhiteFilled } from '../../assets/svgs/dotWhiteFilled.svg';
import { ReactComponent as DotWhiteUnfilled } from '../../assets/svgs/dotWhiteUnfilled.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useIonViewDidEnter } from '@ionic/react';
import { theme } from 'components';
import HeroPromoCard from './HeroPromoCard';

interface Props {
  children: React.ReactElement[];
  desktop?: boolean;
  setCarouselLoading?: (val: boolean) => void;
}

const HeroSectionCarousel: FC<Props> = ({ children, desktop = false, setCarouselLoading }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const xxlUp = useMediaQuery(theme.breakpoints.up('xl'));
  const xlargeUp = useMediaQuery(theme.breakpoints.up('extraLg'));

  useEffect(() => {
    setTimeout(() => {
      setCarouselLoading && setCarouselLoading(false);
    }, 500);
  }, []);

  if (children !== null) {
    if (desktop) {
      return (
        <Box
          className="heroCarouselVertical"
          sx={{
            width: '100%',
            position: 'relative',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="column"
            alignSelf="flex-end"
            mr={13.5}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '608px',
              background:
                'linear-gradient(0deg, rgba(49, 61, 83, 1) 0%, rgba(49, 61, 83, 1) 100%), linear-gradient(99deg, rgba(16, 31, 75, 1) 0%, rgba(233, 104, 138, 1) 100%)',
            }}
          >
            <div
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: 30,
                paddingTop: 30,
              }}
            >
              <HeroPromoCard loading />
            </div>
          </Box>
          <Box style={{ width: '100%', position: 'absolute' }}>
            <Carousel
              axis={'vertical'}
              autoPlay={true}
              infiniteLoop={true}
              interval={6000}
              swipeable={true}
              showThumbs={false}
              showArrows={true}
              showStatus={false}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      left: lgUp ? 'auto' : '24px',
                      right: lgUp ? '698px' : 'auto',
                      bottom: 270 - (children.length * 24) / 2 + (children.length - index) * 24,
                      width: 30,
                    }}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >
                    {isSelected ? (
                      <IconButton size="small" sx={{ padding: '4px', bottom: 'auto' }} disableRipple>
                        <DotWhiteFilled />
                      </IconButton>
                    ) : (
                      <IconButton size="small" sx={{ padding: '4px', bottom: 'auto' }} disableRipple>
                        <DotWhiteUnfilled />
                      </IconButton>
                    )}
                  </div>
                );
              }}
            >
              {children}
            </Carousel>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box>
          <Carousel
            axis={'horizontal'}
            autoPlay={true}
            infiniteLoop={true}
            interval={6000}
            swipeable={true}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              return (
                <span
                  style={{
                    marginBottom: 20,
                    width: 30,
                  }}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                  {isSelected ? (
                    <IconButton size="small" sx={{ padding: '4px', bottom: 'auto' }} disableRipple>
                      <DotWhiteFilled />
                    </IconButton>
                  ) : (
                    <IconButton size="small" sx={{ padding: '4px', bottom: 'auto' }} disableRipple>
                      <DotWhiteUnfilled />
                    </IconButton>
                  )}
                </span>
              );
            }}
          >
            {children}
          </Carousel>
        </Box>
      );
    }
  } else {
    return null;
  }
};

export default HeroSectionCarousel;
