import React, { FC } from 'react';
import { Breadcrumbs, BreadcrumbsProps, Link, LinkProps, styled, Typography } from '@mui/material';

type BarLinkProps = {
  breadcumbProps?: BreadcrumbsProps;
  linkProps?: LinkProps;
  barLinks: { to: string; title: string; exact: boolean }[];
};

const StyledBreadcrumbs = styled((props: BreadcrumbsProps) => <Breadcrumbs {...props} />)`
  color: #313d53;
  text-align: center;
  display: inline-flex;
  &.active {
    border-bottom: none;
  }
`;

const PigogoBreadcrumb: FC<BarLinkProps & BreadcrumbsProps> = ({ title, ...props }) => {
  return (
    <StyledBreadcrumbs
      {...props}
      separator={'>'}
      sx={{
        '& .MuiBreadcrumbs-separator': {
          fontWeight: 400,
          marginInline: 0.5,
          alignItems: 'center',
        },
      }}
    >
      {props.barLinks.map((link, index) => (
        <Link
          key={index}
          {...props.linkProps}
          underline="none"
          href={link.to}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant={'caption'}>{link.title}</Typography>
        </Link>
      ))}
    </StyledBreadcrumbs>
  );
};

export default PigogoBreadcrumb;
