import React, { FC } from 'react';
import { useIonRouter } from '@ionic/react';
import ContactTextFields from './ContactTextFields';
import { PigogoEntryPoint, theme } from 'components';
import CustomContainer from '../CustomContainer';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContactCard from '../ContactCard';

interface Props {
  openLoginModal?: () => void;
}

const ContactMobile: FC<Props> = ({ openLoginModal }) => {
  const router = useIonRouter();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CustomContainer>
      <Box style={{ display: 'flex', paddingTop: 40, paddingBottom: 64, gap: 64, flexDirection: 'column' }}>
        <ContactTextFields />

        <ContactCard />

        <Box
          gap={smDown ? 2 : 5}
          display={'flex'}
          flexDirection={smDown ? 'column' : 'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <PigogoEntryPoint
            title={'Συχνές ερωτήσεις'}
            subTitle={'Μπορεί το ερώτημά σου να έχει ήδη απαντηθεί στις συχνές ερωτήσεις.'}
            linkText={'δες περισσότερα'}
            onClick={() => router.push('/faq')}
          />
          <PigogoEntryPoint
            sx={{ background: '#FDF0F3' }}
            title={'Δημιουργία αιτήματος'}
            subTitle={
              'Ψάχνεις κάτι σχετικό με τις αγορές σου και δεν το βρίσκεις; Μπορείς να δημιουργήσεις ένα αίτημα για να το ερευνήσουμε!'
            }
            linkText={'δες περισσότερα'}
            onClick={() => {
              openLoginModal ? openLoginModal() : router.push('/create-claim');
            }}
          />
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default ContactMobile;
