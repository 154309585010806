import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { PigogoButton } from 'components';
import PigogoSymbolWrapper from './PigogoSymbolWrapper';

const StyleBoxContainer = styled(Box)(({ theme }) => ({
  zIndex: 2,
  margin: 'auto',
  position: 'relative',
  boxSizing: 'border-box',
  [theme.breakpoints.up('extraLg')]: {
    maxWidth: '800px',
    padding: '80px 0px',
  },
  [theme.breakpoints.down('extraLg')]: {
    maxWidth: '1024px',
    padding: '80px 0',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '704px',
    padding: '40px 0',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: '40px 0',
  },
}));

const BoxStyle = styled(Box)(({ theme }) => ({
  margin: 'auto',
  textAlign: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '608px',
  },
  [theme.breakpoints.up('extraLg')]: {
    width: '800px',
  },
}));

const BoxButtonsStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    gap: 16,
    paddingTop: 48,
    flexDirection: 'column',
    alignItems: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    gap: 24,
    paddingTop: 56,
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    gap: 24,
    paddingTop: 48,
    flexDirection: 'row',
  },
  [theme.breakpoints.up('extraLg')]: {
    paddingTop: 80,
  },
}));

type Props = {
  openLogin: () => void;
  openRegister: () => void;
};

const PigogoLoginRegister: FC<Props> = ({ openLogin, openRegister }) => {
  return (
    <PigogoSymbolWrapper typeOfWrapper={'footer'}>
      <StyleBoxContainer>
        <BoxStyle>
          <Box flexDirection={'column'} display={'flex'} gap={2}>
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Typography variant={'smallB'} component="h2" style={{ color: '#FFFF' }}>
                Ξεκίνα σήμερα να κερδίζεις επιστροφή χρημάτων από τις online αγορές σου
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Typography variant={'body2MediumL'} component="p" style={{ color: '#FFFF' }}>
                Γίνε μέλος του Pigogo δωρεάν!
              </Typography>
            </Box>
          </Box>
          <BoxButtonsStyle>
            <PigogoButton
              fullWidth
              text={'Σύνδεση'}
              variation={'white'}
              variant={'outlined'}
              sx={{ width: '327px' }}
              onClick={() => openLogin()}
            />
            <PigogoButton
              fullWidth
              text={'Εγγραφή'}
              variation={'white'}
              sx={{ width: '327px' }}
              onClick={() => openRegister()}
            />
          </BoxButtonsStyle>
        </BoxStyle>
      </StyleBoxContainer>
    </PigogoSymbolWrapper>
  );
};

export default PigogoLoginRegister;
