import React from 'react';
import { Box } from '@mui/material';
import adblock from '../assets/svgs/flipcards/adblock.svg';
import cookie from '../assets/svgs/flipcards/cookie.svg';
import bankCard from '../assets/svgs/flipcards/bankCard.svg';
import click from '../assets/svgs/flipcards/click.svg';
import incognito from '../assets/svgs/flipcards/incognito.svg';
import target from '../assets/svgs/flipcards/target.svg';
import { PigogoFlipCard } from 'components';
import CustomContainer from './CustomContainer';

const flipCardInfo = [
  {
    img: adblock,
    subtitle: 'Αφαίρεσε τον ad-blocker από τον browser σου.',
    description:
      'Οι περισσότεροι ad-blockers εμποδίζουν το σύστημα καταγραφής αγορών που χρησιμοποιούν τα συνεργαζόμενα καταστήματά μας. Για αυτό, αν χρησιμοποιείς κάποιον ad-blocker στον browser του υπολογιστή σου, αφαίρεσέ τον κάνοντας δεξί κλικ πάνω στο εικονίδιό του (επιλέγοντας ‘Κατάργηση από τον Chrome’ ή ‘Αφαίρεση επέκτασης’ για Firefox).',
  },
  {
    img: cookie,
    subtitle: 'Να αποδέχεσαι πάντα τα cookies των καταστημάτων.',
    description:
      'Η καταγραφή των αγορών βασίζεται στα cookies οπότε είναι απαραίτητα να τα αποδέχεσαι αμέσως μόλις μεταφέρεσαι στα καταστήματα (‘Αποδοχή των cookies’). Επιπλέον, ο browser που χρησιμοποιείς δεν θα πρέπει να αποκλείει τα third-party cookies (cookies τρίτων). Έλεγξε τις ρυθμίσεις του browser σου, καθώς ορισμένοι browsers μπλοκάρουν τα cookies τρίτων από προεπιλογή.',
  },
  {
    img: incognito,
    subtitle: 'Μην χρησιμοποιείς Ανώνυμη Περιήγηση.',
    description:
      'Μην χρησιμοποιείς Ανώνυμη Περιήγηση/Ιδιωτικό Παράθυρο (Private Browsing/Incognito Window) όταν κάνεις αγορές στα καταστήματα του Pigogo, καθώς αυτός ο τρόπος αποκλείει τα cookies τα οποία είναι απαραίτητα προκειμένου να καταγράφονται οι αγορές σου στο Pigogo.',
  },
  {
    img: bankCard,
    subtitle: 'Αν πληρώνεις με κάρτα ή PayPal, τότε δώσε προσοχή σε αυτό.',
    description:
      'Για να γίνει η καταγραφή των αγορών σε περιπτώσεις online πληρωμών (όπως κάρτα, PayPal κ.ο.κ.), είναι σημαντικό μετά την πληρωμή σου, να επιστρέψεις στη σελίδα ολοκλήρωσης της παραγγελίας στο site του καταστήματος. Αν και στις περισσότερες περιπτώσεις τραπεζών η ανακατεύθυνση προς το site του καταστήματος γίνεται αυτόματα, σε κάποιες χρειάζεται να το επιλέξεις.',
  },
  {
    img: click,
    subtitle: '…Και φυσικά μην ξεχνάς το πιο σημαντικό: το ΚΛΙΚ!',
    description:
      'Πάντα να κάνεις κλικ στο «Μεταφορά στο κατάστημα» (από το site ή το app) ή κλικ στο «Θέλω επιστροφή χρημάτων» (από το Pigogo ALERT!). Αμέσως μετά από αυτό το κλικ, να ολοκληρώνεις απευθείας την αγορά σου χωρίς να μεσολαβεί αλλαγή συσκευής/browser ή κάποιο άλλο κλικ από οποιαδήποτε άλλη ιστοσελίδα (εκτός του Pigogo) προς το κατάστημα.',
  },
  {
    img: target,
    subtitle: 'Ξεκίνα να απολαμβάνεις τις αγορές σου.',
    description:
      'Αν πραγματοποίησες κάποια αγορά η οποία δεν εμφανίστηκε στη σελίδα των Αγορών σου, μην ανησυχείς καθόλου! Μπορείς να ζητήσεις την επιστροφή σου, δημιουργώντας ένα Αίτημα από την ενότητα «Αιτήματα» του λογαριασμού σου!',
  },
];

const FlipCards = () => {
  return (
    <CustomContainer paddingBottom={10}>
      <Box display={'flex'} flexWrap={'wrap'} gap={2} justifyContent={'center'}>
        {flipCardInfo.map((item, index) => {
          return (
            <Box key={index} width={'330px'} style={{ cursor: 'pointer' }}>
              <PigogoFlipCard
                img={item.img}
                subTitle={item.subtitle}
                description={item.description}
                title={index < 5 ? `tip ${index + 1}` : 'έτοιμος'}
              />
            </Box>
          );
        })}
      </Box>
    </CustomContainer>
  );
};

export default FlipCards;
