import { LinearProgress, linearProgressClasses, LinearProgressProps, styled } from '@mui/material';
import React, { FC } from 'react';

const StyleLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#E9688A' : '#EAECEE',
  },
}));

interface Props {
  linearProps?: LinearProgressProps;
}

const PigogoLineProgress: FC<Props> = ({ linearProps }) => {
  return <StyleLinearProgress {...linearProps} />;
};

export default PigogoLineProgress;
