import { pigogoApi } from '../Api';
import { Favourite } from '../../../models/Favourite';

interface Post {
  id: number;
  shop_id?: number;
  user_id?: number;
  notifications?: boolean;
}

type PostResp = Post;

const favouritesApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    // add shop to favourites
    postFavourite: builder.mutation<unknown, { shop_id: number; notifications: number }>({
      query: ({ shop_id, notifications }) => ({
        url: '/users/self/favourites',
        method: 'POST',
        body: { shop_id, notifications },
      }),
      invalidatesTags: ['favourites'],
    }),
    // remove shop from favourites and update shop and favourites in cache
    deleteFavourite: builder.mutation<unknown, number>({
      query: (shopId) => ({
        url: `/users/self/favouriteShops/${shopId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['favourites'],
    }),

    // revert remove shop from favourites by adding it back
    revertFavourite: builder.mutation<PostResp, { shop_id: number; notifications: number }>({
      query: ({ shop_id, notifications }) => ({
        url: '/users/self/favourites',
        method: 'POST',
        body: { shop_id, notifications },
      }),
      invalidatesTags: ['shops'],
    }),
    // remove shop from favourites and update shop in cache
    removeFavourite: builder.mutation<unknown, number>({
      query: (favouriteId) => ({
        url: `/users/self/favourites/${favouriteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['shops'],
    }),
    // post shop from favourites and update searchStores
    postSearchFavourite: builder.mutation<unknown, { shop_id: number; notifications: number }>({
      query: ({ shop_id, notifications }) => ({
        url: '/users/self/favourites',
        method: 'POST',
        body: { shop_id, notifications },
      }),
      invalidatesTags: ['favourites'],
    }),
    // remove shop from favourites and update searchStores
    deleteSearchFavourite: builder.mutation<unknown, number>({
      query: (shopId) => ({
        url: `/users/self/favouriteShops/${shopId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['favourites'],
    }),

    // update favourite notifications
    changeNotifications: builder.mutation<Favourite, { favouriteId: number; notifications: boolean }>({
      query: ({ favouriteId, notifications }) => ({
        url: `/users/self/favourites/${favouriteId}`,
        method: 'PATCH',
        body: { notifications },
      }),
    }),
  }),
});

export const {
  usePostSearchFavouriteMutation,
  useDeleteSearchFavouriteMutation,
  usePostFavouriteMutation,
  useRevertFavouriteMutation,
  useDeleteFavouriteMutation,
  useRemoveFavouriteMutation,
  useChangeNotificationsMutation,
} = favouritesApi;
