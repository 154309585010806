import React, { FC } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as SearchIllustration } from '../../assets/svgs/illustrations/empty/search_positive.svg';
import { theme } from 'components';
import CustomContainer from '../CustomContainer';

const NoResults: FC = () => {
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <CustomContainer
      gap={5}
      display="flex"
      alignItems={'center'}
      justifyContent="center"
      flexDirection="column"
      pt={xsOnly ? 0 : 5}
      pb={xsOnly ? 4 : 10}
    >
      <Box display="flex" alignItems={'center'} justifyContent="center" gap={3} flexDirection="column">
        <Box>
          <Typography variant={xsOnly ? 'body2' : 'h4'} component="h2" textAlign="center" px={4} color={'#313D53'}>
            Δυστυχώς δεν βρήκαμε αυτό που ψάχνεις.
          </Typography>
        </Box>
        <Box maxWidth={'576px'} textAlign={'center'}>
          <Typography color={'#1D2532'} variant={xsOnly ? 'subtitle1SmallR' : 'mediumM'} component="p">
            Έλεγξε μήπως έχεις κάνει κάποιο ορθογραφικό λάθος ή δοκίμασε ξανά με νέα αναζήτηση.
          </Typography>
        </Box>
      </Box>
      <SearchIllustration />
    </CustomContainer>
  );
};

export default NoResults;
