import { IconButton } from '@mui/material';
import React from 'react';
import { Dot, DotUnchecked } from '../assets';
const PigogoCustomDot = ({ onClick, active }: { onClick?: any; active?: boolean }) => {
  return active ? (
    <IconButton
      size="small"
      sx={{ bottom: -56, padding: '0 3px', backgroundColor: '#FFFFFF' }}
      disableRipple
      onClick={() => onClick()}
    >
      <Dot />
    </IconButton>
  ) : (
    <IconButton
      size="small"
      sx={{ bottom: -56, padding: '0 3px', backgroundColor: '#FFFFFF' }}
      disableRipple
      onClick={() => onClick()}
    >
      <DotUnchecked />
    </IconButton>
  );
};

export default PigogoCustomDot;
