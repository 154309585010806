import { pigogoApi } from '../Api';

const tellAFriendApi = pigogoApi.injectEndpoints({
  endpoints: (builder) => ({
    referral: builder.query<{ referral_link: string }, void>({
      query: () => ({
        url: '/users/actions/send-tell-a-friend/referral-link',
      }),
      providesTags: ['tellAFriend'],
    }),
  }),
});

export const { useReferralQuery } = tellAFriendApi;
