import { styled, TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

const StyledTextField = styled(TextField)(({ error, theme }) => ({
  '&': {
    width: '100%',
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    paddingLeft: '4px',
    paddingRight: '4px',
    top: '0',
    left: '6px',
    color: error ? '#F75676' : theme.palette.primary.main,
    backgroundColor: '#ffffff',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    paddingLeft: '4px',
    paddingRight: '4px',
    top: 0,
    color: error ? '#F75676' : theme.palette.primary.main,
    backgroundColor: '#ffffff',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    color: error ? '#F75676' : theme.palette.primary.main,
    WebkitTextFillColor: error ? '#F75676 !important' : theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '16px',
    padding: '24px',

    '&.Mui-focused fieldset': {
      paddingLeft: '18px',
      border: error ? '1px solid #F75676' : '1px solid #313D53',
    },
    fieldset: {
      paddingLeft: '18px',
      border: error ? '1px solid #F75676' : '1px solid #838B98',

      fontSize: '14px',
      fontWeight: 600,
      span: {
        padding: '0',
      },
    },
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    margin: '8px 24px 0 24px',
    color: error ? '#F75676' : '#838B98',
  },
}));

const useStyles = makeStyles(() => ({
  textarea: {
    resize: 'vertical',
  },
}));

const inputStyle = {
  borderRadius: '0px',
  WebkitTextFillColor: '#313D53',
  WebkitBoxShadow: '0 0 0 1000px white inset',
};

interface Props {
  label?: string;
  inputProps?: TextFieldProps;
  value: string;
  bold?: boolean;
  setValue?: (value: string) => void;
  size?: 'small' | 'medium';
  placeholder?: string;
  helperText?: string;
  error?: boolean;
}

const PigogoTextArea: FC<Props> = ({ label, value, setValue, placeholder, size, inputProps, helperText, error }) => {
  const classes = useStyles();
  return (
    <form style={{ width: '100%' }}>
      <StyledTextField
        {...inputProps}
        helperText={helperText}
        placeholder={placeholder}
        size={size}
        error={error}
        label={label}
        value={value}
        onChange={
          setValue
            ? (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue(e.target.value)
            : undefined
        }
        variant="outlined"
        InputProps={{
          rows: 4,
          multiline: true,
          inputComponent: 'textarea',
        }}
        inputProps={{
          className: classes.textarea,
          style: error ? undefined : inputStyle,
        }}
      />
    </form>
  );
};

export default PigogoTextArea;
