import { Box } from '@mui/material';
import { ReactComponent as ChevronRight } from '../../../../assets/svgs/chevronRightColor.svg';

const chevronICon = {
  size: '24px',
};
function SaverArrowRight({ color }) {
  return (
    <Box
      sx={{
        width: chevronICon.size,
        height: chevronICon.size,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        color: color,
      }}
    >
      <ChevronRight />
    </Box>
  );
}

export default SaverArrowRight;
