import React, { FC } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { Payment } from '../../../models/Payment';
import PaymentsMobileRow from './PaymentsMobileRow';

interface Props {
  payments?: Payment[];
  isLoading?: boolean;
}

const PaymentsTableMobile: FC<Props> = ({ payments, isLoading }) => {
  return (
    <TableContainer>
      <Table aria-label="payments table">
        <TableBody>
          {isLoading && [1, 1, 1, 1].map((item, index) => <PaymentsMobileRow key={index} isLoading />)}
          {!isLoading && payments?.map((row, index) => <PaymentsMobileRow key={index} row={row} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentsTableMobile;
